.cms-page-view {

  .page-main {
    margin: 9rem auto 13rem;
    max-width: 81rem;

    .page-title-wrapper {
      padding: 0;

      .page-title {
        margin-bottom: 0;
      }
    }

    .column.main {
      margin: 2.75rem auto 0;
      max-width: 84.7rem;

      [data-content-type='row'] {

        &[data-appearance='full-width'] {
          display: none !important;
        }

        [data-element='inner'] {
          padding: 0;
        }

        [data-content-type='heading'] {
          margin: 2.4rem 0;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {

    .page-main {
      padding: 4rem 0;
      margin: 0;

      .page-title-wrapper {
        padding: 0;
        text-align: center;
      }

      .column.main {
        margin: 2.75rem auto 1rem;

        [data-content-type='row'] {
          padding: 0.2rem 3.6rem;

          .main-section {
            text-align: center;

            [data-content-type='text'] {
              font-size: 1.8rem;
              line-height: 1.3;

              p {
                margin-bottom: 1.8rem;
              }
            }
          }

          &[data-appearance='full-width'] {
            display: flex !important;
            padding-top: 0;
            padding-right: 0;

            [data-content-type='divider'] {
              display: flex;

              hr {
                margin: 2rem 0;
              }
            }
          }

          [data-content-type='heading'] {
            text-align: left;
            margin: 2rem 0 1rem;
          }

          [data-content-type='text'] {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
