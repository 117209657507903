.modals-wrapper {

  .modal-header {

    .action-close {
      box-sizing: content-box;
      width: $btn-close-width;
      height: $btn-close-height;
      padding: $btn-close-padding-y $btn-close-padding-x;
      color: $btn-close-color;
      background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat; // include transparent for button elements
      border: 0; // for button elements

      @include border-radius();

      opacity: $btn-close-opacity;

      &::before {
        content: unset;
      }

      > span {
        display: none;
      }
    }
  }

  footer {
    text-align: right !important;
  }
}
