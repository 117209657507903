// Global product styles

.box-tocart {
  display: flex;

  .control {
    position: relative;

    &:focus-within .qty {
      color: $input-focus-color;
      background-color: $input-focus-bg;
      border-color: $input-focus-border-color;
    }
  }

  .btn-qty {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    &[data-direction='minus'] {
      left: 0;

      &:disabled {
        border: none;
      }
    }

    &[data-direction='add'] {
      right: 0;

      &:disabled {
        border: none;
      }
    }

    &:focus-visible {
      opacity: 0.7;
    }
  }

  .field.qty {
    flex-grow: 1;
    margin-right: 2rem;

    > .control {
      height: 5rem;
    }
  }

  input.qty {
    text-align: center;
    font-size: 1.5rem;
  }

  .fieldset {
    margin-bottom: 0;
  }
}

.product-item-inner {

  .btn-out-of-stock {
    margin-bottom: 0.7rem;

    .action.tocart {
      text-transform: none;
    }
  }

  .actions-primary,
  .action.tocart {
    position: relative;
    z-index: 0;
    overflow: hidden;
    height: 6rem;

    span {
      display: block;
      transform: translateY(0);
      transition: $transition-base;
    }

    .spinner-border {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      border-width: 2px;
    }

    img:not(.wishlist-icon) {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      transform: translateY(50px);
      transition: $transition-base;
      filter: invert(1);
    }

    &:focus {

      img {
        filter: invert(0);
      }

      .spinner-border {
        filter: invert(1);
      }
    }

    &.adding {
      pointer-events: none;
    }

    &.added,
    &.product-in-cart {
      pointer-events: all;
      background-color: $primary-hover;
      border-color: $primary-hover;

      span {
        transform: translateY(-50px);
      }

      img {
        transform: translateY(0);
        filter: invert(0);
      }
    }
  }
}
