.sensei-recommendations {
  position: relative;

  @include media-breakpoint-up(lg) {

    &.sensei-recommendations {
      margin-bottom: 7rem;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -3.6rem;
    width: 100%;
    height: 0.1rem;
    background-color: rgba(#707070, 0.15);
  }

  .slick-track,
  .slick-slide,
  .product-item,
  .product-item-info {
    height: 100%;
  }

  .slick-list > div {
    margin-left: 0;
  }

  .slick-slide > div {
    height: 100%;

    @include media-breakpoint-up(lg) {
      height: 96%;
    }
  }

  .products-grid {

    .block-title {
      margin: 3.6rem auto 2.7rem;
      text-align: center;

      @include media-breakpoint-up(lg) {
        margin-bottom: 5rem;
      }
    }

    .block-related-heading {
      font-family: $headings-font-family;
      font-size: 1.8rem;
      line-height: 1.11;
      font-weight: 700;
    }

    .product-items {
      display: flex;
      justify-content: space-around;
      max-width: 1178px;

      @include media-breakpoint-up(lg) {

        &.product-items {
          margin: auto;
        }
      }

      .product-item.product-item {
        max-width: 25rem;
        width: 100%;
        margin-left: 0;

        @include media-breakpoint-up(lg) {
          max-width: 100%;
        }

        .product-item-info {
          position: relative;
          display: flex;
          flex-direction: column;

          .product-image-container {
            width: 19.4rem;

            .product-image-wrapper {
              padding-bottom: 100%;
            }
          }

          .product-item-badge {
            position: absolute;
          }

          .product-item-details {
            margin-top: auto;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {

    .product-items {

      .product-item {

        &::after {
          content: unset !important;
        }
      }
    }

    .slick-initialized.slick-standard {
      width: 100vw;
      margin-left: calc((100vw - 100%) / -2);

      .slick-prev:not(.primary),
      .slick-next:not(.primary) {
        background: rgba($white, 0.36);
      }

      .slick-arrow {
        width: 4.7rem;
        top: 43%;
        bottom: -29%;
        height: calc(100% - 5.1rem);
      }

      .slick-prev,
      .slick-next {
        width: 24%;

        &::before {
          position: absolute;
          font-size: 1.5rem;
        }
      }

      .slick-prev {
        left: 0;

        &::before {
          left: 1.5rem;
        }
      }

      .slick-next {
        right: 0;

        &::before {
          right: 1.5rem;
        }
      }

      .slick-dots {
        margin-top: 0;
        margin-left: 0;
      }
    }
  }
}
