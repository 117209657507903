.product-items {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;

  .product-item {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    margin-bottom: 2.5rem;
    max-width: 25rem;

    @include media-breakpoint-down(md) {
      width: 50%;
      padding: 2.5rem 1.15rem 2rem;
      margin-left: 0;
      margin-bottom: 0;
      border-bottom: 2px solid #d3cfcc;

      &:nth-child(odd) {
        border-right: 2px solid #d3cfcc;
      }

      &:first-child,
      &:nth-last-child(2):nth-child(odd),
      &:last-of-type:nth-of-type(odd) {
        border-right: 0;

        &::after {
          border-right-width: 2px;
          border-right-style: solid;
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: -1;
        }
      }

      &:nth-last-child(2):nth-child(odd),
      &:last-of-type:nth-of-type(odd) {

        &::after {
          border-image:
            linear-gradient(
              to bottom,
              #d3cfcc,
              rgba(0, 0, 0, 0)
            )
            1 100%;
        }
      }

      &:first-child::after {
        border-image:
          linear-gradient(
            to top,
            #d3cfcc,
            rgba(0, 0, 0, 0)
          )
          1 100%;
      }

      &:last-child:first-child::after {
        background:
          linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(211, 207, 204, 1) 25%,
            rgba(211, 207, 204, 1) 75%,
            rgba(255, 255, 255, 0) 100%
          );
        width: 1px;
        right: 0;
        left: 100%;
        border: none;
      }

      &:last-child,
      &:nth-last-child(2):nth-child(odd) {
        border-bottom: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;

      @include media-breakpoint-down(xl) {
        content: none;
      }
    }

    &:hover::before {
      border: 2px solid #ededed;
    }

    .product-item-info {
      position: relative;
      padding: 1rem 1.9rem;
      width: auto;
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      @include media-breakpoint-down(xl) {
        padding: 1rem;
      }

      @include media-breakpoint-down(lg) {
        padding: 2rem 1.1rem 1.5rem;
      }
    }

    @include media-breakpoint-down(xl) {

      &:nth-last-child(n+2):nth-last-child(-n+2):first-child,
      &:first-child + li:last-child {

        &::after {
          background:
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(211, 207, 204, 1) 25%,
              rgba(211, 207, 204, 1) 75%,
              rgba(255, 255, 255, 0) 100%
            );
          width: 1px;
          left: 0;
          right: 100%;
          border: none;
          content: '';
          position: absolute;
          height: 100%;
        }
      }
    }
  }

  .product-image-container {
    position: relative;
    margin: 1.5rem auto 0;

    @include media-breakpoint-down(md) {
      position: initial;
      margin-top: 0;
      width: 100%;

      .product-item-photo {
        max-width: 12.8rem;
        display: block;
        margin: auto;
      }
    }

    .actions-secondary {
      position: absolute;
      right: 1rem;
      bottom: 2rem;
      z-index: 1;

      .action.towishlist,
      .action.in-wishlist {

        &:hover {
          opacity: 0.7;
        }
      }

      @include media-breakpoint-down(md) {
        bottom: 1.5rem;

        .action.towishlist {
          padding: 0.88rem;
        }
      }
    }

    .product-item-badge {
      text-align: center;
      position: absolute;
      top: 0;
      z-index: 1;

      + .deal,
      + .clearance,
      + .new {
        top: 3rem;
      }

      &.no-gwp {
        top: 0;
      }

      @include media-breakpoint-down(md) {
        top: 2rem;

        &.deal,
        &.clearance,
        &.new {
          top: 5rem;
        }

        &.no-gwp {
          top: 2rem;
        }
      }

      span {
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }

    .product-label {
      background-color: $secondary;
      color: $white;
      font-size: 1.2rem;
      text-transform: uppercase;
      z-index: 1;
      border-radius: 2px;
      padding: 0.2rem 2.32rem;
      border: 1px solid $secondary;

      @include media-breakpoint-down(md) {
        padding: 0.2rem 1.32rem;
      }
    }

    .deal {
      background-color: $secondary;
      width: 6.5rem;
      border-radius: 0.2rem;

      span {
        color: $white;
      }
    }

    .clearance {
      background-color: $white;
      border: 1px solid $secondary;
      width: 10.2rem;
      border-radius: 0.2rem;

      span {
        color: $secondary;
        font-weight: $headings-font-weight;
      }
    }

    .new {
      right: 0;

      @include media-breakpoint-down(xl) {
        right: 0.5rem;
        top: 2.25rem;
      }
    }

    .unavailable {
      left: 0;
      right: 0;
      top: -0.6rem;

      @include media-breakpoint-down(xl) {
        top: 0;
      }

      span {
        color: $dark;
      }
    }
  }

  .product-item-details {
    margin-top: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: $headings-font-family;

    .product-item-sku {
      font-size: 1.3rem;
    }

    .product-item-brand {
      font-size: 1.3rem;
      font-weight: $headings-font-weight;
      text-transform: uppercase;
      margin-bottom: 0;

      a {
        text-decoration: none;
        z-index: 2;
        position: -webkit-sticky;
        position: sticky;
      }

      a:hover {
        text-decoration: underline;
      }

      @include media-breakpoint-down(xl) {
        font-size: 1.2rem;
      }
    }

    .product-item-name {
      margin: 0 0 auto;

      @include media-breakpoint-down(xl) {
        font-size: 1.3rem;
      }

      .product-item-link {

        &:hover {
          text-decoration: none;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          @include media-breakpoint-down(xl) {
            content: none;
          }
        }
      }
    }

    .ingredient-icons {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;

      li {
        position: relative;
        z-index: 1;
        margin-bottom: 0.3rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 0.5rem;
        }

        &:not(:last-child) {
          margin-right: 0.44rem;

          @include media-breakpoint-only(xl) {
            margin-right: 1.24rem;
          }

          @include media-breakpoint-only(md) {
            margin-right: 1.24rem;
          }
        }
      }
    }

    .product-item-offer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0;
      font-size: 1.2rem;
      text-align: center;
      font-family: $font-family-sans-serif;

      > div {
        flex: 0 0 auto;
        width: 49%;
        background-color: $light;

        @include media-breakpoint-down(lg) {
          width: auto;
        }

        span {
          font-weight: $headings-font-weight;
        }
      }

      .product-item-discount {
        padding: 0.5rem 0;
        font-weight: 700;
        color: $secondary;

        @include media-breakpoint-down(lg) {
          margin-bottom: 0.4rem;
          padding: 0.4rem 1.25rem 0.2rem 1.15rem;
        }
      }

      .product-item-best-before-date {
        padding: 0.5rem;

        @include media-breakpoint-down(lg) {
          padding: 0.4rem 1.15rem 0.2rem 1.05rem;
        }
      }
    }

    .amshopby-option-link {
      display: none;
    }

    .price-section,
    .price-container-wrapper {
      width: 100%;
    }

    .price-box,
    .price-container-wrapper {
      clear: both;
      display: flex;
      flex-wrap: wrap;
      margin: 0.25rem 0 0;
      align-items: center;

      .price-container {
        line-height: 1;
      }

      .old-price {
        text-decoration: none;
        margin-left: auto;

        .price {
          font-weight: normal;
          text-decoration: line-through;
        }
      }

      .price-wrapper {

        .price {
          font-size: 2.4rem;

          @include media-breakpoint-down(xl) {
            font-size: 1.6rem;
          }
        }

        &.regular-price {
          display: flex;
          align-items: center;

          span {
            font-weight: $headings-font-weight;
          }
        }
      }

      em {
        font-size: 1rem;
        font-weight: $font-weight-normal;
        font-style: normal;
        margin-left: 0.4rem;

        @include media-breakpoint-down(xl) {
          display: none;
        }
      }

      .special-price {
        display: flex;
        align-items: center;
        color: $secondary;
      }

      .rrp-price {
        flex-basis: 100%;
        font-size: 1.5rem;
        font-family: $headings-font-family;

        .price {
          font-weight: 400;
        }

        .gst {
          color: $dark;
          font-style: normal;
        }
      }
    }

    .product-item-inner {

      .btn-out-of-stock {
        margin-bottom: 0.7rem;

        .action.tocart {
          text-transform: none;
        }
      }

      .actions-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .product-item-actions {

        @include media-breakpoint-up(md) {
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }

      .action.tocart {
        width: 100%;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 2.8;
        border-radius: $border-radius-sm;
        height: 4rem;

        @include media-breakpoint-down(md) {
          border-radius: 0.2rem;
          padding: 0;
        }
      }

      .action.tocart[title='Notify Me'],
      .action.tocart[title='Add'],
      .action.tocart[title='Adding...'],
      .action.tocart[title='Added'] {

        @include media-breakpoint-down(md) {
          width: 76.5%;
        }
      }

      .actions-primary {
        height: 100%;
        width: 100%;
      }

      [data-role='add-to-links'] {
        margin: 0 1rem;

        .action.towishlist {
          display: flex;
          align-items: center;
          justify-content: center;

          .wishlist-icon {
            width: 2.4rem;
          }
        }
      }

      .control.qty {
        display: flex;
      }
    }

    .stock .notified {
      border: 2px solid $primary;
      background-color: $primary;
      color: $white;

      &:hover {
        color: $dark;
        background-color: $primary-hover;
        border-color: $primary-hover;
      }
    }
  }
}
