.page-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: $white;
  margin-bottom: 0;

  &::before {
    box-shadow: inset 0 5px 6px rgba($black, 0.16);
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 1.2rem;
    pointer-events: none;
  }

  .header-section-wrapper {
    background-color: $white;
    position: relative;

    .header.content {
      display: flex;
      align-items: center;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      position: initial;

      @include media-breakpoint-down(xl) {
        max-width: 100%;
      }

      @include media-breakpoint-down(lg) {
        height: 70px;
        justify-content: space-between;
        padding-left: 1.4rem;
        padding-right: 1.4rem;

        .nav-toggle {
          position: static;
          padding: 0 1.2rem 0 0;
          height: 3.2rem;
          border: none;

          img {
            width: 3.2rem;
            height: 3.2rem;
          }
        }

        .search-toggle {
          width: 3.2rem;
          height: 3.2rem;
          margin-left: 0.25rem;
          border: none;
          display: block;

          @include media-breakpoint-down(lg) {

            &.hide {
              display: none;
            }
          }

          @include media-breakpoint-only(xs) {
            margin-left: 0;
          }
        }

        .logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          &.b2c {
            margin-left: 0;
          }

          img {
            width: 9.8rem;
            height: 4.9rem;
          }
        }

        .header-links {

          .header.links {
            padding-left: 0;

            @include media-breakpoint-only(xs) {
              margin-right: -0.5rem;
            }

            li.welcome {
              display: none;
            }

            li.header-link-account,
            li.header-link.wishlist,
            li.minicart-wrapper-mobile {

              img {
                width: 3.2rem;
                height: 3.2rem;
              }
            }
          }
        }
      }
    }
  }

  .lac-notification-sticky ~ .header-section-wrapper {

    .amsearch-wrapper-block {

      @include media-breakpoint-down(md) {
        top: 11.3rem;
      }

      @include media-breakpoint-only(md) {
        top: 8.7rem;
      }
    }
  }

  .amsearch-wrapper-block {
    position: static;

    &::before {
      display: none;
    }

    @include media-breakpoint-down(lg) {
      position: fixed;
      top: 3rem;
      left: 0;
      margin: 0;
      z-index: 99;
      background: $white;
      padding: 0;
      display: block;
      width: 100%;

      &.hide {
        display: none;
      }
    }

    .amsearch-form-block {
      position: initial;

      .amsearch-input-wrapper {
        width: 50rem;
        height: 5.3rem;

        @include media-breakpoint-up(lg) {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);
        }

        @include media-breakpoint-only(lg) {
          max-width: 350px;
          margin-left: -5px;
        }

        @include media-breakpoint-down(lg) {
          width: 100%;
          height: 7.1rem;
        }

        .amsearch-input {
          width: 100%;
          height: 100%;
          border-radius: 40px;
          border: 2px solid #c7c7c7;
          padding-left: 3rem;

          @include media-breakpoint-only(lg) {
            padding-left: 2rem;
            font-size: 1.4rem;
          }

          &:focus-visible {
            outline-color: $gray-750;
          }

          @include media-breakpoint-down(lg) {
            border: none;
            border-radius: 0;
            padding-left: 2rem;

            &:focus-visible {
              outline: none;
            }
          }
        }

        .amsearch-loader-block {
          display: none !important;
        }

        .amsearch-button {

          &.-loupe {
            background-image: none;
            right: 3rem;
            top: 0;
            width: auto;
            height: auto;
            opacity: 1;

            @include media-breakpoint-only(lg) {
              right: 1.3rem;
            }

            svg {

              path {
                fill: $gray-750;

                @include media-breakpoint-down(sm) {
                  fill: $white;
                }
              }
            }

            @include media-breakpoint-down(sm) {
              right: 0;
              background-color: $secondary;
              padding: 0 1.95rem;
              opacity: 1;

              svg {
                width: 3.2rem;
                height: 3.2rem;
              }
            }
          }
        }

        &.-typed {

          .amsearch-button {

            &.-close {
              right: 6.5rem;
              background-image: none;
              color: $gray-700;
              font-size: 1.3rem;

              @include media-breakpoint-down(sm) {
                right: 8rem;
              }
            }

            &.-loupe {
              left: auto;
              background-image: none;
            }
          }
        }
      }

      &.-opened {
        width: 100% !important;

        .amsearch-input-wrapper {

          .amsearch-button {

            &.-loupe {

              svg {

                path {
                  fill: $gray-750;

                  @include media-breakpoint-down(sm) {
                    fill: $white;
                  }
                }
              }
            }
          }
        }
      }

      .amsearch-result-section {
        margin-top: 0;
        border-radius: 0;
      }
    }
  }

  .logo {
    margin-bottom: 0;
    margin-left: 0;
  }

  .header-links {
    margin-left: auto;

    .header.links {
      list-style: none;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      flex-shrink: 0;

      @include media-breakpoint-up(lg) {
        margin-right: 1rem;
      }

      > li {
        float: none;
        margin-left: 1.2rem;
        margin-top: 0;
        margin-bottom: 0;

        @include media-breakpoint-only(xs) {
          margin-left: 0.7rem;
        }

        .header-link {
          position: relative;
          transition: $transition-fade;
          display: flex;
          align-items: center;
          text-decoration: none;

          &:hover {
            opacity: 0.7;
          }
        }

        > .showAccount span {
          margin-right: 0.8rem;
          font-weight: $headings-font-weight;
          font-size: 1.2rem; // Overriding design because the font deforms at 11px

          &:hover {
            text-decoration: underline;
          }
        }

        &.welcome {
          max-width: 190px;
          text-align: right;

          @include media-breakpoint-only(lg) {
            max-width: 148px;
          }

          p {
            margin-bottom: 0;
            font-size: 1.1rem;
            line-height: 1.3rem;
          }

          span {
            font-weight: 700;
          }
        }

        .counter.qty:not(:empty) {
          position: absolute;
          top: 50%;
          right: -4px;
          min-width: 20px;
          height: 20px;
          margin: -0.8rem 0 0;
          padding: 0 0.3rem;
          border-radius: 10px;
          transform: translateY(-50%);
          background-color: $secondary;
          color: $white;
          line-height: 19px;
          text-align: center;
          font-weight: 700;
          font-size: 1.2rem;
        }

        &.wishlist {

          .counter {
            display: none;
          }
        }

        &.minicart-wrapper,
        &.minicart-wrapper-mobile {

          .counter.qty:not(:empty) {
            top: unset;
            bottom: 0;
            right: 4px;
            min-width: 1.9rem;
            height: 1.9rem;

            @include media-breakpoint-down(lg) {
              bottom: -6px;

              .loading-mask {

                .loader-container .spinner-lg {
                  width: 0.5rem;
                  height: 0.5rem;
                }
              }
            }
          }
        }

        &.customer-welcome {

          .customer-menu {
            display: none;
            position: relative;

            .account-links {
              display: flex;
              flex-direction: column;
              list-style: none;
              position: absolute;
              border: 1px solid $gray-500;
              z-index: 9;
              min-width: 16rem;
              background: $white;
              top: 0;
              right: 0;
              padding: 0;
              box-shadow: 0 3px 3px rgba($black, 0.15);
              border-radius: $border-radius;

              @include media-breakpoint-down(lg) {
                right: -5px;
              }

              &::before {
                content: '';
                display: block;
                height: 0;
                position: absolute;
                width: 0;
                border: 6px solid;
                border-color: transparent transparent $white transparent;
                z-index: 99;
                right: 1.3rem;
                top: -1.2rem;
              }

              &::after {
                content: '';
                display: block;
                height: 0;
                position: absolute;
                width: 0;
                border: 7px solid;
                border-color: transparent transparent $gray-500 transparent;
                z-index: 98;
                right: 1.2rem;
                top: -1.4rem;
              }

              li {
                margin: 0;

                a {
                  text-decoration: none;
                  display: block;
                  padding: 1rem 1.5rem;
                  font-size: 1.3rem;

                  &:hover {
                    background-color: #e8e8e8;
                  }
                }

                &:first-child a {
                  border-radius: $border-radius $border-radius 0 0;
                }

                &:last-child a {
                  border-radius: 0 0 $border-radius $border-radius;
                }
              }
            }
          }

          &.active,
          &:hover {

            .customer-menu {
              display: block;
            }
          }
        }
      }
    }
  }
}

.section-header .customer-section {
  display: none;
}

@include media-breakpoint-down(lg) {

  .uhp-header-cta-wrapper {
    height: 3rem;
  }

  .header.content {

    .nav-toggle {

      &::after {
        content: none;
      }
    }
  }

  .nav-open .nav-sections {
    box-shadow: none;
  }
}

.uhp-header-cta-wrapper {

  .header-cta-wrapper {
    background-color: $primary;
    padding: 0.6rem $spacer;
    text-align: center;
    color: $white;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    z-index: auto;

    p {
      margin-bottom: 0;
    }
  }
}

.nav-sections-item-content {
  flex-direction: column;
}

.header-menu-cta-wrapper.header-menu-cta-wrapper {
  background-color: $secondary;
  min-width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  font-weight: $font-weight-normal;

  ul {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    li.level0 {
      display: flex;
      align-items: stretch;
      height: 5rem;
      margin: 0;

      &.parent > .submenu {
        display: none;
        top: 100%;
        flex-direction: column;
        min-width: 140px;

        .level1 {
          padding: 0;

          [href] {
            text-decoration: none;
            color: $dark;
            font-size: 1.3rem;
            line-height: 2.7;
          }
        }
      }

      &:hover {
        background-color: $dark;

        &.parent > .submenu {
          display: flex;
        }
      }

      > a {
        display: flex;
        align-items: center;
        color: $white;
        padding: 0 2.8rem;
        text-transform: uppercase;
        text-decoration: none;
        line-height: 1;
        font-family: $headings-font-family;
        font-weight: 600;
        font-size: 1.5rem;
      }

      ul.submenu {
        background-color: $white;
        text-transform: capitalize;
        border: 0;
        min-width: initial;
        width: 100%;

        li.level1 {
          list-style: none none;
          text-align: left;
          color: $white;
          font-weight: $font-weight-normal;
          padding-left: 2rem;

          &:hover {
            background-color: $white;
          }

          a {
            color: $white;
          }
        }
      }
    }
  }
}

.customer-welcome,
.customer-section {

  .action.switch {
    border: none;
    background-color: transparent;
    padding: 0;

    &:active {
      box-shadow: none;
    }
  }
}

.section-item-content {

  .customer-menu {
    display: none;

    .account-links {
      display: flex;
      flex-direction: column;
      list-style: none none;

      li > a {
        padding-top: 1.6rem;
        padding-left: 7rem;
        padding-bottom: 1.6rem;
        color: #575757 !important;
      }
    }
  }
}
