[data-content-type='html'] {

  >.slick-standard:not(.slick-initialized) {
    display: none;
  }
}

.slick-initialized.slick-standard {

  .slick-prev::before,
  .slick-next::before,
  .slick-prev::after,
  .slick-next::after {
    font-size: 2.4rem;
    color: $black;
  }

  .slick-prev:hover.slick-disabled,
  .slick-next:hover.slick-disabled,
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0;
  }

  .slick-prev:active,
  .slick-next:active,
  .slick-prev:focus,
  .slick-next:focus,
  .slick-prev:not(.primary),
  .slick-next:not(.primary) {
    background: unset;
  }

  .slick-prev,
  .slick-next {
    width: 2.4rem;
    height: 2.4rem;
    top: 41%;
  }

  .slick-prev {
    left: -9%;
  }

  .slick-next {
    right: -9%;
  }

  .slick-list {
    width: 100%;

    .slick-track {
      display: flex;
      justify-content: center;
      gap: 0.8rem;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }
  }

  .slick-dots {
    margin-top: 1.5rem;

    li {

      button {
        background-color: $input-border-color;
      }
    }

    .slick-active {

      button {
        background-color: $input-placeholder-color;
      }
    }
  }
}
