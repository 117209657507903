.customer-account-login {

  .page-main {
    padding-top: 5rem;
  }

  .offcanvas-body {
    overflow: visible;
  }
}

.customer-account-forgotpassword {

  .page-main {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.form-login {

  h3 {
    font-size: 2rem;
  }

  .action.remind {
    font-size: $font-size-sm;
  }
}

.login-content {
  max-width: 348px;
  margin: 2.9rem auto 2.2rem;
  text-align: center;
  font-family: $headings-font-family;
  font-weight: 700;
  font-size: 1.3rem;

  h4 {
    margin-bottom: 0.5rem;
  }
}

.login-container,
.customer-account-forgotpassword .main {

  .block {
    width: 100%;
    max-width: 500px;
  }
}

.miniaccount-wrapper {

  @include media-breakpoint-down(sm) {

    .offcanvas-header {
      padding: 0 2.5rem;
      height: 5rem;

      .offcanvas-title {
        line-height: 1.125;
      }
    }

    .fieldset {

      .field {

        .label {
          margin-bottom: 0.1rem;
        }

        .control {

          &::before {
            top: 1.4rem;
          }

          .form-control {
            font-size: 1.4rem;
            line-height: 1.43;
          }
        }
      }
    }
  }
}

.lac-notification-sticky {
  position: sticky;
  z-index: 0;
  top: 0 !important;

  .lac-notification {
    display: block !important;

    @include media-breakpoint-down(md) {
      height: 84px;

      .lac-notification-text {
        float: none;
        margin: 0 auto;
        max-width: 300px;
        font-size: 1.4rem;
        line-height: 1.1;
        padding: 2.2rem 0;
      }

      .lac-notification-links {
        float: none;
        padding: 0;

        a {
          font-size: 1.4rem;
          position: absolute;
          top: 5px;
          right: 5px;
        }
      }
    }
  }
}
