.breadcrumb {
  margin-top: $breadcrumb-margin-bottom;
  color: $breadcrumb-divider-color;
  font-size: 1.39rem;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumb-item {
    margin-bottom: 0;

    &:first-child {

      a {
        font-weight: 700;
      }
    }
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}
