.offcanvas {

  .offcanvas-header {
    background-color: $primary;
    color: $white;
    padding-top: 0.6rem;
    padding-bottom: 0.5rem;
  }

  .offcanvas-title {
    font-size: 2.4rem;
  }
}
