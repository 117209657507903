@import '../vendors/bootstrap/functions';
@import '../vendors/bootstrap/mixins';
@import '../vendors/bootstrap/variables';
@import '../variables';
@import '../components/_buttons';

.rendered-form .fieldset {

  .fields {

    .form-control {
      color: $input-placeholder-color;
      padding: 1.4rem 1.5rem 1.4rem 1.8rem;
      border-radius: 0.3rem;
    }

    .fb-text-label,
    .fb-select-label {
      font-size: 1.3rem;
      margin-bottom: 0.25rem;
    }

    .fb-radio,
    .fb-checkbox {

      .radio-group,
      .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1rem;

        label {
          font-size: 1.3rem;
        }
      }

      .amform-groupv2 {
        background: #f8f8f8;
        border: 1px solid $input-border-color;
        border-radius: 3px 50px 50px 3px;
        padding: 1.2rem 0;
        flex: 0 0 49%;

        .form-check-input {
          margin-left: 1.5rem;
          margin-right: 1rem;
        }

        input[type='radio'] {
          position: relative;
          background-image: none;
          background-color: $white;
          border-color: $input-border-color;

          &:checked::after {
            width: 1rem;
            height: 1rem;
            border-radius: 15px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            background-color: $dark;
            content: '';
          }
        }
      }
    }

    .field {

      .input-file {
        border: none;
        padding: 0;
        margin: 0;

        &::-webkit-file-upload-button {
          display: none;
        }

        &::before {
          content: 'Select Files';
          display: inline-block;
          background: $dark;
          color: $white;
          padding: 0.7rem 2.27rem;
          outline: none;
          white-space: nowrap;
          cursor: pointer;
          font-size: 1rem;
          text-transform: uppercase;
          margin-right: 1rem;
          border: 1px solid $dark;
          border-radius: 3px;
          font-weight: $headings-font-weight;
          font-family: $headings-font-family;
          letter-spacing: 0.4px;
        }

        &:hover::before {
          background-color: $gray-750;
          border-color: $gray-750;
        }
      }
    }
  }

  .amcform-toolbar {
    width: 100%;

    .amcform-submit {
      width: 100%;
      margin-top: 3rem;
      letter-spacing: 0.56px;

      @include btn();
      @include button-variant(
        $dark,
        $dark,
        $hover-background: $dark-hover,
        $hover-border: $dark-hover,
        $active-background: $dark-hover,
        $active-border: $dark,
        $disabled-background: $gray-100,
        $disabled-border: $gray-100,
        $disabled-color: $gray-500
      );
    }
  }
}
