.sales-order-history,
.sales-invoice-history {

  .toolbar-products .pages {
    display: block;
  }

  .table-wrapper {
    margin-bottom: 10rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 5rem;
    }

    .table:not(.cart):not(.totals):not(.table-comparison) {

      thead > tr > th {
        border-bottom: none !important;
        text-transform: capitalize;
        font-family: $headings-font-family;

        &.col.id {
          padding-left: 0;
        }

        &.col:not(:last-child) {
          width: 15%;
        }

        &.col:last-child {
          width: auto;
        }
      }

      tbody > tr {

        .col.id,
        .col.actions {
          padding-left: 0;
        }

        .col.actions {
          padding-right: 0;
        }

        @include media-breakpoint-down(sm) {
          border-bottom: 1px solid #e2e2e2;

          td:not(:last-child) {
            padding: 0.5rem 0 0;
          }

          td::before {
            padding-right: 0.4rem;
          }
        }

        &:not(:first-child) {
          margin-top: 2rem;
        }

        > td:first-child {

          @include media-breakpoint-down(sm) {
            padding-top: 0;
          }
        }
      }

      .col.track {
        display: flex;
        flex-direction: column;
      }

      .col.actions {
        display: flex;
        justify-content: space-evenly;

        .action {

          .action-link.disabled {
            pointer-events: none;
            user-select: none;
            opacity: $btn-close-disabled-opacity;
            text-decoration: none;
          }
        }

        .action:not(:last-child) {
          margin-right: 0;
        }

        @include media-breakpoint-down(md) {
          justify-content: flex-start;

          .action {
            flex: 0;
          }

          .action:not(:last-child) {
            margin-right: 2rem;
          }
        }
      }
    }
  }

  .toolbar.toolbar-products {

    @include media-breakpoint-down(sm) {
      margin-bottom: 3rem;
    }
  }
}

.sales-order-view,
.sales-order-print,
.sales-order-invoice,
.sales-order-printinvoice {

  .mark {
    background-color: transparent;
  }
}

.sales-order-print,
.sales-order-printinvoice {

  .order-status {
    display: block;
  }
}

.sales-order-invoice {

  .order-date {
    margin: 0 0 1.8rem;

    .label {
      padding-left: 0;
    }
  }
}
