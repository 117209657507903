/*!
 * Bootstrap v5.2.0 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
/*!
   * Bootstrap  v5.2.0 (https://getbootstrap.com/)
   * Copyright 2011-2022 The Bootstrap Authors
   * Copyright 2011-2022 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root {
  --blue: #0d6efd;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #CE0F69;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #40C5A2;
  --teal: #20c997;
  --cyan: #0dcaf0;
  --white: #fff;
  --gray: #BCBCBC;
  --gray-dark: #343a40;
  --gray-100: #F1F0EF;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #D4CFCC;
  --gray-600: #BCBCBC;
  --gray-700: #837C78;
  --gray-800: #343a40;
  --gray-900: #2D2926;
  --primary: #40C5A2;
  --secondary: #CE0F69;
  --success: #40C5A2;
  --info: #0dcaf0;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #F1F0EF;
  --dark: #2D2926;
  --primary-rgb: 64, 197, 162;
  --secondary-rgb: 206, 15, 105;
  --success-rgb: 64, 197, 162;
  --info-rgb: 13, 202, 240;
  --warning-rgb: 255, 193, 7;
  --danger-rgb: 220, 53, 69;
  --light-rgb: 241, 240, 239;
  --dark-rgb: 45, 41, 38;
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  --body-color-rgb: 45, 41, 38;
  --body-bg-rgb: 255, 255, 255;
  --font-sans-serif: "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --body-font-family: var(--font-sans-serif);
  --body-font-size: 1rem;
  --body-font-weight: 400;
  --body-line-height: 1.6;
  --body-color: #2D2926;
  --body-bg: #fff;
  --border-width: 1px;
  --border-style: solid;
  --border-color: #dee2e6;
  --border-color-translucent: rgba(0, 0, 0, 0.175);
  --border-radius: 0.4rem;
  --border-radius-sm: 0.3rem;
  --border-radius-lg: 50rem;
  --border-radius-xl: 1rem;
  --border-radius-2xl: 2rem;
  --border-radius-pill: 50rem;
  --link-color: #2D2926;
  --link-hover-color: #000;
  --code-color: #CE0F69;
  --highlight-bg: #fff3cd; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--body-font-size);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
  color: var(--body-color);
  text-align: var(--body-text-align);
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 2rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  line-height: 1.25; }

h1, .h1 {
  font-size: 2.4rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.8rem; }

h4, .h4 {
  font-size: 1.6rem; }

h5, .h5 {
  font-size: 1.5rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1.5rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  background-color: var(--highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: var(--link-color);
  text-decoration: underline; }
  a:hover {
    color: var(--link-hover-color);
    text-decoration: none; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--body-bg);
  background-color: var(--body-color);
  border-radius: 0.3rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #D4CFCC;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit; }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 5rem;
  font-weight: 300;
  line-height: 1.25; }

.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.25; }

.display-3 {
  font-size: 4rem;
  font-weight: 300;
  line-height: 1.25; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.25; }

.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.25; }

.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.25; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 2rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -2rem;
  margin-bottom: 2rem;
  font-size: 0.875em;
  color: #BCBCBC; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid var(--border-color);
  border-radius: 0.4rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 1rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #BCBCBC; }

.container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  --gutter-x: 4rem;
  --gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--gutter-x) * .5);
  padding-left: calc(var(--gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .container-sm {
    max-width: 550px; } }

@media (min-width: 768px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .container-sm, .container-md {
    max-width: 740px; } }

@media (min-width: 1024px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .container-sm, .container-md, .container-lg {
    max-width: 1000px; } }

@media (min-width: 1366px) {
  .container, .navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1322px; } }

.row {
  --gutter-x: 4rem;
  --gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gutter-y));
  margin-right: calc(-.5 * var(--gutter-x));
  margin-left: calc(-.5 * var(--gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--gutter-x) * .5);
    padding-left: calc(var(--gutter-x) * .5);
    margin-top: var(--gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --gutter-x: 0; }

.g-0,
.gy-0 {
  --gutter-y: 0; }

.g-1,
.gx-1 {
  --gutter-x: 0.5rem; }

.g-1,
.gy-1 {
  --gutter-y: 0.5rem; }

.g-2,
.gx-2 {
  --gutter-x: 1rem; }

.g-2,
.gy-2 {
  --gutter-y: 1rem; }

.g-3,
.gx-3 {
  --gutter-x: 2rem; }

.g-3,
.gy-3 {
  --gutter-y: 2rem; }

.g-4,
.gx-4 {
  --gutter-x: 4rem; }

.g-4,
.gy-4 {
  --gutter-y: 4rem; }

.g-5,
.gx-5 {
  --gutter-x: 9rem; }

.g-5,
.gy-5 {
  --gutter-y: 9rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --gutter-x: 0.5rem; }
  .g-sm-1,
  .gy-sm-1 {
    --gutter-y: 0.5rem; }
  .g-sm-2,
  .gx-sm-2 {
    --gutter-x: 1rem; }
  .g-sm-2,
  .gy-sm-2 {
    --gutter-y: 1rem; }
  .g-sm-3,
  .gx-sm-3 {
    --gutter-x: 2rem; }
  .g-sm-3,
  .gy-sm-3 {
    --gutter-y: 2rem; }
  .g-sm-4,
  .gx-sm-4 {
    --gutter-x: 4rem; }
  .g-sm-4,
  .gy-sm-4 {
    --gutter-y: 4rem; }
  .g-sm-5,
  .gx-sm-5 {
    --gutter-x: 9rem; }
  .g-sm-5,
  .gy-sm-5 {
    --gutter-y: 9rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --gutter-x: 0.5rem; }
  .g-md-1,
  .gy-md-1 {
    --gutter-y: 0.5rem; }
  .g-md-2,
  .gx-md-2 {
    --gutter-x: 1rem; }
  .g-md-2,
  .gy-md-2 {
    --gutter-y: 1rem; }
  .g-md-3,
  .gx-md-3 {
    --gutter-x: 2rem; }
  .g-md-3,
  .gy-md-3 {
    --gutter-y: 2rem; }
  .g-md-4,
  .gx-md-4 {
    --gutter-x: 4rem; }
  .g-md-4,
  .gy-md-4 {
    --gutter-y: 4rem; }
  .g-md-5,
  .gx-md-5 {
    --gutter-x: 9rem; }
  .g-md-5,
  .gy-md-5 {
    --gutter-y: 9rem; } }

@media (min-width: 1024px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --gutter-x: 0.5rem; }
  .g-lg-1,
  .gy-lg-1 {
    --gutter-y: 0.5rem; }
  .g-lg-2,
  .gx-lg-2 {
    --gutter-x: 1rem; }
  .g-lg-2,
  .gy-lg-2 {
    --gutter-y: 1rem; }
  .g-lg-3,
  .gx-lg-3 {
    --gutter-x: 2rem; }
  .g-lg-3,
  .gy-lg-3 {
    --gutter-y: 2rem; }
  .g-lg-4,
  .gx-lg-4 {
    --gutter-x: 4rem; }
  .g-lg-4,
  .gy-lg-4 {
    --gutter-y: 4rem; }
  .g-lg-5,
  .gx-lg-5 {
    --gutter-x: 9rem; }
  .g-lg-5,
  .gy-lg-5 {
    --gutter-y: 9rem; } }

@media (min-width: 1366px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --gutter-x: 0.5rem; }
  .g-xl-1,
  .gy-xl-1 {
    --gutter-y: 0.5rem; }
  .g-xl-2,
  .gx-xl-2 {
    --gutter-x: 1rem; }
  .g-xl-2,
  .gy-xl-2 {
    --gutter-y: 1rem; }
  .g-xl-3,
  .gx-xl-3 {
    --gutter-x: 2rem; }
  .g-xl-3,
  .gy-xl-3 {
    --gutter-y: 2rem; }
  .g-xl-4,
  .gx-xl-4 {
    --gutter-x: 4rem; }
  .g-xl-4,
  .gy-xl-4 {
    --gutter-y: 4rem; }
  .g-xl-5,
  .gx-xl-5 {
    --gutter-x: 9rem; }
  .g-xl-5,
  .gy-xl-5 {
    --gutter-y: 9rem; } }

.form-label {
  margin-bottom: 0.5rem;
  font-size: 1.38rem;
  font-weight: 700;
  color: #2D2926; }

.col-form-label {
  padding-top: calc(1.55rem + 1px);
  padding-bottom: calc(1.55rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1.22;
  color: #2D2926; }

.col-form-label-lg {
  padding-top: calc(1.94rem + 1px);
  padding-bottom: calc(1.94rem + 1px);
  font-size: 1.4rem; }

.col-form-label-sm {
  padding-top: calc(0.7rem + 1px);
  padding-bottom: calc(0.7rem + 1px);
  font-size: 1.4rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #D4CFCC; }

.form-control {
  display: block;
  width: 100%;
  padding: 1.55rem 1.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.22;
  color: #2D2926;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #D4CFCC;
  appearance: none;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #2D2926;
    background-color: #fff;
    border-color: #2D2926;
    outline: 0;
    box-shadow: none; }
  .form-control::-webkit-date-and-time-value {
    height: 1.22em; }
  .form-control::placeholder {
    color: #837C78;
    opacity: 1; }
  .form-control:disabled {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 1.55rem 1.5rem;
    margin: -1.55rem -1.5rem;
    margin-inline-end: 1.5rem;
    color: #2D2926;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 1.55rem 0;
  margin-bottom: 0;
  line-height: 1.22;
  color: #2D2926;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.22em + 1.4rem + 2px);
  padding: 0.7rem 2.93rem;
  font-size: 1.4rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.7rem 2.93rem;
    margin: -0.7rem -2.93rem;
    margin-inline-end: 2.93rem; }

.form-control-lg {
  min-height: calc(1.22em + 3.88rem + 2px);
  padding: 1.94rem 3.1rem;
  font-size: 1.4rem;
  border-radius: 0.2rem; }
  .form-control-lg::file-selector-button {
    padding: 1.94rem 3.1rem;
    margin: -1.94rem -3.1rem;
    margin-inline-end: 3.1rem; }

textarea.form-control {
  min-height: calc(1.22em + 3.1rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.22em + 1.4rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.22em + 3.88rem + 2px); }

.form-control-color {
  width: 3rem;
  height: calc(1.22em + 3.1rem + 2px);
  padding: 1.55rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important;
    border-radius: 0.2rem; }
  .form-control-color::-webkit-color-swatch {
    border-radius: 0.2rem; }
  .form-control-color.form-control-sm {
    height: calc(1.22em + 1.4rem + 2px); }
  .form-control-color.form-control-lg {
    height: calc(1.22em + 3.88rem + 2px); }

.form-select {
  display: block;
  width: 100%;
  padding: 1.55rem 4.65rem 1.55rem 1.55rem;
  -moz-padding-start: calc(1.55rem - 3px);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.22;
  color: #2D2926;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.55rem center;
  background-size: 16px 12px;
  border: 1px solid #D4CFCC;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  .form-select:focus {
    border-color: #2D2926;
    outline: 0;
    box-shadow: none; }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 1.55rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #2D2926; }

.form-select-sm {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  padding-left: 2.93rem;
  font-size: 1.4rem;
  border-radius: 0.2rem; }

.form-select-lg {
  padding-top: 1.94rem;
  padding-bottom: 1.94rem;
  padding-left: 3.1rem;
  font-size: 1.4rem;
  border-radius: 0.2rem; }

.form-check {
  display: block;
  min-height: 1.6rem;
  padding-left: 3.4rem;
  margin-bottom: 1rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -3.4rem; }

.form-check-reverse {
  padding-right: 3.4rem;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -3.4rem;
    margin-left: 0; }

.form-check-input {
  width: 2.4rem;
  height: 2.4rem;
  margin-top: -0.4rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.4rem; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #2D2926;
    outline: 0;
    box-shadow: none; }
  .form-check-input:checked {
    background-color: #000;
    border-color: #000; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3e%3cpath stroke='%23fff' d='M18.708 7.708l-8.354 8.353L6 11.708l.707-.707 3.647 3.646L18 7.001z' fill-rule='evenodd'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #40C5A2;
    border-color: #40C5A2;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232D2926'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 1; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, none; }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #40C5A2;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    .form-range::-webkit-slider-thumb:active {
      background-color: #c6eee3; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #40C5A2;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    .form-range::-moz-range-thumb:active {
      background-color: #c6eee3; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #D4CFCC; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #D4CFCC; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 1.5rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: 1px 0; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 1.55rem 1.5rem;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.22;
  color: #2D2926;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #D4CFCC;
  border-radius: 0.2rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 1.94rem 3.1rem;
  font-size: 1.4rem;
  border-radius: 0.2rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.7rem 2.93rem;
  font-size: 1.4rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 6.2rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n + 3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #40C5A2; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: .1rem;
  font-size: 1.3rem;
  color: #000;
  background-color: rgba(64, 197, 162, 0.9);
  border-radius: 0.4rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #40C5A2;
  padding-right: calc(1.22em + 3.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2340C5A2' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.305em + 0.775rem) center;
  background-size: calc(0.61em + 1.55rem) calc(0.61em + 1.55rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #40C5A2;
    box-shadow: 0 0 0 0.25rem rgba(64, 197, 162, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.22em + 3.1rem);
  background-position: top calc(0.305em + 0.775rem) right calc(0.305em + 0.775rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #40C5A2; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 8.525rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2340C5A2' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 1.55rem center, center right 4.65rem;
    background-size: 16px 12px, calc(0.61em + 1.55rem) calc(0.61em + 1.55rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #40C5A2;
    box-shadow: 0 0 0 0.25rem rgba(64, 197, 162, 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.22em + 3.1rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #40C5A2; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #40C5A2; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(64, 197, 162, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #40C5A2; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: .1rem;
  font-size: 1.3rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.4rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.22em + 3.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.305em + 0.775rem) center;
  background-size: calc(0.61em + 1.55rem) calc(0.61em + 1.55rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.22em + 3.1rem);
  background-position: top calc(0.305em + 0.775rem) right calc(0.305em + 0.775rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 8.525rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 1.55rem center, center right 4.65rem;
    background-size: 16px 12px, calc(0.61em + 1.55rem) calc(0.61em + 1.55rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.22em + 3.1rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  --btn-padding-x: 2.6rem;
  --btn-padding-y: 1.45rem;
  --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --btn-font-size: 1.4rem;
  --btn-font-weight: 700;
  --btn-line-height: 1.55;
  --btn-color: #2D2926;
  --btn-bg: transparent;
  --btn-border-width: 2px;
  --btn-border-color: transparent;
  --btn-border-radius: 0.4rem;
  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --btn-disabled-opacity: 1;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:hover {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color); }
  .btn-check:focus + .btn, .btn:focus {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    outline: 0;
    box-shadow: var(--btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  .btn-check:active + .btn, .btn:active, .btn.active, .btn.show {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color); }
    .btn-check:checked + .btn:focus,
    .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus, .btn.show:focus {
      box-shadow: var(--btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--btn-disabled-color);
    pointer-events: none;
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color);
    opacity: var(--btn-disabled-opacity); }

.btn-primary {
  --btn-color: #000;
  --btn-bg: #40C5A2;
  --btn-border-color: #40C5A2;
  --btn-hover-color: #000;
  --btn-hover-bg: #5dceb0;
  --btn-hover-border-color: #53cbab;
  --btn-focus-shadow-rgb: 54, 167, 138;
  --btn-active-color: #000;
  --btn-active-bg: #66d1b5;
  --btn-active-border-color: #53cbab;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #000;
  --btn-disabled-bg: #40C5A2;
  --btn-disabled-border-color: #40C5A2; }

.btn-secondary {
  --btn-color: #fff;
  --btn-bg: #CE0F69;
  --btn-border-color: #CE0F69;
  --btn-hover-color: #fff;
  --btn-hover-bg: #9b0b4f;
  --btn-hover-border-color: #9b0b4f;
  --btn-focus-shadow-rgb: 213, 51, 128;
  --btn-active-color: #fff;
  --btn-active-bg: #a50c54;
  --btn-active-border-color: #9b0b4f;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #CE0F69;
  --btn-disabled-border-color: #CE0F69; }

.btn-success {
  --btn-color: #000;
  --btn-bg: #40C5A2;
  --btn-border-color: #40C5A2;
  --btn-hover-color: #000;
  --btn-hover-bg: #5dceb0;
  --btn-hover-border-color: #53cbab;
  --btn-focus-shadow-rgb: 54, 167, 138;
  --btn-active-color: #000;
  --btn-active-bg: #66d1b5;
  --btn-active-border-color: #53cbab;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #000;
  --btn-disabled-bg: #40C5A2;
  --btn-disabled-border-color: #40C5A2; }

.btn-info {
  --btn-color: #000;
  --btn-bg: #0dcaf0;
  --btn-border-color: #0dcaf0;
  --btn-hover-color: #000;
  --btn-hover-bg: #31d2f2;
  --btn-hover-border-color: #25cff2;
  --btn-focus-shadow-rgb: 11, 172, 204;
  --btn-active-color: #000;
  --btn-active-bg: #3dd5f3;
  --btn-active-border-color: #25cff2;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #000;
  --btn-disabled-bg: #0dcaf0;
  --btn-disabled-border-color: #0dcaf0; }

.btn-warning {
  --btn-color: #000;
  --btn-bg: #ffc107;
  --btn-border-color: #ffc107;
  --btn-hover-color: #000;
  --btn-hover-bg: #ffca2c;
  --btn-hover-border-color: #ffc720;
  --btn-focus-shadow-rgb: 217, 164, 6;
  --btn-active-color: #000;
  --btn-active-bg: #ffcd39;
  --btn-active-border-color: #ffc720;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #000;
  --btn-disabled-bg: #ffc107;
  --btn-disabled-border-color: #ffc107; }

.btn-danger {
  --btn-color: #fff;
  --btn-bg: #dc3545;
  --btn-border-color: #dc3545;
  --btn-hover-color: #fff;
  --btn-hover-bg: #a52834;
  --btn-hover-border-color: #a52834;
  --btn-focus-shadow-rgb: 225, 83, 97;
  --btn-active-color: #fff;
  --btn-active-bg: #b02a37;
  --btn-active-border-color: #a52834;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #dc3545;
  --btn-disabled-border-color: #dc3545; }

.btn-light {
  --btn-color: #000;
  --btn-bg: #F1F0EF;
  --btn-border-color: #F1F0EF;
  --btn-hover-color: #000;
  --btn-hover-bg: #b5b4b3;
  --btn-hover-border-color: #b5b4b3;
  --btn-focus-shadow-rgb: 205, 204, 203;
  --btn-active-color: #000;
  --btn-active-bg: #c1c0bf;
  --btn-active-border-color: #b5b4b3;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #000;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF; }

.btn-dark {
  --btn-color: #fff;
  --btn-bg: #2D2926;
  --btn-border-color: #2D2926;
  --btn-hover-color: #fff;
  --btn-hover-bg: #4d4947;
  --btn-hover-border-color: #423e3c;
  --btn-focus-shadow-rgb: 77, 73, 71;
  --btn-active-color: #fff;
  --btn-active-bg: #575451;
  --btn-active-border-color: #423e3c;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #fff;
  --btn-disabled-bg: #2D2926;
  --btn-disabled-border-color: #2D2926; }

.btn-outline-primary {
  --btn-color: #40C5A2;
  --btn-border-color: #40C5A2;
  --btn-hover-color: #000;
  --btn-hover-bg: #40C5A2;
  --btn-hover-border-color: #40C5A2;
  --btn-focus-shadow-rgb: 64, 197, 162;
  --btn-active-color: #000;
  --btn-active-bg: #40C5A2;
  --btn-active-border-color: #40C5A2;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #40C5A2;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #40C5A2;
  --gradient: none; }

.btn-outline-secondary {
  --btn-color: #CE0F69;
  --btn-border-color: #CE0F69;
  --btn-hover-color: #fff;
  --btn-hover-bg: #CE0F69;
  --btn-hover-border-color: #CE0F69;
  --btn-focus-shadow-rgb: 206, 15, 105;
  --btn-active-color: #fff;
  --btn-active-bg: #CE0F69;
  --btn-active-border-color: #CE0F69;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #CE0F69;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #CE0F69;
  --gradient: none; }

.btn-outline-success {
  --btn-color: #40C5A2;
  --btn-border-color: #40C5A2;
  --btn-hover-color: #000;
  --btn-hover-bg: #40C5A2;
  --btn-hover-border-color: #40C5A2;
  --btn-focus-shadow-rgb: 64, 197, 162;
  --btn-active-color: #000;
  --btn-active-bg: #40C5A2;
  --btn-active-border-color: #40C5A2;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #40C5A2;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #40C5A2;
  --gradient: none; }

.btn-outline-info {
  --btn-color: #0dcaf0;
  --btn-border-color: #0dcaf0;
  --btn-hover-color: #000;
  --btn-hover-bg: #0dcaf0;
  --btn-hover-border-color: #0dcaf0;
  --btn-focus-shadow-rgb: 13, 202, 240;
  --btn-active-color: #000;
  --btn-active-bg: #0dcaf0;
  --btn-active-border-color: #0dcaf0;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #0dcaf0;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #0dcaf0;
  --gradient: none; }

.btn-outline-warning {
  --btn-color: #ffc107;
  --btn-border-color: #ffc107;
  --btn-hover-color: #000;
  --btn-hover-bg: #ffc107;
  --btn-hover-border-color: #ffc107;
  --btn-focus-shadow-rgb: 255, 193, 7;
  --btn-active-color: #000;
  --btn-active-bg: #ffc107;
  --btn-active-border-color: #ffc107;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #ffc107;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #ffc107;
  --gradient: none; }

.btn-outline-danger {
  --btn-color: #dc3545;
  --btn-border-color: #dc3545;
  --btn-hover-color: #fff;
  --btn-hover-bg: #dc3545;
  --btn-hover-border-color: #dc3545;
  --btn-focus-shadow-rgb: 220, 53, 69;
  --btn-active-color: #fff;
  --btn-active-bg: #dc3545;
  --btn-active-border-color: #dc3545;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #dc3545;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #dc3545;
  --gradient: none; }

.btn-outline-light {
  --btn-color: #F1F0EF;
  --btn-border-color: #F1F0EF;
  --btn-hover-color: #000;
  --btn-hover-bg: #F1F0EF;
  --btn-hover-border-color: #F1F0EF;
  --btn-focus-shadow-rgb: 241, 240, 239;
  --btn-active-color: #000;
  --btn-active-bg: #F1F0EF;
  --btn-active-border-color: #F1F0EF;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #F1F0EF;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #F1F0EF;
  --gradient: none; }

.btn-outline-dark {
  --btn-color: #2D2926;
  --btn-border-color: #2D2926;
  --btn-hover-color: #fff;
  --btn-hover-bg: #2D2926;
  --btn-hover-border-color: #2D2926;
  --btn-focus-shadow-rgb: 45, 41, 38;
  --btn-active-color: #fff;
  --btn-active-bg: #2D2926;
  --btn-active-border-color: #2D2926;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #2D2926;
  --btn-disabled-bg: transparent;
  --btn-disabled-border-color: #2D2926;
  --gradient: none; }

.btn-link {
  --btn-font-weight: 400;
  --btn-color: var(--link-color);
  --btn-bg: transparent;
  --btn-border-color: transparent;
  --btn-hover-color: var(--link-hover-color);
  --btn-hover-border-color: transparent;
  --btn-active-color: var(--link-hover-color);
  --btn-active-border-color: transparent;
  --btn-disabled-color: #BCBCBC;
  --btn-disabled-border-color: transparent;
  --btn-box-shadow: none;
  --btn-focus-shadow-rgb: 54, 167, 138;
  text-decoration: underline; }
  .btn-link:hover, .btn-link:focus {
    text-decoration: none; }
  .btn-link:focus {
    color: var(--btn-color); }
  .btn-link:hover {
    color: var(--btn-hover-color); }

.btn-lg {
  --btn-padding-y: 1.94rem;
  --btn-padding-x: 5.4rem;
  --btn-font-size: 1.4rem;
  --btn-border-radius: 50rem; }

.btn-sm {
  --btn-padding-y: 0.7rem;
  --btn-padding-x: 2.7rem;
  --btn-font-size: 1rem;
  --btn-border-radius: 0.3rem; }

.fade {
  transition: opacity 0.1s linear; }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }

.dropdown-menu {
  --dropdown-min-width: 10rem;
  --dropdown-padding-x: 0;
  --dropdown-padding-y: 0.5rem;
  --dropdown-spacer: 0.125rem;
  --dropdown-font-size: 1rem;
  --dropdown-color: #2D2926;
  --dropdown-bg: #fff;
  --dropdown-border-color: var(--border-color-translucent);
  --dropdown-border-radius: 0.4rem;
  --dropdown-border-width: 1px;
  --dropdown-inner-border-radius: calc(0.4rem - 1px);
  --dropdown-divider-bg: var(--border-color-translucent);
  --dropdown-divider-margin-y: 1rem;
  --dropdown-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  --dropdown-link-color: #2D2926;
  --dropdown-link-hover-color: #292522;
  --dropdown-link-hover-bg: #e9ecef;
  --dropdown-link-active-color: #fff;
  --dropdown-link-active-bg: #40C5A2;
  --dropdown-link-disabled-color: #D4CFCC;
  --dropdown-item-padding-x: 2rem;
  --dropdown-item-padding-y: 0.5rem;
  --dropdown-header-color: #BCBCBC;
  --dropdown-header-padding-x: 2rem;
  --dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: var(--dropdown-min-width);
  padding: var(--dropdown-padding-y) var(--dropdown-padding-x);
  margin: 0;
  font-size: var(--dropdown-font-size);
  color: var(--dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--dropdown-bg);
  background-clip: padding-box;
  border: var(--dropdown-border-width) solid var(--dropdown-border-color);
  border-radius: var(--dropdown-border-radius); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1024px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1366px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--dropdown-spacer); }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--dropdown-spacer); }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--dropdown-spacer); }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--dropdown-link-hover-color);
    background-color: var(--dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--dropdown-header-padding-y) var(--dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 1.3rem;
  color: var(--dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
  color: var(--dropdown-link-color); }

.dropdown-menu-dark {
  --dropdown-color: #dee2e6;
  --dropdown-bg: #343a40;
  --dropdown-border-color: var(--border-color-translucent);
  --dropdown-box-shadow: ;
  --dropdown-link-color: #dee2e6;
  --dropdown-link-hover-color: #fff;
  --dropdown-divider-bg: var(--border-color-translucent);
  --dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --dropdown-link-active-color: #fff;
  --dropdown-link-active-bg: #40C5A2;
  --dropdown-link-disabled-color: #D4CFCC;
  --dropdown-header-color: #D4CFCC; }

.nav {
  --nav-link-padding-x: 1rem;
  --nav-link-padding-y: 0.5rem;
  --nav-link-font-weight: ;
  --nav-link-color: var(--link-color);
  --nav-link-hover-color: var(--link-hover-color);
  --nav-link-disabled-color: #BCBCBC;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--nav-link-padding-y) var(--nav-link-padding-x);
  font-size: var(--nav-link-font-size);
  font-weight: var(--nav-link-font-weight);
  color: var(--nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  .nav-link:hover, .nav-link:focus {
    color: var(--nav-link-hover-color); }
  .nav-link.disabled {
    color: var(--nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --nav-tabs-border-width: 1px;
  --nav-tabs-border-color: #dee2e6;
  --nav-tabs-border-radius: 0.4rem;
  --nav-tabs-link-hover-border-color: #e9ecef #e9ecef #dee2e6;
  --nav-tabs-link-active-color: #837C78;
  --nav-tabs-link-active-bg: #fff;
  --nav-tabs-link-active-border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: var(--nav-tabs-border-width) solid var(--nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(var(--nav-tabs-border-width) * -1);
    background: none;
    border: var(--nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--nav-tabs-border-radius);
    border-top-right-radius: var(--nav-tabs-border-radius); }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--nav-tabs-link-hover-border-color); }
    .nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
      color: var(--nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--nav-tabs-link-active-color);
    background-color: var(--nav-tabs-link-active-bg);
    border-color: var(--nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(var(--nav-tabs-border-width) * -1);
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills {
  --nav-pills-border-radius: 0.4rem;
  --nav-pills-link-active-color: #fff;
  --nav-pills-link-active-bg: #40C5A2; }
  .nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: var(--nav-pills-border-radius); }
    .nav-pills .nav-link:disabled {
      color: var(--nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--nav-pills-link-active-color);
    background-color: var(--nav-pills-link-active-bg); }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.card {
  --card-spacer-y: 2rem;
  --card-spacer-x: 2rem;
  --card-title-spacer-y: 1rem;
  --card-border-width: 1px;
  --card-border-color: var(--border-color-translucent);
  --card-border-radius: 0.4rem;
  --card-box-shadow: ;
  --card-inner-border-radius: calc(0.4rem - 1px);
  --card-cap-padding-y: 1rem;
  --card-cap-padding-x: 2rem;
  --card-cap-bg: rgba(0, 0, 0, 0.03);
  --card-cap-color: ;
  --card-height: ;
  --card-color: ;
  --card-bg: #fff;
  --card-img-overlay-padding: 2rem;
  --card-group-margin: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--card-height);
  word-wrap: break-word;
  background-color: var(--card-bg);
  background-clip: border-box;
  border: var(--card-border-width) solid var(--card-border-color);
  border-radius: var(--card-border-radius); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--card-inner-border-radius);
      border-top-right-radius: var(--card-inner-border-radius); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--card-inner-border-radius);
      border-bottom-left-radius: var(--card-inner-border-radius); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--card-spacer-y) var(--card-spacer-x);
  color: var(--card-color); }

.card-title {
  margin-bottom: var(--card-title-spacer-y); }

.card-subtitle {
  margin-top: calc(-.5 * var(--card-title-spacer-y));
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--card-spacer-x); }

.card-header {
  padding: var(--card-cap-padding-y) var(--card-cap-padding-x);
  margin-bottom: 0;
  color: var(--card-cap-color);
  background-color: var(--card-cap-bg);
  border-bottom: var(--card-border-width) solid var(--card-border-color); }
  .card-header:first-child {
    border-radius: var(--card-inner-border-radius) var(--card-inner-border-radius) 0 0; }

.card-footer {
  padding: var(--card-cap-padding-y) var(--card-cap-padding-x);
  color: var(--card-cap-color);
  background-color: var(--card-cap-bg);
  border-top: var(--card-border-width) solid var(--card-border-color); }
  .card-footer:last-child {
    border-radius: 0 0 var(--card-inner-border-radius) var(--card-inner-border-radius); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--card-cap-padding-x));
  margin-bottom: calc(-1 * var(--card-cap-padding-y));
  margin-left: calc(-.5 * var(--card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--card-bg);
    border-bottom-color: var(--card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--card-cap-padding-x));
  margin-left: calc(-.5 * var(--card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--card-img-overlay-padding);
  border-radius: var(--card-inner-border-radius); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: var(--card-inner-border-radius);
  border-top-right-radius: var(--card-inner-border-radius); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--card-inner-border-radius);
  border-bottom-left-radius: var(--card-inner-border-radius); }

.card-group > .card {
  margin-bottom: var(--card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.breadcrumb {
  --breadcrumb-padding-x: 2rem;
  --breadcrumb-padding-y: 0;
  --breadcrumb-margin-bottom: 1.8rem;
  --breadcrumb-font-size: 1.38rem;
  --breadcrumb-bg: ;
  --breadcrumb-border-radius: ;
  --breadcrumb-divider-color: #2D2926;
  --breadcrumb-item-padding-x: 0.875rem;
  --breadcrumb-item-active-color: #2D2926;
  display: flex;
  flex-wrap: wrap;
  padding: var(--breadcrumb-padding-y) var(--breadcrumb-padding-x);
  margin-bottom: var(--breadcrumb-margin-bottom);
  font-size: var(--breadcrumb-font-size);
  list-style: none;
  background-color: var(--breadcrumb-bg);
  border-radius: var(--breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--breadcrumb-item-padding-x);
    color: var(--breadcrumb-divider-color);
    content: var(--breadcrumb-divider, "/") /* rtl: var(--breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--breadcrumb-item-active-color); }

.pagination {
  --pagination-padding-x: 0;
  --pagination-padding-y: 0;
  --pagination-font-size: 1rem;
  --pagination-color: #2D2926;
  --pagination-bg: #fff;
  --pagination-border-width: 0;
  --pagination-border-color: #dee2e6;
  --pagination-border-radius: 50%;
  --pagination-hover-color: #fff;
  --pagination-hover-bg: #40C5A2;
  --pagination-hover-border-color: #dee2e6;
  --pagination-focus-color: #000;
  --pagination-focus-bg: #e9ecef;
  --pagination-focus-box-shadow: none;
  --pagination-active-color: #fff;
  --pagination-active-bg: #40C5A2;
  --pagination-active-border-color: #40C5A2;
  --pagination-disabled-color: #D4CFCC;
  --pagination-disabled-bg: #fff;
  --pagination-disabled-border-color: #dee2e6;
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--pagination-padding-y) var(--pagination-padding-x);
  font-size: var(--pagination-font-size);
  color: var(--pagination-color);
  text-decoration: none;
  background-color: var(--pagination-bg);
  border: var(--pagination-border-width) solid var(--pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .page-link:hover {
    z-index: 2;
    color: var(--pagination-hover-color);
    background-color: var(--pagination-hover-bg);
    border-color: var(--pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--pagination-focus-color);
    background-color: var(--pagination-focus-bg);
    outline: 0;
    box-shadow: var(--pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--pagination-active-color);
    background-color: var(--pagination-active-bg);
    border-color: var(--pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--pagination-disabled-color);
    pointer-events: none;
    background-color: var(--pagination-disabled-bg);
    border-color: var(--pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: 0; }

.page-item .page-link {
  border-radius: var(--pagination-border-radius); }

.pagination-lg {
  --pagination-padding-x: 1.5rem;
  --pagination-padding-y: 0.75rem;
  --pagination-font-size: 1.25rem;
  --pagination-border-radius: 50rem; }

.pagination-sm {
  --pagination-padding-x: 0.5rem;
  --pagination-padding-y: 0.25rem;
  --pagination-font-size: 1.3rem;
  --pagination-border-radius: 50rem; }

.badge {
  --badge-padding-x: 1rem;
  --badge-padding-y: 0.4rem;
  --badge-font-size: 1.1rem;
  --badge-font-weight: 700;
  --badge-color: #fff;
  --badge-border-radius: 0;
  display: inline-block;
  padding: var(--badge-padding-y) var(--badge-padding-x);
  font-size: var(--badge-font-size);
  font-weight: var(--badge-font-weight);
  line-height: 1;
  color: var(--badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--badge-border-radius); }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.list-group {
  --list-group-color: #2D2926;
  --list-group-bg: #fff;
  --list-group-border-color: rgba(0, 0, 0, 0.125);
  --list-group-border-width: 1px;
  --list-group-border-radius: 0.4rem;
  --list-group-item-padding-x: 2rem;
  --list-group-item-padding-y: 1rem;
  --list-group-action-color: #837C78;
  --list-group-action-hover-color: #837C78;
  --list-group-action-hover-bg: #F1F0EF;
  --list-group-action-active-color: #2D2926;
  --list-group-action-active-bg: #e9ecef;
  --list-group-disabled-color: #BCBCBC;
  --list-group-disabled-bg: #fff;
  --list-group-active-color: #fff;
  --list-group-active-bg: #40C5A2;
  --list-group-active-border-color: #40C5A2;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--list-group-border-radius); }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--list-group-action-active-color);
    background-color: var(--list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--list-group-item-padding-y) var(--list-group-item-padding-x);
  color: var(--list-group-color);
  text-decoration: none;
  background-color: var(--list-group-bg);
  border: var(--list-group-border-width) solid var(--list-group-border-color); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--list-group-disabled-color);
    pointer-events: none;
    background-color: var(--list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--list-group-active-color);
    background-color: var(--list-group-active-bg);
    border-color: var(--list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(var(--list-group-border-width) * -1);
      border-top-width: var(--list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: var(--list-group-border-radius);
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: var(--list-group-border-radius);
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(var(--list-group-border-width) * -1);
      border-left-width: var(--list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: var(--list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: var(--list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--list-group-border-width) * -1);
        border-left-width: var(--list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: var(--list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: var(--list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--list-group-border-width) * -1);
        border-left-width: var(--list-group-border-width); } }

@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: var(--list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: var(--list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--list-group-border-width) * -1);
        border-left-width: var(--list-group-border-width); } }

@media (min-width: 1366px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: var(--list-group-border-radius);
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: var(--list-group-border-radius);
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(var(--list-group-border-width) * -1);
        border-left-width: var(--list-group-border-width); } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 var(--list-group-border-width); }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #267661;
  background-color: #d9f3ec; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #267661;
    background-color: #c3dbd4; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #267661;
    border-color: #267661; }

.list-group-item-secondary {
  color: #7c093f;
  background-color: #f5cfe1; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7c093f;
    background-color: #ddbacb; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7c093f;
    border-color: #7c093f; }

.list-group-item-success {
  color: #267661;
  background-color: #d9f3ec; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #267661;
    background-color: #c3dbd4; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #267661;
    border-color: #267661; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #055160;
    background-color: #badce3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #606060;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #606060;
    background-color: #e3e3e3; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #606060;
    border-color: #606060; }

.list-group-item-dark {
  color: #1b1917;
  background-color: #d5d4d4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1917;
    background-color: #c0bfbf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1917;
    border-color: #1b1917; }

.btn-close {
  box-sizing: content-box;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0 0;
  color: #000;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M18.732 19.417a.767.767 0 0 1-.544-.225L4.723 5.73a.769.769 0 0 1 0-1.088.769.769 0 0 1 1.088 0l13.465 13.465a.769.769 0 0 1-.544 1.31Z'%3E%3C/path%3E%3Cpath d='M18.732 19.417a.767.767 0 0 1-.544-.225L4.723 5.73a.769.769 0 0 1 0-1.088.769.769 0 0 1 1.088 0l13.465 13.465a.769.769 0 0 1-.544 1.31Z'%3E%3C/path%3E%3Cpath d='M5.267 19.417a.767.767 0 0 1-.544-.225.769.769 0 0 1 0-1.088L18.188 4.642a.769.769 0 0 1 1.088 0 .769.769 0 0 1 0 1.088L5.811 19.195a.767.767 0 0 1-.544.222Z'%3E%3C/path%3E%3Cpath d='M5.267 19.417a.767.767 0 0 1-.544-.225.769.769 0 0 1 0-1.088L18.188 4.642a.769.769 0 0 1 1.088 0 .769.769 0 0 1 0 1.088L5.811 19.195a.767.767 0 0 1-.544.222Z'%3E%3C/path%3E%3C/svg%3E") center/3.2rem auto no-repeat;
  border: 0;
  border-radius: 0.4rem;
  opacity: 1; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.7; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0px 0px 3px rgba(64, 197, 162, 0.16);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.875; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--spinner-width);
  height: var(--spinner-height);
  vertical-align: var(--spinner-vertical-align);
  border-radius: 50%;
  animation: var(--spinner-animation-speed) linear infinite var(--spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --spinner-width: 2rem;
  --spinner-height: 2rem;
  --spinner-vertical-align: -0.125em;
  --spinner-border-width: 0.25em;
  --spinner-animation-speed: 0.75s;
  --spinner-animation-name: spinner-border;
  border: var(--spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --spinner-width: 1rem;
  --spinner-height: 1rem;
  --spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --spinner-width: 2rem;
  --spinner-height: 2rem;
  --spinner-vertical-align: -0.125em;
  --spinner-animation-speed: 0.75s;
  --spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --spinner-width: 1rem;
  --spinner-height: 1rem; }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas, .catalog-category-view,
.catalogsearch-result-index,
.ambrand-index-index,
.wishlist-index-index,
.myproducts-index-view {
  --offcanvas-width: 414px;
  --offcanvas-height: 30vh;
  --offcanvas-padding-x: 4.8rem;
  --offcanvas-padding-y: 3rem;
  --offcanvas-color: ;
  --offcanvas-bg: #fff;
  --offcanvas-border-width: 0;
  --offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --offcanvas-box-shadow: none; }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--offcanvas-width);
      border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--offcanvas-width);
      border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--offcanvas-height);
      max-height: 100%;
      border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--offcanvas-height);
      max-height: 100%;
      border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--offcanvas-width);
      border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--offcanvas-width);
      border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--offcanvas-height);
      max-height: 100%;
      border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--offcanvas-height);
      max-height: 100%;
      border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1023.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--offcanvas-width);
      border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--offcanvas-width);
      border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--offcanvas-height);
      max-height: 100%;
      border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--offcanvas-height);
      max-height: 100%;
      border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 1024px) {
  .offcanvas-lg {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1365.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--offcanvas-color);
    visibility: hidden;
    background-color: var(--offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out; }
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--offcanvas-width);
      border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--offcanvas-width);
      border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--offcanvas-height);
      max-height: 100%;
      border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--offcanvas-height);
      max-height: 100%;
      border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1366px) {
  .offcanvas-xl {
    --offcanvas-height: auto;
    --offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--offcanvas-color);
  visibility: hidden;
  background-color: var(--offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--offcanvas-padding-y) * .5) calc(var(--offcanvas-padding-x) * .5);
    margin-top: calc(var(--offcanvas-padding-y) * -.5);
    margin-right: calc(var(--offcanvas-padding-x) * -.5);
    margin-bottom: calc(var(--offcanvas-padding-y) * -.5); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6; }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);
  overflow-y: auto; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #000 !important;
  background-color: RGBA(64, 197, 162, var(--bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(206, 15, 105, var(--bg-opacity, 1)) !important; }

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(64, 197, 162, var(--bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(241, 240, 239, var(--bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(45, 41, 38, var(--bg-opacity, 1)) !important; }

.link-primary {
  color: #40C5A2 !important; }
  .link-primary:hover, .link-primary:focus {
    color: #66d1b5 !important; }

.link-secondary {
  color: #CE0F69 !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: #a50c54 !important; }

.link-success {
  color: #40C5A2 !important; }
  .link-success:hover, .link-success:focus {
    color: #66d1b5 !important; }

.link-info {
  color: #0dcaf0 !important; }
  .link-info:hover, .link-info:focus {
    color: #3dd5f3 !important; }

.link-warning {
  color: #ffc107 !important; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39 !important; }

.link-danger {
  color: #dc3545 !important; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37 !important; }

.link-light {
  color: #F1F0EF !important; }
  .link-light:hover, .link-light:focus {
    color: #f4f3f2 !important; }

.link-dark {
  color: #2D2926 !important; }
  .link-dark:hover, .link-dark:focus {
    color: #24211e !important; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --aspect-ratio: 100%; }

.ratio-4x3 {
  --aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1024px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1366px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important; }

.shadow-sm {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16) !important; }

.shadow-lg {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.16) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--border-width) var(--border-style) var(--border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--border-width) var(--border-style) var(--border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--border-width) var(--border-style) var(--border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--border-width) var(--border-style) var(--border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--border-width) var(--border-style) var(--border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --border-opacity: 1;
  border-color: rgba(var(--primary-rgb), var(--border-opacity)) !important; }

.border-secondary {
  --border-opacity: 1;
  border-color: rgba(var(--secondary-rgb), var(--border-opacity)) !important; }

.border-success {
  --border-opacity: 1;
  border-color: rgba(var(--success-rgb), var(--border-opacity)) !important; }

.border-info {
  --border-opacity: 1;
  border-color: rgba(var(--info-rgb), var(--border-opacity)) !important; }

.border-warning {
  --border-opacity: 1;
  border-color: rgba(var(--warning-rgb), var(--border-opacity)) !important; }

.border-danger {
  --border-opacity: 1;
  border-color: rgba(var(--danger-rgb), var(--border-opacity)) !important; }

.border-light {
  --border-opacity: 1;
  border-color: rgba(var(--light-rgb), var(--border-opacity)) !important; }

.border-dark {
  --border-opacity: 1;
  border-color: rgba(var(--dark-rgb), var(--border-opacity)) !important; }

.border-white {
  --border-opacity: 1;
  border-color: rgba(var(--white-rgb), var(--border-opacity)) !important; }

.border-1 {
  --border-width: 1px; }

.border-2 {
  --border-width: 2px; }

.border-3 {
  --border-width: 3px; }

.border-4 {
  --border-width: 4px; }

.border-5 {
  --border-width: 5px; }

.border-opacity-10 {
  --border-opacity: 0.1; }

.border-opacity-25 {
  --border-opacity: 0.25; }

.border-opacity-50 {
  --border-opacity: 0.5; }

.border-opacity-75 {
  --border-opacity: 0.75; }

.border-opacity-100 {
  --border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.m-3 {
  margin: 2rem !important; }

.m-4 {
  margin: 4rem !important; }

.m-5 {
  margin: 9rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-2 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-3 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.mx-4 {
  margin-right: 4rem !important;
  margin-left: 4rem !important; }

.mx-5 {
  margin-right: 9rem !important;
  margin-left: 9rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-3 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.my-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.my-5 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.5rem !important; }

.mt-2 {
  margin-top: 1rem !important; }

.mt-3 {
  margin-top: 2rem !important; }

.mt-4 {
  margin-top: 4rem !important; }

.mt-5 {
  margin-top: 9rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.5rem !important; }

.me-2 {
  margin-right: 1rem !important; }

.me-3 {
  margin-right: 2rem !important; }

.me-4 {
  margin-right: 4rem !important; }

.me-5 {
  margin-right: 9rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.5rem !important; }

.mb-2 {
  margin-bottom: 1rem !important; }

.mb-3 {
  margin-bottom: 2rem !important; }

.mb-4 {
  margin-bottom: 4rem !important; }

.mb-5 {
  margin-bottom: 9rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.5rem !important; }

.ms-2 {
  margin-left: 1rem !important; }

.ms-3 {
  margin-left: 2rem !important; }

.ms-4 {
  margin-left: 4rem !important; }

.ms-5 {
  margin-left: 9rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.5rem !important; }

.m-n2 {
  margin: -1rem !important; }

.m-n3 {
  margin: -2rem !important; }

.m-n4 {
  margin: -4rem !important; }

.m-n5 {
  margin: -9rem !important; }

.mx-n1 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n2 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n3 {
  margin-right: -2rem !important;
  margin-left: -2rem !important; }

.mx-n4 {
  margin-right: -4rem !important;
  margin-left: -4rem !important; }

.mx-n5 {
  margin-right: -9rem !important;
  margin-left: -9rem !important; }

.my-n1 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n2 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n3 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important; }

.my-n4 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important; }

.my-n5 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important; }

.mt-n1 {
  margin-top: -0.5rem !important; }

.mt-n2 {
  margin-top: -1rem !important; }

.mt-n3 {
  margin-top: -2rem !important; }

.mt-n4 {
  margin-top: -4rem !important; }

.mt-n5 {
  margin-top: -9rem !important; }

.me-n1 {
  margin-right: -0.5rem !important; }

.me-n2 {
  margin-right: -1rem !important; }

.me-n3 {
  margin-right: -2rem !important; }

.me-n4 {
  margin-right: -4rem !important; }

.me-n5 {
  margin-right: -9rem !important; }

.mb-n1 {
  margin-bottom: -0.5rem !important; }

.mb-n2 {
  margin-bottom: -1rem !important; }

.mb-n3 {
  margin-bottom: -2rem !important; }

.mb-n4 {
  margin-bottom: -4rem !important; }

.mb-n5 {
  margin-bottom: -9rem !important; }

.ms-n1 {
  margin-left: -0.5rem !important; }

.ms-n2 {
  margin-left: -1rem !important; }

.ms-n3 {
  margin-left: -2rem !important; }

.ms-n4 {
  margin-left: -4rem !important; }

.ms-n5 {
  margin-left: -9rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.5rem !important; }

.p-2 {
  padding: 1rem !important; }

.p-3 {
  padding: 2rem !important; }

.p-4 {
  padding: 4rem !important; }

.p-5 {
  padding: 9rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-2 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-3 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.px-4 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.px-5 {
  padding-right: 9rem !important;
  padding-left: 9rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-3 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.py-5 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.5rem !important; }

.pt-2 {
  padding-top: 1rem !important; }

.pt-3 {
  padding-top: 2rem !important; }

.pt-4 {
  padding-top: 4rem !important; }

.pt-5 {
  padding-top: 9rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.5rem !important; }

.pe-2 {
  padding-right: 1rem !important; }

.pe-3 {
  padding-right: 2rem !important; }

.pe-4 {
  padding-right: 4rem !important; }

.pe-5 {
  padding-right: 9rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.5rem !important; }

.pb-2 {
  padding-bottom: 1rem !important; }

.pb-3 {
  padding-bottom: 2rem !important; }

.pb-4 {
  padding-bottom: 4rem !important; }

.pb-5 {
  padding-bottom: 9rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.5rem !important; }

.ps-2 {
  padding-left: 1rem !important; }

.ps-3 {
  padding-left: 2rem !important; }

.ps-4 {
  padding-left: 4rem !important; }

.ps-5 {
  padding-left: 9rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.5rem !important; }

.gap-2 {
  gap: 1rem !important; }

.gap-3 {
  gap: 2rem !important; }

.gap-4 {
  gap: 4rem !important; }

.gap-5 {
  gap: 9rem !important; }

.font-monospace {
  font-family: var(--font-monospace) !important; }

.fs-1 {
  font-size: 2.4rem !important; }

.fs-2 {
  font-size: 2rem !important; }

.fs-3 {
  font-size: 1.8rem !important; }

.fs-4 {
  font-size: 1.6rem !important; }

.fs-5 {
  font-size: 1.5rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.6 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --text-opacity: 1;
  color: rgba(var(--primary-rgb), var(--text-opacity)) !important; }

.text-secondary {
  --text-opacity: 1;
  color: rgba(var(--secondary-rgb), var(--text-opacity)) !important; }

.text-success {
  --text-opacity: 1;
  color: rgba(var(--success-rgb), var(--text-opacity)) !important; }

.text-info {
  --text-opacity: 1;
  color: rgba(var(--info-rgb), var(--text-opacity)) !important; }

.text-warning {
  --text-opacity: 1;
  color: rgba(var(--warning-rgb), var(--text-opacity)) !important; }

.text-danger {
  --text-opacity: 1;
  color: rgba(var(--danger-rgb), var(--text-opacity)) !important; }

.text-light {
  --text-opacity: 1;
  color: rgba(var(--light-rgb), var(--text-opacity)) !important; }

.text-dark {
  --text-opacity: 1;
  color: rgba(var(--dark-rgb), var(--text-opacity)) !important; }

.text-black {
  --text-opacity: 1;
  color: rgba(var(--black-rgb), var(--text-opacity)) !important; }

.text-white {
  --text-opacity: 1;
  color: rgba(var(--white-rgb), var(--text-opacity)) !important; }

.text-body {
  --text-opacity: 1;
  color: rgba(var(--body-color-rgb), var(--text-opacity)) !important; }

.text-muted {
  --text-opacity: 1;
  color: #D4CFCC !important; }

.text-black-50 {
  --text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --text-opacity: 0.25; }

.text-opacity-50 {
  --text-opacity: 0.5; }

.text-opacity-75 {
  --text-opacity: 0.75; }

.text-opacity-100 {
  --text-opacity: 1; }

.bg-primary {
  --bg-opacity: 1;
  background-color: rgba(var(--primary-rgb), var(--bg-opacity)) !important; }

.bg-secondary {
  --bg-opacity: 1;
  background-color: rgba(var(--secondary-rgb), var(--bg-opacity)) !important; }

.bg-success {
  --bg-opacity: 1;
  background-color: rgba(var(--success-rgb), var(--bg-opacity)) !important; }

.bg-info {
  --bg-opacity: 1;
  background-color: rgba(var(--info-rgb), var(--bg-opacity)) !important; }

.bg-warning {
  --bg-opacity: 1;
  background-color: rgba(var(--warning-rgb), var(--bg-opacity)) !important; }

.bg-danger {
  --bg-opacity: 1;
  background-color: rgba(var(--danger-rgb), var(--bg-opacity)) !important; }

.bg-light {
  --bg-opacity: 1;
  background-color: rgba(var(--light-rgb), var(--bg-opacity)) !important; }

.bg-dark {
  --bg-opacity: 1;
  background-color: rgba(var(--dark-rgb), var(--bg-opacity)) !important; }

.bg-black {
  --bg-opacity: 1;
  background-color: rgba(var(--black-rgb), var(--bg-opacity)) !important; }

.bg-white {
  --bg-opacity: 1;
  background-color: rgba(var(--white-rgb), var(--bg-opacity)) !important; }

.bg-body {
  --bg-opacity: 1;
  background-color: rgba(var(--body-bg-rgb), var(--bg-opacity)) !important; }

.bg-transparent {
  --bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bg-opacity: 0.1; }

.bg-opacity-25 {
  --bg-opacity: 0.25; }

.bg-opacity-50 {
  --bg-opacity: 0.5; }

.bg-opacity-75 {
  --bg-opacity: 0.75; }

.bg-opacity-100 {
  --bg-opacity: 1; }

.bg-gradient {
  background-image: var(--gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--border-radius) !important; }

.rounded-3 {
  border-radius: var(--border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--border-radius-2xl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important; }

.rounded-end {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important; }

.rounded-start {
  border-bottom-left-radius: var(--border-radius) !important;
  border-top-left-radius: var(--border-radius) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.5rem !important; }
  .m-sm-2 {
    margin: 1rem !important; }
  .m-sm-3 {
    margin: 2rem !important; }
  .m-sm-4 {
    margin: 4rem !important; }
  .m-sm-5 {
    margin: 9rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-sm-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-sm-5 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-sm-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-sm-5 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.5rem !important; }
  .mt-sm-2 {
    margin-top: 1rem !important; }
  .mt-sm-3 {
    margin-top: 2rem !important; }
  .mt-sm-4 {
    margin-top: 4rem !important; }
  .mt-sm-5 {
    margin-top: 9rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.5rem !important; }
  .me-sm-2 {
    margin-right: 1rem !important; }
  .me-sm-3 {
    margin-right: 2rem !important; }
  .me-sm-4 {
    margin-right: 4rem !important; }
  .me-sm-5 {
    margin-right: 9rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-2 {
    margin-bottom: 1rem !important; }
  .mb-sm-3 {
    margin-bottom: 2rem !important; }
  .mb-sm-4 {
    margin-bottom: 4rem !important; }
  .mb-sm-5 {
    margin-bottom: 9rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.5rem !important; }
  .ms-sm-2 {
    margin-left: 1rem !important; }
  .ms-sm-3 {
    margin-left: 2rem !important; }
  .ms-sm-4 {
    margin-left: 4rem !important; }
  .ms-sm-5 {
    margin-left: 9rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.5rem !important; }
  .m-sm-n2 {
    margin: -1rem !important; }
  .m-sm-n3 {
    margin: -2rem !important; }
  .m-sm-n4 {
    margin: -4rem !important; }
  .m-sm-n5 {
    margin: -9rem !important; }
  .mx-sm-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n3 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-sm-n4 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-sm-n5 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-sm-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n3 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-sm-n4 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-sm-n5 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-sm-n1 {
    margin-top: -0.5rem !important; }
  .mt-sm-n2 {
    margin-top: -1rem !important; }
  .mt-sm-n3 {
    margin-top: -2rem !important; }
  .mt-sm-n4 {
    margin-top: -4rem !important; }
  .mt-sm-n5 {
    margin-top: -9rem !important; }
  .me-sm-n1 {
    margin-right: -0.5rem !important; }
  .me-sm-n2 {
    margin-right: -1rem !important; }
  .me-sm-n3 {
    margin-right: -2rem !important; }
  .me-sm-n4 {
    margin-right: -4rem !important; }
  .me-sm-n5 {
    margin-right: -9rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n2 {
    margin-bottom: -1rem !important; }
  .mb-sm-n3 {
    margin-bottom: -2rem !important; }
  .mb-sm-n4 {
    margin-bottom: -4rem !important; }
  .mb-sm-n5 {
    margin-bottom: -9rem !important; }
  .ms-sm-n1 {
    margin-left: -0.5rem !important; }
  .ms-sm-n2 {
    margin-left: -1rem !important; }
  .ms-sm-n3 {
    margin-left: -2rem !important; }
  .ms-sm-n4 {
    margin-left: -4rem !important; }
  .ms-sm-n5 {
    margin-left: -9rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.5rem !important; }
  .p-sm-2 {
    padding: 1rem !important; }
  .p-sm-3 {
    padding: 2rem !important; }
  .p-sm-4 {
    padding: 4rem !important; }
  .p-sm-5 {
    padding: 9rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-sm-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-sm-5 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-sm-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-sm-5 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.5rem !important; }
  .pt-sm-2 {
    padding-top: 1rem !important; }
  .pt-sm-3 {
    padding-top: 2rem !important; }
  .pt-sm-4 {
    padding-top: 4rem !important; }
  .pt-sm-5 {
    padding-top: 9rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.5rem !important; }
  .pe-sm-2 {
    padding-right: 1rem !important; }
  .pe-sm-3 {
    padding-right: 2rem !important; }
  .pe-sm-4 {
    padding-right: 4rem !important; }
  .pe-sm-5 {
    padding-right: 9rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-2 {
    padding-bottom: 1rem !important; }
  .pb-sm-3 {
    padding-bottom: 2rem !important; }
  .pb-sm-4 {
    padding-bottom: 4rem !important; }
  .pb-sm-5 {
    padding-bottom: 9rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.5rem !important; }
  .ps-sm-2 {
    padding-left: 1rem !important; }
  .ps-sm-3 {
    padding-left: 2rem !important; }
  .ps-sm-4 {
    padding-left: 4rem !important; }
  .ps-sm-5 {
    padding-left: 9rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.5rem !important; }
  .gap-sm-2 {
    gap: 1rem !important; }
  .gap-sm-3 {
    gap: 2rem !important; }
  .gap-sm-4 {
    gap: 4rem !important; }
  .gap-sm-5 {
    gap: 9rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.5rem !important; }
  .m-md-2 {
    margin: 1rem !important; }
  .m-md-3 {
    margin: 2rem !important; }
  .m-md-4 {
    margin: 4rem !important; }
  .m-md-5 {
    margin: 9rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-md-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-md-5 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-md-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-md-5 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.5rem !important; }
  .mt-md-2 {
    margin-top: 1rem !important; }
  .mt-md-3 {
    margin-top: 2rem !important; }
  .mt-md-4 {
    margin-top: 4rem !important; }
  .mt-md-5 {
    margin-top: 9rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.5rem !important; }
  .me-md-2 {
    margin-right: 1rem !important; }
  .me-md-3 {
    margin-right: 2rem !important; }
  .me-md-4 {
    margin-right: 4rem !important; }
  .me-md-5 {
    margin-right: 9rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.5rem !important; }
  .mb-md-2 {
    margin-bottom: 1rem !important; }
  .mb-md-3 {
    margin-bottom: 2rem !important; }
  .mb-md-4 {
    margin-bottom: 4rem !important; }
  .mb-md-5 {
    margin-bottom: 9rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.5rem !important; }
  .ms-md-2 {
    margin-left: 1rem !important; }
  .ms-md-3 {
    margin-left: 2rem !important; }
  .ms-md-4 {
    margin-left: 4rem !important; }
  .ms-md-5 {
    margin-left: 9rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.5rem !important; }
  .m-md-n2 {
    margin: -1rem !important; }
  .m-md-n3 {
    margin: -2rem !important; }
  .m-md-n4 {
    margin: -4rem !important; }
  .m-md-n5 {
    margin: -9rem !important; }
  .mx-md-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n3 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-md-n4 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-md-n5 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-md-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n3 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-md-n4 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-md-n5 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-md-n1 {
    margin-top: -0.5rem !important; }
  .mt-md-n2 {
    margin-top: -1rem !important; }
  .mt-md-n3 {
    margin-top: -2rem !important; }
  .mt-md-n4 {
    margin-top: -4rem !important; }
  .mt-md-n5 {
    margin-top: -9rem !important; }
  .me-md-n1 {
    margin-right: -0.5rem !important; }
  .me-md-n2 {
    margin-right: -1rem !important; }
  .me-md-n3 {
    margin-right: -2rem !important; }
  .me-md-n4 {
    margin-right: -4rem !important; }
  .me-md-n5 {
    margin-right: -9rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n2 {
    margin-bottom: -1rem !important; }
  .mb-md-n3 {
    margin-bottom: -2rem !important; }
  .mb-md-n4 {
    margin-bottom: -4rem !important; }
  .mb-md-n5 {
    margin-bottom: -9rem !important; }
  .ms-md-n1 {
    margin-left: -0.5rem !important; }
  .ms-md-n2 {
    margin-left: -1rem !important; }
  .ms-md-n3 {
    margin-left: -2rem !important; }
  .ms-md-n4 {
    margin-left: -4rem !important; }
  .ms-md-n5 {
    margin-left: -9rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.5rem !important; }
  .p-md-2 {
    padding: 1rem !important; }
  .p-md-3 {
    padding: 2rem !important; }
  .p-md-4 {
    padding: 4rem !important; }
  .p-md-5 {
    padding: 9rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-md-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-md-5 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-md-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-md-5 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.5rem !important; }
  .pt-md-2 {
    padding-top: 1rem !important; }
  .pt-md-3 {
    padding-top: 2rem !important; }
  .pt-md-4 {
    padding-top: 4rem !important; }
  .pt-md-5 {
    padding-top: 9rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.5rem !important; }
  .pe-md-2 {
    padding-right: 1rem !important; }
  .pe-md-3 {
    padding-right: 2rem !important; }
  .pe-md-4 {
    padding-right: 4rem !important; }
  .pe-md-5 {
    padding-right: 9rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.5rem !important; }
  .pb-md-2 {
    padding-bottom: 1rem !important; }
  .pb-md-3 {
    padding-bottom: 2rem !important; }
  .pb-md-4 {
    padding-bottom: 4rem !important; }
  .pb-md-5 {
    padding-bottom: 9rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.5rem !important; }
  .ps-md-2 {
    padding-left: 1rem !important; }
  .ps-md-3 {
    padding-left: 2rem !important; }
  .ps-md-4 {
    padding-left: 4rem !important; }
  .ps-md-5 {
    padding-left: 9rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.5rem !important; }
  .gap-md-2 {
    gap: 1rem !important; }
  .gap-md-3 {
    gap: 2rem !important; }
  .gap-md-4 {
    gap: 4rem !important; }
  .gap-md-5 {
    gap: 9rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.5rem !important; }
  .m-lg-2 {
    margin: 1rem !important; }
  .m-lg-3 {
    margin: 2rem !important; }
  .m-lg-4 {
    margin: 4rem !important; }
  .m-lg-5 {
    margin: 9rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-lg-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-lg-5 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-lg-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-lg-5 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.5rem !important; }
  .mt-lg-2 {
    margin-top: 1rem !important; }
  .mt-lg-3 {
    margin-top: 2rem !important; }
  .mt-lg-4 {
    margin-top: 4rem !important; }
  .mt-lg-5 {
    margin-top: 9rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.5rem !important; }
  .me-lg-2 {
    margin-right: 1rem !important; }
  .me-lg-3 {
    margin-right: 2rem !important; }
  .me-lg-4 {
    margin-right: 4rem !important; }
  .me-lg-5 {
    margin-right: 9rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-2 {
    margin-bottom: 1rem !important; }
  .mb-lg-3 {
    margin-bottom: 2rem !important; }
  .mb-lg-4 {
    margin-bottom: 4rem !important; }
  .mb-lg-5 {
    margin-bottom: 9rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.5rem !important; }
  .ms-lg-2 {
    margin-left: 1rem !important; }
  .ms-lg-3 {
    margin-left: 2rem !important; }
  .ms-lg-4 {
    margin-left: 4rem !important; }
  .ms-lg-5 {
    margin-left: 9rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.5rem !important; }
  .m-lg-n2 {
    margin: -1rem !important; }
  .m-lg-n3 {
    margin: -2rem !important; }
  .m-lg-n4 {
    margin: -4rem !important; }
  .m-lg-n5 {
    margin: -9rem !important; }
  .mx-lg-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n3 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-lg-n4 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-lg-n5 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-lg-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n3 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-lg-n4 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-lg-n5 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-lg-n1 {
    margin-top: -0.5rem !important; }
  .mt-lg-n2 {
    margin-top: -1rem !important; }
  .mt-lg-n3 {
    margin-top: -2rem !important; }
  .mt-lg-n4 {
    margin-top: -4rem !important; }
  .mt-lg-n5 {
    margin-top: -9rem !important; }
  .me-lg-n1 {
    margin-right: -0.5rem !important; }
  .me-lg-n2 {
    margin-right: -1rem !important; }
  .me-lg-n3 {
    margin-right: -2rem !important; }
  .me-lg-n4 {
    margin-right: -4rem !important; }
  .me-lg-n5 {
    margin-right: -9rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n2 {
    margin-bottom: -1rem !important; }
  .mb-lg-n3 {
    margin-bottom: -2rem !important; }
  .mb-lg-n4 {
    margin-bottom: -4rem !important; }
  .mb-lg-n5 {
    margin-bottom: -9rem !important; }
  .ms-lg-n1 {
    margin-left: -0.5rem !important; }
  .ms-lg-n2 {
    margin-left: -1rem !important; }
  .ms-lg-n3 {
    margin-left: -2rem !important; }
  .ms-lg-n4 {
    margin-left: -4rem !important; }
  .ms-lg-n5 {
    margin-left: -9rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.5rem !important; }
  .p-lg-2 {
    padding: 1rem !important; }
  .p-lg-3 {
    padding: 2rem !important; }
  .p-lg-4 {
    padding: 4rem !important; }
  .p-lg-5 {
    padding: 9rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-lg-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-lg-5 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-lg-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-lg-5 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.5rem !important; }
  .pt-lg-2 {
    padding-top: 1rem !important; }
  .pt-lg-3 {
    padding-top: 2rem !important; }
  .pt-lg-4 {
    padding-top: 4rem !important; }
  .pt-lg-5 {
    padding-top: 9rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.5rem !important; }
  .pe-lg-2 {
    padding-right: 1rem !important; }
  .pe-lg-3 {
    padding-right: 2rem !important; }
  .pe-lg-4 {
    padding-right: 4rem !important; }
  .pe-lg-5 {
    padding-right: 9rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-2 {
    padding-bottom: 1rem !important; }
  .pb-lg-3 {
    padding-bottom: 2rem !important; }
  .pb-lg-4 {
    padding-bottom: 4rem !important; }
  .pb-lg-5 {
    padding-bottom: 9rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.5rem !important; }
  .ps-lg-2 {
    padding-left: 1rem !important; }
  .ps-lg-3 {
    padding-left: 2rem !important; }
  .ps-lg-4 {
    padding-left: 4rem !important; }
  .ps-lg-5 {
    padding-left: 9rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.5rem !important; }
  .gap-lg-2 {
    gap: 1rem !important; }
  .gap-lg-3 {
    gap: 2rem !important; }
  .gap-lg-4 {
    gap: 4rem !important; }
  .gap-lg-5 {
    gap: 9rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1366px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.5rem !important; }
  .m-xl-2 {
    margin: 1rem !important; }
  .m-xl-3 {
    margin: 2rem !important; }
  .m-xl-4 {
    margin: 4rem !important; }
  .m-xl-5 {
    margin: 9rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-2 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-3 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xl-4 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xl-5 {
    margin-right: 9rem !important;
    margin-left: 9rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-3 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xl-4 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xl-5 {
    margin-top: 9rem !important;
    margin-bottom: 9rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.5rem !important; }
  .mt-xl-2 {
    margin-top: 1rem !important; }
  .mt-xl-3 {
    margin-top: 2rem !important; }
  .mt-xl-4 {
    margin-top: 4rem !important; }
  .mt-xl-5 {
    margin-top: 9rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.5rem !important; }
  .me-xl-2 {
    margin-right: 1rem !important; }
  .me-xl-3 {
    margin-right: 2rem !important; }
  .me-xl-4 {
    margin-right: 4rem !important; }
  .me-xl-5 {
    margin-right: 9rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-2 {
    margin-bottom: 1rem !important; }
  .mb-xl-3 {
    margin-bottom: 2rem !important; }
  .mb-xl-4 {
    margin-bottom: 4rem !important; }
  .mb-xl-5 {
    margin-bottom: 9rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.5rem !important; }
  .ms-xl-2 {
    margin-left: 1rem !important; }
  .ms-xl-3 {
    margin-left: 2rem !important; }
  .ms-xl-4 {
    margin-left: 4rem !important; }
  .ms-xl-5 {
    margin-left: 9rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.5rem !important; }
  .m-xl-n2 {
    margin: -1rem !important; }
  .m-xl-n3 {
    margin: -2rem !important; }
  .m-xl-n4 {
    margin: -4rem !important; }
  .m-xl-n5 {
    margin: -9rem !important; }
  .mx-xl-n1 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n2 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n3 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xl-n4 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-xl-n5 {
    margin-right: -9rem !important;
    margin-left: -9rem !important; }
  .my-xl-n1 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n2 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n3 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xl-n4 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-xl-n5 {
    margin-top: -9rem !important;
    margin-bottom: -9rem !important; }
  .mt-xl-n1 {
    margin-top: -0.5rem !important; }
  .mt-xl-n2 {
    margin-top: -1rem !important; }
  .mt-xl-n3 {
    margin-top: -2rem !important; }
  .mt-xl-n4 {
    margin-top: -4rem !important; }
  .mt-xl-n5 {
    margin-top: -9rem !important; }
  .me-xl-n1 {
    margin-right: -0.5rem !important; }
  .me-xl-n2 {
    margin-right: -1rem !important; }
  .me-xl-n3 {
    margin-right: -2rem !important; }
  .me-xl-n4 {
    margin-right: -4rem !important; }
  .me-xl-n5 {
    margin-right: -9rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n2 {
    margin-bottom: -1rem !important; }
  .mb-xl-n3 {
    margin-bottom: -2rem !important; }
  .mb-xl-n4 {
    margin-bottom: -4rem !important; }
  .mb-xl-n5 {
    margin-bottom: -9rem !important; }
  .ms-xl-n1 {
    margin-left: -0.5rem !important; }
  .ms-xl-n2 {
    margin-left: -1rem !important; }
  .ms-xl-n3 {
    margin-left: -2rem !important; }
  .ms-xl-n4 {
    margin-left: -4rem !important; }
  .ms-xl-n5 {
    margin-left: -9rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.5rem !important; }
  .p-xl-2 {
    padding: 1rem !important; }
  .p-xl-3 {
    padding: 2rem !important; }
  .p-xl-4 {
    padding: 4rem !important; }
  .p-xl-5 {
    padding: 9rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-2 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xl-4 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xl-5 {
    padding-right: 9rem !important;
    padding-left: 9rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xl-4 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xl-5 {
    padding-top: 9rem !important;
    padding-bottom: 9rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.5rem !important; }
  .pt-xl-2 {
    padding-top: 1rem !important; }
  .pt-xl-3 {
    padding-top: 2rem !important; }
  .pt-xl-4 {
    padding-top: 4rem !important; }
  .pt-xl-5 {
    padding-top: 9rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.5rem !important; }
  .pe-xl-2 {
    padding-right: 1rem !important; }
  .pe-xl-3 {
    padding-right: 2rem !important; }
  .pe-xl-4 {
    padding-right: 4rem !important; }
  .pe-xl-5 {
    padding-right: 9rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-2 {
    padding-bottom: 1rem !important; }
  .pb-xl-3 {
    padding-bottom: 2rem !important; }
  .pb-xl-4 {
    padding-bottom: 4rem !important; }
  .pb-xl-5 {
    padding-bottom: 9rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.5rem !important; }
  .ps-xl-2 {
    padding-left: 1rem !important; }
  .ps-xl-3 {
    padding-left: 2rem !important; }
  .ps-xl-4 {
    padding-left: 4rem !important; }
  .ps-xl-5 {
    padding-left: 9rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.5rem !important; }
  .gap-xl-2 {
    gap: 1rem !important; }
  .gap-xl-3 {
    gap: 2rem !important; }
  .gap-xl-4 {
    gap: 4rem !important; }
  .gap-xl-5 {
    gap: 9rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

html {
  font-size: 0.625rem; }

body {
  font-size: 1.5rem; }

@font-face {
  font-family: 'Articulat Cf';
  src: url("../fonts/articulatcf-demibold-webfont.woff2") format("woff2"), url("../fonts/articulatcf-demibold-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Articulat Cf';
  src: url("../fonts/articulatcf-extrabold-webfont.woff2") format("woff2"), url("../fonts/articulatcf-extrabold-webfont.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Articulat Cf';
  src: url("../fonts/articulatcf-bold-webfont.woff2") format("woff2"), url("../fonts/articulatcf-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Articulat Cf';
  src: url("../fonts/articulatcf-normal-webfont.woff2") format("woff2"), url("../fonts/articulatcf-normal-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Basic Sans';
  src: url("../fonts/basic-sans.woff2") format("woff2"), url("../fonts/basic-sans.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Basic Sans';
  src: url("../fonts/basic-sans-bold.woff2") format("woff2"), url("../fonts/basic-sans-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

.messages .btn-close {
  display: none; }

.message.message.message {
  position: relative;
  margin-bottom: 0.9rem;
  padding: 1.021rem 1.7rem;
  background-color: #fff;
  font-weight: 500;
  font-size: 1.3rem;
  color: #fff;
  border-left: 5px solid #40C5A2;
  border-radius: 2px; }
  .message.message.message.error .btn-close, .message.message.message.success .btn-close, .message.message.message.info .btn-close, .message.message.message.notice .btn-close, .message.message.message.warning .btn-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: block; }
  .message.message.message.error {
    background-color: #fce1e2;
    border-left-color: #e82e31;
    color: #e82e31; }
  .message.message.message.success {
    background-color: #cbead6;
    border-left-color: #004d23;
    color: #004d23; }
  .message.message.message.info, .message.message.message.notice {
    background-color: #d3eaff;
    border-left-color: #2680eb;
    color: #2680eb; }
  .message.message.message.warning {
    background-color: #fff1c9;
    border-left-color: #a36e1b;
    color: #a36e1b; }
  .message.message.message > *:first-child::before {
    content: '';
    width: auto;
    margin: 0; }
  .message.message.message a {
    color: inherit;
    text-decoration: underline; }
    .message.message.message a:hover {
      text-decoration: none; }

.breadcrumb {
  margin-top: 1.8rem;
  color: #2D2926;
  font-size: 1.39rem; }
  .breadcrumb a {
    text-decoration: none; }
    .breadcrumb a:hover {
      text-decoration: underline; }
  .breadcrumb .breadcrumb-item {
    margin-bottom: 0; }
    .breadcrumb .breadcrumb-item:first-child a {
      font-weight: 700; }
  @media (max-width: 767.98px) {
    .breadcrumb {
      justify-content: center; } }

.btn {
  height: 50px;
  line-height: 1.55; }
  .btn.btn {
    border-width: 2px; }
    .btn.btn:not(.btn-link) {
      text-transform: uppercase;
      letter-spacing: 0.056rem; }
  .btn:focus-visible {
    outline: none; }

.btn-sm {
  height: 30px;
  line-height: 1.4; }

.btn-primary {
  --btn-color: #fff;
  --btn-bg: #40C5A2;
  --btn-border-color: #40C5A2;
  --btn-hover-color: #2D2926;
  --btn-hover-bg: #B7E9DB;
  --btn-hover-border-color: #B7E9DB;
  --btn-focus-shadow-rgb: 93, 206, 176;
  --btn-active-color: #2D2926;
  --btn-active-bg: #B7E9DB;
  --btn-active-border-color: #40C5A2;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF; }

.btn-secondary {
  --btn-color: #fff;
  --btn-bg: #CE0F69;
  --btn-border-color: #CE0F69;
  --btn-hover-color: #2D2926;
  --btn-hover-bg: #FFBFD9;
  --btn-hover-border-color: #FFBFD9;
  --btn-focus-shadow-rgb: 213, 51, 128;
  --btn-active-color: #000;
  --btn-active-bg: #FFBFD9;
  --btn-active-border-color: #CE0F69;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF; }

.btn-dark {
  --btn-color: #fff;
  --btn-bg: #2D2926;
  --btn-border-color: #2D2926;
  --btn-hover-color: #fff;
  --btn-hover-bg: #606060;
  --btn-hover-border-color: #606060;
  --btn-focus-shadow-rgb: 77, 73, 71;
  --btn-active-color: #fff;
  --btn-active-bg: #606060;
  --btn-active-border-color: #2D2926;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF; }

.action::before {
  display: none !important; }

.action.primary, .action.secondary {
  text-transform: uppercase;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  padding: 1.45rem 2.6rem;
  font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.3;
  color: #fff; }
  .action.primary:focus, .action.secondary:focus {
    box-shadow: none !important; }
  .action.primary:focus-visible, .action.secondary:focus-visible {
    outline: none; }

.action.clear, .action.back, .action.link {
  background: none;
  border: none;
  text-decoration: underline;
  font-weight: normal; }
  .action.clear:hover, .action.clear:focus, .action.back:hover, .action.back:focus, .action.link:hover, .action.link:focus {
    text-decoration: none; }

.action.continue, .action.update {
  padding-left: 2.6rem;
  padding-right: 2.6rem; }

.btn-close {
  background-size: 32px 32px; }
  .btn-close:hover {
    border: none; }

.action.primary,
.action-primary {
  --btn-padding-x: 2.6rem;
  --btn-padding-y: 1.45rem;
  --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --btn-font-size: 1.4rem;
  --btn-font-weight: 700;
  --btn-line-height: 1.55;
  --btn-color: #2D2926;
  --btn-bg: transparent;
  --btn-border-width: 2px;
  --btn-border-color: transparent;
  --btn-border-radius: 0.4rem;
  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --btn-disabled-opacity: 1;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  --btn-color: #fff;
  --btn-bg: #40C5A2;
  --btn-border-color: #40C5A2;
  --btn-hover-color: #2D2926;
  --btn-hover-bg: #B7E9DB;
  --btn-hover-border-color: #B7E9DB;
  --btn-focus-shadow-rgb: 93, 206, 176;
  --btn-active-color: #2D2926;
  --btn-active-bg: #B7E9DB;
  --btn-active-border-color: #40C5A2;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF;
  text-transform: uppercase; }
  .action.primary:hover,
  .action-primary:hover {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color); }
  .btn-check:focus + .action.primary, .action.primary:focus, .btn-check:focus +
  .action-primary,
  .action-primary:focus {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    outline: 0;
    box-shadow: var(--btn-focus-box-shadow); }
  .btn-check:checked + .action.primary,
  .btn-check:active + .action.primary, .action.primary:active, .action.primary.active, .action.primary.show, .btn-check:checked +
  .action-primary,
  .btn-check:active +
  .action-primary,
  .action-primary:active,
  .action-primary.active,
  .action-primary.show {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color); }
    .btn-check:checked + .action.primary:focus,
    .btn-check:active + .action.primary:focus, .action.primary:active:focus, .action.primary.active:focus, .action.primary.show:focus, .btn-check:checked +
    .action-primary:focus,
    .btn-check:active +
    .action-primary:focus,
    .action-primary:active:focus,
    .action-primary.active:focus,
    .action-primary.show:focus {
      box-shadow: var(--btn-focus-box-shadow); }
  .action.primary:disabled, .action.primary.disabled,
  fieldset:disabled .action.primary,
  .action-primary:disabled,
  .action-primary.disabled,
  fieldset:disabled
  .action-primary {
    color: var(--btn-disabled-color);
    pointer-events: none;
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color);
    opacity: var(--btn-disabled-opacity); }

.action.secondary,
.action-secondary {
  --btn-padding-x: 2.6rem;
  --btn-padding-y: 1.45rem;
  --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --btn-font-size: 1.4rem;
  --btn-font-weight: 700;
  --btn-line-height: 1.55;
  --btn-color: #2D2926;
  --btn-bg: transparent;
  --btn-border-width: 2px;
  --btn-border-color: transparent;
  --btn-border-radius: 0.4rem;
  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --btn-disabled-opacity: 1;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  --btn-color: #fff;
  --btn-bg: #CE0F69;
  --btn-border-color: #CE0F69;
  --btn-hover-color: #2D2926;
  --btn-hover-bg: #FFBFD9;
  --btn-hover-border-color: #FFBFD9;
  --btn-focus-shadow-rgb: 213, 51, 128;
  --btn-active-color: #000;
  --btn-active-bg: #FFBFD9;
  --btn-active-border-color: #CE0F69;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF;
  text-transform: uppercase; }
  .action.secondary:hover,
  .action-secondary:hover {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color); }
  .btn-check:focus + .action.secondary, .action.secondary:focus, .btn-check:focus +
  .action-secondary,
  .action-secondary:focus {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    outline: 0;
    box-shadow: var(--btn-focus-box-shadow); }
  .btn-check:checked + .action.secondary,
  .btn-check:active + .action.secondary, .action.secondary:active, .action.secondary.active, .action.secondary.show, .btn-check:checked +
  .action-secondary,
  .btn-check:active +
  .action-secondary,
  .action-secondary:active,
  .action-secondary.active,
  .action-secondary.show {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color); }
    .btn-check:checked + .action.secondary:focus,
    .btn-check:active + .action.secondary:focus, .action.secondary:active:focus, .action.secondary.active:focus, .action.secondary.show:focus, .btn-check:checked +
    .action-secondary:focus,
    .btn-check:active +
    .action-secondary:focus,
    .action-secondary:active:focus,
    .action-secondary.active:focus,
    .action-secondary.show:focus {
      box-shadow: var(--btn-focus-box-shadow); }
  .action.secondary:disabled, .action.secondary.disabled,
  fieldset:disabled .action.secondary,
  .action-secondary:disabled,
  .action-secondary.disabled,
  fieldset:disabled
  .action-secondary {
    color: var(--btn-disabled-color);
    pointer-events: none;
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color);
    opacity: var(--btn-disabled-opacity); }

.btn-outline-primary {
  color: #2D2926; }

.input-text,
.select {
  display: block;
  width: 100%;
  height: 50px;
  padding: 1.55rem 1.5rem;
  font-weight: 400;
  line-height: 1.22;
  color: #2D2926;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #D4CFCC;
  font-size: 1.4rem;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .input-text:focus,
  .select:focus {
    color: #2D2926;
    background-color: #fff;
    border-color: #2D2926;
    outline: 0;
    /* stylelint-disable-line */
    box-shadow: none; }
  .input-text::placeholder,
  .select::placeholder {
    color: #837C78;
    opacity: 1; }
  .input-text:disabled, .input-text[readonly],
  .select:disabled,
  .select[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .input-text:not(:first-child),
  .select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    label + .input-text:not(:first-child), label +
    .select:not(:first-child) {
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem; }
  .input-text:not(:last-child),
  .select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.form-control,
.input-text,
.select {
  border-width: 1px;
  transition: all 0.2s ease-in-out; }
  .form-control:focus,
  .input-text:focus,
  .select:focus {
    box-shadow: none; }
  .form-control.mage-error,
  .input-text.mage-error,
  .select.mage-error {
    border-color: #dc3545; }

div.mage-error {
  width: 100%;
  margin-top: 0;
  padding-left: 0.2rem;
  font-size: 1.1rem;
  color: #dc3545; }

textarea.input-text {
  height: auto; }

select {
  appearance: none;
  display: block;
  width: 100%;
  height: auto;
  padding: 1.55rem 4.65rem 1.55rem 1.55rem;
  -moz-padding-start: calc(1.55rem - 3px);
  font-weight: 400;
  line-height: 1.22;
  color: #2D2926;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.55rem center;
  background-size: 16px 12px;
  border: 1px solid #D4CFCC;
  font-size: 1.4rem;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  select:focus {
    border-color: #2D2926;
    outline: 0;
    /* stylelint-disable-line */
    box-shadow: none; }
  select[multiple], select[size]:not([size="1"]) {
    padding-right: 1.55rem;
    background-image: none; }
  select:disabled {
    background-color: #e9ecef; }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #2D2926; }

.label {
  margin-bottom: 0.5rem;
  font-weight: 700;
  color: #2D2926;
  font-size: 1.38rem; }

.label,
.form-label {
  position: relative;
  z-index: 1;
  transform: translate(1.3rem, 1.3rem);
  background: #fff;
  padding: 0 0.7rem; }
  input[type='checkbox'] .label,
  input[type='radio'] .label, input[type='checkbox']
  .form-label,
  input[type='radio']
  .form-label {
    transform: none;
    padding: 0;
    background: none; }

.fieldset > .legend,
.fieldset > .field:not(.choice) > .control,
.fieldset > .field:not(.choice) > .label {
  float: none;
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  text-align: left; }

.fieldset > .field.field {
  margin-bottom: 0.5rem; }
  .fieldset > .field.field::before {
    display: none; }
  .fieldset > .field.field:not(.choice) > .label {
    width: auto;
    padding: 0 0.7rem; }
    .fieldset > .field.field:not(.choice) > .label::after {
      margin-left: 2px;
      vertical-align: top;
      color: inherit; }

.form {
  position: relative;
  z-index: 0; }
  .form .email .control,
  .form .password .control,
  .form .confirmation .control {
    position: relative;
    z-index: 0; }
    .form .email .control::before,
    .form .password .control::before,
    .form .confirmation .control::before {
      content: '';
      position: absolute;
      z-index: 3;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-size: contain;
      top: 1.2rem;
      left: 1.5rem; }
    .form .email .control .form-control,
    .form .email .control .input-text,
    .form .password .control .form-control,
    .form .password .control .input-text,
    .form .confirmation .control .form-control,
    .form .confirmation .control .input-text {
      padding-left: 4.5rem; }
  .form .email .control::before {
    background-image: url("../images/icon-email.svg"); }
  .form .password .control::before,
  .form .confirmation .control::before {
    background-image: url("../images/icon-password.svg"); }

.actions-toolbar.actions-toolbar.actions-toolbar.actions-toolbar {
  margin-left: 0; }

.form-check {
  line-height: 1.3;
  font-size: 1.5rem; }
  .form-check .form-check-input[type='checkbox']:not(:checked):hover {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3e%3cpath stroke='%23EAEAEA' d='M18.708 7.708l-8.354 8.353L6 11.708l.707-.707 3.647 3.646L18 7.001z' fill-rule='evenodd'/%3e%3c/svg%3e"); }
  .form-check .form-check-input[type='radio'] {
    margin-top: -0.3rem;
    background-size: 1.4rem; }
    .form-check .form-check-input[type='radio']:not(:checked):hover {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23EAEAEA'/%3e%3c/svg%3e"); }

.amform-body .amcform-page-wrap .field {
  display: initial; }

.shipping-offer-message {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 0 -2.5rem;
  padding: 2.1rem 2.5rem;
  border: 1px solid #efeeed;
  border-radius: 0.3rem; }
  @media (max-height: 820px) {
    .block-minicart .shipping-offer-message {
      display: none; } }
  .shipping-offer-message .message.message {
    align-self: center;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: 0.8rem;
    align-items: baseline;
    width: 100%;
    max-width: 46rem;
    border: none;
    border-radius: 0; }
    .shipping-offer-message .message.message .text {
      color: #2D2926;
      font-size: 1.5rem;
      line-height: 1.6; }
  .shipping-offer-message .bar-wrapper {
    align-self: center;
    max-width: 46rem;
    width: 100%;
    display: flex;
    flex-direction: column; }
    .shipping-offer-message .bar-wrapper .bar {
      display: flex;
      height: 0.4rem;
      width: 100%; }
      .shipping-offer-message .bar-wrapper .bar .ordered {
        height: 100%;
        background-color: #78D6BD; }
      .shipping-offer-message .bar-wrapper .bar .distance {
        height: 100%;
        background-color: #E9F8F4; }
    .shipping-offer-message .bar-wrapper .range {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .shipping-offer-message .bar-wrapper .range .min,
      .shipping-offer-message .bar-wrapper .range .max {
        font-size: 1.3rem;
        line-height: 1.7; }

.minicart-wrapper {
  display: list-item; }
  .minicart-wrapper .block-minicart {
    border: none;
    list-style: none none;
    min-width: unset;
    z-index: 1045;
    display: flex;
    position: fixed;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0; }
    .minicart-wrapper .block-minicart::before, .minicart-wrapper .block-minicart::after {
      content: unset; }
    .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] {
      height: 100%; }
      .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-header {
        background-color: #40C5A2;
        padding: 1.5rem 3.3rem 1.1rem 4.8rem;
        height: 5rem;
        align-items: center; }
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-header .offcanvas-title {
          line-height: 1.12; }
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-header .actions {
          flex-grow: 1;
          text-align: right; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-header .actions .action {
            border-radius: 0;
            color: #fff;
            font-size: 1.2rem; }
      .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 5rem; }
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper {
          flex-grow: 1;
          padding: 0;
          height: 100%;
          border: none;
          margin: 0 -2.5rem;
          overflow-x: hidden; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product {
            display: flex;
            position: relative;
            z-index: 0; }
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-photo {
              width: 8rem;
              padding: 0;
              margin-right: 1.1rem; }
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details {
              padding-left: 0;
              flex-grow: 1; }
              .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .product-item-name {
                margin-bottom: 2rem;
                font-weight: normal; }
                .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .product-item-name a {
                  padding-left: 0;
                  color: inherit;
                  font-size: 1.5rem;
                  line-height: 1.6; }
              .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .product-sku,
              .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .product-brand {
                font-size: 1.3rem;
                line-height: 1.7;
                text-transform: uppercase; }
              .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .product-brand {
                font-weight: bold; }
              .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .product-item-pricing {
                display: none; }
              .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions {
                float: none;
                justify-content: space-between;
                align-items: center; }
                .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper {
                  display: flex; }
                  .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty {
                    position: relative;
                    width: 10.5rem;
                    min-width: 10.5rem;
                    display: flex;
                    margin-top: 0;
                    text-align: left;
                    border: 1px solid #dedbd8;
                    border-radius: 0.2rem;
                    height: 4rem; }
                    .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty .btn-qty {
                      display: flex;
                      padding: 0;
                      justify-content: center;
                      align-items: center;
                      min-width: 30%;
                      height: auto;
                      border: none;
                      box-shadow: none; }
                      .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty .btn-qty:hover, .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty .btn-qty:active {
                        border: none;
                        box-shadow: none;
                        opacity: 0.7; }
                    .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty .cart-item-display-qty {
                      font-size: 1.5rem;
                      line-height: 1.6;
                      width: 100%;
                      padding: 0;
                      text-align: center; }
                    .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty .cart-item-qty {
                      display: none; }
                    .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty .form-control {
                      border: none; }
                    .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty .spinner-border {
                      display: none;
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      z-index: 2;
                      margin: auto;
                      border-color: #CE0F69;
                      border-right-color: transparent; }
                    .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty.loading::before {
                      content: '';
                      display: block;
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      background: #fff;
                      z-index: 1;
                      opacity: 0.6; }
                    .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .details-qty.loading .spinner-border {
                      display: block; }
                  .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .qty-wrapper .cart-item-uom-qty {
                    margin-left: 2rem;
                    padding: 0.7rem 2.2rem;
                    font-size: 1.5rem;
                    line-height: 1.6; }
                .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .update-cart-item {
                  padding: 0; }
                .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .product-item-details .actions .secondary span {
                  position: relative;
                  width: auto;
                  margin: 0;
                  height: 100%; }
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .action-delete {
              position: absolute;
              top: 0;
              right: 0; }
              .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .action-delete .action {
                text-decoration: underline; }
                .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .action-delete .action:hover, .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .action-delete .action:visited {
                  text-decoration: underline; }
                .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .minicart-items-wrapper li.item > .product .action-delete .action .delete-label {
                  position: unset;
                  font-size: 1.3rem;
                  line-height: 1.7;
                  margin: 0; }
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .subtotal,
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .tax,
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 0.5rem 0;
          flex-shrink: 0; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .subtotal .label,
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .tax .label,
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .total .label {
            margin: 0;
            padding-left: 0;
            font-size: 1.5rem;
            line-height: 1.6;
            font-weight: normal;
            transform: none; }
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .subtotal .label::after,
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .tax .label::after,
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .total .label::after {
              content: unset; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .subtotal .price-wrapper .price,
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .tax .price-wrapper .price,
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .total .price-wrapper .price {
            font-size: 1.5rem;
            font-weight: normal;
            line-height: 1.6; }
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .total {
          margin: 0 0 0.8rem 0; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .total .label {
            font-weight: bold; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .total .price-wrapper .price {
            font-weight: bold; }
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .items-total {
          display: block;
          margin: 2.5rem 0 1.5rem; }
          @media (max-height: 820px) {
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .items-total {
              margin-top: 0; } }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .items-total .item,
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .items-total .count {
            font-weight: bold; }
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .product-item {
          border-top: 1px solid rgba(112, 112, 112, 0.15);
          border-bottom: none;
          position: relative; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .product-item:first-child {
            padding-top: 2rem; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .product-item:last-child {
            border-bottom: 1px solid rgba(112, 112, 112, 0.15); }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .product-item .loading-mask {
            position: absolute; }
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .product-item .loading-mask .loader {
              height: 100%; }
              .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .product-item .loading-mask .loader .loader-container {
                position: absolute; }
        .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .actions {
          margin-top: 0.5rem; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .actions .secondary {
            background-color: #CE0F69;
            border-radius: 0.3rem; }
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .actions .secondary .action.viewcart {
              padding-top: 1.3rem;
              padding-bottom: 1.3rem;
              text-decoration: none;
              display: block;
              height: 100%;
              width: 100%; }
              .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .actions .secondary .action.viewcart .view-cart-label {
                color: #fff;
                font-size: 1.4rem;
                line-height: 1;
                letter-spacing: 0.056rem;
                text-transform: uppercase;
                text-decoration: none; }
          .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .actions .btn-continue-shopping {
            position: relative;
            border: none;
            background-color: unset;
            margin-top: 1.5rem;
            letter-spacing: 0; }
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .actions .btn-continue-shopping:active {
              box-shadow: unset;
              color: #2D2926; }
            .minicart-wrapper .block-minicart [id='minicart-content-wrapper'] .offcanvas-body .actions .btn-continue-shopping::after {
              content: ' ';
              background: url('data:image/svg+xml, %3csvg data-name="icon-arrow2-right 24x24" xmlns="http://www.w3.org/2000/svg" width="24" height="24"%3e%3cpath data-name="Path 10" d="M0 0h24v24H0Z" fill="none"/%3e%3cg data-name="Group 17703"%3e%3cpath data-name="Line 9" d="m17.762 13.145-13.465-.013a.769.769 0 0 1-.769-.77.769.769 0 0 1 .77-.769l13.464.014a.769.769 0 0 1 .769.77.769.769 0 0 1-.769.768Z"/%3e%3cpath data-name="Path 8287" d="M11.029 19.876a.769.769 0 0 1-.544-1.313l6.188-6.188-6.188-6.188a.77.77 0 0 1 1.088-1.088l6.732 6.732a.769.769 0 0 1 0 1.088l-6.732 6.732a.767.767 0 0 1-.544.225Z"/%3e%3c/g%3e%3c/svg%3e') center no-repeat;
              position: absolute;
              bottom: 0;
              right: -3rem;
              width: 3rem;
              height: 3rem; }
  @media (max-width: 1023.98px) {
    .minicart-wrapper {
      display: none; } }

.minicart-wrapper-mobile {
  display: none; }
  .minicart-wrapper-mobile .counter.empty {
    display: none; }
  .minicart-wrapper-mobile .counter-label {
    display: none; }
  @media (max-width: 1023.98px) {
    .minicart-wrapper-mobile {
      display: list-item; } }

.modals-wrapper .modal-header .action-close {
  box-sizing: content-box;
  width: 3.2rem;
  height: 3.2rem;
  padding: 0 0;
  color: #000;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath d='M18.732 19.417a.767.767 0 0 1-.544-.225L4.723 5.73a.769.769 0 0 1 0-1.088.769.769 0 0 1 1.088 0l13.465 13.465a.769.769 0 0 1-.544 1.31Z'%3E%3C/path%3E%3Cpath d='M18.732 19.417a.767.767 0 0 1-.544-.225L4.723 5.73a.769.769 0 0 1 0-1.088.769.769 0 0 1 1.088 0l13.465 13.465a.769.769 0 0 1-.544 1.31Z'%3E%3C/path%3E%3Cpath d='M5.267 19.417a.767.767 0 0 1-.544-.225.769.769 0 0 1 0-1.088L18.188 4.642a.769.769 0 0 1 1.088 0 .769.769 0 0 1 0 1.088L5.811 19.195a.767.767 0 0 1-.544.222Z'%3E%3C/path%3E%3Cpath d='M5.267 19.417a.767.767 0 0 1-.544-.225.769.769 0 0 1 0-1.088L18.188 4.642a.769.769 0 0 1 1.088 0 .769.769 0 0 1 0 1.088L5.811 19.195a.767.767 0 0 1-.544.222Z'%3E%3C/path%3E%3C/svg%3E") center/3.2rem auto no-repeat;
  border: 0;
  border-radius: 0.4rem;
  opacity: 1; }
  .modals-wrapper .modal-header .action-close::before {
    content: unset; }
  .modals-wrapper .modal-header .action-close > span {
    display: none; }

.modals-wrapper footer {
  text-align: right !important; }

.offcanvas .offcanvas-header {
  background-color: #40C5A2;
  color: #fff;
  padding-top: 0.6rem;
  padding-bottom: 0.5rem; }

.offcanvas .offcanvas-title {
  font-size: 2.4rem; }

.toolbar.toolbar-products {
  margin-bottom: 10rem; }
  .toolbar.toolbar-products .sorter {
    position: absolute;
    right: 0;
    top: 4rem;
    z-index: 1; }
  .toolbar.toolbar-products .pages {
    float: none !important;
    max-width: 100%; }
    .toolbar.toolbar-products .pages .pages-items {
      display: flex;
      justify-content: center; }
      .toolbar.toolbar-products .pages .pages-items li {
        margin-left: 1.6rem; }
        @media (max-width: 1365.98px) {
          .toolbar.toolbar-products .pages .pages-items li {
            margin-left: 0.8rem; } }
        .toolbar.toolbar-products .pages .pages-items li.item {
          display: block;
          height: 2.4rem;
          width: 2.4rem;
          border-radius: 3rem;
          color: #fff; }
          .toolbar.toolbar-products .pages .pages-items li.item a {
            text-decoration: none; }
        .toolbar.toolbar-products .pages .pages-items li.item:hover {
          background-color: #CE0F69; }
          .toolbar.toolbar-products .pages .pages-items li.item:hover a {
            color: #fff; }
        .toolbar.toolbar-products .pages .pages-items li.current {
          background-color: #CE0F69; }

.cart-products-toolbar,
.order-pager-wrapper {
  margin-bottom: 10rem; }
  .cart-products-toolbar .pager,
  .order-pager-wrapper .pager {
    display: flex;
    justify-content: space-between; }
    .cart-products-toolbar .pager .toolbar-amount,
    .order-pager-wrapper .pager .toolbar-amount {
      float: none !important; }
    .cart-products-toolbar .pager .pages,
    .order-pager-wrapper .pager .pages {
      float: none !important;
      margin: 0 0 1.5rem; }
      .cart-products-toolbar .pager .pages .pages-items,
      .order-pager-wrapper .pager .pages .pages-items {
        display: flex;
        justify-content: flex-end; }
        .cart-products-toolbar .pager .pages .pages-items li,
        .order-pager-wrapper .pager .pages .pages-items li {
          margin-left: 1.6rem; }
          @media (max-width: 1365.98px) {
            .cart-products-toolbar .pager .pages .pages-items li,
            .order-pager-wrapper .pager .pages .pages-items li {
              margin-left: 0.8rem; } }
          .cart-products-toolbar .pager .pages .pages-items li.item,
          .order-pager-wrapper .pager .pages .pages-items li.item {
            display: block;
            height: 2.4rem;
            width: 2.4rem;
            border-radius: 3rem;
            color: #fff;
            text-align: center; }
            .cart-products-toolbar .pager .pages .pages-items li.item a,
            .order-pager-wrapper .pager .pages .pages-items li.item a {
              text-decoration: none; }
          .cart-products-toolbar .pager .pages .pages-items li.item:hover,
          .order-pager-wrapper .pager .pages .pages-items li.item:hover {
            background-color: #CE0F69; }
            .cart-products-toolbar .pager .pages .pages-items li.item:hover a,
            .order-pager-wrapper .pager .pages .pages-items li.item:hover a {
              color: #fff; }
          .cart-products-toolbar .pager .pages .pages-items li.current,
          .order-pager-wrapper .pager .pages .pages-items li.current {
            background-color: #CE0F69; }

.product-items {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start; }
  .product-items .product-item {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    margin-bottom: 2.5rem;
    max-width: 25rem; }
    @media (max-width: 767.98px) {
      .product-items .product-item {
        width: 50%;
        padding: 2.5rem 1.15rem 2rem;
        margin-left: 0;
        margin-bottom: 0;
        border-bottom: 2px solid #d3cfcc; }
        .product-items .product-item:nth-child(odd) {
          border-right: 2px solid #d3cfcc; }
        .product-items .product-item:first-child, .product-items .product-item:nth-last-child(2):nth-child(odd), .product-items .product-item:last-of-type:nth-of-type(odd) {
          border-right: 0; }
          .product-items .product-item:first-child::after, .product-items .product-item:nth-last-child(2):nth-child(odd)::after, .product-items .product-item:last-of-type:nth-of-type(odd)::after {
            border-right-width: 2px;
            border-right-style: solid;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1; }
        .product-items .product-item:nth-last-child(2):nth-child(odd)::after, .product-items .product-item:last-of-type:nth-of-type(odd)::after {
          border-image: linear-gradient(to bottom, #d3cfcc, rgba(0, 0, 0, 0)) 1 100%; }
        .product-items .product-item:first-child::after {
          border-image: linear-gradient(to top, #d3cfcc, rgba(0, 0, 0, 0)) 1 100%; }
        .product-items .product-item:last-child:first-child::after {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #d3cfcc 25%, #d3cfcc 75%, rgba(255, 255, 255, 0) 100%);
          width: 1px;
          right: 0;
          left: 100%;
          border: none; }
        .product-items .product-item:last-child, .product-items .product-item:nth-last-child(2):nth-child(odd) {
          border-bottom: 0; } }
    .product-items .product-item::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%; }
      @media (max-width: 1365.98px) {
        .product-items .product-item::before {
          content: none; } }
    .product-items .product-item:hover::before {
      border: 2px solid #ededed; }
    .product-items .product-item .product-item-info {
      position: relative;
      padding: 1rem 1.9rem;
      width: auto;
      display: flex;
      flex-direction: column;
      flex-grow: 1; }
      @media (max-width: 1365.98px) {
        .product-items .product-item .product-item-info {
          padding: 1rem; } }
      @media (max-width: 1023.98px) {
        .product-items .product-item .product-item-info {
          padding: 2rem 1.1rem 1.5rem; } }
    @media (max-width: 1365.98px) {
      .product-items .product-item:nth-last-child(n+2):nth-last-child(-n+2):first-child::after,
      .product-items .product-item:first-child + li:last-child::after {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #d3cfcc 25%, #d3cfcc 75%, rgba(255, 255, 255, 0) 100%);
        width: 1px;
        left: 0;
        right: 100%;
        border: none;
        content: '';
        position: absolute;
        height: 100%; } }
  .product-items .product-image-container {
    position: relative;
    margin: 1.5rem auto 0; }
    @media (max-width: 767.98px) {
      .product-items .product-image-container {
        position: initial;
        margin-top: 0;
        width: 100%; }
        .product-items .product-image-container .product-item-photo {
          max-width: 12.8rem;
          display: block;
          margin: auto; } }
    .product-items .product-image-container .actions-secondary {
      position: absolute;
      right: 1rem;
      bottom: 2rem;
      z-index: 1; }
      .product-items .product-image-container .actions-secondary .action.towishlist:hover,
      .product-items .product-image-container .actions-secondary .action.in-wishlist:hover {
        opacity: 0.7; }
      @media (max-width: 767.98px) {
        .product-items .product-image-container .actions-secondary {
          bottom: 1.5rem; }
          .product-items .product-image-container .actions-secondary .action.towishlist {
            padding: 0.88rem; } }
    .product-items .product-image-container .product-item-badge {
      text-align: center;
      position: absolute;
      top: 0;
      z-index: 1; }
      .product-items .product-image-container .product-item-badge + .deal,
      .product-items .product-image-container .product-item-badge + .clearance,
      .product-items .product-image-container .product-item-badge + .new {
        top: 3rem; }
      .product-items .product-image-container .product-item-badge.no-gwp {
        top: 0; }
      @media (max-width: 767.98px) {
        .product-items .product-image-container .product-item-badge {
          top: 2rem; }
          .product-items .product-image-container .product-item-badge.deal, .product-items .product-image-container .product-item-badge.clearance, .product-items .product-image-container .product-item-badge.new {
            top: 5rem; }
          .product-items .product-image-container .product-item-badge.no-gwp {
            top: 2rem; } }
      .product-items .product-image-container .product-item-badge span {
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 0.5px; }
    .product-items .product-image-container .product-label {
      background-color: #CE0F69;
      color: #fff;
      font-size: 1.2rem;
      text-transform: uppercase;
      z-index: 1;
      border-radius: 2px;
      padding: 0.2rem 2.32rem;
      border: 1px solid #CE0F69; }
      @media (max-width: 767.98px) {
        .product-items .product-image-container .product-label {
          padding: 0.2rem 1.32rem; } }
    .product-items .product-image-container .deal {
      background-color: #CE0F69;
      width: 6.5rem;
      border-radius: 0.2rem; }
      .product-items .product-image-container .deal span {
        color: #fff; }
    .product-items .product-image-container .clearance {
      background-color: #fff;
      border: 1px solid #CE0F69;
      width: 10.2rem;
      border-radius: 0.2rem; }
      .product-items .product-image-container .clearance span {
        color: #CE0F69;
        font-weight: 700; }
    .product-items .product-image-container .new {
      right: 0; }
      @media (max-width: 1365.98px) {
        .product-items .product-image-container .new {
          right: 0.5rem;
          top: 2.25rem; } }
    .product-items .product-image-container .unavailable {
      left: 0;
      right: 0;
      top: -0.6rem; }
      @media (max-width: 1365.98px) {
        .product-items .product-image-container .unavailable {
          top: 0; } }
      .product-items .product-image-container .unavailable span {
        color: #2D2926; }
  .product-items .product-item-details {
    margin-top: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
    .product-items .product-item-details .product-item-sku {
      font-size: 1.3rem; }
    .product-items .product-item-details .product-item-brand {
      font-size: 1.3rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0; }
      .product-items .product-item-details .product-item-brand a {
        text-decoration: none;
        z-index: 2;
        position: -webkit-sticky;
        position: sticky; }
      .product-items .product-item-details .product-item-brand a:hover {
        text-decoration: underline; }
      @media (max-width: 1365.98px) {
        .product-items .product-item-details .product-item-brand {
          font-size: 1.2rem; } }
    .product-items .product-item-details .product-item-name {
      margin: 0 0 auto; }
      @media (max-width: 1365.98px) {
        .product-items .product-item-details .product-item-name {
          font-size: 1.3rem; } }
      .product-items .product-item-details .product-item-name .product-item-link:hover {
        text-decoration: none; }
      .product-items .product-item-details .product-item-name .product-item-link::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
        @media (max-width: 1365.98px) {
          .product-items .product-item-details .product-item-name .product-item-link::before {
            content: none; } }
    .product-items .product-item-details .ingredient-icons {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0; }
      .product-items .product-item-details .ingredient-icons li {
        position: relative;
        z-index: 1;
        margin-bottom: 0.3rem; }
        @media (min-width: 768px) {
          .product-items .product-item-details .ingredient-icons li {
            margin-bottom: 0.5rem; } }
        .product-items .product-item-details .ingredient-icons li:not(:last-child) {
          margin-right: 0.44rem; }
          @media (min-width: 1366px) {
            .product-items .product-item-details .ingredient-icons li:not(:last-child) {
              margin-right: 1.24rem; } }
          @media (min-width: 768px) and (max-width: 1023.98px) {
            .product-items .product-item-details .ingredient-icons li:not(:last-child) {
              margin-right: 1.24rem; } }
    .product-items .product-item-details .product-item-offer {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0;
      font-size: 1.2rem;
      text-align: center;
      font-family: "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
      .product-items .product-item-details .product-item-offer > div {
        flex: 0 0 auto;
        width: 49%;
        background-color: #F1F0EF; }
        @media (max-width: 1023.98px) {
          .product-items .product-item-details .product-item-offer > div {
            width: auto; } }
        .product-items .product-item-details .product-item-offer > div span {
          font-weight: 700; }
      .product-items .product-item-details .product-item-offer .product-item-discount {
        padding: 0.5rem 0;
        font-weight: 700;
        color: #CE0F69; }
        @media (max-width: 1023.98px) {
          .product-items .product-item-details .product-item-offer .product-item-discount {
            margin-bottom: 0.4rem;
            padding: 0.4rem 1.25rem 0.2rem 1.15rem; } }
      .product-items .product-item-details .product-item-offer .product-item-best-before-date {
        padding: 0.5rem; }
        @media (max-width: 1023.98px) {
          .product-items .product-item-details .product-item-offer .product-item-best-before-date {
            padding: 0.4rem 1.15rem 0.2rem 1.05rem; } }
    .product-items .product-item-details .amshopby-option-link {
      display: none; }
    .product-items .product-item-details .price-section,
    .product-items .product-item-details .price-container-wrapper {
      width: 100%; }
    .product-items .product-item-details .price-box,
    .product-items .product-item-details .price-container-wrapper {
      clear: both;
      display: flex;
      flex-wrap: wrap;
      margin: 0.25rem 0 0;
      align-items: center; }
      .product-items .product-item-details .price-box .price-container,
      .product-items .product-item-details .price-container-wrapper .price-container {
        line-height: 1; }
      .product-items .product-item-details .price-box .old-price,
      .product-items .product-item-details .price-container-wrapper .old-price {
        text-decoration: none;
        margin-left: auto; }
        .product-items .product-item-details .price-box .old-price .price,
        .product-items .product-item-details .price-container-wrapper .old-price .price {
          font-weight: normal;
          text-decoration: line-through; }
      .product-items .product-item-details .price-box .price-wrapper .price,
      .product-items .product-item-details .price-container-wrapper .price-wrapper .price {
        font-size: 2.4rem; }
        @media (max-width: 1365.98px) {
          .product-items .product-item-details .price-box .price-wrapper .price,
          .product-items .product-item-details .price-container-wrapper .price-wrapper .price {
            font-size: 1.6rem; } }
      .product-items .product-item-details .price-box .price-wrapper.regular-price,
      .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price {
        display: flex;
        align-items: center; }
        .product-items .product-item-details .price-box .price-wrapper.regular-price span,
        .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price span {
          font-weight: 700; }
      .product-items .product-item-details .price-box em,
      .product-items .product-item-details .price-container-wrapper em {
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
        margin-left: 0.4rem; }
        @media (max-width: 1365.98px) {
          .product-items .product-item-details .price-box em,
          .product-items .product-item-details .price-container-wrapper em {
            display: none; } }
      .product-items .product-item-details .price-box .special-price,
      .product-items .product-item-details .price-container-wrapper .special-price {
        display: flex;
        align-items: center;
        color: #CE0F69; }
      .product-items .product-item-details .price-box .rrp-price,
      .product-items .product-item-details .price-container-wrapper .rrp-price {
        flex-basis: 100%;
        font-size: 1.5rem;
        font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
        .product-items .product-item-details .price-box .rrp-price .price,
        .product-items .product-item-details .price-container-wrapper .rrp-price .price {
          font-weight: 400; }
        .product-items .product-item-details .price-box .rrp-price .gst,
        .product-items .product-item-details .price-container-wrapper .rrp-price .gst {
          color: #2D2926;
          font-style: normal; }
    .product-items .product-item-details .product-item-inner .btn-out-of-stock {
      margin-bottom: 0.7rem; }
      .product-items .product-item-details .product-item-inner .btn-out-of-stock .action.tocart {
        text-transform: none; }
    .product-items .product-item-details .product-item-inner .actions-wrapper {
      display: flex;
      justify-content: center;
      align-items: center; }
    @media (min-width: 768px) {
      .product-items .product-item-details .product-item-inner .product-item-actions {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem; } }
    .product-items .product-item-details .product-item-inner .action.tocart {
      width: 100%;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 2.8;
      border-radius: 0.3rem;
      height: 4rem; }
      @media (max-width: 767.98px) {
        .product-items .product-item-details .product-item-inner .action.tocart {
          border-radius: 0.2rem;
          padding: 0; } }
    @media (max-width: 767.98px) {
      .product-items .product-item-details .product-item-inner .action.tocart[title='Notify Me'],
      .product-items .product-item-details .product-item-inner .action.tocart[title='Add'],
      .product-items .product-item-details .product-item-inner .action.tocart[title='Adding...'],
      .product-items .product-item-details .product-item-inner .action.tocart[title='Added'] {
        width: 76.5%; } }
    .product-items .product-item-details .product-item-inner .actions-primary {
      height: 100%;
      width: 100%; }
    .product-items .product-item-details .product-item-inner [data-role='add-to-links'] {
      margin: 0 1rem; }
      .product-items .product-item-details .product-item-inner [data-role='add-to-links'] .action.towishlist {
        display: flex;
        align-items: center;
        justify-content: center; }
        .product-items .product-item-details .product-item-inner [data-role='add-to-links'] .action.towishlist .wishlist-icon {
          width: 2.4rem; }
    .product-items .product-item-details .product-item-inner .control.qty {
      display: flex; }
    .product-items .product-item-details .stock .notified {
      border: 2px solid #40C5A2;
      background-color: #40C5A2;
      color: #fff; }
      .product-items .product-item-details .stock .notified:hover {
        color: #2D2926;
        background-color: #B7E9DB;
        border-color: #B7E9DB; }

@media (min-width: 1024px) {
  .nav-sections {
    background-color: #fff;
    z-index: auto;
    margin-bottom: 0; }
    .nav-sections .level-top.level-top {
      color: #2D2926; }
    .nav-sections .nav-sections-items {
      display: flex; }
    .nav-sections .nav-sections-item-content {
      display: flex !important;
      width: 100%;
      z-index: auto; }
    .nav-sections .nav-sections-item-content:last-child {
      display: none !important; }
    .nav-sections .navigation {
      background-color: #fff;
      font-weight: 400;
      display: flex;
      padding: 0 0.5rem;
      max-height: 20rem;
      z-index: auto;
      position: static; } }
    @media (min-width: 1024px) and (min-width: 1024px) and (max-width: 1365.98px) {
      .nav-sections .navigation {
        max-width: none;
        padding: 0; } }

@media (min-width: 1024px) {
      .nav-sections .navigation > .navigation-items {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        text-align: center;
        position: static; }
        .nav-sections .navigation > .navigation-items .level0.parent {
          position: unset; }
          .nav-sections .navigation > .navigation-items .level0.parent .category-content {
            background: #fff;
            display: flex;
            min-width: 230px;
            margin-left: 0;
            margin-right: 0;
            position: absolute;
            padding: 3rem 0 3rem;
            left: 0;
            right: 0;
            visibility: hidden;
            opacity: 0;
            z-index: -1;
            border: 1px solid rgba(112, 112, 112, 0.25); } }
          @media (min-width: 1024px) and (min-width: 1366px) {
            .nav-sections .navigation > .navigation-items .level0.parent .category-content {
              padding: 3.8rem calc((100% - 1317px) / 2) 3.2rem; } }

@media (min-width: 1024px) {
          .nav-sections .navigation > .navigation-items .level0.parent .level0.submenu {
            right: 0;
            border: none;
            box-shadow: none;
            position: unset; }
            .nav-sections .navigation > .navigation-items .level0.parent .level0.submenu .level1 {
              max-width: 100%;
              break-inside: avoid; }
              .nav-sections .navigation > .navigation-items .level0.parent .level0.submenu .level1.force-break {
                break-before: column; }
            .nav-sections .navigation > .navigation-items .level0.parent .level0.submenu [href] {
              text-decoration: none;
              color: #2D2926;
              font-size: 1.5rem;
              font-weight: 700; }
              .nav-sections .navigation > .navigation-items .level0.parent .level0.submenu [href]:hover {
                background-color: unset;
                text-decoration: underline; }
          .nav-sections .navigation > .navigation-items .level0.parent .level1.submenu {
            right: 0; }
            .nav-sections .navigation > .navigation-items .level0.parent .level1.submenu [href] {
              font-weight: normal;
              padding: 0; }
          .nav-sections .navigation > .navigation-items .level0.parent::after {
            background-color: #000;
            opacity: 0;
            visibility: hidden;
            content: '';
            display: block;
            height: 100%;
            left: auto;
            pointer-events: none;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: -3; }
          .nav-sections .navigation > .navigation-items .level0.parent.show .category-content {
            font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
            justify-content: space-between;
            visibility: visible;
            opacity: 1;
            transition: all 0.2s ease-in-out; }
          .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra {
            width: 23.5%;
            border: 1px solid #f2f2f2;
            height: fit-content;
            margin-right: 2rem; }
            .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra .category-image {
              padding: 0;
              position: relative; }
              .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra .category-image figure {
                margin-bottom: 0; }
            .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra .pagebuilder-mobile-hidden.pagebuilder-mobile-hidden {
              width: 100%; }
            .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info {
              padding: 0; }
              .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info h1, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .h1 {
                line-height: 1.08 !important;
                font-size: 2.6rem;
                margin-bottom: 0; }
              .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info h1 + p, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .h1 + p {
                margin-top: 1rem; }
              .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info p {
                font-family: "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                margin-bottom: 0;
                font-size: 1.3rem;
                line-height: 1.69 !important; }
              .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn {
                margin: 1.5rem 0 2.5rem; }
                .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary {
                  height: 3rem;
                  width: 11.9rem;
                  padding: 0.7rem 0 !important;
                  line-height: 1.1 !important;
                  --btn-padding-x: 2.6rem;
                  --btn-padding-y: 1.45rem;
                  --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
                  --btn-font-size: 1.4rem;
                  --btn-font-weight: 700;
                  --btn-line-height: 1.55;
                  --btn-color: #2D2926;
                  --btn-bg: transparent;
                  --btn-border-width: 2px;
                  --btn-border-color: transparent;
                  --btn-border-radius: 0.4rem;
                  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
                  --btn-disabled-opacity: 1;
                  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
                  display: inline-block;
                  padding: var(--btn-padding-y) var(--btn-padding-x);
                  font-family: var(--btn-font-family);
                  font-size: var(--btn-font-size);
                  font-weight: var(--btn-font-weight);
                  line-height: var(--btn-line-height);
                  color: var(--btn-color);
                  text-align: center;
                  text-decoration: none;
                  vertical-align: middle;
                  cursor: pointer;
                  user-select: none;
                  border: var(--btn-border-width) solid var(--btn-border-color);
                  border-radius: var(--btn-border-radius);
                  background-color: var(--btn-bg);
                  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                  --btn-color: #fff;
                  --btn-bg: #2D2926;
                  --btn-border-color: #2D2926;
                  --btn-hover-color: #fff;
                  --btn-hover-bg: #606060;
                  --btn-hover-border-color: #606060;
                  --btn-focus-shadow-rgb: 77, 73, 71;
                  --btn-active-color: #fff;
                  --btn-active-bg: #606060;
                  --btn-active-border-color: #2D2926;
                  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                  --btn-disabled-color: #D4CFCC;
                  --btn-disabled-bg: #F1F0EF;
                  --btn-disabled-border-color: #F1F0EF; }
                  .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary:hover {
                    color: var(--btn-hover-color);
                    background-color: var(--btn-hover-bg);
                    border-color: var(--btn-hover-border-color); }
                  .btn-check:focus + .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary:focus {
                    color: var(--btn-hover-color);
                    background-color: var(--btn-hover-bg);
                    border-color: var(--btn-hover-border-color);
                    outline: 0;
                    box-shadow: var(--btn-focus-box-shadow); }
                  .btn-check:checked + .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary,
                  .btn-check:active + .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary:active, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary.active, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary.show {
                    color: var(--btn-active-color);
                    background-color: var(--btn-active-bg);
                    border-color: var(--btn-active-border-color); }
                    .btn-check:checked + .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary:focus,
                    .btn-check:active + .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary:focus, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary:active:focus, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary.active:focus, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary.show:focus {
                      box-shadow: var(--btn-focus-box-shadow); }
                  .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary:disabled, .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary.disabled,
                  fieldset:disabled .nav-sections .navigation > .navigation-items .level0.parent.show .category-extra [data-content-type=row] .category-info .shop-now-btn .pagebuilder-button-secondary {
                    color: var(--btn-disabled-color);
                    pointer-events: none;
                    background-color: var(--btn-disabled-bg);
                    border-color: var(--btn-disabled-border-color);
                    opacity: var(--btn-disabled-opacity); }
          .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu {
            display: block;
            column-count: 5;
            gap: 0;
            width: 76.5%; }
            .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu.snacks, .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu.home, .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu.naturalremedies {
              column-count: 4;
              width: 60%; }
            .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu.ecoliving, .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu.superfoods, .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu.sportsnutrition, .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu.display {
              column-count: 3;
              width: 46%; }
            .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu .level1.all-category {
              margin-top: 1rem; }
            .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu .level1 [href] {
              color: #2D2926; }
            .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu .level1.highlighted {
              margin-bottom: 1.4rem; }
              .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu .level1.highlighted [href] {
                color: #CE0F69;
                padding: 0.3rem 2rem;
                line-height: 1; }
              .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu .level1.highlighted + .non-highlighted {
                break-before: column; }
            .nav-sections .navigation > .navigation-items .level0.parent.show .level0.submenu .level1.submenu {
              font-family: "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
              position: unset;
              display: block;
              border: none;
              box-shadow: none;
              padding-left: 2rem;
              padding-bottom: 1rem;
              min-width: 100%; }
          .nav-sections .navigation > .navigation-items .level0.parent.show::after {
            display: block;
            opacity: 0.5;
            visibility: visible;
            transition: all 0.2s ease-in-out; }
        .nav-sections .navigation > .navigation-items .level0.active > .level-top, .nav-sections .navigation > .navigation-items .level0:hover > .level-top, .nav-sections .navigation > .navigation-items .level0.show > .level-top {
          color: #CE0F69;
          box-shadow: inset 0 -3px 0 #CE0F69; }
        .nav-sections .navigation > .navigation-items .level0 > .level-top {
          border: none;
          font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 700;
          font-size: 1.5rem;
          line-height: 40px;
          padding-right: 1.2rem; }
        .nav-sections .navigation > .navigation-items .level0 .ui-icon-caret-1-e.ui-icon-caret-1-e.ui-icon-caret-1-e {
          display: none; }
        .nav-sections .navigation > .navigation-items .level0 .submenu {
          text-align: left;
          font-weight: 400; } }

@media (min-width: 768px) {
  .navigation .level0 .submenu .active a {
    border: none; } }

@media (min-width: 576px) {
  .navigation {
    max-width: 132.2rem; } }

@media (min-width: 768px) {
  .navigation .menu-cta {
    padding: 0; } }

@media (max-width: 767.98px) {
  .navigation a,
  .navigation a:hover {
    font-size: 1.5rem;
    color: #2D2926; } }

@media (max-width: 1023.98px) {
  .nav-sections {
    left: -100%;
    width: 100%;
    background: #fff; }
    .nav-sections .ui-menu-item.parent > [href]::after,
    .nav-sections .category-item.parent > [href]::after,
    .nav-sections .all-category.parent > [href]::after {
      content: '';
      mask-image: url(../images/icon-chevron-down.svg);
      background-color: #2D2926;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: auto;
      width: 2.4rem;
      right: 1rem;
      height: 2.4rem;
      margin-right: 1rem; }
    .nav-sections .ui-menu-item.open > [href], .nav-sections .ui-menu-item:hover > [href], .nav-sections .ui-menu-item:active > [href],
    .nav-sections .category-item.open > [href],
    .nav-sections .category-item:hover > [href],
    .nav-sections .category-item:active > [href],
    .nav-sections .all-category.open > [href],
    .nav-sections .all-category:hover > [href],
    .nav-sections .all-category:active > [href] {
      background-color: #CE0F69;
      color: #fff !important; }
      .nav-sections .ui-menu-item.open > [href]::after, .nav-sections .ui-menu-item:hover > [href]::after, .nav-sections .ui-menu-item:active > [href]::after,
      .nav-sections .category-item.open > [href]::after,
      .nav-sections .category-item:hover > [href]::after,
      .nav-sections .category-item:active > [href]::after,
      .nav-sections .all-category.open > [href]::after,
      .nav-sections .all-category:hover > [href]::after,
      .nav-sections .all-category:active > [href]::after {
        background-color: #fff; }
    .nav-sections .section-header {
      display: flex;
      align-items: center;
      margin: 1.9rem 0 0.7rem; }
      .nav-sections .section-header .btn.btn-close {
        width: 2rem;
        height: 2rem;
        padding: 0.6rem;
        margin-left: 1.1rem;
        margin-right: 1.1rem; }
        .nav-sections .section-header .btn.btn-close.b2c {
          margin-top: 3rem; }
        .nav-sections .section-header .btn.btn-close > span {
          border: 0;
          clip: rect(0, 0, 0, 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px; }
      .nav-sections .section-header .customer-section {
        display: flex;
        align-items: flex-start;
        margin-left: 0.7rem; }
        .nav-sections .section-header .customer-section .svg-container {
          display: flex;
          align-items: center;
          padding: 0.6rem;
          width: 4.4rem;
          height: 4.4rem; }
        .nav-sections .section-header .customer-section .welcome {
          display: flex;
          padding: 1rem 0.6rem 0;
          align-items: center;
          font-size: 1.1rem;
          line-height: 1.27; }
          .nav-sections .section-header .customer-section .welcome > p {
            margin-bottom: unset;
            max-width: 22.4rem;
            padding-right: 2.4rem; }
    .nav-sections .nav-sections-item-title {
      display: none; }
    .nav-sections .nav-sections-item-content {
      margin-top: 1.5rem;
      padding-top: 0; }
      .nav-sections .nav-sections-item-content .parent {
        flex: 1; }
        .nav-sections .nav-sections-item-content .parent > .submenu,
        .nav-sections .nav-sections-item-content .parent > .category-content {
          display: none;
          overflow: hidden;
          max-height: 0; }
        .nav-sections .nav-sections-item-content .parent.open > [href]::after {
          transform: scale(1, -1); }
        .nav-sections .nav-sections-item-content .parent.open > .submenu,
        .nav-sections .nav-sections-item-content .parent.open > .category-content {
          display: flex !important;
          flex-direction: column;
          max-height: 300rem;
          transition: max-height 0.5s linear; }
        .nav-sections .nav-sections-item-content .parent [href] {
          position: relative;
          text-transform: none; }
      .nav-sections .nav-sections-item-content .level0:hover, .nav-sections .nav-sections-item-content .level0:active,
      .nav-sections .nav-sections-item-content .level1:hover,
      .nav-sections .nav-sections-item-content .level1:active {
        background-color: unset; }
        .nav-sections .nav-sections-item-content .level0:hover > [href], .nav-sections .nav-sections-item-content .level0:active > [href],
        .nav-sections .nav-sections-item-content .level1:hover > [href],
        .nav-sections .nav-sections-item-content .level1:active > [href] {
          color: #fff;
          background-color: #CE0F69; }
        .nav-sections .nav-sections-item-content .level0:hover::after, .nav-sections .nav-sections-item-content .level0:active::after,
        .nav-sections .nav-sections-item-content .level1:hover::after,
        .nav-sections .nav-sections-item-content .level1:active::after {
          background-color: #fff; }
      .nav-sections .nav-sections-item-content .header-menu-cta-wrapper {
        background-color: #fff; }
        .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] {
          width: 100%;
          display: flex;
          flex-direction: column; }
          .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .submenu {
            display: none; }
          .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0 {
            border-top: unset;
            margin-bottom: 0.8rem;
            display: block; }
            .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0 > [href] {
              padding-top: 1.6rem;
              padding-left: 7rem;
              padding-bottom: 1.6rem; }
            .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0.parent {
              border: none;
              margin-bottom: 0; }
              .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0.parent > .submenu {
                flex-direction: column; }
                .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0.parent > .submenu .level1 {
                  padding-left: 0;
                  margin-bottom: 1rem; }
                  .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0.parent > .submenu .level1:first-child {
                    margin-top: 0.5rem; }
                  .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0.parent > .submenu .level1:last-child {
                    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
                    padding-bottom: 2rem;
                    margin-bottom: 0; }
                  .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0.parent > .submenu .level1::after {
                    content: unset; }
                  .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0.parent > .submenu .level1 > [href] {
                    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                    padding-left: 7rem;
                    font-size: 1.5rem;
                    line-height: 1.3;
                    font-weight: normal;
                    color: #2D2926; }
            .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta > [data-content-type='html'] .level0:not(.parent) .submenu .ui-menu-item::after {
              content: unset; }
        .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .menu-cta.menu-cta-b2c [data-content-type='html'] .level-top:last-child {
          border-bottom: none; }
        .nav-sections .nav-sections-item-content .header-menu-cta-wrapper [href] {
          font-size: 1.8rem;
          line-height: 1.11;
          font-weight: 700;
          text-transform: none;
          color: #CE0F69; }
        .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .ui-menu {
          justify-content: flex-start; }
        .nav-sections .nav-sections-item-content .header-menu-cta-wrapper .ui-menu-item {
          flex-direction: column; }
      .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] {
        background-color: #fff;
        padding-left: 0;
        padding-right: 0; }
        .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .navigation-items {
          padding-left: 0; }
          .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .navigation-items span {
            font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
          .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .navigation-items .level0 > [href] > span,
          .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .navigation-items .highlighted > [href] > span {
            font-weight: 700; }
          .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .navigation-items .level0 {
            border: none;
            margin-bottom: 0.5rem; }
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .navigation-items .level0 [href] {
              padding-left: 7rem; }
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .navigation-items .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon),
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .navigation-items .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon),
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .navigation-items .level0.show > a:not(.ui-state-active) span:not(.ui-menu-icon) {
              margin-left: 0; }
        .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .all-category {
          font-weight: normal; }
        .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item.level1 {
          font-weight: normal; }
        .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content {
          margin-bottom: 2.5rem;
          border-bottom: 0.1rem solid rgba(112, 112, 112, 0.2); }
          .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra {
            display: flex;
            max-height: 14rem;
            margin: 2.5rem 3rem 5.5rem;
            border: 0.1rem solid #f2f2f2; }
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra a {
              padding: 0; }
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra figure {
              margin: 0; }
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra .pagebuilder-button-secondary {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0.3rem auto;
              width: 11.9rem;
              height: 2.8rem;
              background-color: #2D2926;
              color: #fff;
              font-size: 1.2rem;
              line-height: 1;
              font-weight: 700;
              text-decoration: unset;
              text-transform: uppercase;
              border-radius: 0.3rem;
              padding-left: 0 !important;
              padding-right: 0 !important;
              letter-spacing: 0.048rem; }
              .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra .pagebuilder-button-secondary span {
                transform: translateY(1px); }
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra .category-image {
              padding: 0 !important;
              height: 100%;
              overflow: hidden; }
              .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra .category-image .pagebuilder-mobile-only {
                height: 14rem !important;
                object-fit: cover;
                width: 100%; }
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] {
              flex: 1 1 40%; }
              .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] h1, .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] .h1,
              .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] h2,
              .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] .h2 {
                margin: 0;
                font-size: 1.8rem;
                line-height: 1.125 !important; }
                .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] h1 span, .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] .h1 span,
                .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] h2 span,
                .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] .h2 span {
                  font-size: inherit !important;
                  font-weight: 700; }
              .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] h3, .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] .h3 {
                margin-bottom: 0;
                line-height: 1.125;
                font-size: 1.6rem; } }
              @media (max-width: 1023.98px) and (max-width: 575.98px) {
                .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] h3, .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] .h3 {
                  line-height: 1.1; } }

@media (max-width: 1023.98px) {
              .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] p {
                display: none; }
            .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] + [data-content-type='row'] {
              flex: 1 1 60%;
              display: flex;
              align-items: center;
              justify-content: center; }
              .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] + [data-content-type='row'] .category-info {
                margin-top: 1.2rem; } }
              @media (max-width: 1023.98px) and (max-width: 575.98px) {
                .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] + [data-content-type='row'] .category-info {
                  padding-left: 0.5rem;
                  padding-right: 0.5rem; } }

@media (max-width: 1023.98px) {
                .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] + [data-content-type='row'] .category-info .mt-3 {
                  margin-top: 0.5rem !important; }
                .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .category-item .category-content .category-extra [data-content-type='row'] + [data-content-type='row'] .category-info .action {
                  margin-top: 0.1rem;
                  font-size: 1.2rem;
                  font-weight: 700;
                  line-height: 1.08; }
        .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .level1.submenu {
          background-color: #F1F0EF; }
          .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .level1.submenu .level2 > [href] {
            padding-left: 9rem; }
        .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .active > a:not(.ui-state-active),
        .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .has-active > a:not(.ui-state-active),
        .nav-sections .nav-sections-item-content .navigation[data-action='navigation'] .show > a:not(.ui-state-active) {
          border: none;
          padding-left: 7rem !important; } }

@media (max-width: 767.98px) {
  .menu-cta .ui-menu-item {
    min-height: 5rem; }
    .menu-cta .ui-menu-item [href] {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem; }
  .nav-sections .ui-menu-item,
  .nav-sections .category-item {
    min-height: 5rem; }
    .nav-sections .ui-menu-item [href],
    .nav-sections .category-item [href] {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem; }
  .nav-sections .category-extra .category-image {
    padding: 0 !important; }
  .nav-sections .category-extra [data-content-type='buttons'] > .action {
    margin-top: 1rem; } }

.sensei-recommendations {
  position: relative; }
  @media (min-width: 1024px) {
    .sensei-recommendations.sensei-recommendations {
      margin-bottom: 7rem; } }
  .sensei-recommendations::before {
    content: '';
    position: absolute;
    top: -3.6rem;
    width: 100%;
    height: 0.1rem;
    background-color: rgba(112, 112, 112, 0.15); }
  .sensei-recommendations .slick-track,
  .sensei-recommendations .slick-slide,
  .sensei-recommendations .product-item,
  .sensei-recommendations .product-item-info {
    height: 100%; }
  .sensei-recommendations .slick-list > div {
    margin-left: 0; }
  .sensei-recommendations .slick-slide > div {
    height: 100%; }
    @media (min-width: 1024px) {
      .sensei-recommendations .slick-slide > div {
        height: 96%; } }
  .sensei-recommendations .products-grid .block-title {
    margin: 3.6rem auto 2.7rem;
    text-align: center; }
    @media (min-width: 1024px) {
      .sensei-recommendations .products-grid .block-title {
        margin-bottom: 5rem; } }
  .sensei-recommendations .products-grid .block-related-heading {
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.8rem;
    line-height: 1.11;
    font-weight: 700; }
  .sensei-recommendations .products-grid .product-items {
    display: flex;
    justify-content: space-around;
    max-width: 1178px; }
    @media (min-width: 1024px) {
      .sensei-recommendations .products-grid .product-items.product-items {
        margin: auto; } }
    .sensei-recommendations .products-grid .product-items .product-item.product-item {
      max-width: 25rem;
      width: 100%;
      margin-left: 0; }
      @media (min-width: 1024px) {
        .sensei-recommendations .products-grid .product-items .product-item.product-item {
          max-width: 100%; } }
      .sensei-recommendations .products-grid .product-items .product-item.product-item .product-item-info {
        position: relative;
        display: flex;
        flex-direction: column; }
        .sensei-recommendations .products-grid .product-items .product-item.product-item .product-item-info .product-image-container {
          width: 19.4rem; }
          .sensei-recommendations .products-grid .product-items .product-item.product-item .product-item-info .product-image-container .product-image-wrapper {
            padding-bottom: 100%; }
        .sensei-recommendations .products-grid .product-items .product-item.product-item .product-item-info .product-item-badge {
          position: absolute; }
        .sensei-recommendations .products-grid .product-items .product-item.product-item .product-item-info .product-item-details {
          margin-top: auto; }
  @media (max-width: 767.98px) {
    .sensei-recommendations .product-items .product-item::after {
      content: unset !important; }
    .sensei-recommendations .slick-initialized.slick-standard {
      width: 100vw;
      margin-left: calc((100vw - 100%) / -2); }
      .sensei-recommendations .slick-initialized.slick-standard .slick-prev:not(.primary),
      .sensei-recommendations .slick-initialized.slick-standard .slick-next:not(.primary) {
        background: rgba(255, 255, 255, 0.36); }
      .sensei-recommendations .slick-initialized.slick-standard .slick-arrow {
        width: 4.7rem;
        top: 43%;
        bottom: -29%;
        height: calc(100% - 5.1rem); }
      .sensei-recommendations .slick-initialized.slick-standard .slick-prev,
      .sensei-recommendations .slick-initialized.slick-standard .slick-next {
        width: 24%; }
        .sensei-recommendations .slick-initialized.slick-standard .slick-prev::before,
        .sensei-recommendations .slick-initialized.slick-standard .slick-next::before {
          position: absolute;
          font-size: 1.5rem; }
      .sensei-recommendations .slick-initialized.slick-standard .slick-prev {
        left: 0; }
        .sensei-recommendations .slick-initialized.slick-standard .slick-prev::before {
          left: 1.5rem; }
      .sensei-recommendations .slick-initialized.slick-standard .slick-next {
        right: 0; }
        .sensei-recommendations .slick-initialized.slick-standard .slick-next::before {
          right: 1.5rem; }
      .sensei-recommendations .slick-initialized.slick-standard .slick-dots {
        margin-top: 0;
        margin-left: 0; } }

@media (min-width: 1024px) {
  .block-search {
    float: none;
    max-width: 515px;
    width: 56%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0; } }
  @media (min-width: 1024px) and (max-width: 1365.98px) {
    .block-search {
      margin-left: -0.5rem; } }

@media (min-width: 1024px) {
    .block-search .block-content:focus-within form .input-text {
      border-color: #CE0F69; }
    .block-search .block-content:focus-within .action.search svg {
      fill: #CE0F69; }
    .block-search .block-content form .input-text {
      height: 5rem;
      border-radius: 50rem;
      border-width: 2px;
      padding-left: 2.8rem;
      padding-right: 2.8rem;
      font-size: 1.5rem; }
      .block-search .block-content form .input-text::placeholder {
        color: #837C78; } }
    @media (min-width: 1024px) and (max-width: 1365.98px) {
      .block-search .block-content form .input-text {
        padding-left: 1.6rem;
        padding-right: 1.6rem; } }

@media (min-width: 1024px) {
    .block-search .block-content form .minisearch-clear {
      position: absolute;
      right: 8rem;
      top: 0;
      line-height: 5rem; }
      .block-search .block-content form .minisearch-clear a {
        color: rgba(0, 0, 0, 0.32);
        text-decoration: none;
        font-size: 1.3rem; }
        .block-search .block-content form .minisearch-clear a:hover {
          text-decoration: underline; }
    .block-search .action.search {
      height: 5rem;
      width: 5rem;
      background-color: unset;
      border: 0;
      right: 2.3rem; }
      .block-search .action.search span {
        display: none; }
      .block-search .action.search:active {
        box-shadow: none; } }

@media (min-width: 1024px) and (max-width: 1365.98px) {
  .block-search {
    max-width: 346px; } }

@media (min-width: 1366px) {
  .block-search {
    max-width: 515px; } }

.block-search .control {
  padding-bottom: 0; }

.amsearch-products-section.-grid {
  padding: 3rem 0 0;
  display: block; }
  @media (max-width: 575.98px) {
    .amsearch-products-section.-grid {
      border-top: none;
      padding: 3rem 0 0; } }
  .amsearch-products-section.-grid .amsearch-product-list {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    gap: 0 3.5rem;
    justify-content: center; }
    .amsearch-products-section.-grid .amsearch-product-list .amsearch-item {
      max-width: 24rem;
      width: 100%;
      padding: 0 0 2.8rem;
      justify-content: center; }
      @media (max-width: 575.98px) {
        .amsearch-products-section.-grid .amsearch-product-list .amsearch-item {
          justify-content: flex-start;
          padding: 0 0 1rem 1rem;
          max-width: 100%; }
          .amsearch-products-section.-grid .amsearch-product-list .amsearch-item:nth-of-type(1n+5) {
            display: none; } }
      .amsearch-products-section.-grid .amsearch-product-list .amsearch-item:hover {
        background: transparent; }
      .amsearch-products-section.-grid .amsearch-product-list .amsearch-item .amsearch-image {
        max-width: 8.7rem;
        display: block;
        padding-bottom: 0; }
      .amsearch-products-section.-grid .amsearch-product-list .amsearch-item .product-item-details {
        max-width: 13.9rem;
        width: 100%;
        padding: 0.7rem 0 0 1rem; }
        @media (max-width: 575.98px) {
          .amsearch-products-section.-grid .amsearch-product-list .amsearch-item .product-item-details {
            max-width: 25rem; } }
        .amsearch-products-section.-grid .amsearch-product-list .amsearch-item .product-item-details .product-item-link {
          font-size: 1.3rem;
          color: #000;
          line-height: 1.3; }
          .amsearch-products-section.-grid .amsearch-product-list .amsearch-item .product-item-details .product-item-link .amsearch-highlight {
            color: #000;
            font-weight: 400; }
        .amsearch-products-section.-grid .amsearch-product-list .amsearch-item .product-item-details .product-item-brand {
          font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: 700;
          font-size: 0.9rem;
          text-transform: uppercase;
          margin-bottom: 0.5rem; }

.view-all {
  padding: 2rem 0 3rem; }
  @media (max-width: 575.98px) {
    .view-all {
      padding: 0 0 2.9rem 2.7rem; } }
  .view-all .amsearch-link {
    color: #000;
    display: flex;
    justify-content: center; }
    @media (max-width: 575.98px) {
      .view-all .amsearch-link {
        justify-content: flex-start; } }
    .view-all .amsearch-link img {
      margin-left: 1rem; }

.amsearch-result-section {
  flex-direction: column;
  flex-wrap: wrap; }
  .amsearch-result-section::before {
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.06);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1.2rem;
    pointer-events: none; }
  @media (max-width: 575.98px) {
    .amsearch-result-section {
      max-height: 70vh;
      flex-direction: initial; } }
  .amsearch-result-section .results-for {
    text-align: center;
    margin-top: 2.5rem;
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
    @media (max-width: 575.98px) {
      .amsearch-result-section .results-for {
        text-align: left;
        margin: 2.3rem 0 0 2rem; } }
    .amsearch-result-section .results-for .search-string {
      font-weight: 700; }
    .amsearch-result-section .results-for .ellipsis {
      font-weight: 700; }
    .amsearch-result-section .results-for span:first-child {
      margin-right: 0.5rem; }
  .amsearch-result-section .close-search {
    position: absolute;
    right: 4rem; }
    @media (max-width: 575.98px) {
      .amsearch-result-section .close-search {
        right: -1rem; } }
    .amsearch-result-section .close-search:focus {
      border: none;
      box-shadow: none; }
  .amsearch-result-section .amsearch-message-block {
    background: transparent; }

[data-content-type='html'] > .slick-standard:not(.slick-initialized) {
  display: none; }

.slick-initialized.slick-standard .slick-prev::before,
.slick-initialized.slick-standard .slick-next::before,
.slick-initialized.slick-standard .slick-prev::after,
.slick-initialized.slick-standard .slick-next::after {
  font-size: 2.4rem;
  color: #000; }

.slick-initialized.slick-standard .slick-prev:hover.slick-disabled,
.slick-initialized.slick-standard .slick-next:hover.slick-disabled,
.slick-initialized.slick-standard .slick-prev.slick-disabled,
.slick-initialized.slick-standard .slick-next.slick-disabled {
  opacity: 0; }

.slick-initialized.slick-standard .slick-prev:active,
.slick-initialized.slick-standard .slick-next:active,
.slick-initialized.slick-standard .slick-prev:focus,
.slick-initialized.slick-standard .slick-next:focus,
.slick-initialized.slick-standard .slick-prev:not(.primary),
.slick-initialized.slick-standard .slick-next:not(.primary) {
  background: unset; }

.slick-initialized.slick-standard .slick-prev,
.slick-initialized.slick-standard .slick-next {
  width: 2.4rem;
  height: 2.4rem;
  top: 41%; }

.slick-initialized.slick-standard .slick-prev {
  left: -9%; }

.slick-initialized.slick-standard .slick-next {
  right: -9%; }

.slick-initialized.slick-standard .slick-list {
  width: 100%; }
  .slick-initialized.slick-standard .slick-list .slick-track {
    display: flex;
    justify-content: center;
    gap: 0.8rem; }
    @media (max-width: 575.98px) {
      .slick-initialized.slick-standard .slick-list .slick-track {
        display: block; } }

.slick-initialized.slick-standard .slick-dots {
  margin-top: 1.5rem; }
  .slick-initialized.slick-standard .slick-dots li button {
    background-color: #D4CFCC; }
  .slick-initialized.slick-standard .slick-dots .slick-active button {
    background-color: #837C78; }

.actions-secondary .action.towishlist:hover,
.actions-secondary .action.remove:hover,
.product-addto-links .action.towishlist:hover,
.product-addto-links .action.remove:hover {
  opacity: 0.7; }

.actions-secondary .action.towishlist svg,
.actions-secondary .action.remove svg,
.product-addto-links .action.towishlist svg,
.product-addto-links .action.remove svg {
  width: 2rem;
  height: 1.8rem; }

.actions-secondary .action.remove.in-wishlist svg path,
.product-addto-links .action.remove.in-wishlist svg path {
  fill: currentColor; }

.btn {
  height: 50px;
  line-height: 1.55; }
  .btn.btn {
    border-width: 2px; }
    .btn.btn:not(.btn-link) {
      text-transform: uppercase;
      letter-spacing: 0.056rem; }
  .btn:focus-visible {
    outline: none; }

.btn-sm {
  height: 30px;
  line-height: 1.4; }

.btn-primary {
  --btn-color: #fff;
  --btn-bg: #40C5A2;
  --btn-border-color: #40C5A2;
  --btn-hover-color: #2D2926;
  --btn-hover-bg: #B7E9DB;
  --btn-hover-border-color: #B7E9DB;
  --btn-focus-shadow-rgb: 93, 206, 176;
  --btn-active-color: #2D2926;
  --btn-active-bg: #B7E9DB;
  --btn-active-border-color: #40C5A2;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF; }

.btn-secondary {
  --btn-color: #fff;
  --btn-bg: #CE0F69;
  --btn-border-color: #CE0F69;
  --btn-hover-color: #2D2926;
  --btn-hover-bg: #FFBFD9;
  --btn-hover-border-color: #FFBFD9;
  --btn-focus-shadow-rgb: 213, 51, 128;
  --btn-active-color: #000;
  --btn-active-bg: #FFBFD9;
  --btn-active-border-color: #CE0F69;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF; }

.btn-dark {
  --btn-color: #fff;
  --btn-bg: #2D2926;
  --btn-border-color: #2D2926;
  --btn-hover-color: #fff;
  --btn-hover-bg: #606060;
  --btn-hover-border-color: #606060;
  --btn-focus-shadow-rgb: 77, 73, 71;
  --btn-active-color: #fff;
  --btn-active-bg: #606060;
  --btn-active-border-color: #2D2926;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF; }

.action::before {
  display: none !important; }

.action.primary, .action.secondary {
  text-transform: uppercase;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  padding: 1.45rem 2.6rem;
  font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.3;
  color: #fff; }
  .action.primary:focus, .action.secondary:focus {
    box-shadow: none !important; }
  .action.primary:focus-visible, .action.secondary:focus-visible {
    outline: none; }

.action.clear, .action.back, .action.link {
  background: none;
  border: none;
  text-decoration: underline;
  font-weight: normal; }
  .action.clear:hover, .action.clear:focus, .action.back:hover, .action.back:focus, .action.link:hover, .action.link:focus {
    text-decoration: none; }

.action.continue, .action.update {
  padding-left: 2.6rem;
  padding-right: 2.6rem; }

.btn-close {
  background-size: 32px 32px; }
  .btn-close:hover {
    border: none; }

.action.primary,
.action-primary {
  --btn-padding-x: 2.6rem;
  --btn-padding-y: 1.45rem;
  --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --btn-font-size: 1.4rem;
  --btn-font-weight: 700;
  --btn-line-height: 1.55;
  --btn-color: #2D2926;
  --btn-bg: transparent;
  --btn-border-width: 2px;
  --btn-border-color: transparent;
  --btn-border-radius: 0.4rem;
  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --btn-disabled-opacity: 1;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  --btn-color: #fff;
  --btn-bg: #40C5A2;
  --btn-border-color: #40C5A2;
  --btn-hover-color: #2D2926;
  --btn-hover-bg: #B7E9DB;
  --btn-hover-border-color: #B7E9DB;
  --btn-focus-shadow-rgb: 93, 206, 176;
  --btn-active-color: #2D2926;
  --btn-active-bg: #B7E9DB;
  --btn-active-border-color: #40C5A2;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF;
  text-transform: uppercase; }
  .action.primary:hover,
  .action-primary:hover {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color); }
  .btn-check:focus + .action.primary, .action.primary:focus, .btn-check:focus +
  .action-primary,
  .action-primary:focus {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    outline: 0;
    box-shadow: var(--btn-focus-box-shadow); }
  .btn-check:checked + .action.primary,
  .btn-check:active + .action.primary, .action.primary:active, .action.primary.active, .action.primary.show, .btn-check:checked +
  .action-primary,
  .btn-check:active +
  .action-primary,
  .action-primary:active,
  .action-primary.active,
  .action-primary.show {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color); }
    .btn-check:checked + .action.primary:focus,
    .btn-check:active + .action.primary:focus, .action.primary:active:focus, .action.primary.active:focus, .action.primary.show:focus, .btn-check:checked +
    .action-primary:focus,
    .btn-check:active +
    .action-primary:focus,
    .action-primary:active:focus,
    .action-primary.active:focus,
    .action-primary.show:focus {
      box-shadow: var(--btn-focus-box-shadow); }
  .action.primary:disabled, .action.primary.disabled,
  fieldset:disabled .action.primary,
  .action-primary:disabled,
  .action-primary.disabled,
  fieldset:disabled
  .action-primary {
    color: var(--btn-disabled-color);
    pointer-events: none;
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color);
    opacity: var(--btn-disabled-opacity); }

.action.secondary,
.action-secondary {
  --btn-padding-x: 2.6rem;
  --btn-padding-y: 1.45rem;
  --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --btn-font-size: 1.4rem;
  --btn-font-weight: 700;
  --btn-line-height: 1.55;
  --btn-color: #2D2926;
  --btn-bg: transparent;
  --btn-border-width: 2px;
  --btn-border-color: transparent;
  --btn-border-radius: 0.4rem;
  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --btn-disabled-opacity: 1;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  --btn-color: #fff;
  --btn-bg: #CE0F69;
  --btn-border-color: #CE0F69;
  --btn-hover-color: #2D2926;
  --btn-hover-bg: #FFBFD9;
  --btn-hover-border-color: #FFBFD9;
  --btn-focus-shadow-rgb: 213, 51, 128;
  --btn-active-color: #000;
  --btn-active-bg: #FFBFD9;
  --btn-active-border-color: #CE0F69;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF;
  text-transform: uppercase; }
  .action.secondary:hover,
  .action-secondary:hover {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color); }
  .btn-check:focus + .action.secondary, .action.secondary:focus, .btn-check:focus +
  .action-secondary,
  .action-secondary:focus {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    outline: 0;
    box-shadow: var(--btn-focus-box-shadow); }
  .btn-check:checked + .action.secondary,
  .btn-check:active + .action.secondary, .action.secondary:active, .action.secondary.active, .action.secondary.show, .btn-check:checked +
  .action-secondary,
  .btn-check:active +
  .action-secondary,
  .action-secondary:active,
  .action-secondary.active,
  .action-secondary.show {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color); }
    .btn-check:checked + .action.secondary:focus,
    .btn-check:active + .action.secondary:focus, .action.secondary:active:focus, .action.secondary.active:focus, .action.secondary.show:focus, .btn-check:checked +
    .action-secondary:focus,
    .btn-check:active +
    .action-secondary:focus,
    .action-secondary:active:focus,
    .action-secondary.active:focus,
    .action-secondary.show:focus {
      box-shadow: var(--btn-focus-box-shadow); }
  .action.secondary:disabled, .action.secondary.disabled,
  fieldset:disabled .action.secondary,
  .action-secondary:disabled,
  .action-secondary.disabled,
  fieldset:disabled
  .action-secondary {
    color: var(--btn-disabled-color);
    pointer-events: none;
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color);
    opacity: var(--btn-disabled-opacity); }

.btn-outline-primary {
  color: #2D2926; }

.rendered-form .fieldset .fields .form-control {
  color: #837C78;
  padding: 1.4rem 1.5rem 1.4rem 1.8rem;
  border-radius: 0.3rem; }

.rendered-form .fieldset .fields .fb-text-label,
.rendered-form .fieldset .fields .fb-select-label {
  font-size: 1.3rem;
  margin-bottom: 0.25rem; }

.rendered-form .fieldset .fields .fb-radio .radio-group,
.rendered-form .fieldset .fields .fb-radio .checkbox-group,
.rendered-form .fieldset .fields .fb-checkbox .radio-group,
.rendered-form .fieldset .fields .fb-checkbox .checkbox-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem; }
  .rendered-form .fieldset .fields .fb-radio .radio-group label,
  .rendered-form .fieldset .fields .fb-radio .checkbox-group label,
  .rendered-form .fieldset .fields .fb-checkbox .radio-group label,
  .rendered-form .fieldset .fields .fb-checkbox .checkbox-group label {
    font-size: 1.3rem; }

.rendered-form .fieldset .fields .fb-radio .amform-groupv2,
.rendered-form .fieldset .fields .fb-checkbox .amform-groupv2 {
  background: #f8f8f8;
  border: 1px solid #D4CFCC;
  border-radius: 3px 50px 50px 3px;
  padding: 1.2rem 0;
  flex: 0 0 49%; }
  .rendered-form .fieldset .fields .fb-radio .amform-groupv2 .form-check-input,
  .rendered-form .fieldset .fields .fb-checkbox .amform-groupv2 .form-check-input {
    margin-left: 1.5rem;
    margin-right: 1rem; }
  .rendered-form .fieldset .fields .fb-radio .amform-groupv2 input[type='radio'],
  .rendered-form .fieldset .fields .fb-checkbox .amform-groupv2 input[type='radio'] {
    position: relative;
    background-image: none;
    background-color: #fff;
    border-color: #D4CFCC; }
    .rendered-form .fieldset .fields .fb-radio .amform-groupv2 input[type='radio']:checked::after,
    .rendered-form .fieldset .fields .fb-checkbox .amform-groupv2 input[type='radio']:checked::after {
      width: 1rem;
      height: 1rem;
      border-radius: 15px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      background-color: #2D2926;
      content: ''; }

.rendered-form .fieldset .fields .field .input-file {
  border: none;
  padding: 0;
  margin: 0; }
  .rendered-form .fieldset .fields .field .input-file::-webkit-file-upload-button {
    display: none; }
  .rendered-form .fieldset .fields .field .input-file::before {
    content: 'Select Files';
    display: inline-block;
    background: #2D2926;
    color: #fff;
    padding: 0.7rem 2.27rem;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 1rem;
    text-transform: uppercase;
    margin-right: 1rem;
    border: 1px solid #2D2926;
    border-radius: 3px;
    font-weight: 700;
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: 0.4px; }
  .rendered-form .fieldset .fields .field .input-file:hover::before {
    background-color: #606060;
    border-color: #606060; }

.rendered-form .fieldset .amcform-toolbar {
  width: 100%; }
  .rendered-form .fieldset .amcform-toolbar .amcform-submit {
    width: 100%;
    margin-top: 3rem;
    letter-spacing: 0.56px;
    --btn-padding-x: 2.6rem;
    --btn-padding-y: 1.45rem;
    --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    --btn-font-size: 1.4rem;
    --btn-font-weight: 700;
    --btn-line-height: 1.55;
    --btn-color: #2D2926;
    --btn-bg: transparent;
    --btn-border-width: 2px;
    --btn-border-color: transparent;
    --btn-border-radius: 0.4rem;
    --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
    --btn-disabled-opacity: 1;
    --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
    display: inline-block;
    padding: var(--btn-padding-y) var(--btn-padding-x);
    font-family: var(--btn-font-family);
    font-size: var(--btn-font-size);
    font-weight: var(--btn-font-weight);
    line-height: var(--btn-line-height);
    color: var(--btn-color);
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: var(--btn-border-width) solid var(--btn-border-color);
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-bg);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    --btn-color: #fff;
    --btn-bg: #2D2926;
    --btn-border-color: #2D2926;
    --btn-hover-color: #fff;
    --btn-hover-bg: #606060;
    --btn-hover-border-color: #606060;
    --btn-focus-shadow-rgb: 77, 73, 71;
    --btn-active-color: #fff;
    --btn-active-bg: #606060;
    --btn-active-border-color: #2D2926;
    --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --btn-disabled-color: #D4CFCC;
    --btn-disabled-bg: #F1F0EF;
    --btn-disabled-border-color: #F1F0EF; }
    .rendered-form .fieldset .amcform-toolbar .amcform-submit:hover {
      color: var(--btn-hover-color);
      background-color: var(--btn-hover-bg);
      border-color: var(--btn-hover-border-color); }
    .btn-check:focus + .rendered-form .fieldset .amcform-toolbar .amcform-submit, .rendered-form .fieldset .amcform-toolbar .amcform-submit:focus {
      color: var(--btn-hover-color);
      background-color: var(--btn-hover-bg);
      border-color: var(--btn-hover-border-color);
      outline: 0;
      box-shadow: var(--btn-focus-box-shadow); }
    .btn-check:checked + .rendered-form .fieldset .amcform-toolbar .amcform-submit,
    .btn-check:active + .rendered-form .fieldset .amcform-toolbar .amcform-submit, .rendered-form .fieldset .amcform-toolbar .amcform-submit:active, .rendered-form .fieldset .amcform-toolbar .amcform-submit.active, .rendered-form .fieldset .amcform-toolbar .amcform-submit.show {
      color: var(--btn-active-color);
      background-color: var(--btn-active-bg);
      border-color: var(--btn-active-border-color); }
      .btn-check:checked + .rendered-form .fieldset .amcform-toolbar .amcform-submit:focus,
      .btn-check:active + .rendered-form .fieldset .amcform-toolbar .amcform-submit:focus, .rendered-form .fieldset .amcform-toolbar .amcform-submit:active:focus, .rendered-form .fieldset .amcform-toolbar .amcform-submit.active:focus, .rendered-form .fieldset .amcform-toolbar .amcform-submit.show:focus {
        box-shadow: var(--btn-focus-box-shadow); }
    .rendered-form .fieldset .amcform-toolbar .amcform-submit:disabled, .rendered-form .fieldset .amcform-toolbar .amcform-submit.disabled,
    fieldset:disabled .rendered-form .fieldset .amcform-toolbar .amcform-submit {
      color: var(--btn-disabled-color);
      pointer-events: none;
      background-color: var(--btn-disabled-bg);
      border-color: var(--btn-disabled-border-color);
      opacity: var(--btn-disabled-opacity); }

.qty-wrapper {
  display: flex;
  margin-bottom: 0.7rem;
  gap: 0.7rem;
  font-size: 1.5rem;
  line-height: 1.6; }
  .qty-wrapper.out-of-stock .control.qty {
    background-color: #e9ecef; }
  .qty-wrapper .control.qty {
    position: relative;
    width: 35%;
    height: 4rem;
    max-width: 35%;
    display: flex;
    margin-top: 0;
    text-align: left;
    border: 1px solid #dedbd8;
    border-radius: 0.2rem; }
    .qty-wrapper .control.qty .btn-qty {
      width: 33%;
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: center;
      min-width: 30%;
      height: auto;
      border: none;
      box-shadow: none; }
      .qty-wrapper .control.qty .btn-qty:hover, .qty-wrapper .control.qty .btn-qty:active {
        border: none;
        box-shadow: none;
        opacity: 0.7; }
      .qty-wrapper .control.qty .btn-qty svg {
        width: 1.2rem;
        height: 1.2rem;
        color: #2D2926; }
    .qty-wrapper .control.qty .input-text {
      width: 34%;
      padding: 1.1rem 0;
      text-align: center;
      height: 100%;
      border: none; }
    .qty-wrapper .control.qty .cart-item-display-qty {
      font-size: 1.5rem;
      line-height: 1.6;
      width: 40%;
      padding: 0;
      text-align: center; }
    .qty-wrapper .control.qty .cart-item-qty {
      display: none; }
    .qty-wrapper .control.qty .form-control {
      border: none; }
  .qty-wrapper .control.uom {
    width: 65%;
    height: 4rem; }
    .qty-wrapper .control.uom .unit-of-measures {
      height: 100%;
      font-size: inherit;
      line-height: inherit;
      padding: 0 0 0 2rem; }
      @media (max-width: 1365.98px) {
        .qty-wrapper .control.uom .unit-of-measures {
          padding: 0 0 0 0.5rem;
          background-position: right 0.5rem center; } }
  @media (max-width: 767.98px) {
    .qty-wrapper {
      flex-direction: column; }
      .qty-wrapper .control.qty,
      .qty-wrapper .control.uom {
        min-width: 100%; } }

body {
  font-size: 1.5rem; }

.navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {
  /* stylelint-disable-line */ }

.page-layout-cms-full-width .page-main,
[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  max-width: 1920px;
  padding-left: 0 !important;
  padding-right: 0 !important; }

.promo-banner .slick-initialized {
  min-height: 0 !important; }

.promo-banner .row-full-width-inner,
.promo-banner .slick-initialized,
.promo-banner .slick-list,
.promo-banner .slick-track,
.promo-banner .slick-slide,
.promo-banner .slick-slide > div,
.promo-banner [data-content-type='slide'],
.promo-banner [data-element='link'],
.promo-banner .pagebuilder-slide-wrapper {
  height: inherit; }

.promo-banner [data-element='link'] {
  display: block; }

[data-content-type='row'][data-appearance='full-width'] {
  padding-left: 0 !important;
  padding-right: 0 !important; }

[data-content-type='row'][data-appearance='contained'] {
  max-width: 1322px; }
  [data-content-type='row'][data-appearance='contained'] [data-element='inner'] {
    margin: 0;
    padding: 0 2rem; }

::selection {
  background-color: #40C5A2;
  color: #fff; }

.column.main.main {
  padding-bottom: 0; }

.loader-container {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.3); }
  .loader-container .spinner-lg {
    width: 4rem;
    height: 4rem; }
  .loader-container .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0; }

.footer.content {
  border-top: none;
  max-width: inherit;
  margin-top: 0;
  padding: 0;
  background-color: #2D2926; }
  .footer.content .newsletter-wrapper {
    display: flex;
    justify-content: center;
    background-color: #CE0F69;
    margin-bottom: 4rem; }
    .footer.content .newsletter-wrapper.b2c {
      background-color: transparent; }
      @media (min-width: 768px) {
        .footer.content .newsletter-wrapper.b2c {
          position: relative;
          top: 41px;
          margin-bottom: 0.1rem; } }
    @media (max-width: 767.98px) {
      .footer.content .newsletter-wrapper {
        margin-bottom: 3.5rem; } }
    .footer.content .newsletter-wrapper .block.newsletter {
      max-width: 132.8rem;
      width: 100%;
      display: flex;
      margin-bottom: 0;
      float: none;
      flex-wrap: wrap; }
      @media (max-width: 767.98px) {
        .footer.content .newsletter-wrapper .block.newsletter {
          padding: 2.3rem 3.4rem 3.7rem 3.5rem;
          flex-direction: column;
          align-items: center; }
          .footer.content .newsletter-wrapper .block.newsletter .content {
            width: 100%;
            margin-bottom: 1rem;
            margin-top: -0.5rem; }
          .footer.content .newsletter-wrapper .block.newsletter label {
            width: 100%; } }
      .footer.content .newsletter-wrapper .block.newsletter div {
        flex-grow: 1;
        margin: 1rem 0; }
      .footer.content .newsletter-wrapper .block.newsletter .stay-in-the-know {
        color: #fff;
        margin-left: 4rem;
        align-items: center;
        display: flex;
        height: 70px; }
        @media (max-width: 575.98px) {
          .footer.content .newsletter-wrapper .block.newsletter .stay-in-the-know {
            margin: 0;
            height: 64px; }
            .footer.content .newsletter-wrapper .block.newsletter .stay-in-the-know svg {
              height: 46px; } }
      .footer.content .newsletter-wrapper .block.newsletter .subscribe .control::before {
        content: ''; }
      .footer.content .newsletter-wrapper .block.newsletter .subscribe .control label {
        position: relative; }
        .footer.content .newsletter-wrapper .block.newsletter .subscribe .control label::before {
          position: absolute;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("../images/icon-email.svg");
          content: '';
          height: 2.9rem;
          width: 2.9rem;
          top: 1rem;
          left: 2rem;
          font-size: 4rem; }
        .footer.content .newsletter-wrapper .block.newsletter .subscribe .control label::after {
          position: absolute;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("../images/icon-arrow-right.svg");
          content: '';
          height: 2.9rem;
          width: 2.9rem;
          top: 1rem;
          right: 2rem;
          font-size: 4rem; }
      .footer.content .newsletter-wrapper .block.newsletter .subscribe .control button {
        position: absolute;
        right: 1rem;
        top: 0;
        width: 5rem;
        height: 100%;
        z-index: 1;
        background: none;
        border: none; }
        .footer.content .newsletter-wrapper .block.newsletter .subscribe .control button:active {
          box-shadow: none; }
        .footer.content .newsletter-wrapper .block.newsletter .subscribe .control button i {
          font-size: 2.4rem; }
      .footer.content .newsletter-wrapper .block.newsletter .subscribe input[name='email'] {
        border-radius: 50rem;
        border: 2px solid #F1F0EF;
        padding: 0 0 0 6rem;
        font-size: 1.5rem; }
        .footer.content .newsletter-wrapper .block.newsletter .subscribe input[name='email']::placeholder {
          color: #a5a5a5; }
        @media (min-width: 768px) {
          .footer.content .newsletter-wrapper .block.newsletter .subscribe input[name='email'] {
            min-width: 30rem; } }
        @media (min-width: 1024px) {
          .footer.content .newsletter-wrapper .block.newsletter .subscribe input[name='email'] {
            min-width: 40rem; } }
      .footer.content .newsletter-wrapper .block.newsletter .subscribe #newsletter-error {
        position: absolute;
        color: #fff;
        margin: 0; }
  .footer.content .footer-social {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    @media (max-width: 767.98px) {
      .footer.content .footer-social {
        justify-content: center;
        padding: 0;
        margin: 0 !important; } }
    .footer.content .footer-social p {
      color: #fff;
      margin: 0 2rem 0 0;
      font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700; }
    .footer.content .footer-social a {
      transition: opacity 0.1s linear; }
      .footer.content .footer-social a::before {
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 2.9rem;
        width: 2.9rem;
        margin-right: 1rem;
        display: inline-block;
        vertical-align: top; }
        @media (max-width: 575.98px) {
          .footer.content .footer-social a::before {
            margin-right: 1.358rem; } }
      .footer.content .footer-social a:last-child::before {
        margin-right: 0; }
      .footer.content .footer-social a.facebook::before {
        background-image: url("../images/icon-facebook.svg"); }
      .footer.content .footer-social a.instagram::before {
        background-image: url("../images/icon-instagram.svg"); }
      .footer.content .footer-social a.linkedin::before {
        background-image: url("../images/icon-linkedin.svg"); }
      .footer.content .footer-social a:hover {
        opacity: 0.7; }
  @media (max-width: 575.98px) {
    .footer.content .footer-wrapper {
      padding: 0 2rem; } }
  @media (min-width: 768px) {
    .footer.content .footer-wrapper .accordion .collapse,
    .footer.content .footer-wrapper .accordion .collapsing {
      display: block !important;
      height: auto !important; }
    .footer.content .footer-wrapper .accordion .btn-link {
      font-weight: 700;
      text-decoration: none;
      color: #fff;
      padding: 0;
      text-align: left; }
      .footer.content .footer-wrapper .accordion .btn-link:hover, .footer.content .footer-wrapper .accordion .btn-link:focus, .footer.content .footer-wrapper .accordion .btn-link:active {
        text-decoration: none;
        cursor: default;
        box-shadow: none !important; }
      .footer.content .footer-wrapper .accordion .btn-link svg {
        display: none; } }
  @media (max-width: 767.98px) {
    .footer.content .footer-wrapper .accordion ul {
      margin: -0.5rem 0 0 0.3rem;
      padding-bottom: 1rem; }
      .footer.content .footer-wrapper .accordion ul li {
        margin: 0.85rem 0 !important; }
    .footer.content .footer-wrapper .accordion .btn-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0.2rem;
      padding: 1.8rem 0 1.8rem 0;
      font-weight: 700;
      text-decoration: none;
      color: #fff; }
      .footer.content .footer-wrapper .accordion .btn-link:focus {
        box-shadow: none; }
      .footer.content .footer-wrapper .accordion .btn-link svg {
        margin-top: -1rem; }
        .footer.content .footer-wrapper .accordion .btn-link svg:last-child {
          display: none; }
      .footer.content .footer-wrapper .accordion .btn-link:not(.collapsed) svg {
        display: none; }
        .footer.content .footer-wrapper .accordion .btn-link:not(.collapsed) svg:last-child {
          display: block; } }
  @media (max-width: 575.98px) {
    .footer.content .footer-wrapper .footer-links {
      padding-left: 2rem;
      padding-right: 0.5rem; } }
  .footer.content .footer-wrapper .footer-links h2 button, .footer.content .footer-wrapper .footer-links .h2 button {
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.6rem; }
  .footer.content .footer-wrapper .footer-links ul li {
    margin: 1rem 0; }
    .footer.content .footer-wrapper .footer-links ul li a {
      font-size: 1.3rem;
      text-decoration: none;
      color: #fff; }
      .footer.content .footer-wrapper .footer-links ul li a:hover {
        text-decoration: underline; }
  .footer.content .footer-wrapper .bottom-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 3rem 0; }
    @media (max-width: 575.98px) {
      .footer.content .footer-wrapper .bottom-footer {
        padding: 3.5rem 0 5.097rem;
        flex-direction: column;
        align-items: flex-start; }
        .footer.content .footer-wrapper .bottom-footer .acknowledgement {
          max-width: 33.8rem;
          width: 100%;
          text-align: left;
          margin-top: 2.8rem; } }
  .footer.content .footer-wrapper .copyright {
    margin-top: 0; }
    .footer.content .footer-wrapper .copyright span {
      color: #fff;
      font-size: 1.3rem;
      font-weight: 700; }
  .footer.content .footer-wrapper .acknowledgement {
    width: 42.2rem;
    text-align: right;
    line-height: 1.7; }
  .footer.content .footer-wrapper .acknowledgement,
  .footer.content .footer-wrapper .footer-note {
    font-size: 1.3rem;
    color: #fff; }
  @media (max-width: 767.98px) {
    .footer.content .newsletter-wrapper.b2c {
      margin-bottom: 4.5rem; }
      .footer.content .newsletter-wrapper.b2c .block.newsletter {
        padding: 2.61rem 0 2.148rem; }
    .footer.content .footer-wrapper.b2c .bottom-footer {
      padding: 5rem 0 8.897rem; } }

.page-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
  margin-bottom: 0; }
  .page-header::before {
    box-shadow: inset 0 5px 6px rgba(0, 0, 0, 0.16);
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 1.2rem;
    pointer-events: none; }
  .page-header .header-section-wrapper {
    background-color: #fff;
    position: relative; }
    .page-header .header-section-wrapper .header.content {
      display: flex;
      align-items: center;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      position: initial; }
      @media (max-width: 1365.98px) {
        .page-header .header-section-wrapper .header.content {
          max-width: 100%; } }
      @media (max-width: 1023.98px) {
        .page-header .header-section-wrapper .header.content {
          height: 70px;
          justify-content: space-between;
          padding-left: 1.4rem;
          padding-right: 1.4rem; }
          .page-header .header-section-wrapper .header.content .nav-toggle {
            position: static;
            padding: 0 1.2rem 0 0;
            height: 3.2rem;
            border: none; }
            .page-header .header-section-wrapper .header.content .nav-toggle img {
              width: 3.2rem;
              height: 3.2rem; }
          .page-header .header-section-wrapper .header.content .search-toggle {
            width: 3.2rem;
            height: 3.2rem;
            margin-left: 0.25rem;
            border: none;
            display: block; } }
    @media (max-width: 1023.98px) and (max-width: 1023.98px) {
      .page-header .header-section-wrapper .header.content .search-toggle.hide {
        display: none; } }
    @media (max-width: 1023.98px) and (max-width: 575.98px) {
      .page-header .header-section-wrapper .header.content .search-toggle {
        margin-left: 0; } }
      @media (max-width: 1023.98px) {
          .page-header .header-section-wrapper .header.content .logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
            .page-header .header-section-wrapper .header.content .logo.b2c {
              margin-left: 0; }
            .page-header .header-section-wrapper .header.content .logo img {
              width: 9.8rem;
              height: 4.9rem; }
          .page-header .header-section-wrapper .header.content .header-links .header.links {
            padding-left: 0; } }
    @media (max-width: 1023.98px) and (max-width: 575.98px) {
      .page-header .header-section-wrapper .header.content .header-links .header.links {
        margin-right: -0.5rem; } }
      @media (max-width: 1023.98px) {
            .page-header .header-section-wrapper .header.content .header-links .header.links li.welcome {
              display: none; }
            .page-header .header-section-wrapper .header.content .header-links .header.links li.header-link-account img,
            .page-header .header-section-wrapper .header.content .header-links .header.links li.header-link.wishlist img,
            .page-header .header-section-wrapper .header.content .header-links .header.links li.minicart-wrapper-mobile img {
              width: 3.2rem;
              height: 3.2rem; } }
  @media (max-width: 767.98px) {
    .page-header .lac-notification-sticky ~ .header-section-wrapper .amsearch-wrapper-block {
      top: 11.3rem; } }
  @media (min-width: 768px) and (max-width: 1023.98px) {
    .page-header .lac-notification-sticky ~ .header-section-wrapper .amsearch-wrapper-block {
      top: 8.7rem; } }
  .page-header .amsearch-wrapper-block {
    position: static; }
    .page-header .amsearch-wrapper-block::before {
      display: none; }
    @media (max-width: 1023.98px) {
      .page-header .amsearch-wrapper-block {
        position: fixed;
        top: 3rem;
        left: 0;
        margin: 0;
        z-index: 99;
        background: #fff;
        padding: 0;
        display: block;
        width: 100%; }
        .page-header .amsearch-wrapper-block.hide {
          display: none; } }
    .page-header .amsearch-wrapper-block .amsearch-form-block {
      position: initial; }
      .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper {
        width: 50rem;
        height: 5.3rem; }
        @media (min-width: 1024px) {
          .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            transform: translate(-50%, -50%); } }
        @media (min-width: 1024px) and (max-width: 1365.98px) {
          .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper {
            max-width: 350px;
            margin-left: -5px; } }
        @media (max-width: 1023.98px) {
          .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper {
            width: 100%;
            height: 7.1rem; } }
        .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-input {
          width: 100%;
          height: 100%;
          border-radius: 40px;
          border: 2px solid #c7c7c7;
          padding-left: 3rem; }
          @media (min-width: 1024px) and (max-width: 1365.98px) {
            .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-input {
              padding-left: 2rem;
              font-size: 1.4rem; } }
          .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-input:focus-visible {
            outline-color: #606060; }
          @media (max-width: 1023.98px) {
            .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-input {
              border: none;
              border-radius: 0;
              padding-left: 2rem; }
              .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-input:focus-visible {
                outline: none; } }
        .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-loader-block {
          display: none !important; }
        .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-loupe {
          background-image: none;
          right: 3rem;
          top: 0;
          width: auto;
          height: auto;
          opacity: 1; }
          @media (min-width: 1024px) and (max-width: 1365.98px) {
            .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-loupe {
              right: 1.3rem; } }
          .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-loupe svg path {
            fill: #606060; }
            @media (max-width: 575.98px) {
              .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-loupe svg path {
                fill: #fff; } }
          @media (max-width: 575.98px) {
            .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-loupe {
              right: 0;
              background-color: #CE0F69;
              padding: 0 1.95rem;
              opacity: 1; }
              .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper .amsearch-button.-loupe svg {
                width: 3.2rem;
                height: 3.2rem; } }
        .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper.-typed .amsearch-button.-close {
          right: 6.5rem;
          background-image: none;
          color: #837C78;
          font-size: 1.3rem; }
          @media (max-width: 575.98px) {
            .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper.-typed .amsearch-button.-close {
              right: 8rem; } }
        .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-input-wrapper.-typed .amsearch-button.-loupe {
          left: auto;
          background-image: none; }
      .page-header .amsearch-wrapper-block .amsearch-form-block.-opened {
        width: 100% !important; }
        .page-header .amsearch-wrapper-block .amsearch-form-block.-opened .amsearch-input-wrapper .amsearch-button.-loupe svg path {
          fill: #606060; }
          @media (max-width: 575.98px) {
            .page-header .amsearch-wrapper-block .amsearch-form-block.-opened .amsearch-input-wrapper .amsearch-button.-loupe svg path {
              fill: #fff; } }
      .page-header .amsearch-wrapper-block .amsearch-form-block .amsearch-result-section {
        margin-top: 0;
        border-radius: 0; }
  .page-header .logo {
    margin-bottom: 0;
    margin-left: 0; }
  .page-header .header-links {
    margin-left: auto; }
    .page-header .header-links .header.links {
      list-style: none;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      flex-shrink: 0; }
      @media (min-width: 1024px) {
        .page-header .header-links .header.links {
          margin-right: 1rem; } }
      .page-header .header-links .header.links > li {
        float: none;
        margin-left: 1.2rem;
        margin-top: 0;
        margin-bottom: 0; }
        @media (max-width: 575.98px) {
          .page-header .header-links .header.links > li {
            margin-left: 0.7rem; } }
        .page-header .header-links .header.links > li .header-link {
          position: relative;
          transition: opacity 0.1s linear;
          display: flex;
          align-items: center;
          text-decoration: none; }
          .page-header .header-links .header.links > li .header-link:hover {
            opacity: 0.7; }
        .page-header .header-links .header.links > li > .showAccount span {
          margin-right: 0.8rem;
          font-weight: 700;
          font-size: 1.2rem; }
          .page-header .header-links .header.links > li > .showAccount span:hover {
            text-decoration: underline; }
        .page-header .header-links .header.links > li.welcome {
          max-width: 190px;
          text-align: right; }
          @media (min-width: 1024px) and (max-width: 1365.98px) {
            .page-header .header-links .header.links > li.welcome {
              max-width: 148px; } }
          .page-header .header-links .header.links > li.welcome p {
            margin-bottom: 0;
            font-size: 1.1rem;
            line-height: 1.3rem; }
          .page-header .header-links .header.links > li.welcome span {
            font-weight: 700; }
        .page-header .header-links .header.links > li .counter.qty:not(:empty) {
          position: absolute;
          top: 50%;
          right: -4px;
          min-width: 20px;
          height: 20px;
          margin: -0.8rem 0 0;
          padding: 0 0.3rem;
          border-radius: 10px;
          transform: translateY(-50%);
          background-color: #CE0F69;
          color: #fff;
          line-height: 19px;
          text-align: center;
          font-weight: 700;
          font-size: 1.2rem; }
        .page-header .header-links .header.links > li.wishlist .counter {
          display: none; }
        .page-header .header-links .header.links > li.minicart-wrapper .counter.qty:not(:empty), .page-header .header-links .header.links > li.minicart-wrapper-mobile .counter.qty:not(:empty) {
          top: unset;
          bottom: 0;
          right: 4px;
          min-width: 1.9rem;
          height: 1.9rem; }
          @media (max-width: 1023.98px) {
            .page-header .header-links .header.links > li.minicart-wrapper .counter.qty:not(:empty), .page-header .header-links .header.links > li.minicart-wrapper-mobile .counter.qty:not(:empty) {
              bottom: -6px; }
              .page-header .header-links .header.links > li.minicart-wrapper .counter.qty:not(:empty) .loading-mask .loader-container .spinner-lg, .page-header .header-links .header.links > li.minicart-wrapper-mobile .counter.qty:not(:empty) .loading-mask .loader-container .spinner-lg {
                width: 0.5rem;
                height: 0.5rem; } }
        .page-header .header-links .header.links > li.customer-welcome .customer-menu {
          display: none;
          position: relative; }
          .page-header .header-links .header.links > li.customer-welcome .customer-menu .account-links {
            display: flex;
            flex-direction: column;
            list-style: none;
            position: absolute;
            border: 1px solid #D4CFCC;
            z-index: 9;
            min-width: 16rem;
            background: #fff;
            top: 0;
            right: 0;
            padding: 0;
            box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
            border-radius: 0.4rem; }
            @media (max-width: 1023.98px) {
              .page-header .header-links .header.links > li.customer-welcome .customer-menu .account-links {
                right: -5px; } }
            .page-header .header-links .header.links > li.customer-welcome .customer-menu .account-links::before {
              content: '';
              display: block;
              height: 0;
              position: absolute;
              width: 0;
              border: 6px solid;
              border-color: transparent transparent #fff transparent;
              z-index: 99;
              right: 1.3rem;
              top: -1.2rem; }
            .page-header .header-links .header.links > li.customer-welcome .customer-menu .account-links::after {
              content: '';
              display: block;
              height: 0;
              position: absolute;
              width: 0;
              border: 7px solid;
              border-color: transparent transparent #D4CFCC transparent;
              z-index: 98;
              right: 1.2rem;
              top: -1.4rem; }
            .page-header .header-links .header.links > li.customer-welcome .customer-menu .account-links li {
              margin: 0; }
              .page-header .header-links .header.links > li.customer-welcome .customer-menu .account-links li a {
                text-decoration: none;
                display: block;
                padding: 1rem 1.5rem;
                font-size: 1.3rem; }
                .page-header .header-links .header.links > li.customer-welcome .customer-menu .account-links li a:hover {
                  background-color: #e8e8e8; }
              .page-header .header-links .header.links > li.customer-welcome .customer-menu .account-links li:first-child a {
                border-radius: 0.4rem 0.4rem 0 0; }
              .page-header .header-links .header.links > li.customer-welcome .customer-menu .account-links li:last-child a {
                border-radius: 0 0 0.4rem 0.4rem; }
        .page-header .header-links .header.links > li.customer-welcome.active .customer-menu, .page-header .header-links .header.links > li.customer-welcome:hover .customer-menu {
          display: block; }

.section-header .customer-section {
  display: none; }

@media (max-width: 1023.98px) {
  .uhp-header-cta-wrapper {
    height: 3rem; }
  .header.content .nav-toggle::after {
    content: none; }
  .nav-open .nav-sections {
    box-shadow: none; } }

.uhp-header-cta-wrapper .header-cta-wrapper {
  background-color: #40C5A2;
  padding: 0.6rem 2rem;
  text-align: center;
  color: #fff;
  font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 600;
  font-size: 1.2rem;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  z-index: auto; }
  .uhp-header-cta-wrapper .header-cta-wrapper p {
    margin-bottom: 0; }

.nav-sections-item-content {
  flex-direction: column; }

.header-menu-cta-wrapper.header-menu-cta-wrapper {
  background-color: #CE0F69;
  min-width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400; }
  .header-menu-cta-wrapper.header-menu-cta-wrapper ul {
    display: flex;
    justify-content: center;
    margin: 0 auto; }
    .header-menu-cta-wrapper.header-menu-cta-wrapper ul > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }
    .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0 {
      display: flex;
      align-items: stretch;
      height: 5rem;
      margin: 0; }
      .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0.parent > .submenu {
        display: none;
        top: 100%;
        flex-direction: column;
        min-width: 140px; }
        .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0.parent > .submenu .level1 {
          padding: 0; }
          .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0.parent > .submenu .level1 [href] {
            text-decoration: none;
            color: #2D2926;
            font-size: 1.3rem;
            line-height: 2.7; }
      .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0:hover {
        background-color: #2D2926; }
        .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0:hover.parent > .submenu {
          display: flex; }
      .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0 > a {
        display: flex;
        align-items: center;
        color: #fff;
        padding: 0 2.8rem;
        text-transform: uppercase;
        text-decoration: none;
        line-height: 1;
        font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-weight: 600;
        font-size: 1.5rem; }
      .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0 ul.submenu {
        background-color: #fff;
        text-transform: capitalize;
        border: 0;
        min-width: initial;
        width: 100%; }
        .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0 ul.submenu li.level1 {
          list-style: none none;
          text-align: left;
          color: #fff;
          font-weight: 400;
          padding-left: 2rem; }
          .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0 ul.submenu li.level1:hover {
            background-color: #fff; }
          .header-menu-cta-wrapper.header-menu-cta-wrapper ul li.level0 ul.submenu li.level1 a {
            color: #fff; }

.customer-welcome .action.switch,
.customer-section .action.switch {
  border: none;
  background-color: transparent;
  padding: 0; }
  .customer-welcome .action.switch:active,
  .customer-section .action.switch:active {
    box-shadow: none; }

.section-item-content .customer-menu {
  display: none; }
  .section-item-content .customer-menu .account-links {
    display: flex;
    flex-direction: column;
    list-style: none none; }
    .section-item-content .customer-menu .account-links li > a {
      padding-top: 1.6rem;
      padding-left: 7rem;
      padding-bottom: 1.6rem;
      color: #575757 !important; }

.cms-home-page .page-wrapper .page-main,
.cms-b2b-home-page .page-wrapper .page-main {
  max-width: none;
  padding: 0;
  margin: 0; }
  .cms-home-page .page-wrapper .page-main .page-title-wrapper,
  .cms-b2b-home-page .page-wrapper .page-main .page-title-wrapper {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important; }
  .cms-home-page .page-wrapper .page-main .column.main,
  .cms-b2b-home-page .page-wrapper .page-main .column.main {
    margin: 0;
    max-width: none; }
    .cms-home-page .page-wrapper .page-main .column.main .home-blog,
    .cms-b2b-home-page .page-wrapper .page-main .column.main .home-blog {
      max-width: 1325px;
      padding: 0 1.5rem; }
      @media (max-width: 575.98px) {
        .cms-home-page .page-wrapper .page-main .column.main .home-blog,
        .cms-b2b-home-page .page-wrapper .page-main .column.main .home-blog {
          padding: 0; } }
    .cms-home-page .page-wrapper .page-main .column.main .home-banner [data-content-type='slide'],
    .cms-b2b-home-page .page-wrapper .page-main .column.main .home-banner [data-content-type='slide'] {
      position: relative;
      height: 0;
      padding-bottom: 35.4%; }
    .cms-home-page .page-wrapper .page-main .column.main .home-banner .pagebuilder-column,
    .cms-home-page .page-wrapper .page-main .column.main .home-banner .pagebuilder-overlay,
    .cms-b2b-home-page .page-wrapper .page-main .column.main .home-banner .pagebuilder-column,
    .cms-b2b-home-page .page-wrapper .page-main .column.main .home-banner .pagebuilder-overlay {
      min-height: 0 !important; }
    .cms-home-page .page-wrapper .page-main .column.main .home-banner .pagebuilder-slide-wrapper,
    .cms-b2b-home-page .page-wrapper .page-main .column.main .home-banner .pagebuilder-slide-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center !important;
      min-height: 0 !important; }
    .cms-home-page .page-wrapper .page-main .column.main .home-banner .slick-slider,
    .cms-b2b-home-page .page-wrapper .page-main .column.main .home-banner .slick-slider {
      min-height: unset !important; }
    @media (max-width: 640px) {
      .cms-home-page .page-wrapper .page-main .column.main .home-banner .slick-slider [data-content-type='slide'],
      .cms-b2b-home-page .page-wrapper .page-main .column.main .home-banner .slick-slider [data-content-type='slide'] {
        padding-bottom: 60.4%; }
        .cms-home-page .page-wrapper .page-main .column.main .home-banner .slick-slider [data-content-type='slide'] .pagebuilder-slide-wrapper,
        .cms-b2b-home-page .page-wrapper .page-main .column.main .home-banner .slick-slider [data-content-type='slide'] .pagebuilder-slide-wrapper {
          min-height: unset !important; } }

.cms-home-page .home-shop-our-brands {
  margin-top: 3rem !important; }
  .cms-home-page .home-shop-our-brands h2, .cms-home-page .home-shop-our-brands .h2 {
    margin-bottom: 1rem;
    font-size: 2.4rem; }
  .cms-home-page .home-shop-our-brands .pagebuilder-column {
    margin: 0 1rem; }
    .cms-home-page .home-shop-our-brands .pagebuilder-column:first-child {
      margin-left: 0; }

.cms-home-page .home-view-all-brands {
  padding: 1rem 2rem 3rem; }
  .cms-home-page .home-view-all-brands a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .cms-home-page .home-view-all-brands a::after {
      content: '';
      display: inline-block;
      background-image: url("../images/icon-arrow-right.svg");
      background-size: 24px 24px;
      background-repeat: no-repeat;
      width: 2.4rem;
      height: 2.4rem;
      margin-left: 0.5rem; }

.cms-home-page .home-featured-on-the-blog {
  margin-top: 3rem !important; }
  .cms-home-page .home-featured-on-the-blog h2, .cms-home-page .home-featured-on-the-blog .h2 {
    margin-bottom: 2rem;
    font-size: 2.4rem; }

.cms-home-page .home-specialisation-container {
  padding: 6rem 6rem 3rem !important; }
  .cms-home-page .home-specialisation-container h2, .cms-home-page .home-specialisation-container .h2 {
    display: none; }
  .cms-home-page .home-specialisation-container .svg-container {
    margin-bottom: 1rem;
    height: 47.5px;
    display: flex;
    align-items: center; }
    .cms-home-page .home-specialisation-container .svg-container svg {
      fill: var(--body-color);
      margin: auto; }
      @media (max-width: 575.98px) {
        .cms-home-page .home-specialisation-container .svg-container svg {
          height: 35px; } }
  .cms-home-page .home-specialisation-container ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0 2rem; }
    .cms-home-page .home-specialisation-container ul li {
      text-transform: uppercase;
      font-size: 3rem;
      font-weight: 700;
      font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
      .cms-home-page .home-specialisation-container ul li:not(:last-child)::after {
        content: '';
        display: inline-block;
        width: 1rem;
        height: 1rem;
        border-radius: 10px;
        background-color: #CE0F69;
        margin-left: 3.5rem;
        margin-right: 3rem;
        margin-bottom: 0.5rem; }
      @media (max-width: 1023.98px) {
        .cms-home-page .home-specialisation-container ul li {
          font-size: 2.5rem; } }
      @media (max-width: 575.98px) {
        .cms-home-page .home-specialisation-container ul li {
          font-size: 1.8rem;
          display: inline; }
          .cms-home-page .home-specialisation-container ul li:not(:last-child)::after {
            margin-left: 1rem;
            margin-right: 1rem;
            margin-bottom: 0.1rem;
            font-size: 0.4rem; } }
    @media (max-width: 575.98px) {
      .cms-home-page .home-specialisation-container ul {
        padding: 0;
        justify-content: center;
        margin-bottom: 3rem; } }
  .cms-home-page .home-specialisation-container p {
    font-size: 2rem;
    font-weight: 700;
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-align: center;
    line-height: 1.5; }
  @media (max-width: 575.98px) {
    .cms-home-page .home-specialisation-container {
      padding: 4.5rem 3rem 3rem !important; } }

.cms-home-page .home-brands-container {
  margin-left: auto;
  margin-right: auto;
  background-color: #DCF4EE;
  padding: 6rem 0 7rem !important; }
  .cms-home-page .home-brands-container .b2c-brands {
    max-width: 132.2rem;
    width: 100%;
    margin: 0 auto;
    padding: 0 4rem; }
    .cms-home-page .home-brands-container .b2c-brands .slick-track {
      gap: 0; }
      .cms-home-page .home-brands-container .b2c-brands .slick-track .slick-slide {
        line-height: 0; }
        .cms-home-page .home-brands-container .b2c-brands .slick-track .slick-slide .b2c-brand {
          overflow: hidden; }
        .cms-home-page .home-brands-container .b2c-brands .slick-track .slick-slide .slick-item-img {
          transition: 0.5s all ease-in-out; }
          .cms-home-page .home-brands-container .b2c-brands .slick-track .slick-slide .slick-item-img:hover {
            transform: scale(1.1); }
  .cms-home-page .home-brands-container .pagebuilder-column-group {
    justify-content: center; }
    .cms-home-page .home-brands-container .pagebuilder-column-group .pagebuilder-column {
      display: inline-flex !important;
      width: auto !important;
      margin-bottom: -1rem; }
      @media (max-width: 575.98px) {
        .cms-home-page .home-brands-container .pagebuilder-column-group .pagebuilder-column {
          flex-basis: 50%; } }
  .cms-home-page .home-brands-container h2, .cms-home-page .home-brands-container .h2 {
    margin-bottom: 0;
    font-size: 3rem; }
    @media (max-width: 575.98px) {
      .cms-home-page .home-brands-container h2, .cms-home-page .home-brands-container .h2 {
        font-size: 2.2rem;
        padding: 0 7.5rem; } }
  .cms-home-page .home-brands-container [data-content-type='buttons'] {
    margin: 4rem auto 4.5rem; }
    .cms-home-page .home-brands-container [data-content-type='buttons'].desktop-only {
      display: block; }
    .cms-home-page .home-brands-container [data-content-type='buttons'].mobile-only {
      display: none; }
    @media (max-width: 575.98px) {
      .cms-home-page .home-brands-container [data-content-type='buttons'] {
        padding: 0 !important; }
        .cms-home-page .home-brands-container [data-content-type='buttons'].desktop-only {
          display: none; }
        .cms-home-page .home-brands-container [data-content-type='buttons'].mobile-only {
          display: block; } }
    .cms-home-page .home-brands-container [data-content-type='buttons'] a {
      height: 50px;
      text-transform: uppercase;
      letter-spacing: 0.056rem;
      --btn-padding-x: 2.6rem;
      --btn-padding-y: 1.45rem;
      --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      --btn-font-size: 1.4rem;
      --btn-font-weight: 700;
      --btn-line-height: 1.55;
      --btn-color: #2D2926;
      --btn-bg: transparent;
      --btn-border-width: 2px;
      --btn-border-color: transparent;
      --btn-border-radius: 0.4rem;
      --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
      --btn-disabled-opacity: 1;
      --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
      display: inline-block;
      padding: var(--btn-padding-y) var(--btn-padding-x);
      font-family: var(--btn-font-family);
      font-size: var(--btn-font-size);
      font-weight: var(--btn-font-weight);
      line-height: var(--btn-line-height);
      color: var(--btn-color);
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
      border: var(--btn-border-width) solid var(--btn-border-color);
      border-radius: var(--btn-border-radius);
      background-color: var(--btn-bg);
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      --btn-color: #2D2926;
      --btn-bg: transparent;
      --btn-border-color: #2D2926;
      --btn-hover-color: #fff;
      --btn-hover-bg: #2D2926;
      --btn-hover-border-color: #2D2926;
      --btn-focus-shadow-rgb: 45, 41, 38;
      --btn-active-color: #fff;
      --btn-active-bg: #2D2926;
      --btn-active-border-color: #2D2926;
      --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      --btn-disabled-color: #D4CFCC;
      --btn-disabled-bg: #F1F0EF;
      --btn-disabled-border-color: #F1F0EF; }
      .cms-home-page .home-brands-container [data-content-type='buttons'] a:hover {
        color: var(--btn-hover-color);
        background-color: var(--btn-hover-bg);
        border-color: var(--btn-hover-border-color); }
      .btn-check:focus + .cms-home-page .home-brands-container [data-content-type='buttons'] a, .cms-home-page .home-brands-container [data-content-type='buttons'] a:focus {
        color: var(--btn-hover-color);
        background-color: var(--btn-hover-bg);
        border-color: var(--btn-hover-border-color);
        outline: 0;
        box-shadow: var(--btn-focus-box-shadow); }
      .btn-check:checked + .cms-home-page .home-brands-container [data-content-type='buttons'] a,
      .btn-check:active + .cms-home-page .home-brands-container [data-content-type='buttons'] a, .cms-home-page .home-brands-container [data-content-type='buttons'] a:active, .cms-home-page .home-brands-container [data-content-type='buttons'] a.active, .cms-home-page .home-brands-container [data-content-type='buttons'] a.show {
        color: var(--btn-active-color);
        background-color: var(--btn-active-bg);
        border-color: var(--btn-active-border-color); }
        .btn-check:checked + .cms-home-page .home-brands-container [data-content-type='buttons'] a:focus,
        .btn-check:active + .cms-home-page .home-brands-container [data-content-type='buttons'] a:focus, .cms-home-page .home-brands-container [data-content-type='buttons'] a:active:focus, .cms-home-page .home-brands-container [data-content-type='buttons'] a.active:focus, .cms-home-page .home-brands-container [data-content-type='buttons'] a.show:focus {
          box-shadow: var(--btn-focus-box-shadow); }
      .cms-home-page .home-brands-container [data-content-type='buttons'] a:disabled, .cms-home-page .home-brands-container [data-content-type='buttons'] a.disabled,
      fieldset:disabled .cms-home-page .home-brands-container [data-content-type='buttons'] a {
        color: var(--btn-disabled-color);
        pointer-events: none;
        background-color: var(--btn-disabled-bg);
        border-color: var(--btn-disabled-border-color);
        opacity: var(--btn-disabled-opacity); }
  @media (max-width: 575.98px) {
    .cms-home-page .home-brands-container {
      padding: 3.8rem 0 1.8rem !important; }
      .cms-home-page .home-brands-container .b2c-brands {
        padding: 0; }
      .cms-home-page .home-brands-container .slick-initialized.slick-standard .b2c-brand {
        max-width: 16rem; }
      .cms-home-page .home-brands-container .slick-initialized.slick-standard .slick-list {
        padding-left: 4.7rem; }
      .cms-home-page .home-brands-container .slick-initialized.slick-standard .slick-prev:not(.primary),
      .cms-home-page .home-brands-container .slick-initialized.slick-standard .slick-next:not(.primary) {
        background: rgba(220, 244, 238, 0.8); }
      .cms-home-page .home-brands-container .slick-initialized.slick-standard .slick-arrow {
        width: 4.7rem;
        top: 43%;
        bottom: -29%;
        height: calc(100% - 5.1rem); }
      .cms-home-page .home-brands-container .slick-initialized.slick-standard .slick-prev {
        left: 0; }
      .cms-home-page .home-brands-container .slick-initialized.slick-standard .slick-next {
        right: 0; }
      .cms-home-page .home-brands-container .slick-initialized.slick-standard .slick-dots {
        margin-top: 0.5rem;
        margin-left: 0; } }

.cms-home-page .home-business-container {
  padding: 6rem 4rem !important; }
  .cms-home-page .home-business-container h2, .cms-home-page .home-business-container .h2 {
    margin-bottom: 0;
    font-size: 3rem; }
    .cms-home-page .home-business-container h2 + div, .cms-home-page .home-business-container .h2 + div {
      width: 60rem;
      margin: 0 auto; }
  .cms-home-page .home-business-container .pagebuilder-column-group {
    gap: 3.6rem; }
    @media (min-width: 576px) {
      .cms-home-page .home-business-container .pagebuilder-column-group {
        flex-wrap: nowrap; }
        .cms-home-page .home-business-container .pagebuilder-column-group .pagebuilder-column {
          width: 50%; } }
  .cms-home-page .home-business-container .pagebuilder-column {
    color: #fff; }
    .cms-home-page .home-business-container .pagebuilder-column:first-child {
      background-color: #ff8674; }
    .cms-home-page .home-business-container .pagebuilder-column:last-child {
      background-color: #40C5A2; }
    .cms-home-page .home-business-container .pagebuilder-column a {
      height: 50px;
      min-width: 115px;
      letter-spacing: 0.54px;
      text-transform: uppercase;
      --btn-padding-x: 2.6rem;
      --btn-padding-y: 1.45rem;
      --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
      --btn-font-size: 1.4rem;
      --btn-font-weight: 700;
      --btn-line-height: 1.55;
      --btn-color: #2D2926;
      --btn-bg: transparent;
      --btn-border-width: 2px;
      --btn-border-color: transparent;
      --btn-border-radius: 0.4rem;
      --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
      --btn-disabled-opacity: 1;
      --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
      display: inline-block;
      padding: var(--btn-padding-y) var(--btn-padding-x);
      font-family: var(--btn-font-family);
      font-size: var(--btn-font-size);
      font-weight: var(--btn-font-weight);
      line-height: var(--btn-line-height);
      color: var(--btn-color);
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
      border: var(--btn-border-width) solid var(--btn-border-color);
      border-radius: var(--btn-border-radius);
      background-color: var(--btn-bg);
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      --btn-color: #fff;
      --btn-bg: transparent;
      --btn-border-color: #fff;
      --btn-hover-color: #2D2926;
      --btn-hover-bg: #fff;
      --btn-hover-border-color: #fff;
      --btn-focus-shadow-rgb: 255, 255, 255;
      --btn-active-color: #2D2926;
      --btn-active-bg: #fff;
      --btn-active-border-color: #fff;
      --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      --btn-disabled-color: #D4CFCC;
      --btn-disabled-bg: #F1F0EF;
      --btn-disabled-border-color: #F1F0EF; }
      .cms-home-page .home-business-container .pagebuilder-column a:hover {
        color: var(--btn-hover-color);
        background-color: var(--btn-hover-bg);
        border-color: var(--btn-hover-border-color); }
      .btn-check:focus + .cms-home-page .home-business-container .pagebuilder-column a, .cms-home-page .home-business-container .pagebuilder-column a:focus {
        color: var(--btn-hover-color);
        background-color: var(--btn-hover-bg);
        border-color: var(--btn-hover-border-color);
        outline: 0;
        box-shadow: var(--btn-focus-box-shadow); }
      .btn-check:checked + .cms-home-page .home-business-container .pagebuilder-column a,
      .btn-check:active + .cms-home-page .home-business-container .pagebuilder-column a, .cms-home-page .home-business-container .pagebuilder-column a:active, .cms-home-page .home-business-container .pagebuilder-column a.active, .cms-home-page .home-business-container .pagebuilder-column a.show {
        color: var(--btn-active-color);
        background-color: var(--btn-active-bg);
        border-color: var(--btn-active-border-color); }
        .btn-check:checked + .cms-home-page .home-business-container .pagebuilder-column a:focus,
        .btn-check:active + .cms-home-page .home-business-container .pagebuilder-column a:focus, .cms-home-page .home-business-container .pagebuilder-column a:active:focus, .cms-home-page .home-business-container .pagebuilder-column a.active:focus, .cms-home-page .home-business-container .pagebuilder-column a.show:focus {
          box-shadow: var(--btn-focus-box-shadow); }
      .cms-home-page .home-business-container .pagebuilder-column a:disabled, .cms-home-page .home-business-container .pagebuilder-column a.disabled,
      fieldset:disabled .cms-home-page .home-business-container .pagebuilder-column a {
        color: var(--btn-disabled-color);
        pointer-events: none;
        background-color: var(--btn-disabled-bg);
        border-color: var(--btn-disabled-border-color);
        opacity: var(--btn-disabled-opacity); }
    .cms-home-page .home-business-container .pagebuilder-column div p:last-child {
      margin-top: auto;
      margin-bottom: 0; }
    @media (min-width: 1024px) {
      .cms-home-page .home-business-container .pagebuilder-column {
        position: relative;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: left center; }
        .cms-home-page .home-business-container .pagebuilder-column:first-child {
          background-image: url("../images/brand-partner-bg.png"); }
        .cms-home-page .home-business-container .pagebuilder-column:last-child {
          background-image: url("../images/retailer-signup-bg.png"); }
        .cms-home-page .home-business-container .pagebuilder-column figure {
          position: absolute;
          top: 0;
          left: 50%;
          width: 50%;
          height: 100%; }
        .cms-home-page .home-business-container .pagebuilder-column img {
          width: 100%;
          height: 100% !important;
          object-fit: cover;
          object-position: right; }
        .cms-home-page .home-business-container .pagebuilder-column div {
          width: 50%;
          height: 100%;
          padding: 5.6rem 1.6rem 5.6rem 4.6rem;
          font-weight: 700;
          font-size: 1.7rem;
          line-height: 1.3; }
        .cms-home-page .home-business-container .pagebuilder-column h3, .cms-home-page .home-business-container .pagebuilder-column .h3 {
          font-size: 2.4rem; } }
  @media (max-width: 1023.98px) {
    .cms-home-page .home-business-container {
      padding: 6rem 2.3rem 5.8rem !important; }
      .cms-home-page .home-business-container h2, .cms-home-page .home-business-container .h2 {
        font-size: 2.4rem;
        padding: 0 5rem; }
      .cms-home-page .home-business-container h2 + div, .cms-home-page .home-business-container .h2 + div {
        font-size: 1.5rem;
        padding: 0 2.8rem; }
      .cms-home-page .home-business-container .pagebuilder-column-group {
        gap: 2.5rem; }
      .cms-home-page .home-business-container .pagebuilder-column img {
        width: 100%; }
      .cms-home-page .home-business-container .pagebuilder-column div {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 2.6rem 3.6rem 4.1rem; }
        .cms-home-page .home-business-container .pagebuilder-column div p:last-child {
          padding-top: 2rem; }
        .cms-home-page .home-business-container .pagebuilder-column div br {
          display: none; } }

.cms-home-page .home-unique-container {
  margin-left: auto;
  margin-right: auto;
  background-color: #DCF4EE;
  padding: 6rem 3rem 2rem !important; }
  .cms-home-page .home-unique-container .pagebuilder-column-group {
    max-width: 900px;
    justify-content: space-between;
    margin: 3.5rem auto; }
    @media (max-width: 767.98px) {
      .cms-home-page .home-unique-container .pagebuilder-column-group {
        justify-content: center; } }
    .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column {
      text-align: center;
      max-width: 290px;
      padding: 0 20px; }
      .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column [data-content-type='html'] {
        display: flex;
        justify-content: center; }
      .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .inner-wrapper {
        height: 25rem;
        width: 25rem; }
        .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .inner-wrapper:hover .main-image {
          display: none; }
          .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .inner-wrapper:hover .main-image + .hover-image {
            display: block !important; }
        .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .inner-wrapper p {
          width: 22.5rem;
          margin: 1.5rem auto 0; }
      .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .hover-image {
        display: none; }
      .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .we-are-unique-text {
        max-width: 22.5rem;
        width: 100%;
        margin: 1.5rem auto 0;
        padding: 0 1.3rem; }
      .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .we-are-unique-mobile-text {
        display: none; }
  .cms-home-page .home-unique-container h2, .cms-home-page .home-unique-container .h2 {
    font-size: 3rem; }
  @media (max-width: 575.98px) {
    .cms-home-page .home-unique-container {
      padding: 6rem 3rem 1rem !important;
      margin-bottom: 4rem; }
      .cms-home-page .home-unique-container .pagebuilder-column-group {
        margin-top: 1.5rem;
        gap: 1.5rem; }
        .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .inner-wrapper {
          max-width: 16rem;
          height: auto !important; }
        .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .we-are-unique-mobile-text {
          display: block;
          margin-top: 1rem;
          font-size: 1.5rem; }
        .cms-home-page .home-unique-container .pagebuilder-column-group .pagebuilder-column .we-are-unique-text {
          display: none; }
      .cms-home-page .home-unique-container h2, .cms-home-page .home-unique-container .h2 {
        font-size: 2.4rem; } }

@media (max-width: 1023.98px) {
  .cms-home-page .home-business-container h2 + div, .cms-home-page .home-business-container .h2 + div {
    width: auto; } }

.cms-b2b-home-page .breadcrumb {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.cms-b2b-home-page .page.messages {
  max-width: 132.2rem;
  margin: 0 auto; }

.cms-b2b-home-page .home-b2b-whats-new {
  padding: 5rem 0 !important; }
  @media (max-width: 575.98px) {
    .cms-b2b-home-page .home-b2b-whats-new {
      padding: 4rem 0 6.6rem !important; } }
  .cms-b2b-home-page .home-b2b-whats-new h2 + div, .cms-b2b-home-page .home-b2b-whats-new .h2 + div {
    margin-top: 2rem; }
    @media (max-width: 575.98px) {
      .cms-b2b-home-page .home-b2b-whats-new h2 + div, .cms-b2b-home-page .home-b2b-whats-new .h2 + div {
        margin-top: 3rem; } }
  .cms-b2b-home-page .home-b2b-whats-new .pagebuilder-column-group {
    justify-content: space-between;
    padding: 0 3.7rem; }
    @media (max-width: 575.98px) {
      .cms-b2b-home-page .home-b2b-whats-new .pagebuilder-column-group {
        justify-content: center; } }
  .cms-b2b-home-page .home-b2b-whats-new .pagebuilder-column {
    flex-basis: auto; }
    @media (max-width: 575.98px) {
      .cms-b2b-home-page .home-b2b-whats-new .pagebuilder-column {
        width: 16.4rem !important; } }
    .cms-b2b-home-page .home-b2b-whats-new .pagebuilder-column > div {
      max-width: 28rem;
      margin: 0 auto; }
    .cms-b2b-home-page .home-b2b-whats-new .pagebuilder-column .inner-wrapper:hover .main-image {
      display: none; }
      .cms-b2b-home-page .home-b2b-whats-new .pagebuilder-column .inner-wrapper:hover .main-image + .hover-image {
        display: block !important; }
    .cms-b2b-home-page .home-b2b-whats-new .pagebuilder-column .hover-image {
      display: none; }
  .cms-b2b-home-page .home-b2b-whats-new .home-b2b-btn {
    margin-top: 1rem; }

.cms-b2b-home-page .home-b2b-whats-on-deal {
  background-color: #DCF4EE;
  padding: 6rem 3rem 5rem !important;
  max-width: 192rem;
  min-height: 55rem;
  margin: 0 auto; }
  .cms-b2b-home-page .home-b2b-whats-on-deal h2 + div, .cms-b2b-home-page .home-b2b-whats-on-deal .h2 + div {
    margin-top: 2.2rem; }
  .cms-b2b-home-page .home-b2b-whats-on-deal [data-content-type='buttons'] {
    margin: 4rem auto 0; }
  .cms-b2b-home-page .home-b2b-whats-on-deal .deals {
    padding: 0; }
    .cms-b2b-home-page .home-b2b-whats-on-deal .deals .slick-track .slick-slide {
      max-width: 28rem; }
      .cms-b2b-home-page .home-b2b-whats-on-deal .deals .slick-track .slick-slide:not(:last-child) {
        margin-right: 2.2rem; }
  @media (max-width: 575.98px) {
    .cms-b2b-home-page .home-b2b-whats-on-deal {
      padding: 5rem 0 4rem !important; }
      .cms-b2b-home-page .home-b2b-whats-on-deal [data-content-type='buttons'] {
        margin-top: 2rem !important;
        margin-bottom: 2rem; }
      .cms-b2b-home-page .home-b2b-whats-on-deal .slick-track .slick-slide {
        width: 28rem; } }

.cms-b2b-home-page .home-b2b-promo {
  padding: 5rem 0 !important; }
  @media (max-width: 575.98px) {
    .cms-b2b-home-page .home-b2b-promo {
      padding: 3rem 0 !important; }
      .cms-b2b-home-page .home-b2b-promo .pagebuilder-column-group {
        padding: 1rem 3rem; } }
  .cms-b2b-home-page .home-b2b-promo .pagebuilder-column {
    padding: 1rem 0; }
    .cms-b2b-home-page .home-b2b-promo .pagebuilder-column figure {
      max-width: 63.2rem;
      margin: 0 auto; }

.cms-b2b-home-page .home-b2b-btn a {
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 0.056rem;
  --btn-padding-x: 2.6rem;
  --btn-padding-y: 1.45rem;
  --btn-font-family: Articulat Cf, Basic Sans, system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  --btn-font-size: 1.4rem;
  --btn-font-weight: 700;
  --btn-line-height: 1.55;
  --btn-color: #2D2926;
  --btn-bg: transparent;
  --btn-border-width: 2px;
  --btn-border-color: transparent;
  --btn-border-radius: 0.4rem;
  --btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --btn-disabled-opacity: 1;
  --btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  --btn-color: #2D2926;
  --btn-bg: transparent;
  --btn-border-color: #2D2926;
  --btn-hover-color: #fff;
  --btn-hover-bg: #2D2926;
  --btn-hover-border-color: #2D2926;
  --btn-focus-shadow-rgb: 45, 41, 38;
  --btn-active-color: #fff;
  --btn-active-bg: #2D2926;
  --btn-active-border-color: #2D2926;
  --btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --btn-disabled-color: #D4CFCC;
  --btn-disabled-bg: #F1F0EF;
  --btn-disabled-border-color: #F1F0EF; }
  .cms-b2b-home-page .home-b2b-btn a:hover {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color); }
  .btn-check:focus + .cms-b2b-home-page .home-b2b-btn a, .cms-b2b-home-page .home-b2b-btn a:focus {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    outline: 0;
    box-shadow: var(--btn-focus-box-shadow); }
  .btn-check:checked + .cms-b2b-home-page .home-b2b-btn a,
  .btn-check:active + .cms-b2b-home-page .home-b2b-btn a, .cms-b2b-home-page .home-b2b-btn a:active, .cms-b2b-home-page .home-b2b-btn a.active, .cms-b2b-home-page .home-b2b-btn a.show {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color); }
    .btn-check:checked + .cms-b2b-home-page .home-b2b-btn a:focus,
    .btn-check:active + .cms-b2b-home-page .home-b2b-btn a:focus, .cms-b2b-home-page .home-b2b-btn a:active:focus, .cms-b2b-home-page .home-b2b-btn a.active:focus, .cms-b2b-home-page .home-b2b-btn a.show:focus {
      box-shadow: var(--btn-focus-box-shadow); }
  .cms-b2b-home-page .home-b2b-btn a:disabled, .cms-b2b-home-page .home-b2b-btn a.disabled,
  fieldset:disabled .cms-b2b-home-page .home-b2b-btn a {
    color: var(--btn-disabled-color);
    pointer-events: none;
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color);
    opacity: var(--btn-disabled-opacity); }

.cms-b2b-home-page .home-b2b-shop-our-brands {
  padding: 0 0 5rem 0 !important; }
  .cms-b2b-home-page .home-b2b-shop-our-brands h2, .cms-b2b-home-page .home-b2b-shop-our-brands .h2 {
    margin-bottom: 2rem; }
  .cms-b2b-home-page .home-b2b-shop-our-brands .brands {
    padding: 0 1.5rem; }
    .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide {
      flex: 0 0 25%; }
      .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide .brand {
        display: flex !important;
        position: relative;
        min-width: 17.3rem;
        min-height: 20rem;
        height: 20rem;
        background-color: #F1F0EF;
        background-position: left top;
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: scroll;
        padding: 0.5rem;
        background-clip: content-box; }
        .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide .brand .brand-url {
          position: relative;
          display: flex;
          flex: 1; }
          .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide .brand .brand-url:hover .slick-item-img {
            display: none; }
          .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide .brand .brand-url:hover .slick-item-hover-img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover; }
          .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide .brand .brand-url .slick-item-img {
            position: absolute;
            max-width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0.5); }
            @media (max-width: 767.98px) {
              .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide .brand .brand-url .slick-item-img {
                transform: translate(-50%, -50%) scale(0.6); } }
          .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide .brand .brand-url .slick-item-hover-img {
            display: none;
            width: 100%; }
          .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide .brand .brand-url img:not(.slick-item-hover-img) {
            margin: auto;
            object-fit: contain;
            width: 100%;
            height: 100%;
            object-position: center;
            padding: 2.5rem; }
    .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-dots {
      margin-top: 0; }
    @media (max-width: 575.98px) {
      .cms-b2b-home-page .home-b2b-shop-our-brands .brands {
        padding: 0 3rem; }
        .cms-b2b-home-page .home-b2b-shop-our-brands .brands h2, .cms-b2b-home-page .home-b2b-shop-our-brands .brands .h2 {
          margin-bottom: 3rem; }
        .cms-b2b-home-page .home-b2b-shop-our-brands .brands [data-content-type='heading'] {
          margin-bottom: 3rem; }
        .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track {
          width: auto; }
          .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide div:first-child {
            margin-bottom: 1.2rem; }
          .cms-b2b-home-page .home-b2b-shop-our-brands .brands .slick-track .slick-slide .brand {
            width: calc((100vw - 7rem) / 2) !important;
            height: 11.9rem;
            min-width: 0;
            min-height: 0;
            padding: 0;
            margin-right: 1.3rem;
            background-clip: content-box; } }
  .cms-b2b-home-page .home-b2b-shop-our-brands .pagebuilder-column-group {
    padding: 1rem 0;
    gap: 0.8rem; }
    @media (max-width: 767.98px) {
      .cms-b2b-home-page .home-b2b-shop-our-brands .pagebuilder-column-group {
        justify-content: space-evenly;
        padding: 1rem 3rem; } }
    .cms-b2b-home-page .home-b2b-shop-our-brands .pagebuilder-column-group .pagebuilder-column {
      position: relative;
      min-width: 17.3rem;
      min-height: 11.9rem !important; }
      @media (max-width: 767.98px) {
        .cms-b2b-home-page .home-b2b-shop-our-brands .pagebuilder-column-group .pagebuilder-column {
          flex-basis: 45%; }
          .cms-b2b-home-page .home-b2b-shop-our-brands .pagebuilder-column-group .pagebuilder-column:not(:first-child) {
            margin-top: 0; } }
      .cms-b2b-home-page .home-b2b-shop-our-brands .pagebuilder-column-group .pagebuilder-column figure {
        width: 100%; }
        .cms-b2b-home-page .home-b2b-shop-our-brands .pagebuilder-column-group .pagebuilder-column figure a {
          width: 100%;
          height: 100%;
          display: block; }
        .cms-b2b-home-page .home-b2b-shop-our-brands .pagebuilder-column-group .pagebuilder-column figure img {
          position: absolute;
          transform: translate(-50%, -50%) scale(0.5);
          top: 50%;
          left: 50%; }

@media (max-width: 1023.98px) {
  .cms-b2b-home-page .home-b2b-shop-our-brands .pagebuilder-column:not(:first-child) {
    margin-top: 2rem; } }

.home-blog.home-blog.home-blog {
  padding: 0; }
  @media (min-width: 768px) {
    .home-blog.home-blog.home-blog {
      max-width: 1295px;
      margin: 4.3rem auto 6.3rem; }
      .cms-b2b-home-page .home-blog.home-blog.home-blog {
        margin-bottom: 11rem; } }
  .home-blog.home-blog.home-blog .amblog-element-block {
    background: none;
    border-radius: 0;
    box-shadow: none;
    border: none; }
    @media (max-width: 575.98px) {
      .home-blog.home-blog.home-blog .amblog-element-block .btn-outline-primary {
        width: 14.2rem; } }
  .home-blog.home-blog.home-blog h2, .home-blog.home-blog.home-blog .h2 {
    margin-bottom: 4.5rem;
    text-align: center;
    font-size: 3rem; }
    .cms-b2b-home-page .home-blog.home-blog.home-blog h2, .cms-b2b-home-page .home-blog.home-blog.home-blog .h2 {
      font-size: 2rem; }
  .home-blog.home-blog.home-blog .amblog-list {
    padding: 0; }
    .home-blog.home-blog.home-blog .amblog-list > .slick-standard:not(.slick-initialized) {
      display: none; }
  .home-blog.home-blog.home-blog .post-items {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    justify-content: center; }
    .home-blog.home-blog.home-blog .post-items .slick-list {
      padding: 0 !important; }
      .home-blog.home-blog.home-blog .post-items .slick-list .slick-track {
        gap: 0; }
      .home-blog.home-blog.home-blog .post-items .slick-list .slick-slide:first-child {
        margin-left: 0; }
      .home-blog.home-blog.home-blog .post-items .slick-list .slick-slide:last-child {
        margin-right: 0; }
  .home-blog.home-blog.home-blog .slick-slide {
    height: auto; }
    .home-blog.home-blog.home-blog .slick-slide > div {
      display: flex;
      height: 100%; }
  .home-blog.home-blog.home-blog .post-item {
    position: relative;
    border: 1px solid #dee2e6;
    border-radius: 0.3rem;
    overflow: hidden;
    text-align: center; }
    @media (max-width: 575.98px) {
      .home-blog.home-blog.home-blog .post-item {
        width: 30rem !important; } }
    @media (min-width: 768px) {
      .home-blog.home-blog.home-blog .post-item {
        width: 25%;
        width: calc(25% - ((1.5rem * 3) / 4)); }
        .home-blog.home-blog.home-blog .post-item + .post-item {
          margin-left: 1.5rem; } }
    @media (min-width: 1024px) and (max-width: 1365.98px) {
      .home-blog.home-blog.home-blog .post-item {
        width: calc(25% - ((2rem * 3) / 4)); }
        .home-blog.home-blog.home-blog .post-item + .post-item {
          margin-left: 2rem; } }
    @media (min-width: 1366px) {
      .home-blog.home-blog.home-blog .post-item {
        width: calc(25% - ((2.5rem * 3) / 4)); }
        .home-blog.home-blog.home-blog .post-item + .post-item {
          margin-left: 2.5rem; } }
    .home-blog.home-blog.home-blog .post-item article {
      display: flex;
      flex-direction: column;
      height: 100%; }
    .home-blog.home-blog.home-blog .post-item .post-item-details {
      flex-grow: 1;
      padding: 3rem 1.5rem 3.3rem;
      align-items: center; }
      @media (min-width: 1024px) {
        .home-blog.home-blog.home-blog .post-item .post-item-details {
          padding-left: 2rem;
          padding-right: 2rem; } }
    .home-blog.home-blog.home-blog .post-item .amblog-featured-card-bottom {
      margin-top: auto; }
    .home-blog.home-blog.home-blog .post-item .amblog-headline {
      margin-bottom: 1.8rem;
      line-height: 1.3;
      font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.8rem; }
      @media (min-width: 1366px) {
        .home-blog.home-blog.home-blog .post-item .amblog-headline {
          font-size: 2rem; } }
      @media (max-width: 575.98px) {
        .home-blog.home-blog.home-blog .post-item .amblog-headline {
          padding: 0 1rem;
          font-size: 2rem;
          line-height: 1.3; } }
      .home-blog.home-blog.home-blog .post-item .amblog-headline:hover {
        color: inherit; }
    .home-blog.home-blog.home-blog .post-item .btn-primary {
      font-size: 1rem; }
      .home-blog.home-blog.home-blog .post-item .btn-primary::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
  @media (min-width: 768px) {
    .home-blog.home-blog.home-blog .btn-outline-primary {
      margin-top: 3.3rem;
      padding-left: 3.425rem;
      padding-right: 3.425rem; } }
  @media (max-width: 575.98px) {
    .home-blog.home-blog.home-blog {
      padding: 2.4rem 0 6.5rem; }
      .home-blog.home-blog.home-blog h2, .home-blog.home-blog.home-blog .h2 {
        font-size: 2rem;
        margin-bottom: 3.5rem; }
      .home-blog.home-blog.home-blog .text-center {
        margin-top: 2.5rem; }
      .home-blog.home-blog.home-blog .slick-slide {
        width: 30.3rem !important;
        margin: 0 1.5rem 0 0; }
        .home-blog.home-blog.home-blog .slick-slide:last-child {
          margin: 0; } }

.customer-account-login .page-main {
  padding-top: 5rem; }

.customer-account-login .offcanvas-body {
  overflow: visible; }

.customer-account-forgotpassword .page-main {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.form-login h3, .form-login .h3 {
  font-size: 2rem; }

.form-login .action.remind {
  font-size: 1.3rem; }

.login-content {
  max-width: 348px;
  margin: 2.9rem auto 2.2rem;
  text-align: center;
  font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 1.3rem; }
  .login-content h4, .login-content .h4 {
    margin-bottom: 0.5rem; }

.login-container .block,
.customer-account-forgotpassword .main .block {
  width: 100%;
  max-width: 500px; }

@media (max-width: 575.98px) {
  .miniaccount-wrapper .offcanvas-header {
    padding: 0 2.5rem;
    height: 5rem; }
    .miniaccount-wrapper .offcanvas-header .offcanvas-title {
      line-height: 1.125; }
  .miniaccount-wrapper .fieldset .field .label {
    margin-bottom: 0.1rem; }
  .miniaccount-wrapper .fieldset .field .control::before {
    top: 1.4rem; }
  .miniaccount-wrapper .fieldset .field .control .form-control {
    font-size: 1.4rem;
    line-height: 1.43; } }

.lac-notification-sticky {
  position: sticky;
  z-index: 0;
  top: 0 !important; }
  .lac-notification-sticky .lac-notification {
    display: block !important; }
    @media (max-width: 767.98px) {
      .lac-notification-sticky .lac-notification {
        height: 84px; }
        .lac-notification-sticky .lac-notification .lac-notification-text {
          float: none;
          margin: 0 auto;
          max-width: 300px;
          font-size: 1.4rem;
          line-height: 1.1;
          padding: 2.2rem 0; }
        .lac-notification-sticky .lac-notification .lac-notification-links {
          float: none;
          padding: 0; }
          .lac-notification-sticky .lac-notification .lac-notification-links a {
            font-size: 1.4rem;
            position: absolute;
            top: 5px;
            right: 5px; } }

.box-tocart {
  display: flex; }
  .box-tocart .control {
    position: relative; }
    .box-tocart .control:focus-within .qty {
      color: #2D2926;
      background-color: #fff;
      border-color: #2D2926; }
  .box-tocart .btn-qty {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
    .box-tocart .btn-qty[data-direction='minus'] {
      left: 0; }
      .box-tocart .btn-qty[data-direction='minus']:disabled {
        border: none; }
    .box-tocart .btn-qty[data-direction='add'] {
      right: 0; }
      .box-tocart .btn-qty[data-direction='add']:disabled {
        border: none; }
    .box-tocart .btn-qty:focus-visible {
      opacity: 0.7; }
  .box-tocart .field.qty {
    flex-grow: 1;
    margin-right: 2rem; }
    .box-tocart .field.qty > .control {
      height: 5rem; }
  .box-tocart input.qty {
    text-align: center;
    font-size: 1.5rem; }
  .box-tocart .fieldset {
    margin-bottom: 0; }

.product-item-inner .btn-out-of-stock {
  margin-bottom: 0.7rem; }
  .product-item-inner .btn-out-of-stock .action.tocart {
    text-transform: none; }

.product-item-inner .actions-primary,
.product-item-inner .action.tocart {
  position: relative;
  z-index: 0;
  overflow: hidden;
  height: 6rem; }
  .product-item-inner .actions-primary span,
  .product-item-inner .action.tocart span {
    display: block;
    transform: translateY(0);
    transition: all 0.2s ease-in-out; }
  .product-item-inner .actions-primary .spinner-border,
  .product-item-inner .action.tocart .spinner-border {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-width: 2px; }
  .product-item-inner .actions-primary img:not(.wishlist-icon),
  .product-item-inner .action.tocart img:not(.wishlist-icon) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    transform: translateY(50px);
    transition: all 0.2s ease-in-out;
    filter: invert(1); }
  .product-item-inner .actions-primary:focus img,
  .product-item-inner .action.tocart:focus img {
    filter: invert(0); }
  .product-item-inner .actions-primary:focus .spinner-border,
  .product-item-inner .action.tocart:focus .spinner-border {
    filter: invert(1); }
  .product-item-inner .actions-primary.adding,
  .product-item-inner .action.tocart.adding {
    pointer-events: none; }
  .product-item-inner .actions-primary.added, .product-item-inner .actions-primary.product-in-cart,
  .product-item-inner .action.tocart.added,
  .product-item-inner .action.tocart.product-in-cart {
    pointer-events: all;
    background-color: #B7E9DB;
    border-color: #B7E9DB; }
    .product-item-inner .actions-primary.added span, .product-item-inner .actions-primary.product-in-cart span,
    .product-item-inner .action.tocart.added span,
    .product-item-inner .action.tocart.product-in-cart span {
      transform: translateY(-50px); }
    .product-item-inner .actions-primary.added img, .product-item-inner .actions-primary.product-in-cart img,
    .product-item-inner .action.tocart.added img,
    .product-item-inner .action.tocart.product-in-cart img {
      transform: translateY(0);
      filter: invert(0); }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas, .catalog-category-view,
.catalogsearch-result-index,
.ambrand-index-index,
.wishlist-index-index,
.myproducts-index-view {
  --offcanvas-width: 414px;
  --offcanvas-height: 30vh;
  --offcanvas-padding-x: 4.8rem;
  --offcanvas-padding-y: 3rem;
  --offcanvas-color: ;
  --offcanvas-bg: #fff;
  --offcanvas-border-width: 0;
  --offcanvas-border-color: rgba(0, 0, 0, 0.2);
  --offcanvas-box-shadow: none; }

@media (max-width: 1023.98px) {
  .page-products .columns {
    position: unset; } }

.am_shopby_apply_filters {
  transform: translateX(100%); }

.catalog-category-view .columns,
.catalogsearch-result-index .columns,
.ambrand-index-index .columns,
.wishlist-index-index .columns,
.myproducts-index-view .columns {
  display: flex; }
  .catalog-category-view .columns .main,
  .catalogsearch-result-index .columns .main,
  .ambrand-index-index .columns .main,
  .wishlist-index-index .columns .main,
  .myproducts-index-view .columns .main {
    padding-left: 0;
    width: 100%; }

@media (max-width: 1023.98px) {
  .catalog-category-view .page-main,
  .catalogsearch-result-index .page-main,
  .ambrand-index-index .page-main,
  .wishlist-index-index .page-main,
  .myproducts-index-view .page-main {
    padding-left: 0;
    padding-right: 0; }
  .catalog-category-view .message.message,
  .catalogsearch-result-index .message.message,
  .ambrand-index-index .message.message,
  .wishlist-index-index .message.message,
  .myproducts-index-view .message.message {
    margin: 0 2rem 2rem; } }

.catalog-category-view .page-title-wrapper,
.catalogsearch-result-index .page-title-wrapper,
.ambrand-index-index .page-title-wrapper,
.wishlist-index-index .page-title-wrapper,
.myproducts-index-view .page-title-wrapper {
  text-align: center; }

.catalog-category-view .block.filter,
.catalogsearch-result-index .block.filter,
.ambrand-index-index .block.filter,
.wishlist-index-index .block.filter,
.myproducts-index-view .block.filter {
  margin: 4.4rem 0 4.4rem; }

.catalog-category-view .filter-mobile,
.catalogsearch-result-index .filter-mobile,
.ambrand-index-index .filter-mobile,
.wishlist-index-index .filter-mobile,
.myproducts-index-view .filter-mobile {
  display: none; }
  @media (max-width: 1023.98px) {
    .catalog-category-view .filter-mobile,
    .catalogsearch-result-index .filter-mobile,
    .ambrand-index-index .filter-mobile,
    .wishlist-index-index .filter-mobile,
    .myproducts-index-view .filter-mobile {
      display: block; } }

.catalog-category-view .show-more,
.catalogsearch-result-index .show-more,
.ambrand-index-index .show-more,
.wishlist-index-index .show-more,
.myproducts-index-view .show-more {
  display: none; }

@media (max-width: 1023.98px) {
  .catalog-category-view #amasty-shopby-product-list .toolbar,
  .catalogsearch-result-index #amasty-shopby-product-list .toolbar,
  .ambrand-index-index #amasty-shopby-product-list .toolbar,
  .wishlist-index-index #amasty-shopby-product-list .toolbar,
  .myproducts-index-view #amasty-shopby-product-list .toolbar {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    padding: 0 1.4rem 0 1.4rem; } }

@media (max-width: 1023.98px) {
  .catalog-category-view #amasty-shopby-product-list .filter-title,
  .catalogsearch-result-index #amasty-shopby-product-list .filter-title,
  .ambrand-index-index #amasty-shopby-product-list .filter-title,
  .wishlist-index-index #amasty-shopby-product-list .filter-title,
  .myproducts-index-view #amasty-shopby-product-list .filter-title {
    flex: 50%;
    display: flex;
    padding-right: 0.7rem; } }

.catalog-category-view .filter-mobile .refine-control,
.catalogsearch-result-index .filter-mobile .refine-control,
.ambrand-index-index .filter-mobile .refine-control,
.wishlist-index-index .filter-mobile .refine-control,
.myproducts-index-view .filter-mobile .refine-control {
  position: absolute;
  top: 1rem;
  align-items: center;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.3rem;
  color: #837C78;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: 400;
  max-height: 5rem;
  width: 100%;
  padding: 1.7rem 1.9rem;
  text-align: center;
  text-transform: unset; }

.catalog-category-view .sidebar-main,
.catalogsearch-result-index .sidebar-main,
.ambrand-index-index .sidebar-main,
.wishlist-index-index .sidebar-main,
.myproducts-index-view .sidebar-main {
  min-height: 13.8rem; }

@media (max-width: 1023.98px) {
  .catalog-category-view .sidebar-main,
  .catalogsearch-result-index .sidebar-main,
  .ambrand-index-index .sidebar-main,
  .wishlist-index-index .sidebar-main,
  .myproducts-index-view .sidebar-main {
    min-height: unset;
    max-width: 50%;
    padding: 0 1.4rem; }
  .catalog-category-view .filter-mobile,
  .catalogsearch-result-index .filter-mobile,
  .ambrand-index-index .filter-mobile,
  .wishlist-index-index .filter-mobile,
  .myproducts-index-view .filter-mobile {
    position: relative;
    padding-right: 1.4rem; }
  .catalog-category-view #amasty-shopby-product-list .top-toolbar,
  .catalogsearch-result-index #amasty-shopby-product-list .top-toolbar,
  .ambrand-index-index #amasty-shopby-product-list .top-toolbar,
  .wishlist-index-index #amasty-shopby-product-list .top-toolbar,
  .myproducts-index-view #amasty-shopby-product-list .top-toolbar {
    max-width: 50%;
    margin-left: auto; }
  .catalog-category-view #amasty-shopby-product-list .product-items,
  .catalogsearch-result-index #amasty-shopby-product-list .product-items,
  .ambrand-index-index #amasty-shopby-product-list .product-items,
  .wishlist-index-index #amasty-shopby-product-list .product-items,
  .myproducts-index-view #amasty-shopby-product-list .product-items {
    justify-content: center; } }

@media (max-width: 1023.98px) {
  .catalog-category-view .sorter .dropdown-toggle,
  .catalogsearch-result-index .sorter .dropdown-toggle,
  .ambrand-index-index .sorter .dropdown-toggle,
  .wishlist-index-index .sorter .dropdown-toggle,
  .myproducts-index-view .sorter .dropdown-toggle {
    min-width: unset; } }

.catalog-category-view #amasty-shopby-product-list .filter-title strong,
.catalogsearch-result-index #amasty-shopby-product-list .filter-title strong,
.ambrand-index-index #amasty-shopby-product-list .filter-title strong,
.wishlist-index-index #amasty-shopby-product-list .filter-title strong,
.myproducts-index-view #amasty-shopby-product-list .filter-title strong {
  font-weight: 400; }

@media (max-width: 1023.98px) {
  .catalog-category-view #amasty-shopby-product-list .toolbar-sorter,
  .catalogsearch-result-index #amasty-shopby-product-list .toolbar-sorter,
  .ambrand-index-index #amasty-shopby-product-list .toolbar-sorter,
  .wishlist-index-index #amasty-shopby-product-list .toolbar-sorter,
  .myproducts-index-view #amasty-shopby-product-list .toolbar-sorter {
    flex: 50%;
    padding: 0 0 0 0.7rem;
    position: unset; } }

.catalog-category-view .block-content .filter-content,
.catalogsearch-result-index .block-content .filter-content,
.ambrand-index-index .block-content .filter-content,
.wishlist-index-index .block-content .filter-content,
.myproducts-index-view .block-content .filter-content {
  width: 100%;
  height: 100%; }

.catalog-category-view .apply-holder,
.catalogsearch-result-index .apply-holder,
.ambrand-index-index .apply-holder,
.wishlist-index-index .apply-holder,
.myproducts-index-view .apply-holder {
  display: none; }

.catalog-category-view .filter-modal-header,
.catalogsearch-result-index .filter-modal-header,
.ambrand-index-index .filter-modal-header,
.wishlist-index-index .filter-modal-header,
.myproducts-index-view .filter-modal-header {
  display: none; }

@media (max-width: 1023.98px) {
  .catalog-category-view #filterContainer,
  .catalogsearch-result-index #filterContainer,
  .ambrand-index-index #filterContainer,
  .wishlist-index-index #filterContainer,
  .myproducts-index-view #filterContainer {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
    margin: 0; } }

@media (max-width: 1023.98px) {
  .catalog-category-view #filterContainer.active,
  .catalogsearch-result-index #filterContainer.active,
  .ambrand-index-index #filterContainer.active,
  .wishlist-index-index #filterContainer.active,
  .myproducts-index-view #filterContainer.active {
    background: #fff;
    position: fixed;
    margin: 0;
    z-index: 1025;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    height: 100%;
    width: 100%;
    outline: 0;
    transition: transform 0.3s ease-in-out;
    top: 0;
    right: 0;
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    visibility: visible;
    transform: none; } }

.catalog-category-view #filterContainer.active .filter-content,
.catalogsearch-result-index #filterContainer.active .filter-content,
.ambrand-index-index #filterContainer.active .filter-content,
.wishlist-index-index #filterContainer.active .filter-content,
.myproducts-index-view #filterContainer.active .filter-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto; }

.catalog-category-view #filterContainer.active .show-more,
.catalogsearch-result-index #filterContainer.active .show-more,
.ambrand-index-index #filterContainer.active .show-more,
.wishlist-index-index #filterContainer.active .show-more,
.myproducts-index-view #filterContainer.active .show-more {
  display: block;
  cursor: pointer; }

.catalog-category-view #filterContainer.active .filter-options,
.catalogsearch-result-index #filterContainer.active .filter-options,
.ambrand-index-index #filterContainer.active .filter-options,
.wishlist-index-index #filterContainer.active .filter-options,
.myproducts-index-view #filterContainer.active .filter-options {
  display: block;
  flex-grow: 1;
  padding: 0; }

.catalog-category-view #filterContainer.active .filter-options-content,
.catalogsearch-result-index #filterContainer.active .filter-options-content,
.ambrand-index-index #filterContainer.active .filter-options-content,
.wishlist-index-index #filterContainer.active .filter-options-content,
.myproducts-index-view #filterContainer.active .filter-options-content {
  margin: 0;
  padding: 2.5rem;
  border-bottom: #dee2e6 solid 1px; }
  @media (max-width: 1023.98px) {
    .catalog-category-view #filterContainer.active .filter-options-content,
    .catalogsearch-result-index #filterContainer.active .filter-options-content,
    .ambrand-index-index #filterContainer.active .filter-options-content,
    .wishlist-index-index #filterContainer.active .filter-options-content,
    .myproducts-index-view #filterContainer.active .filter-options-content {
      padding-left: 2.8rem;
      padding-right: 2.8rem; } }

.catalog-category-view #filterContainer.active .filter-options-content.fold .item,
.catalogsearch-result-index #filterContainer.active .filter-options-content.fold .item,
.ambrand-index-index #filterContainer.active .filter-options-content.fold .item,
.wishlist-index-index #filterContainer.active .filter-options-content.fold .item,
.myproducts-index-view #filterContainer.active .filter-options-content.fold .item {
  margin-bottom: 1.9rem; }

.catalog-category-view #filterContainer.active .filter-options-content.fold .item:nth-of-type(1n+7),
.catalogsearch-result-index #filterContainer.active .filter-options-content.fold .item:nth-of-type(1n+7),
.ambrand-index-index #filterContainer.active .filter-options-content.fold .item:nth-of-type(1n+7),
.wishlist-index-index #filterContainer.active .filter-options-content.fold .item:nth-of-type(1n+7),
.myproducts-index-view #filterContainer.active .filter-options-content.fold .item:nth-of-type(1n+7) {
  display: none; }

.catalog-category-view #filterContainer.active .filter-options-content.fold.up .item:nth-of-type(1n+7),
.catalogsearch-result-index #filterContainer.active .filter-options-content.fold.up .item:nth-of-type(1n+7),
.ambrand-index-index #filterContainer.active .filter-options-content.fold.up .item:nth-of-type(1n+7),
.wishlist-index-index #filterContainer.active .filter-options-content.fold.up .item:nth-of-type(1n+7),
.myproducts-index-view #filterContainer.active .filter-options-content.fold.up .item:nth-of-type(1n+7) {
  display: flex; }

.catalog-category-view #filterContainer.active .amshopby-items,
.catalogsearch-result-index #filterContainer.active .amshopby-items,
.ambrand-index-index #filterContainer.active .amshopby-items,
.wishlist-index-index #filterContainer.active .amshopby-items,
.myproducts-index-view #filterContainer.active .amshopby-items {
  display: flex;
  flex-wrap: wrap; }

.catalog-category-view #filterContainer.active .amshopby-item,
.catalogsearch-result-index #filterContainer.active .amshopby-item,
.ambrand-index-index #filterContainer.active .amshopby-item,
.wishlist-index-index #filterContainer.active .amshopby-item,
.myproducts-index-view #filterContainer.active .amshopby-item {
  margin-bottom: 1rem; }

.catalog-category-view #filterContainer.active .filter-options-content .dropdown-toggle,
.catalogsearch-result-index #filterContainer.active .filter-options-content .dropdown-toggle,
.ambrand-index-index #filterContainer.active .filter-options-content .dropdown-toggle,
.wishlist-index-index #filterContainer.active .filter-options-content .dropdown-toggle,
.myproducts-index-view #filterContainer.active .filter-options-content .dropdown-toggle {
  border: none;
  font-weight: 700;
  padding: 0 0 2.5rem 0;
  line-height: 1.5rem;
  font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.5rem;
  color: #2D2926;
  pointer-events: none; }

.catalog-category-view #filterContainer.active .filter-options-content .dropdown-toggle::after,
.catalogsearch-result-index #filterContainer.active .filter-options-content .dropdown-toggle::after,
.ambrand-index-index #filterContainer.active .filter-options-content .dropdown-toggle::after,
.wishlist-index-index #filterContainer.active .filter-options-content .dropdown-toggle::after,
.myproducts-index-view #filterContainer.active .filter-options-content .dropdown-toggle::after {
  display: none; }

.catalog-category-view #filterContainer.active .filter-options-content .dropdown-menu,
.catalogsearch-result-index #filterContainer.active .filter-options-content .dropdown-menu,
.ambrand-index-index #filterContainer.active .filter-options-content .dropdown-menu,
.wishlist-index-index #filterContainer.active .filter-options-content .dropdown-menu,
.myproducts-index-view #filterContainer.active .filter-options-content .dropdown-menu {
  position: unset;
  border: none;
  box-shadow: unset;
  padding: 0;
  margin: 0;
  display: block; }

.catalog-category-view #filterContainer.active .filter-options .am-labels-folding .item,
.catalogsearch-result-index #filterContainer.active .filter-options .am-labels-folding .item,
.ambrand-index-index #filterContainer.active .filter-options .am-labels-folding .item,
.wishlist-index-index #filterContainer.active .filter-options .am-labels-folding .item,
.myproducts-index-view #filterContainer.active .filter-options .am-labels-folding .item {
  padding-left: 0; }

.catalog-category-view #filterContainer.active .filter-options-content .items.-am-multiselect .item .items-children.-folding.-folding.-folding.-folding.-folding,
.catalogsearch-result-index #filterContainer.active .filter-options-content .items.-am-multiselect .item .items-children.-folding.-folding.-folding.-folding.-folding,
.ambrand-index-index #filterContainer.active .filter-options-content .items.-am-multiselect .item .items-children.-folding.-folding.-folding.-folding.-folding,
.wishlist-index-index #filterContainer.active .filter-options-content .items.-am-multiselect .item .items-children.-folding.-folding.-folding.-folding.-folding,
.myproducts-index-view #filterContainer.active .filter-options-content .items.-am-multiselect .item .items-children.-folding.-folding.-folding.-folding.-folding {
  padding-left: 2rem; }

.catalog-category-view #filterContainer.active .filter-options-content .items.-am-multiselect .item [class*='am-filter-item'],
.catalogsearch-result-index #filterContainer.active .filter-options-content .items.-am-multiselect .item [class*='am-filter-item'],
.ambrand-index-index #filterContainer.active .filter-options-content .items.-am-multiselect .item [class*='am-filter-item'],
.wishlist-index-index #filterContainer.active .filter-options-content .items.-am-multiselect .item [class*='am-filter-item'],
.myproducts-index-view #filterContainer.active .filter-options-content .items.-am-multiselect .item [class*='am-filter-item'] {
  padding-left: 2rem;
  line-height: 2.3rem; }

.catalog-category-view #filterContainer.active .filter-content .filter-state,
.catalogsearch-result-index #filterContainer.active .filter-content .filter-state,
.ambrand-index-index #filterContainer.active .filter-content .filter-state,
.wishlist-index-index #filterContainer.active .filter-content .filter-state,
.myproducts-index-view #filterContainer.active .filter-content .filter-state {
  display: flex;
  padding: 2.5rem 2.5rem 0;
  flex-direction: column-reverse;
  margin: 0; }

.catalog-category-view #filterContainer.active .filter-actions,
.catalogsearch-result-index #filterContainer.active .filter-actions,
.ambrand-index-index #filterContainer.active .filter-actions,
.wishlist-index-index #filterContainer.active .filter-actions,
.myproducts-index-view #filterContainer.active .filter-actions {
  width: 100%;
  margin: 0 0 1.5rem 0; }

.catalog-category-view #filterContainer.active .filter-current,
.catalogsearch-result-index #filterContainer.active .filter-current,
.ambrand-index-index #filterContainer.active .filter-current,
.wishlist-index-index #filterContainer.active .filter-current,
.myproducts-index-view #filterContainer.active .filter-current {
  width: 100%; }

.catalog-category-view #filterContainer.active .apply-holder,
.catalogsearch-result-index #filterContainer.active .apply-holder,
.ambrand-index-index #filterContainer.active .apply-holder,
.wishlist-index-index #filterContainer.active .apply-holder,
.myproducts-index-view #filterContainer.active .apply-holder {
  position: fixed;
  z-index: 9;
  bottom: 0;
  width: 100%;
  padding: 1.5rem 2.5rem;
  background: rgba(255, 255, 255, 0.57);
  display: block; }
  @media (max-width: 1023.98px) {
    .catalog-category-view #filterContainer.active .apply-holder,
    .catalogsearch-result-index #filterContainer.active .apply-holder,
    .ambrand-index-index #filterContainer.active .apply-holder,
    .wishlist-index-index #filterContainer.active .apply-holder,
    .myproducts-index-view #filterContainer.active .apply-holder {
      padding-left: 2.8rem;
      padding-right: 2.8rem; } }

.catalog-category-view #filterContainer.active .filter-modal-header,
.catalogsearch-result-index #filterContainer.active .filter-modal-header,
.ambrand-index-index #filterContainer.active .filter-modal-header,
.wishlist-index-index #filterContainer.active .filter-modal-header,
.myproducts-index-view #filterContainer.active .filter-modal-header {
  background-color: #40C5A2;
  color: #fff;
  font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 2.4rem;
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6rem 2.5rem; }
  @media (max-width: 1023.98px) {
    .catalog-category-view #filterContainer.active .filter-modal-header,
    .catalogsearch-result-index #filterContainer.active .filter-modal-header,
    .ambrand-index-index #filterContainer.active .filter-modal-header,
    .wishlist-index-index #filterContainer.active .filter-modal-header,
    .myproducts-index-view #filterContainer.active .filter-modal-header {
      padding-left: 2.8rem;
      padding-right: 2.8rem; } }

@media (max-width: 1023.98px) {
  .catalog-category-view .filter-content .filter-state,
  .catalogsearch-result-index .filter-content .filter-state,
  .ambrand-index-index .filter-content .filter-state,
  .wishlist-index-index .filter-content .filter-state,
  .myproducts-index-view .filter-content .filter-state {
    display: none; } }

.catalog-category-view .filter-options,
.catalogsearch-result-index .filter-options,
.ambrand-index-index .filter-options,
.wishlist-index-index .filter-options,
.myproducts-index-view .filter-options {
  display: flex; }
  @media (max-width: 1023.98px) {
    .catalog-category-view .filter-options,
    .catalogsearch-result-index .filter-options,
    .ambrand-index-index .filter-options,
    .wishlist-index-index .filter-options,
    .myproducts-index-view .filter-options {
      display: none; } }
  .catalog-category-view .filter-options .filter-options-content,
  .catalogsearch-result-index .filter-options .filter-options-content,
  .ambrand-index-index .filter-options .filter-options-content,
  .wishlist-index-index .filter-options .filter-options-content,
  .myproducts-index-view .filter-options .filter-options-content {
    border-radius: unset;
    box-shadow: none;
    margin-bottom: 0;
    position: unset; }
    .catalog-category-view .filter-options .filter-options-content:not(:last-child),
    .catalogsearch-result-index .filter-options .filter-options-content:not(:last-child),
    .ambrand-index-index .filter-options .filter-options-content:not(:last-child),
    .wishlist-index-index .filter-options .filter-options-content:not(:last-child),
    .myproducts-index-view .filter-options .filter-options-content:not(:last-child) {
      margin-right: 2.2rem; }

.catalog-category-view .filter-state,
.catalogsearch-result-index .filter-state,
.ambrand-index-index .filter-state,
.wishlist-index-index .filter-state,
.myproducts-index-view .filter-state {
  display: flex;
  align-items: center;
  margin-top: 2rem; }
  .catalog-category-view .filter-state .amshopby-filter-current,
  .catalogsearch-result-index .filter-state .amshopby-filter-current,
  .ambrand-index-index .filter-state .amshopby-filter-current,
  .wishlist-index-index .filter-state .amshopby-filter-current,
  .myproducts-index-view .filter-state .amshopby-filter-current {
    display: inline-block; }
    .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items,
    .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items,
    .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items,
    .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items,
    .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items {
      display: inline-flex;
      padding: 0; }
      .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item,
      .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item,
      .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item,
      .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item,
      .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item {
        background-color: #F1F0EF;
        border-radius: 10rem;
        margin-bottom: 0;
        padding: 0.46rem 3.5rem 0.46rem 1.5rem; }
        .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:not(:last-child),
        .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:not(:last-child),
        .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:not(:last-child),
        .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:not(:last-child),
        .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:not(:last-child) {
          margin-right: 1rem; }
        .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove,
        .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove,
        .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove,
        .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove,
        .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove {
          height: 100%;
          width: 100%;
          left: -0.5rem; }
          .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::before, .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::after,
          .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::before,
          .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::after,
          .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::before,
          .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::after,
          .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::before,
          .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::after,
          .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::before,
          .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-remove::after {
            background-color: #2D2926;
            height: 1px;
            position: absolute;
            width: 12px;
            right: 11px; }
        .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-filter-value,
        .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-filter-value,
        .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-filter-value,
        .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-filter-value,
        .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item .amshopby-filter-value {
          font-size: 1.3rem; }
        .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover,
        .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover,
        .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover,
        .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover,
        .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover {
          background-color: #2D2926;
          color: #fff; }
          .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::before, .catalog-category-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::after,
          .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::before,
          .catalogsearch-result-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::after,
          .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::before,
          .ambrand-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::after,
          .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::before,
          .wishlist-index-index .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::after,
          .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::before,
          .myproducts-index-view .filter-state .amshopby-filter-current .amshopby-items .amshopby-item:hover .amshopby-remove::after {
            background-color: #fff; }
  .catalog-category-view .filter-state .filter-actions,
  .catalogsearch-result-index .filter-state .filter-actions,
  .ambrand-index-index .filter-state .filter-actions,
  .wishlist-index-index .filter-state .filter-actions,
  .myproducts-index-view .filter-state .filter-actions {
    margin-bottom: 0;
    margin-left: 1rem; }
    .catalog-category-view .filter-state .filter-actions .filter-clear,
    .catalogsearch-result-index .filter-state .filter-actions .filter-clear,
    .ambrand-index-index .filter-state .filter-actions .filter-clear,
    .wishlist-index-index .filter-state .filter-actions .filter-clear,
    .myproducts-index-view .filter-state .filter-actions .filter-clear {
      color: #2D2926; }

.catalog-category-view .dropdown-toggle,
.catalogsearch-result-index .dropdown-toggle,
.ambrand-index-index .dropdown-toggle,
.wishlist-index-index .dropdown-toggle,
.myproducts-index-view .dropdown-toggle {
  position: relative;
  align-items: center;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.3rem;
  color: #837C78;
  display: inline-flex;
  font-size: 1.4rem;
  font-weight: normal;
  max-height: 5rem;
  min-width: 18.2rem;
  padding: 1.7rem 1.9rem;
  text-align: left;
  text-transform: unset;
  width: 100%; }
  .catalog-category-view .dropdown-toggle::after,
  .catalogsearch-result-index .dropdown-toggle::after,
  .ambrand-index-index .dropdown-toggle::after,
  .wishlist-index-index .dropdown-toggle::after,
  .myproducts-index-view .dropdown-toggle::after {
    content: '';
    background-image: url("../images/icon-chevron-down.svg");
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 2.4rem;
    right: 0;
    height: 2.4rem;
    margin-right: 1rem; }
  .catalog-category-view .dropdown-toggle.show,
  .catalogsearch-result-index .dropdown-toggle.show,
  .ambrand-index-index .dropdown-toggle.show,
  .wishlist-index-index .dropdown-toggle.show,
  .myproducts-index-view .dropdown-toggle.show {
    border: 1px solid #2D2926;
    color: #2D2926; }
    .catalog-category-view .dropdown-toggle.show::after,
    .catalogsearch-result-index .dropdown-toggle.show::after,
    .ambrand-index-index .dropdown-toggle.show::after,
    .wishlist-index-index .dropdown-toggle.show::after,
    .myproducts-index-view .dropdown-toggle.show::after {
      transform: rotate(180deg); }

.catalog-category-view .dropdown-menu,
.catalogsearch-result-index .dropdown-menu,
.ambrand-index-index .dropdown-menu,
.wishlist-index-index .dropdown-menu,
.myproducts-index-view .dropdown-menu {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  color: #837C78;
  max-width: 29rem;
  padding: 2.5rem;
  width: 100%; }
  .catalog-category-view .dropdown-menu .dropdown-item,
  .catalogsearch-result-index .dropdown-menu .dropdown-item,
  .ambrand-index-index .dropdown-menu .dropdown-item,
  .wishlist-index-index .dropdown-menu .dropdown-item,
  .myproducts-index-view .dropdown-menu .dropdown-item {
    padding: 0; }
    .catalog-category-view .dropdown-menu .dropdown-item:hover,
    .catalogsearch-result-index .dropdown-menu .dropdown-item:hover,
    .ambrand-index-index .dropdown-menu .dropdown-item:hover,
    .wishlist-index-index .dropdown-menu .dropdown-item:hover,
    .myproducts-index-view .dropdown-menu .dropdown-item:hover {
      color: #2D2926;
      text-decoration: underline;
      background-color: unset; }
  .catalog-category-view .dropdown-menu:not(.sort-options),
  .catalogsearch-result-index .dropdown-menu:not(.sort-options),
  .ambrand-index-index .dropdown-menu:not(.sort-options),
  .wishlist-index-index .dropdown-menu:not(.sort-options),
  .myproducts-index-view .dropdown-menu:not(.sort-options) {
    padding: 3.3rem 4.2rem 2.5rem 3.8rem; }
  .catalog-category-view .dropdown-menu .item,
  .catalogsearch-result-index .dropdown-menu .item,
  .ambrand-index-index .dropdown-menu .item,
  .wishlist-index-index .dropdown-menu .item,
  .myproducts-index-view .dropdown-menu .item {
    display: flex;
    opacity: 1; }
    .catalog-category-view .dropdown-menu .item:not(:last-child),
    .catalogsearch-result-index .dropdown-menu .item:not(:last-child),
    .ambrand-index-index .dropdown-menu .item:not(:last-child),
    .wishlist-index-index .dropdown-menu .item:not(:last-child),
    .myproducts-index-view .dropdown-menu .item:not(:last-child) {
      margin-bottom: 1.9rem; }
    .catalog-category-view .dropdown-menu .item .items .item,
    .catalogsearch-result-index .dropdown-menu .item .items .item,
    .ambrand-index-index .dropdown-menu .item .items .item,
    .wishlist-index-index .dropdown-menu .item .items .item,
    .myproducts-index-view .dropdown-menu .item .items .item {
      margin-top: 1.9rem; }
    .catalog-category-view .dropdown-menu .item [data-am-js='filter-item-default'],
    .catalog-category-view .dropdown-menu .item .amshopby-filter-parent,
    .catalogsearch-result-index .dropdown-menu .item [data-am-js='filter-item-default'],
    .catalogsearch-result-index .dropdown-menu .item .amshopby-filter-parent,
    .ambrand-index-index .dropdown-menu .item [data-am-js='filter-item-default'],
    .ambrand-index-index .dropdown-menu .item .amshopby-filter-parent,
    .wishlist-index-index .dropdown-menu .item [data-am-js='filter-item-default'],
    .wishlist-index-index .dropdown-menu .item .amshopby-filter-parent,
    .myproducts-index-view .dropdown-menu .item [data-am-js='filter-item-default'],
    .myproducts-index-view .dropdown-menu .item .amshopby-filter-parent {
      text-decoration: unset;
      padding-left: 3.5rem !important; }
    .catalog-category-view .dropdown-menu .item .label,
    .catalog-category-view .dropdown-menu .item .count,
    .catalogsearch-result-index .dropdown-menu .item .label,
    .catalogsearch-result-index .dropdown-menu .item .count,
    .ambrand-index-index .dropdown-menu .item .label,
    .ambrand-index-index .dropdown-menu .item .count,
    .wishlist-index-index .dropdown-menu .item .label,
    .wishlist-index-index .dropdown-menu .item .count,
    .myproducts-index-view .dropdown-menu .item .label,
    .myproducts-index-view .dropdown-menu .item .count {
      font-size: 1.3rem;
      font-weight: normal;
      padding-left: 0; }
      @media (max-width: 1023.98px) {
        .catalog-category-view .dropdown-menu .item .label,
        .catalog-category-view .dropdown-menu .item .count,
        .catalogsearch-result-index .dropdown-menu .item .label,
        .catalogsearch-result-index .dropdown-menu .item .count,
        .ambrand-index-index .dropdown-menu .item .label,
        .ambrand-index-index .dropdown-menu .item .count,
        .wishlist-index-index .dropdown-menu .item .label,
        .wishlist-index-index .dropdown-menu .item .count,
        .myproducts-index-view .dropdown-menu .item .label,
        .myproducts-index-view .dropdown-menu .item .count {
          font-size: 1.5rem; } }
    .catalog-category-view .dropdown-menu .item .amshopby-filter-parent .label,
    .catalog-category-view .dropdown-menu .item .amshopby-filter-parent .count,
    .catalog-category-view .dropdown-menu .item .sort-desc .label,
    .catalog-category-view .dropdown-menu .item .sort-desc .count,
    .catalog-category-view .dropdown-menu .item .sort-asc .label,
    .catalog-category-view .dropdown-menu .item .sort-asc .count,
    .catalogsearch-result-index .dropdown-menu .item .amshopby-filter-parent .label,
    .catalogsearch-result-index .dropdown-menu .item .amshopby-filter-parent .count,
    .catalogsearch-result-index .dropdown-menu .item .sort-desc .label,
    .catalogsearch-result-index .dropdown-menu .item .sort-desc .count,
    .catalogsearch-result-index .dropdown-menu .item .sort-asc .label,
    .catalogsearch-result-index .dropdown-menu .item .sort-asc .count,
    .ambrand-index-index .dropdown-menu .item .amshopby-filter-parent .label,
    .ambrand-index-index .dropdown-menu .item .amshopby-filter-parent .count,
    .ambrand-index-index .dropdown-menu .item .sort-desc .label,
    .ambrand-index-index .dropdown-menu .item .sort-desc .count,
    .ambrand-index-index .dropdown-menu .item .sort-asc .label,
    .ambrand-index-index .dropdown-menu .item .sort-asc .count,
    .wishlist-index-index .dropdown-menu .item .amshopby-filter-parent .label,
    .wishlist-index-index .dropdown-menu .item .amshopby-filter-parent .count,
    .wishlist-index-index .dropdown-menu .item .sort-desc .label,
    .wishlist-index-index .dropdown-menu .item .sort-desc .count,
    .wishlist-index-index .dropdown-menu .item .sort-asc .label,
    .wishlist-index-index .dropdown-menu .item .sort-asc .count,
    .myproducts-index-view .dropdown-menu .item .amshopby-filter-parent .label,
    .myproducts-index-view .dropdown-menu .item .amshopby-filter-parent .count,
    .myproducts-index-view .dropdown-menu .item .sort-desc .label,
    .myproducts-index-view .dropdown-menu .item .sort-desc .count,
    .myproducts-index-view .dropdown-menu .item .sort-asc .label,
    .myproducts-index-view .dropdown-menu .item .sort-asc .count {
      margin-left: 0; }
    .catalog-category-view .dropdown-menu .item .amshopby-filter-parent .label,
    .catalogsearch-result-index .dropdown-menu .item .amshopby-filter-parent .label,
    .ambrand-index-index .dropdown-menu .item .amshopby-filter-parent .label,
    .wishlist-index-index .dropdown-menu .item .amshopby-filter-parent .label,
    .myproducts-index-view .dropdown-menu .item .amshopby-filter-parent .label {
      top: 1px;
      display: block;
      transform: none;
      padding-right: 0; }
    .catalog-category-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice,
    .catalogsearch-result-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice,
    .ambrand-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice,
    .wishlist-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice,
    .myproducts-index-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice {
      top: 0; }
      .catalog-category-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice:hover::before,
      .catalogsearch-result-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice:hover::before,
      .ambrand-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice:hover::before,
      .wishlist-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice:hover::before,
      .myproducts-index-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice:hover::before {
        border-color: #2D2926; }
      .catalog-category-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before,
      .catalogsearch-result-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before,
      .ambrand-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before,
      .wishlist-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before,
      .myproducts-index-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before {
        border-radius: 0.4rem;
        height: 2.4rem;
        width: 2.4rem;
        border-color: #2D2926; }
        .catalog-category-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before:not(.-empty-value),
        .catalogsearch-result-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before:not(.-empty-value),
        .ambrand-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before:not(.-empty-value),
        .wishlist-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before:not(.-empty-value),
        .myproducts-index-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice::before:not(.-empty-value) {
          border-color: #2D2926; }
      .catalog-category-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice::after,
      .catalogsearch-result-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice::after,
      .ambrand-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice::after,
      .wishlist-index-index .dropdown-menu .item [type='checkbox'] + .amshopby-choice::after,
      .myproducts-index-view .dropdown-menu .item [type='checkbox'] + .amshopby-choice::after {
        background-color: #2D2926;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3e%3cpath stroke='%23fff' d='M18.708 7.708l-8.354 8.353L6 11.708l.707-.707 3.647 3.646L18 7.001z' fill-rule='evenodd'/%3e%3c/svg%3e");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 2.4rem;
        width: 2.4rem;
        border-radius: 0.4rem; }

.catalog-category-view .products,
.catalogsearch-result-index .products,
.ambrand-index-index .products,
.wishlist-index-index .products,
.myproducts-index-view .products {
  margin: 0.9rem 0 3rem; }

.catalog-category-view .top-toolbar .toolbar.toolbar-products,
.catalogsearch-result-index .top-toolbar .toolbar.toolbar-products,
.ambrand-index-index .top-toolbar .toolbar.toolbar-products,
.wishlist-index-index .top-toolbar .toolbar.toolbar-products,
.myproducts-index-view .top-toolbar .toolbar.toolbar-products {
  margin-bottom: 0; }

.catalog-category-view .product-items,
.catalogsearch-result-index .product-items,
.ambrand-index-index .product-items,
.wishlist-index-index .product-items,
.myproducts-index-view .product-items {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start; }
  .catalog-category-view .product-items .product-item,
  .catalogsearch-result-index .product-items .product-item,
  .ambrand-index-index .product-items .product-item,
  .wishlist-index-index .product-items .product-item,
  .myproducts-index-view .product-items .product-item {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    margin-bottom: 1.5rem;
    max-width: 25rem; }
    .catalog-category-view .product-items .product-item .product-item-photo,
    .catalogsearch-result-index .product-items .product-item .product-item-photo,
    .ambrand-index-index .product-items .product-item .product-item-photo,
    .wishlist-index-index .product-items .product-item .product-item-photo,
    .myproducts-index-view .product-items .product-item .product-item-photo {
      max-width: 100%; }
    @media (min-width: 1366px) {
      .catalog-category-view .product-items .product-item,
      .catalogsearch-result-index .product-items .product-item,
      .ambrand-index-index .product-items .product-item,
      .wishlist-index-index .product-items .product-item,
      .myproducts-index-view .product-items .product-item {
        margin-right: 0.8rem; }
        .catalog-category-view .product-items .product-item:nth-child(5n),
        .catalogsearch-result-index .product-items .product-item:nth-child(5n),
        .ambrand-index-index .product-items .product-item:nth-child(5n),
        .wishlist-index-index .product-items .product-item:nth-child(5n),
        .myproducts-index-view .product-items .product-item:nth-child(5n) {
          margin-right: 0; } }
    @media (max-width: 767.98px) {
      .catalog-category-view .product-items .product-item,
      .catalogsearch-result-index .product-items .product-item,
      .ambrand-index-index .product-items .product-item,
      .wishlist-index-index .product-items .product-item,
      .myproducts-index-view .product-items .product-item {
        width: 50%;
        padding: 2.5rem 1.15rem 2rem;
        margin-left: 0;
        margin-bottom: 0;
        border-bottom: 2px solid #d3cfcc; }
        .catalog-category-view .product-items .product-item:nth-child(odd),
        .catalogsearch-result-index .product-items .product-item:nth-child(odd),
        .ambrand-index-index .product-items .product-item:nth-child(odd),
        .wishlist-index-index .product-items .product-item:nth-child(odd),
        .myproducts-index-view .product-items .product-item:nth-child(odd) {
          border-right: 2px solid #d3cfcc; }
        .catalog-category-view .product-items .product-item:first-child, .catalog-category-view .product-items .product-item:nth-last-child(2):nth-child(odd), .catalog-category-view .product-items .product-item:last-of-type:nth-of-type(odd),
        .catalogsearch-result-index .product-items .product-item:first-child,
        .catalogsearch-result-index .product-items .product-item:nth-last-child(2):nth-child(odd),
        .catalogsearch-result-index .product-items .product-item:last-of-type:nth-of-type(odd),
        .ambrand-index-index .product-items .product-item:first-child,
        .ambrand-index-index .product-items .product-item:nth-last-child(2):nth-child(odd),
        .ambrand-index-index .product-items .product-item:last-of-type:nth-of-type(odd),
        .wishlist-index-index .product-items .product-item:first-child,
        .wishlist-index-index .product-items .product-item:nth-last-child(2):nth-child(odd),
        .wishlist-index-index .product-items .product-item:last-of-type:nth-of-type(odd),
        .myproducts-index-view .product-items .product-item:first-child,
        .myproducts-index-view .product-items .product-item:nth-last-child(2):nth-child(odd),
        .myproducts-index-view .product-items .product-item:last-of-type:nth-of-type(odd) {
          border-right: 0; }
          .catalog-category-view .product-items .product-item:first-child::after, .catalog-category-view .product-items .product-item:nth-last-child(2):nth-child(odd)::after, .catalog-category-view .product-items .product-item:last-of-type:nth-of-type(odd)::after,
          .catalogsearch-result-index .product-items .product-item:first-child::after,
          .catalogsearch-result-index .product-items .product-item:nth-last-child(2):nth-child(odd)::after,
          .catalogsearch-result-index .product-items .product-item:last-of-type:nth-of-type(odd)::after,
          .ambrand-index-index .product-items .product-item:first-child::after,
          .ambrand-index-index .product-items .product-item:nth-last-child(2):nth-child(odd)::after,
          .ambrand-index-index .product-items .product-item:last-of-type:nth-of-type(odd)::after,
          .wishlist-index-index .product-items .product-item:first-child::after,
          .wishlist-index-index .product-items .product-item:nth-last-child(2):nth-child(odd)::after,
          .wishlist-index-index .product-items .product-item:last-of-type:nth-of-type(odd)::after,
          .myproducts-index-view .product-items .product-item:first-child::after,
          .myproducts-index-view .product-items .product-item:nth-last-child(2):nth-child(odd)::after,
          .myproducts-index-view .product-items .product-item:last-of-type:nth-of-type(odd)::after {
            border-right-width: 2px;
            border-right-style: solid;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1; }
        .catalog-category-view .product-items .product-item:nth-last-child(2):nth-child(odd)::after, .catalog-category-view .product-items .product-item:last-of-type:nth-of-type(odd)::after,
        .catalogsearch-result-index .product-items .product-item:nth-last-child(2):nth-child(odd)::after,
        .catalogsearch-result-index .product-items .product-item:last-of-type:nth-of-type(odd)::after,
        .ambrand-index-index .product-items .product-item:nth-last-child(2):nth-child(odd)::after,
        .ambrand-index-index .product-items .product-item:last-of-type:nth-of-type(odd)::after,
        .wishlist-index-index .product-items .product-item:nth-last-child(2):nth-child(odd)::after,
        .wishlist-index-index .product-items .product-item:last-of-type:nth-of-type(odd)::after,
        .myproducts-index-view .product-items .product-item:nth-last-child(2):nth-child(odd)::after,
        .myproducts-index-view .product-items .product-item:last-of-type:nth-of-type(odd)::after {
          border-image: linear-gradient(to bottom, #d3cfcc, rgba(0, 0, 0, 0)) 1 100%; }
        .catalog-category-view .product-items .product-item:first-child::after,
        .catalogsearch-result-index .product-items .product-item:first-child::after,
        .ambrand-index-index .product-items .product-item:first-child::after,
        .wishlist-index-index .product-items .product-item:first-child::after,
        .myproducts-index-view .product-items .product-item:first-child::after {
          border-image: linear-gradient(to top, #d3cfcc, rgba(0, 0, 0, 0)) 1 100%; }
        .catalog-category-view .product-items .product-item:last-child:first-child::after,
        .catalogsearch-result-index .product-items .product-item:last-child:first-child::after,
        .ambrand-index-index .product-items .product-item:last-child:first-child::after,
        .wishlist-index-index .product-items .product-item:last-child:first-child::after,
        .myproducts-index-view .product-items .product-item:last-child:first-child::after {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #d3cfcc 25%, #d3cfcc 75%, rgba(255, 255, 255, 0) 100%);
          width: 1px;
          right: 0;
          left: 100%;
          border: none; }
        .catalog-category-view .product-items .product-item:last-child, .catalog-category-view .product-items .product-item:nth-last-child(2):nth-child(odd),
        .catalogsearch-result-index .product-items .product-item:last-child,
        .catalogsearch-result-index .product-items .product-item:nth-last-child(2):nth-child(odd),
        .ambrand-index-index .product-items .product-item:last-child,
        .ambrand-index-index .product-items .product-item:nth-last-child(2):nth-child(odd),
        .wishlist-index-index .product-items .product-item:last-child,
        .wishlist-index-index .product-items .product-item:nth-last-child(2):nth-child(odd),
        .myproducts-index-view .product-items .product-item:last-child,
        .myproducts-index-view .product-items .product-item:nth-last-child(2):nth-child(odd) {
          border-bottom: 0; } }
    .catalog-category-view .product-items .product-item::before,
    .catalogsearch-result-index .product-items .product-item::before,
    .ambrand-index-index .product-items .product-item::before,
    .wishlist-index-index .product-items .product-item::before,
    .myproducts-index-view .product-items .product-item::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%; }
      @media (max-width: 1023.98px) {
        .catalog-category-view .product-items .product-item::before,
        .catalogsearch-result-index .product-items .product-item::before,
        .ambrand-index-index .product-items .product-item::before,
        .wishlist-index-index .product-items .product-item::before,
        .myproducts-index-view .product-items .product-item::before {
          content: none; } }
    .catalog-category-view .product-items .product-item:hover::before,
    .catalogsearch-result-index .product-items .product-item:hover::before,
    .ambrand-index-index .product-items .product-item:hover::before,
    .wishlist-index-index .product-items .product-item:hover::before,
    .myproducts-index-view .product-items .product-item:hover::before {
      border: 2px solid #ededed; }
    .catalog-category-view .product-items .product-item .product-item-info,
    .catalogsearch-result-index .product-items .product-item .product-item-info,
    .ambrand-index-index .product-items .product-item .product-item-info,
    .wishlist-index-index .product-items .product-item .product-item-info,
    .myproducts-index-view .product-items .product-item .product-item-info {
      position: relative;
      padding: 1.5rem 1.9rem;
      width: auto;
      display: flex;
      flex-direction: column;
      flex-grow: 1; }
      @media (max-width: 1365.98px) {
        .catalog-category-view .product-items .product-item .product-item-info,
        .catalogsearch-result-index .product-items .product-item .product-item-info,
        .ambrand-index-index .product-items .product-item .product-item-info,
        .wishlist-index-index .product-items .product-item .product-item-info,
        .myproducts-index-view .product-items .product-item .product-item-info {
          padding: 1rem; } }
      @media (max-width: 1023.98px) {
        .catalog-category-view .product-items .product-item .product-item-info,
        .catalogsearch-result-index .product-items .product-item .product-item-info,
        .ambrand-index-index .product-items .product-item .product-item-info,
        .wishlist-index-index .product-items .product-item .product-item-info,
        .myproducts-index-view .product-items .product-item .product-item-info {
          padding: 2rem 1.1rem 1.5rem; } }
    @media (max-width: 1023.98px) {
      .catalog-category-view .product-items .product-item:nth-last-child(n+2):nth-last-child(-n+2):first-child::after,
      .catalog-category-view .product-items .product-item:first-child + li:last-child::after,
      .catalogsearch-result-index .product-items .product-item:nth-last-child(n+2):nth-last-child(-n+2):first-child::after,
      .catalogsearch-result-index .product-items .product-item:first-child + li:last-child::after,
      .ambrand-index-index .product-items .product-item:nth-last-child(n+2):nth-last-child(-n+2):first-child::after,
      .ambrand-index-index .product-items .product-item:first-child + li:last-child::after,
      .wishlist-index-index .product-items .product-item:nth-last-child(n+2):nth-last-child(-n+2):first-child::after,
      .wishlist-index-index .product-items .product-item:first-child + li:last-child::after,
      .myproducts-index-view .product-items .product-item:nth-last-child(n+2):nth-last-child(-n+2):first-child::after,
      .myproducts-index-view .product-items .product-item:first-child + li:last-child::after {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #d3cfcc 25%, #d3cfcc 75%, rgba(255, 255, 255, 0) 100%);
        width: 1px;
        left: 0;
        right: 100%;
        border: none;
        content: '';
        position: absolute;
        height: 100%; } }
  .catalog-category-view .product-items .product-image-container,
  .catalogsearch-result-index .product-items .product-image-container,
  .ambrand-index-index .product-items .product-image-container,
  .wishlist-index-index .product-items .product-image-container,
  .myproducts-index-view .product-items .product-image-container {
    position: relative;
    max-width: inherit;
    margin: 0 auto 0; }
    @media (max-width: 767.98px) {
      .catalog-category-view .product-items .product-image-container,
      .catalogsearch-result-index .product-items .product-image-container,
      .ambrand-index-index .product-items .product-image-container,
      .wishlist-index-index .product-items .product-image-container,
      .myproducts-index-view .product-items .product-image-container {
        position: initial;
        margin-top: 0;
        width: 100%; }
        .catalog-category-view .product-items .product-image-container .product-item-photo,
        .catalogsearch-result-index .product-items .product-image-container .product-item-photo,
        .ambrand-index-index .product-items .product-image-container .product-item-photo,
        .wishlist-index-index .product-items .product-image-container .product-item-photo,
        .myproducts-index-view .product-items .product-image-container .product-item-photo {
          max-width: 12.8rem;
          display: block;
          margin: auto; } }
    .catalog-category-view .product-items .product-image-container .actions-secondary,
    .catalogsearch-result-index .product-items .product-image-container .actions-secondary,
    .ambrand-index-index .product-items .product-image-container .actions-secondary,
    .wishlist-index-index .product-items .product-image-container .actions-secondary,
    .myproducts-index-view .product-items .product-image-container .actions-secondary {
      position: absolute;
      right: 1rem;
      bottom: 2rem;
      z-index: 1; }
      @media (max-width: 767.98px) {
        .catalog-category-view .product-items .product-image-container .actions-secondary,
        .catalogsearch-result-index .product-items .product-image-container .actions-secondary,
        .ambrand-index-index .product-items .product-image-container .actions-secondary,
        .wishlist-index-index .product-items .product-image-container .actions-secondary,
        .myproducts-index-view .product-items .product-image-container .actions-secondary {
          bottom: 2rem; }
          .catalog-category-view .product-items .product-image-container .actions-secondary .action.towishlist,
          .catalogsearch-result-index .product-items .product-image-container .actions-secondary .action.towishlist,
          .ambrand-index-index .product-items .product-image-container .actions-secondary .action.towishlist,
          .wishlist-index-index .product-items .product-image-container .actions-secondary .action.towishlist,
          .myproducts-index-view .product-items .product-image-container .actions-secondary .action.towishlist {
            padding: 0.88rem; } }
    .catalog-category-view .product-items .product-image-container .product-item-badge,
    .catalogsearch-result-index .product-items .product-image-container .product-item-badge,
    .ambrand-index-index .product-items .product-image-container .product-item-badge,
    .wishlist-index-index .product-items .product-image-container .product-item-badge,
    .myproducts-index-view .product-items .product-image-container .product-item-badge {
      text-align: center;
      position: absolute;
      top: 0;
      z-index: 1; }
      .catalog-category-view .product-items .product-image-container .product-item-badge + .deal,
      .catalog-category-view .product-items .product-image-container .product-item-badge + .clearance,
      .catalog-category-view .product-items .product-image-container .product-item-badge + .new,
      .catalogsearch-result-index .product-items .product-image-container .product-item-badge + .deal,
      .catalogsearch-result-index .product-items .product-image-container .product-item-badge + .clearance,
      .catalogsearch-result-index .product-items .product-image-container .product-item-badge + .new,
      .ambrand-index-index .product-items .product-image-container .product-item-badge + .deal,
      .ambrand-index-index .product-items .product-image-container .product-item-badge + .clearance,
      .ambrand-index-index .product-items .product-image-container .product-item-badge + .new,
      .wishlist-index-index .product-items .product-image-container .product-item-badge + .deal,
      .wishlist-index-index .product-items .product-image-container .product-item-badge + .clearance,
      .wishlist-index-index .product-items .product-image-container .product-item-badge + .new,
      .myproducts-index-view .product-items .product-image-container .product-item-badge + .deal,
      .myproducts-index-view .product-items .product-image-container .product-item-badge + .clearance,
      .myproducts-index-view .product-items .product-image-container .product-item-badge + .new {
        top: 3rem; }
      .catalog-category-view .product-items .product-image-container .product-item-badge.no-gwp,
      .catalogsearch-result-index .product-items .product-image-container .product-item-badge.no-gwp,
      .ambrand-index-index .product-items .product-image-container .product-item-badge.no-gwp,
      .wishlist-index-index .product-items .product-image-container .product-item-badge.no-gwp,
      .myproducts-index-view .product-items .product-image-container .product-item-badge.no-gwp {
        top: 0; }
      @media (max-width: 767.98px) {
        .catalog-category-view .product-items .product-image-container .product-item-badge,
        .catalogsearch-result-index .product-items .product-image-container .product-item-badge,
        .ambrand-index-index .product-items .product-image-container .product-item-badge,
        .wishlist-index-index .product-items .product-image-container .product-item-badge,
        .myproducts-index-view .product-items .product-image-container .product-item-badge {
          top: 2rem; }
          .catalog-category-view .product-items .product-image-container .product-item-badge.deal, .catalog-category-view .product-items .product-image-container .product-item-badge.clearance, .catalog-category-view .product-items .product-image-container .product-item-badge.new,
          .catalogsearch-result-index .product-items .product-image-container .product-item-badge.deal,
          .catalogsearch-result-index .product-items .product-image-container .product-item-badge.clearance,
          .catalogsearch-result-index .product-items .product-image-container .product-item-badge.new,
          .ambrand-index-index .product-items .product-image-container .product-item-badge.deal,
          .ambrand-index-index .product-items .product-image-container .product-item-badge.clearance,
          .ambrand-index-index .product-items .product-image-container .product-item-badge.new,
          .wishlist-index-index .product-items .product-image-container .product-item-badge.deal,
          .wishlist-index-index .product-items .product-image-container .product-item-badge.clearance,
          .wishlist-index-index .product-items .product-image-container .product-item-badge.new,
          .myproducts-index-view .product-items .product-image-container .product-item-badge.deal,
          .myproducts-index-view .product-items .product-image-container .product-item-badge.clearance,
          .myproducts-index-view .product-items .product-image-container .product-item-badge.new {
            top: 5rem; }
          .catalog-category-view .product-items .product-image-container .product-item-badge.no-gwp,
          .catalogsearch-result-index .product-items .product-image-container .product-item-badge.no-gwp,
          .ambrand-index-index .product-items .product-image-container .product-item-badge.no-gwp,
          .wishlist-index-index .product-items .product-image-container .product-item-badge.no-gwp,
          .myproducts-index-view .product-items .product-image-container .product-item-badge.no-gwp {
            top: 2rem; } }
      .catalog-category-view .product-items .product-image-container .product-item-badge span,
      .catalogsearch-result-index .product-items .product-image-container .product-item-badge span,
      .ambrand-index-index .product-items .product-image-container .product-item-badge span,
      .wishlist-index-index .product-items .product-image-container .product-item-badge span,
      .myproducts-index-view .product-items .product-image-container .product-item-badge span {
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 0.5px; }
    .catalog-category-view .product-items .product-image-container .product-label,
    .catalogsearch-result-index .product-items .product-image-container .product-label,
    .ambrand-index-index .product-items .product-image-container .product-label,
    .wishlist-index-index .product-items .product-image-container .product-label,
    .myproducts-index-view .product-items .product-image-container .product-label {
      background-color: #CE0F69;
      color: #fff;
      font-size: 1.2rem;
      text-transform: uppercase;
      z-index: 1;
      border-radius: 2px;
      padding: 0.2rem 2.32rem;
      border: 1px solid #CE0F69; }
      @media (max-width: 767.98px) {
        .catalog-category-view .product-items .product-image-container .product-label,
        .catalogsearch-result-index .product-items .product-image-container .product-label,
        .ambrand-index-index .product-items .product-image-container .product-label,
        .wishlist-index-index .product-items .product-image-container .product-label,
        .myproducts-index-view .product-items .product-image-container .product-label {
          padding: 0.2rem 1.32rem; } }
    .catalog-category-view .product-items .product-image-container .deal,
    .catalogsearch-result-index .product-items .product-image-container .deal,
    .ambrand-index-index .product-items .product-image-container .deal,
    .wishlist-index-index .product-items .product-image-container .deal,
    .myproducts-index-view .product-items .product-image-container .deal {
      background-color: #CE0F69;
      width: 6.5rem;
      border-radius: 0.2rem; }
      .catalog-category-view .product-items .product-image-container .deal span,
      .catalogsearch-result-index .product-items .product-image-container .deal span,
      .ambrand-index-index .product-items .product-image-container .deal span,
      .wishlist-index-index .product-items .product-image-container .deal span,
      .myproducts-index-view .product-items .product-image-container .deal span {
        color: #fff; }
    .catalog-category-view .product-items .product-image-container .clearance,
    .catalogsearch-result-index .product-items .product-image-container .clearance,
    .ambrand-index-index .product-items .product-image-container .clearance,
    .wishlist-index-index .product-items .product-image-container .clearance,
    .myproducts-index-view .product-items .product-image-container .clearance {
      background-color: #fff;
      border: 1px solid #CE0F69;
      width: 10.2rem;
      border-radius: 0.2rem; }
      .catalog-category-view .product-items .product-image-container .clearance span,
      .catalogsearch-result-index .product-items .product-image-container .clearance span,
      .ambrand-index-index .product-items .product-image-container .clearance span,
      .wishlist-index-index .product-items .product-image-container .clearance span,
      .myproducts-index-view .product-items .product-image-container .clearance span {
        color: #CE0F69;
        font-weight: 700; }
    .catalog-category-view .product-items .product-image-container .new,
    .catalogsearch-result-index .product-items .product-image-container .new,
    .ambrand-index-index .product-items .product-image-container .new,
    .wishlist-index-index .product-items .product-image-container .new,
    .myproducts-index-view .product-items .product-image-container .new {
      right: 0; }
      @media (max-width: 767.98px) {
        .catalog-category-view .product-items .product-image-container .new,
        .catalogsearch-result-index .product-items .product-image-container .new,
        .ambrand-index-index .product-items .product-image-container .new,
        .wishlist-index-index .product-items .product-image-container .new,
        .myproducts-index-view .product-items .product-image-container .new {
          right: 0.5rem;
          top: 2rem; } }
    .catalog-category-view .product-items .product-image-container .unavailable,
    .catalogsearch-result-index .product-items .product-image-container .unavailable,
    .ambrand-index-index .product-items .product-image-container .unavailable,
    .wishlist-index-index .product-items .product-image-container .unavailable,
    .myproducts-index-view .product-items .product-image-container .unavailable {
      left: 0;
      right: 0;
      top: -0.6rem; }
      @media (max-width: 1023.98px) {
        .catalog-category-view .product-items .product-image-container .unavailable,
        .catalogsearch-result-index .product-items .product-image-container .unavailable,
        .ambrand-index-index .product-items .product-image-container .unavailable,
        .wishlist-index-index .product-items .product-image-container .unavailable,
        .myproducts-index-view .product-items .product-image-container .unavailable {
          top: 0; } }
      .catalog-category-view .product-items .product-image-container .unavailable span,
      .catalogsearch-result-index .product-items .product-image-container .unavailable span,
      .ambrand-index-index .product-items .product-image-container .unavailable span,
      .wishlist-index-index .product-items .product-image-container .unavailable span,
      .myproducts-index-view .product-items .product-image-container .unavailable span {
        color: #2D2926; }
  .catalog-category-view .product-items .product-item-details,
  .catalogsearch-result-index .product-items .product-item-details,
  .ambrand-index-index .product-items .product-item-details,
  .wishlist-index-index .product-items .product-item-details,
  .myproducts-index-view .product-items .product-item-details {
    margin-top: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
    .catalog-category-view .product-items .product-item-details .product-item-sku,
    .catalogsearch-result-index .product-items .product-item-details .product-item-sku,
    .ambrand-index-index .product-items .product-item-details .product-item-sku,
    .wishlist-index-index .product-items .product-item-details .product-item-sku,
    .myproducts-index-view .product-items .product-item-details .product-item-sku {
      font-size: 1.3rem; }
    .catalog-category-view .product-items .product-item-details .product-item-brand,
    .catalogsearch-result-index .product-items .product-item-details .product-item-brand,
    .ambrand-index-index .product-items .product-item-details .product-item-brand,
    .wishlist-index-index .product-items .product-item-details .product-item-brand,
    .myproducts-index-view .product-items .product-item-details .product-item-brand {
      font-size: 1.3rem;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0;
      z-index: 999; }
      @media (max-width: 1023.98px) {
        .catalog-category-view .product-items .product-item-details .product-item-brand,
        .catalogsearch-result-index .product-items .product-item-details .product-item-brand,
        .ambrand-index-index .product-items .product-item-details .product-item-brand,
        .wishlist-index-index .product-items .product-item-details .product-item-brand,
        .myproducts-index-view .product-items .product-item-details .product-item-brand {
          font-size: 1.2rem; } }
      .catalog-category-view .product-items .product-item-details .product-item-brand a,
      .catalogsearch-result-index .product-items .product-item-details .product-item-brand a,
      .ambrand-index-index .product-items .product-item-details .product-item-brand a,
      .wishlist-index-index .product-items .product-item-details .product-item-brand a,
      .myproducts-index-view .product-items .product-item-details .product-item-brand a {
        text-decoration: none; }
      .catalog-category-view .product-items .product-item-details .product-item-brand a:hover,
      .catalogsearch-result-index .product-items .product-item-details .product-item-brand a:hover,
      .ambrand-index-index .product-items .product-item-details .product-item-brand a:hover,
      .wishlist-index-index .product-items .product-item-details .product-item-brand a:hover,
      .myproducts-index-view .product-items .product-item-details .product-item-brand a:hover {
        text-decoration: underline; }
    .catalog-category-view .product-items .product-item-details .product-item-name,
    .catalogsearch-result-index .product-items .product-item-details .product-item-name,
    .ambrand-index-index .product-items .product-item-details .product-item-name,
    .wishlist-index-index .product-items .product-item-details .product-item-name,
    .myproducts-index-view .product-items .product-item-details .product-item-name {
      margin: 0 0 auto; }
      @media (max-width: 1023.98px) {
        .catalog-category-view .product-items .product-item-details .product-item-name,
        .catalogsearch-result-index .product-items .product-item-details .product-item-name,
        .ambrand-index-index .product-items .product-item-details .product-item-name,
        .wishlist-index-index .product-items .product-item-details .product-item-name,
        .myproducts-index-view .product-items .product-item-details .product-item-name {
          font-size: 1.3rem; } }
      .catalog-category-view .product-items .product-item-details .product-item-name .product-item-link:hover,
      .catalogsearch-result-index .product-items .product-item-details .product-item-name .product-item-link:hover,
      .ambrand-index-index .product-items .product-item-details .product-item-name .product-item-link:hover,
      .wishlist-index-index .product-items .product-item-details .product-item-name .product-item-link:hover,
      .myproducts-index-view .product-items .product-item-details .product-item-name .product-item-link:hover {
        text-decoration: none; }
      .catalog-category-view .product-items .product-item-details .product-item-name .product-item-link::before,
      .catalogsearch-result-index .product-items .product-item-details .product-item-name .product-item-link::before,
      .ambrand-index-index .product-items .product-item-details .product-item-name .product-item-link::before,
      .wishlist-index-index .product-items .product-item-details .product-item-name .product-item-link::before,
      .myproducts-index-view .product-items .product-item-details .product-item-name .product-item-link::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; }
        @media (max-width: 1023.98px) {
          .catalog-category-view .product-items .product-item-details .product-item-name .product-item-link::before,
          .catalogsearch-result-index .product-items .product-item-details .product-item-name .product-item-link::before,
          .ambrand-index-index .product-items .product-item-details .product-item-name .product-item-link::before,
          .wishlist-index-index .product-items .product-item-details .product-item-name .product-item-link::before,
          .myproducts-index-view .product-items .product-item-details .product-item-name .product-item-link::before {
            content: none; } }
    .catalog-category-view .product-items .product-item-details .amshopby-option-link,
    .catalogsearch-result-index .product-items .product-item-details .amshopby-option-link,
    .ambrand-index-index .product-items .product-item-details .amshopby-option-link,
    .wishlist-index-index .product-items .product-item-details .amshopby-option-link,
    .myproducts-index-view .product-items .product-item-details .amshopby-option-link {
      display: none; }
    .catalog-category-view .product-items .product-item-details .price-section,
    .catalog-category-view .product-items .product-item-details .price-container-wrapper,
    .catalogsearch-result-index .product-items .product-item-details .price-section,
    .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper,
    .ambrand-index-index .product-items .product-item-details .price-section,
    .ambrand-index-index .product-items .product-item-details .price-container-wrapper,
    .wishlist-index-index .product-items .product-item-details .price-section,
    .wishlist-index-index .product-items .product-item-details .price-container-wrapper,
    .myproducts-index-view .product-items .product-item-details .price-section,
    .myproducts-index-view .product-items .product-item-details .price-container-wrapper {
      width: 100%; }
    .catalog-category-view .product-items .product-item-details .price-box,
    .catalog-category-view .product-items .product-item-details .price-container-wrapper,
    .catalogsearch-result-index .product-items .product-item-details .price-box,
    .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper,
    .ambrand-index-index .product-items .product-item-details .price-box,
    .ambrand-index-index .product-items .product-item-details .price-container-wrapper,
    .wishlist-index-index .product-items .product-item-details .price-box,
    .wishlist-index-index .product-items .product-item-details .price-container-wrapper,
    .myproducts-index-view .product-items .product-item-details .price-box,
    .myproducts-index-view .product-items .product-item-details .price-container-wrapper {
      clear: both;
      display: flex;
      flex-wrap: wrap;
      margin: 0.25rem 0 0;
      align-items: center; }
      .catalog-category-view .product-items .product-item-details .price-box .price-container,
      .catalog-category-view .product-items .product-item-details .price-container-wrapper .price-container,
      .catalogsearch-result-index .product-items .product-item-details .price-box .price-container,
      .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .price-container,
      .ambrand-index-index .product-items .product-item-details .price-box .price-container,
      .ambrand-index-index .product-items .product-item-details .price-container-wrapper .price-container,
      .wishlist-index-index .product-items .product-item-details .price-box .price-container,
      .wishlist-index-index .product-items .product-item-details .price-container-wrapper .price-container,
      .myproducts-index-view .product-items .product-item-details .price-box .price-container,
      .myproducts-index-view .product-items .product-item-details .price-container-wrapper .price-container {
        line-height: 1; }
      .catalog-category-view .product-items .product-item-details .price-box .old-price,
      .catalog-category-view .product-items .product-item-details .price-container-wrapper .old-price,
      .catalogsearch-result-index .product-items .product-item-details .price-box .old-price,
      .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .old-price,
      .ambrand-index-index .product-items .product-item-details .price-box .old-price,
      .ambrand-index-index .product-items .product-item-details .price-container-wrapper .old-price,
      .wishlist-index-index .product-items .product-item-details .price-box .old-price,
      .wishlist-index-index .product-items .product-item-details .price-container-wrapper .old-price,
      .myproducts-index-view .product-items .product-item-details .price-box .old-price,
      .myproducts-index-view .product-items .product-item-details .price-container-wrapper .old-price {
        text-decoration: none;
        margin-left: auto; }
        .catalog-category-view .product-items .product-item-details .price-box .old-price .price,
        .catalog-category-view .product-items .product-item-details .price-container-wrapper .old-price .price,
        .catalogsearch-result-index .product-items .product-item-details .price-box .old-price .price,
        .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .old-price .price,
        .ambrand-index-index .product-items .product-item-details .price-box .old-price .price,
        .ambrand-index-index .product-items .product-item-details .price-container-wrapper .old-price .price,
        .wishlist-index-index .product-items .product-item-details .price-box .old-price .price,
        .wishlist-index-index .product-items .product-item-details .price-container-wrapper .old-price .price,
        .myproducts-index-view .product-items .product-item-details .price-box .old-price .price,
        .myproducts-index-view .product-items .product-item-details .price-container-wrapper .old-price .price {
          font-weight: normal;
          text-decoration: line-through; }
      .catalog-category-view .product-items .product-item-details .price-box .price-wrapper .price,
      .catalog-category-view .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
      .catalogsearch-result-index .product-items .product-item-details .price-box .price-wrapper .price,
      .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
      .ambrand-index-index .product-items .product-item-details .price-box .price-wrapper .price,
      .ambrand-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
      .wishlist-index-index .product-items .product-item-details .price-box .price-wrapper .price,
      .wishlist-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
      .myproducts-index-view .product-items .product-item-details .price-box .price-wrapper .price,
      .myproducts-index-view .product-items .product-item-details .price-container-wrapper .price-wrapper .price {
        font-size: 2.2rem; }
        @media (max-width: 1365.98px) {
          .catalog-category-view .product-items .product-item-details .price-box .price-wrapper .price,
          .catalog-category-view .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
          .catalogsearch-result-index .product-items .product-item-details .price-box .price-wrapper .price,
          .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
          .ambrand-index-index .product-items .product-item-details .price-box .price-wrapper .price,
          .ambrand-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
          .wishlist-index-index .product-items .product-item-details .price-box .price-wrapper .price,
          .wishlist-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
          .myproducts-index-view .product-items .product-item-details .price-box .price-wrapper .price,
          .myproducts-index-view .product-items .product-item-details .price-container-wrapper .price-wrapper .price {
            font-size: 2rem; } }
        @media (max-width: 1023.98px) {
          .catalog-category-view .product-items .product-item-details .price-box .price-wrapper .price,
          .catalog-category-view .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
          .catalogsearch-result-index .product-items .product-item-details .price-box .price-wrapper .price,
          .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
          .ambrand-index-index .product-items .product-item-details .price-box .price-wrapper .price,
          .ambrand-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
          .wishlist-index-index .product-items .product-item-details .price-box .price-wrapper .price,
          .wishlist-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper .price,
          .myproducts-index-view .product-items .product-item-details .price-box .price-wrapper .price,
          .myproducts-index-view .product-items .product-item-details .price-container-wrapper .price-wrapper .price {
            font-size: 1.6rem; } }
      .catalog-category-view .product-items .product-item-details .price-box .price-wrapper.regular-price,
      .catalog-category-view .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price,
      .catalogsearch-result-index .product-items .product-item-details .price-box .price-wrapper.regular-price,
      .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price,
      .ambrand-index-index .product-items .product-item-details .price-box .price-wrapper.regular-price,
      .ambrand-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price,
      .wishlist-index-index .product-items .product-item-details .price-box .price-wrapper.regular-price,
      .wishlist-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price,
      .myproducts-index-view .product-items .product-item-details .price-box .price-wrapper.regular-price,
      .myproducts-index-view .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price {
        display: flex;
        align-items: center; }
        .catalog-category-view .product-items .product-item-details .price-box .price-wrapper.regular-price span,
        .catalog-category-view .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price span,
        .catalogsearch-result-index .product-items .product-item-details .price-box .price-wrapper.regular-price span,
        .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price span,
        .ambrand-index-index .product-items .product-item-details .price-box .price-wrapper.regular-price span,
        .ambrand-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price span,
        .wishlist-index-index .product-items .product-item-details .price-box .price-wrapper.regular-price span,
        .wishlist-index-index .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price span,
        .myproducts-index-view .product-items .product-item-details .price-box .price-wrapper.regular-price span,
        .myproducts-index-view .product-items .product-item-details .price-container-wrapper .price-wrapper.regular-price span {
          font-weight: 700; }
      .catalog-category-view .product-items .product-item-details .price-box em,
      .catalog-category-view .product-items .product-item-details .price-container-wrapper em,
      .catalogsearch-result-index .product-items .product-item-details .price-box em,
      .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper em,
      .ambrand-index-index .product-items .product-item-details .price-box em,
      .ambrand-index-index .product-items .product-item-details .price-container-wrapper em,
      .wishlist-index-index .product-items .product-item-details .price-box em,
      .wishlist-index-index .product-items .product-item-details .price-container-wrapper em,
      .myproducts-index-view .product-items .product-item-details .price-box em,
      .myproducts-index-view .product-items .product-item-details .price-container-wrapper em {
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
        margin-left: 0.4rem; }
        @media (max-width: 1023.98px) {
          .catalog-category-view .product-items .product-item-details .price-box em,
          .catalog-category-view .product-items .product-item-details .price-container-wrapper em,
          .catalogsearch-result-index .product-items .product-item-details .price-box em,
          .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper em,
          .ambrand-index-index .product-items .product-item-details .price-box em,
          .ambrand-index-index .product-items .product-item-details .price-container-wrapper em,
          .wishlist-index-index .product-items .product-item-details .price-box em,
          .wishlist-index-index .product-items .product-item-details .price-container-wrapper em,
          .myproducts-index-view .product-items .product-item-details .price-box em,
          .myproducts-index-view .product-items .product-item-details .price-container-wrapper em {
            display: none; } }
      .catalog-category-view .product-items .product-item-details .price-box .special-price,
      .catalog-category-view .product-items .product-item-details .price-container-wrapper .special-price,
      .catalogsearch-result-index .product-items .product-item-details .price-box .special-price,
      .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .special-price,
      .ambrand-index-index .product-items .product-item-details .price-box .special-price,
      .ambrand-index-index .product-items .product-item-details .price-container-wrapper .special-price,
      .wishlist-index-index .product-items .product-item-details .price-box .special-price,
      .wishlist-index-index .product-items .product-item-details .price-container-wrapper .special-price,
      .myproducts-index-view .product-items .product-item-details .price-box .special-price,
      .myproducts-index-view .product-items .product-item-details .price-container-wrapper .special-price {
        display: flex;
        align-items: center;
        color: #CE0F69; }
      .catalog-category-view .product-items .product-item-details .price-box .rrp-price,
      .catalog-category-view .product-items .product-item-details .price-container-wrapper .rrp-price,
      .catalogsearch-result-index .product-items .product-item-details .price-box .rrp-price,
      .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .rrp-price,
      .ambrand-index-index .product-items .product-item-details .price-box .rrp-price,
      .ambrand-index-index .product-items .product-item-details .price-container-wrapper .rrp-price,
      .wishlist-index-index .product-items .product-item-details .price-box .rrp-price,
      .wishlist-index-index .product-items .product-item-details .price-container-wrapper .rrp-price,
      .myproducts-index-view .product-items .product-item-details .price-box .rrp-price,
      .myproducts-index-view .product-items .product-item-details .price-container-wrapper .rrp-price {
        flex-basis: 100%;
        font-size: 1.5rem;
        font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
        .catalog-category-view .product-items .product-item-details .price-box .rrp-price .gst,
        .catalog-category-view .product-items .product-item-details .price-container-wrapper .rrp-price .gst,
        .catalogsearch-result-index .product-items .product-item-details .price-box .rrp-price .gst,
        .catalogsearch-result-index .product-items .product-item-details .price-container-wrapper .rrp-price .gst,
        .ambrand-index-index .product-items .product-item-details .price-box .rrp-price .gst,
        .ambrand-index-index .product-items .product-item-details .price-container-wrapper .rrp-price .gst,
        .wishlist-index-index .product-items .product-item-details .price-box .rrp-price .gst,
        .wishlist-index-index .product-items .product-item-details .price-container-wrapper .rrp-price .gst,
        .myproducts-index-view .product-items .product-item-details .price-box .rrp-price .gst,
        .myproducts-index-view .product-items .product-item-details .price-container-wrapper .rrp-price .gst {
          color: #2D2926;
          font-style: normal; }

.catalog-category-view .category-view,
.catalogsearch-result-index .category-view,
.ambrand-index-index .category-view,
.wishlist-index-index .category-view,
.myproducts-index-view .category-view {
  margin: 0.5rem 0; }
  .catalog-category-view .category-view .category-description,
  .catalogsearch-result-index .category-view .category-description,
  .ambrand-index-index .category-view .category-description,
  .wishlist-index-index .category-view .category-description,
  .myproducts-index-view .category-view .category-description {
    text-align: center;
    max-width: 73rem;
    margin: 0 auto; }
    .catalog-category-view .category-view .category-description .read-more-link,
    .catalogsearch-result-index .category-view .category-description .read-more-link,
    .ambrand-index-index .category-view .category-description .read-more-link,
    .wishlist-index-index .category-view .category-description .read-more-link,
    .myproducts-index-view .category-view .category-description .read-more-link {
      text-decoration: none;
      padding-right: 1rem; }
      .catalog-category-view .category-view .category-description .read-more-link:hover,
      .catalogsearch-result-index .category-view .category-description .read-more-link:hover,
      .ambrand-index-index .category-view .category-description .read-more-link:hover,
      .wishlist-index-index .category-view .category-description .read-more-link:hover,
      .myproducts-index-view .category-view .category-description .read-more-link:hover {
        text-decoration: underline; }
      .catalog-category-view .category-view .category-description .read-more-link:not(.collapsed) img,
      .catalogsearch-result-index .category-view .category-description .read-more-link:not(.collapsed) img,
      .ambrand-index-index .category-view .category-description .read-more-link:not(.collapsed) img,
      .wishlist-index-index .category-view .category-description .read-more-link:not(.collapsed) img,
      .myproducts-index-view .category-view .category-description .read-more-link:not(.collapsed) img {
        transform: rotate(180deg); }
      .catalog-category-view .category-view .category-description .read-more-link img,
      .catalogsearch-result-index .category-view .category-description .read-more-link img,
      .ambrand-index-index .category-view .category-description .read-more-link img,
      .wishlist-index-index .category-view .category-description .read-more-link img,
      .myproducts-index-view .category-view .category-description .read-more-link img {
        margin: 0 0 0.5rem 1rem; }

@media (max-width: 767.98px) {
  .wishlist-index-index .product-items {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto; }
    .wishlist-index-index .product-items .product-item-details .product-item-inner .action.tocart {
      width: 100%; } }

@media (min-width: 768px) {
  .page-products .column.main {
    width: 100%; } }

@media (min-width: 1024px) {
  .page-products .products-grid .product-item {
    width: 20% !important;
    margin-left: 0 !important; } }

.amblog-related-grid .product-item .ingredient-icons,
.page-products .products-grid .product-item .ingredient-icons {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }
  .amblog-related-grid .product-item .ingredient-icons li,
  .page-products .products-grid .product-item .ingredient-icons li {
    position: relative;
    z-index: 1;
    margin-bottom: 0.3rem; }
    @media (min-width: 768px) {
      .amblog-related-grid .product-item .ingredient-icons li,
      .page-products .products-grid .product-item .ingredient-icons li {
        margin-bottom: 0.5rem; } }
    .amblog-related-grid .product-item .ingredient-icons li:not(:last-child),
    .page-products .products-grid .product-item .ingredient-icons li:not(:last-child) {
      margin-right: 0.44rem; }
      @media (min-width: 1366px) {
        .amblog-related-grid .product-item .ingredient-icons li:not(:last-child),
        .page-products .products-grid .product-item .ingredient-icons li:not(:last-child) {
          margin-right: 1.24rem; } }
      @media (min-width: 768px) and (max-width: 1023.98px) {
        .amblog-related-grid .product-item .ingredient-icons li:not(:last-child),
        .page-products .products-grid .product-item .ingredient-icons li:not(:last-child) {
          margin-right: 1.24rem; } }

.category-advertising-wrapper {
  max-width: 192rem;
  margin: 0 auto;
  width: 100%; }
  .category-advertising-wrapper .block-static-block {
    position: relative; }
    .category-advertising-wrapper .block-static-block .promo-banner {
      height: 0;
      padding-bottom: 38.8%;
      margin-bottom: 0;
      background-position: center !important; }
      @media (min-width: 641px) {
        .category-advertising-wrapper .block-static-block .promo-banner {
          padding-bottom: 18%; } }
      .category-advertising-wrapper .block-static-block .promo-banner > div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-position: center !important; }
    .category-advertising-wrapper .block-static-block img {
      width: 100%; }
    .category-advertising-wrapper .block-static-block [data-content-type='button-item'],
    .category-advertising-wrapper .block-static-block .pagebuilder-button-primary {
      position: absolute;
      width: 100%;
      height: 100%; }

.cms-page-view .page-main {
  margin: 9rem auto 13rem;
  max-width: 81rem; }
  .cms-page-view .page-main .page-title-wrapper {
    padding: 0; }
    .cms-page-view .page-main .page-title-wrapper .page-title {
      margin-bottom: 0; }
  .cms-page-view .page-main .column.main {
    margin: 2.75rem auto 0;
    max-width: 84.7rem; }
    .cms-page-view .page-main .column.main [data-content-type='row'][data-appearance='full-width'] {
      display: none !important; }
    .cms-page-view .page-main .column.main [data-content-type='row'] [data-element='inner'] {
      padding: 0; }
    .cms-page-view .page-main .column.main [data-content-type='row'] [data-content-type='heading'] {
      margin: 2.4rem 0; }

@media (max-width: 575.98px) {
  .cms-page-view .page-main {
    padding: 4rem 0;
    margin: 0; }
    .cms-page-view .page-main .page-title-wrapper {
      padding: 0;
      text-align: center; }
    .cms-page-view .page-main .column.main {
      margin: 2.75rem auto 1rem; }
      .cms-page-view .page-main .column.main [data-content-type='row'] {
        padding: 0.2rem 3.6rem; }
        .cms-page-view .page-main .column.main [data-content-type='row'] .main-section {
          text-align: center; }
          .cms-page-view .page-main .column.main [data-content-type='row'] .main-section [data-content-type='text'] {
            font-size: 1.8rem;
            line-height: 1.3; }
            .cms-page-view .page-main .column.main [data-content-type='row'] .main-section [data-content-type='text'] p {
              margin-bottom: 1.8rem; }
        .cms-page-view .page-main .column.main [data-content-type='row'][data-appearance='full-width'] {
          display: flex !important;
          padding-top: 0;
          padding-right: 0; }
          .cms-page-view .page-main .column.main [data-content-type='row'][data-appearance='full-width'] [data-content-type='divider'] {
            display: flex; }
            .cms-page-view .page-main .column.main [data-content-type='row'][data-appearance='full-width'] [data-content-type='divider'] hr {
              margin: 2rem 0; }
        .cms-page-view .page-main .column.main [data-content-type='row'] [data-content-type='heading'] {
          text-align: left;
          margin: 2rem 0 1rem; }
        .cms-page-view .page-main .column.main [data-content-type='row'] [data-content-type='text'] {
          font-size: 1.5rem; } }

.cms-brands .brands-gateway-banner-wrapper,
.cms-meet-our-brands .brands-gateway-banner-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 18%;
  margin-bottom: 0; }
  @media (max-width: 575.98px) {
    .cms-brands .brands-gateway-banner-wrapper,
    .cms-meet-our-brands .brands-gateway-banner-wrapper {
      padding-bottom: 34%; } }
  .cms-brands .brands-gateway-banner-wrapper .hero-banner,
  .cms-meet-our-brands .brands-gateway-banner-wrapper .hero-banner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center !important; }

.cms-brands .page-main,
.cms-meet-our-brands .page-main {
  margin: 0 auto 1rem;
  max-width: 901px;
  padding: 0 2rem; }
  @media (max-width: 575.98px) {
    .cms-brands .page-main,
    .cms-meet-our-brands .page-main {
      padding: 0; }
      .cms-brands .page-main.b2c,
      .cms-meet-our-brands .page-main.b2c {
        margin: 2.3rem auto 1rem; } }
  .cms-brands .page-main .page-title-wrapper,
  .cms-meet-our-brands .page-main .page-title-wrapper {
    display: flex;
    justify-content: center; }
    .cms-brands .page-main .page-title-wrapper .page-title,
    .cms-meet-our-brands .page-main .page-title-wrapper .page-title {
      margin-bottom: 0; }
      .cms-brands .page-main .page-title-wrapper .page-title .base,
      .cms-meet-our-brands .page-main .page-title-wrapper .page-title .base {
        line-height: 1.125; }
  .cms-brands .page-main .column.main,
  .cms-meet-our-brands .page-main .column.main {
    margin: 1.3rem auto; }
    .cms-brands .page-main .column.main .short-description,
    .cms-meet-our-brands .page-main .column.main .short-description {
      margin-top: 3.15rem;
      padding: 0 1.6rem;
      text-align: center;
      line-height: 1.6; }
      @media (max-width: 767.98px) {
        .cms-brands .page-main .column.main .short-description,
        .cms-meet-our-brands .page-main .column.main .short-description {
          margin-top: 2rem; } }
      @media (max-width: 575.98px) {
        .cms-brands .page-main .column.main .short-description,
        .cms-meet-our-brands .page-main .column.main .short-description {
          margin-top: 1.5rem; } }
    .cms-brands .page-main .column.main .top-categories-wrapper,
    .cms-meet-our-brands .page-main .column.main .top-categories-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 770px;
      margin: 3rem auto 2.8rem; }
      @media (max-width: 575.98px) {
        .cms-brands .page-main .column.main .top-categories-wrapper,
        .cms-meet-our-brands .page-main .column.main .top-categories-wrapper {
          margin: 3.5rem 3.6rem 4rem 3.7rem; } }
      .cms-brands .page-main .column.main .top-categories-wrapper .top-categories,
      .cms-meet-our-brands .page-main .column.main .top-categories-wrapper .top-categories {
        display: flex;
        list-style: none;
        margin: 0;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 0; }
        .cms-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item,
        .cms-meet-our-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item {
          height: 50px;
          display: flex;
          align-items: center;
          padding: 1.5rem 2rem;
          border-radius: 0.2rem;
          border: 1px solid #ddd;
          margin: 0 0.6rem 1.4rem;
          background-color: #fff;
          text-transform: uppercase;
          font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-weight: bold;
          font-size: 1.5rem;
          line-height: 1.2;
          color: #40C5A2;
          white-space: nowrap;
          flex-grow: 1;
          justify-content: center; }
          .cms-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item.active, .cms-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item:hover,
          .cms-meet-our-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item.active,
          .cms-meet-our-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item:hover {
            border-color: #40C5A2;
            background-color: #40C5A2;
            color: #fff; }
            .cms-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item.active .label, .cms-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item:hover .label,
            .cms-meet-our-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item.active .label,
            .cms-meet-our-brands .page-main .column.main .top-categories-wrapper .top-categories .top-category-item:hover .label {
              color: #fff;
              background-color: inherit; }
        .cms-brands .page-main .column.main .top-categories-wrapper .top-categories:first-child .top-category-item,
        .cms-meet-our-brands .page-main .column.main .top-categories-wrapper .top-categories:first-child .top-category-item {
          padding: 1.5rem 2.7rem; }
    .cms-brands .page-main .column.main .ambrands-filters-block,
    .cms-meet-our-brands .page-main .column.main .ambrands-filters-block {
      margin: 2rem 0 4.6rem; }
      @media (max-width: 575.98px) {
        .cms-brands .page-main .column.main .ambrands-filters-block,
        .cms-meet-our-brands .page-main .column.main .ambrands-filters-block {
          margin: 2rem 0 3rem; } }
    .cms-brands .page-main .column.main .ambrands-letters-filter,
    .cms-meet-our-brands .page-main .column.main .ambrands-letters-filter {
      margin-left: -0.4rem;
      margin-right: -0.4rem;
      max-width: 850px; }
      .cms-brands .page-main .column.main .ambrands-letters-filter .ambrands-letter,
      .cms-meet-our-brands .page-main .column.main .ambrands-letters-filter .ambrands-letter {
        width: 50px;
        height: 50px;
        font-size: 1.5rem;
        font-weight: bold;
        font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        color: #40C5A2;
        border-radius: 0.2rem;
        border: 1px solid #ddd;
        margin: 0 1rem 1rem 0; }
        .cms-brands .page-main .column.main .ambrands-letters-filter .ambrands-letter:hover, .cms-brands .page-main .column.main .ambrands-letters-filter .ambrands-letter.-active,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-filter .ambrands-letter:hover,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-filter .ambrands-letter.-active {
          background-color: #40C5A2;
          border: none;
          color: #fff; }
      .cms-brands .page-main .column.main .ambrands-letters-filter .brands-list,
      .cms-meet-our-brands .page-main .column.main .ambrands-letters-filter .brands-list {
        font-size: 1.4rem;
        color: #837C78;
        background-image: url("../images/icon-chevron-down.svg");
        background-size: 24px auto;
        margin: 0 1.7rem; }
        @media (max-width: 575.98px) {
          .cms-brands .page-main .column.main .ambrands-letters-filter .brands-list,
          .cms-meet-our-brands .page-main .column.main .ambrands-letters-filter .brands-list {
            margin: 0 3.6rem 0 3.7rem; } }
    @media (max-width: 575.98px) {
      .cms-brands .page-main .column.main .ambrands-letters-list,
      .cms-meet-our-brands .page-main .column.main .ambrands-letters-list {
        position: relative; } }
    .cms-brands .page-main .column.main .ambrands-letters-list::before,
    .cms-meet-our-brands .page-main .column.main .ambrands-letters-list::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: #f8f7f7;
      position: absolute;
      left: 0;
      z-index: -1;
      min-height: 400px; }
      @media (max-width: 575.98px) {
        .cms-brands .page-main .column.main .ambrands-letters-list::before,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list::before {
          height: 100%; } }
    .cms-brands .page-main .column.main .ambrands-letters-list.even::before,
    .cms-meet-our-brands .page-main .column.main .ambrands-letters-list.even::before {
      background-color: #fff; }
    .cms-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter,
    .cms-meet-our-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter {
      padding: 3rem 8rem; }
      @media (max-width: 767.98px) {
        .cms-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter {
          padding: 3rem 4.7rem; } }
      @media (max-width: 575.98px) {
        .cms-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter {
          padding: 3rem 5.7rem; } }
      .cms-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter .ambrands-content,
      .cms-meet-our-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter .ambrands-content {
        margin-bottom: 1rem; }
        .cms-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter .ambrands-content .ambrands-brand-item,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list.even .ambrands-letter .ambrands-content .ambrands-brand-item {
          flex-grow: 1;
          max-width: 33%; }
    .cms-brands .page-main .column.main .ambrands-letters-list:nth-child(even)::before,
    .cms-meet-our-brands .page-main .column.main .ambrands-letters-list:nth-child(even)::before {
      background-color: #fff; }
    .cms-brands .page-main .column.main .ambrands-letters-list[style*='none'] ~ .ambrands-letters-list::before,
    .cms-meet-our-brands .page-main .column.main .ambrands-letters-list[style*='none'] ~ .ambrands-letters-list::before {
      background-color: #f8f7f7; }
    .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter,
    .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter {
      margin: 0 auto;
      max-width: 862px;
      padding: 3rem 3rem 6.6rem;
      width: 100%; }
      @media (max-width: 767.98px) {
        .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter {
          padding: 3rem 4.7rem; } }
      @media (max-width: 575.98px) {
        .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter {
          min-height: auto; } }
      .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-title,
      .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-title {
        margin-bottom: 1.5rem;
        font-size: 2rem;
        position: relative; }
        @media (max-width: 767.98px) {
          .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-title,
          .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-title {
            margin-bottom: 3.2rem; } }
      .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content,
      .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content {
        display: grid;
        grid-auto-flow: dense;
        grid-auto-columns: 33% 33% 33%; }
        @media (max-width: 767.98px) {
          .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content,
          .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content {
            display: flex; } }
        .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .grid-1,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .grid-1 {
          grid-column: 1; }
        .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .grid-2,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .grid-2 {
          grid-column: 2; }
        .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .grid-3,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .grid-3 {
          grid-column: 3; }
        .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item,
        .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item {
          margin: 0;
          text-align: left;
          max-width: 100% !important; }
          @media (max-width: 767.98px) {
            .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item,
            .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item {
              max-width: 100%;
              margin-bottom: 0.5rem; } }
          .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner,
          .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner {
            display: inline-block;
            border: none;
            border-radius: unset;
            box-shadow: unset;
            background-color: transparent;
            text-decoration: none; }
            .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner:hover,
            .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner:hover {
              text-decoration: underline; }
            .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner.no-link:hover,
            .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner.no-link:hover {
              text-decoration: none; }
            .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner .ambrands-label,
            .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner .ambrands-label {
              font-size: 1.5rem;
              font-weight: normal;
              padding: 0 10px;
              display: flex; }
              @media (min-width: 768px) {
                .cms-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner .ambrands-label,
                .cms-meet-our-brands .page-main .column.main .ambrands-letters-list .ambrands-letter .ambrands-content .ambrands-brand-item .ambrands-inner .ambrands-label {
                  line-height: 23px; } }

.cms-brands .page-footer,
.cms-meet-our-brands .page-footer {
  position: relative; }

.mbrands-wrapper,
.top-categories-wrapper {
  scroll-margin-top: 20rem; }

.cms-meet-our-brands .page-main {
  margin: 4.8rem auto 1rem; }

@media (max-width: 575.98px) {
  .ambrand-index-index .page-title-wrapper .page-title {
    margin-bottom: 1.5rem; } }

.ambrand-index-index .category-advertising-wrapper .category-image {
  position: relative;
  height: 0;
  padding-bottom: 18%;
  margin-bottom: 0; }
  .ambrand-index-index .category-advertising-wrapper .category-image.mobile-image {
    padding-bottom: 38.7%; }
  .ambrand-index-index .category-advertising-wrapper .category-image .image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%; }

.ambrand-index-index .category-view {
  padding-left: 1.8rem;
  padding-right: 1.7rem; }

.ambrand-index-index .category-description p.title {
  font-size: 1.6rem;
  font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.25em;
  font-weight: 700; }

.ambrand-index-index .category-description p {
  line-height: 1.3em; }

.ambrand-index-index .category-description ul.brand-tags {
  display: block;
  text-transform: uppercase;
  font-size: 1.8rem;
  justify-content: center;
  font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  padding-left: 0;
  text-align: center;
  list-style: none; }
  .ambrand-index-index .category-description ul.brand-tags li {
    line-height: 1.3; }

@media (max-width: 575.98px) {
  .ambrand-index-index .category-description .read-more {
    margin-top: 1.3rem; } }

.brand-listing-guest .page-main {
  max-width: none; }
  @media (max-width: 575.98px) {
    .brand-listing-guest .page-main {
      padding-left: 3.5rem;
      padding-right: 3.5rem; } }

.brand-listing-guest .category-description {
  max-width: 50.9rem;
  margin: 0 auto; }
  @media (max-width: 575.98px) {
    .brand-listing-guest .category-description {
      width: 100%; } }
  .brand-listing-guest .category-description .amshopby-descr {
    text-align: center; }
    .brand-listing-guest .category-description .amshopby-descr ul {
      padding-left: 0; }
  .brand-listing-guest .category-description .brand-cta-block {
    text-align: center; }

.brand-listing-guest .page-title-wrapper {
  padding: 4.5rem; }
  @media (max-width: 575.98px) {
    .brand-listing-guest .page-title-wrapper {
      padding: 2.3em 4.5rem 2rem; } }

.brand-listing-guest .bestseller-wrapper {
  margin-bottom: 12rem; }
  @media (max-width: 575.98px) {
    .brand-listing-guest .bestseller-wrapper {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;
      overflow-x: hidden; }
      .brand-listing-guest .bestseller-wrapper .row {
        margin: 0; }
        .brand-listing-guest .bestseller-wrapper .row > div {
          padding: 0; } }
  @media (max-width: 767.98px) {
    .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper {
      margin-top: 5rem;
      padding: 0 6.1rem; } }
  .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .product-item-details {
    margin-top: 0.5rem; }
  .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .title {
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    text-align: center;
    margin-top: -2.5rem;
    margin-bottom: 3.5rem; }
    @media (max-width: 575.98px) {
      .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .title {
        margin-top: 0; } }
  .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items {
    padding-left: 0; }
    .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .product-item-brand {
      font-size: 1.3rem;
      font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-weight: 700;
      margin: 0; }
    @media (max-width: 767.98px) {
      .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .product-item-photo {
        display: flex;
        justify-content: center; } }
    .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .product-item-name {
      font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.3rem;
      margin: 0; }
    @media (max-width: 575.98px) {
      .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-list {
        overflow: visible; } }
    .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-track {
      display: flex; }
      .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-track .slick-slide {
        margin-right: 3rem; }
        @media (max-width: 767.98px) {
          .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-track .slick-slide {
            margin-right: 0; }
            .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-track .slick-slide > div {
              max-width: 20.2rem;
              margin: 0 auto; } }
    @media (max-width: 767.98px) {
      .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-prev {
        left: -9.5rem; } }
    @media (max-width: 767.98px) {
      .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-next {
        right: -9.5rem; } }
    .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-arrow {
      background: #fff;
      border-radius: 40px;
      height: 5rem;
      width: 5rem;
      top: 40%; }
      @media (max-width: 767.98px) {
        .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-arrow {
          top: 0;
          bottom: 8rem;
          width: 9.6rem;
          height: auto;
          border-radius: 0;
          transform: none;
          background: rgba(255, 255, 255, 0.39); } }
      .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-arrow::before {
        font-size: 2rem;
        color: #000; }
    @media (max-width: 767.98px) {
      .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-dots {
        padding: 3rem 0 3.7rem; }
        .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-dots li button {
          background: #D4CFCC;
          height: 1.2rem;
          width: 1.2rem; }
        .brand-listing-guest .bestseller-wrapper .bestseller-items-wrapper .bestseller-items .slick-dots li.slick-active button {
          background: #837C78; } }

@media (max-width: 575.98px) {
  .catalogsearch-result-index .page-title-wrapper {
    padding: 0 10rem; }
  .catalogsearch-result-index .category-advertising-wrapper .block-static-block {
    padding-bottom: 33.9%; } }

.catalogsearch-result-index .search.results dl.block {
  padding: 1.5rem 2rem;
  margin-bottom: 0; }

.catalogsearch-result-index .message.message.message {
  font-size: 1.3rem; }
  .catalogsearch-result-index .message.message.message .query-text {
    font-weight: 700; }
  .catalogsearch-result-index .message.message.message dl.block {
    margin-top: 2rem; }
    .catalogsearch-result-index .message.message.message dl.block dd {
      margin-bottom: 1rem; }

.account.page-layout-2columns-left.wishlist-index-index .columns {
  position: relative; }

.account.page-layout-2columns-left.wishlist-index-index .sidebar-main {
  padding-right: 2%; }
  @media (max-width: 767.98px) {
    .account.page-layout-2columns-left.wishlist-index-index .sidebar-main {
      order: 0; } }
  @media (max-width: 1023.98px) {
    .account.page-layout-2columns-left.wishlist-index-index .sidebar-main {
      min-height: unset;
      max-width: 50%;
      padding: 0 1.4rem; } }

.account.page-layout-2columns-left.wishlist-index-index .page-main,
.account.page-layout-2columns-left.wishlist-index-index .column.main {
  padding-left: 0;
  padding-right: 0;
  margin-top: 0; }

@media (min-width: 768px) {
  .account.page-layout-2columns-left.wishlist-index-index .column.main {
    width: 100%; } }

@media (min-width: 1024px) {
  .account.page-layout-2columns-left.wishlist-index-index .products-grid .product-item {
    width: 20% !important;
    margin-left: 0 !important; } }

.account.page-layout-2columns-left.wishlist-index-index .page-main {
  padding-top: 0;
  max-width: 132.2rem;
  padding-right: calc(var(--gutter-x) * 0.5);
  padding-left: calc(var(--gutter-x) * 0.5); }

.account.page-layout-2columns-left.wishlist-index-index .page-title-wrapper .page-title {
  margin-right: 0; }

.account.page-layout-2columns-left.wishlist-index-index .toolbar.toolbar-products {
  position: unset; }

.account.page-layout-2columns-left.wishlist-index-index .product-items .product-item:first-child {
  border-top: 0; }

.account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-info .product-item-photo {
  float: none; }

@media (max-width: 767.98px) {
  .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-info .product-image-container {
    max-width: inherit; } }

.account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .product-item-inner {
  z-index: 1; }
  .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .product-item-inner .product-item-actions {
    text-align: center;
    float: none; }
    .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .product-item-inner .product-item-actions .btn-remove {
      font-size: 1.5rem; }
    @media (max-width: 575.98px) {
      .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .product-item-inner .product-item-actions .actions-primary,
      .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .product-item-inner .product-item-actions .action.tocart {
        width: 100%; }
      .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .product-item-inner .product-item-actions .action.delete {
        float: none; } }

.account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .ingredient-icons {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0; }
  .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .ingredient-icons li {
    position: relative;
    z-index: 1;
    margin-bottom: 0.3rem; }
    @media (min-width: 768px) {
      .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .ingredient-icons li {
        margin-bottom: 0.5rem; } }
    .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .ingredient-icons li:not(:last-child) {
      margin-right: 0.44rem; }
      @media (min-width: 1366px) {
        .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .ingredient-icons li:not(:last-child) {
          margin-right: 1.24rem; } }
      @media (min-width: 768px) and (max-width: 1023.98px) {
        .account.page-layout-2columns-left.wishlist-index-index .product-items .product-item .product-item-details .ingredient-icons li:not(:last-child) {
          margin-right: 1.24rem; } }

.account.page-layout-2columns-left .column.main {
  padding-left: 3.7rem;
  padding-right: 7.3rem;
  /* Have replaced breakpoint includes to get this to work at exactly 768px
     * (Sorry future me - we could have changed the Magento breakpoints from the start if we knew)
     */ }
  @media (max-width: 768.98px) {
    .account.page-layout-2columns-left .column.main {
      margin-top: 3rem;
      padding-left: 1.3rem;
      padding-right: 1.5rem; } }

.account.page-layout-2columns-left .page-main {
  max-width: 136.6rem;
  padding-left: 2.5rem;
  color: #2D2926; }

.account.page-layout-2columns-left .sidebar-main {
  padding-right: 3.7rem; }

.account.page-layout-2columns-left .account-title {
  margin: 1.9rem 0 1.5rem;
  border-bottom: 1px solid #e2e2e2; }
  @media (max-width: 768.98px) {
    .account.page-layout-2columns-left .account-title {
      margin: 1.9rem 0 0; } }
  .account.page-layout-2columns-left .account-title h2, .account.page-layout-2columns-left .account-title .h2 {
    margin-bottom: 0.6rem; }

.account.page-layout-2columns-left .page-title {
  text-align: center;
  margin-bottom: 2.2rem; }
  @media (max-width: 768.98px) {
    .account.page-layout-2columns-left .page-title {
      margin: 1.2rem 0 1.8rem 0; } }

@media (max-width: 768.98px) {
  .account.page-layout-2columns-left .block-collapsible-nav {
    top: 0; } }

@media (max-width: 768.98px) {
  .account.page-layout-2columns-left .block-collapsible-nav-title {
    border: 1px solid #D4CFCC;
    margin: 0 3.6rem 0 3.7rem;
    border-radius: 2px;
    padding: 1.2rem 4rem 1.2rem 2rem;
    color: #837C78;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1.55rem center;
    background-size: 1.6rem 1.2rem; }
    .account.page-layout-2columns-left .block-collapsible-nav-title::after {
      content: ''; } }

.account.page-layout-2columns-left .block-collapsible-nav-content {
  min-width: 180px;
  padding: 3.35rem 0 5.2rem 4.1rem;
  background-color: rgba(239, 238, 237, 0.6);
  border-radius: 2px; }
  @media (max-width: 768.98px) {
    .account.page-layout-2columns-left .block-collapsible-nav-content {
      margin: 0 3.6rem 0 3.7rem;
      padding: 1.2rem 4rem 1.2rem 2rem;
      background-color: #F1F0EF; } }
  .account.page-layout-2columns-left .block-collapsible-nav-content h2, .account.page-layout-2columns-left .block-collapsible-nav-content .h2 {
    margin-bottom: 3.4rem; }
    @media (max-width: 768.98px) {
      .account.page-layout-2columns-left .block-collapsible-nav-content h2, .account.page-layout-2columns-left .block-collapsible-nav-content .h2 {
        display: none; } }
  .account.page-layout-2columns-left .block-collapsible-nav-content .nav.items {
    flex-direction: column; }
    .account.page-layout-2columns-left .block-collapsible-nav-content .nav.items .nav.item {
      margin: 0; }
      .account.page-layout-2columns-left .block-collapsible-nav-content .nav.items .nav.item.current > strong,
      .account.page-layout-2columns-left .block-collapsible-nav-content .nav.items .nav.item.current a {
        border-color: transparent; }
      .account.page-layout-2columns-left .block-collapsible-nav-content .nav.items .nav.item a:hover {
        background: transparent;
        text-decoration: underline; }
      .account.page-layout-2columns-left .block-collapsible-nav-content .nav.items .nav.item a,
      .account.page-layout-2columns-left .block-collapsible-nav-content .nav.items .nav.item strong {
        padding-left: 0;
        padding-bottom: 0.4rem;
        color: #2D2926; }
        @media (max-width: 575.98px) {
          .account.page-layout-2columns-left .block-collapsible-nav-content .nav.items .nav.item a,
          .account.page-layout-2columns-left .block-collapsible-nav-content .nav.items .nav.item strong {
            font-size: 1.4rem; } }

.account.page-layout-2columns-left .order-actions-toolbar .actions {
  display: flex;
  justify-content: space-between; }

.wishlist-index-index .message.info.empty {
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.customer-account-logoutsuccess .page-main {
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center; }

.customer-account-logoutsuccess .column.main {
  min-height: 0; }

.customer-account-index .block-dashboard-info .box-content {
  margin: 1.7rem 0 1.5rem 0;
  line-height: 1.6; }

.customer-account-index .account-type {
  margin: 1rem 0 3.8rem 0; }

.customer-account-index .aged-balance {
  padding-left: 0;
  list-style: none none;
  margin: 2rem 0 4.4rem 0; }
  .customer-account-index .aged-balance .item {
    display: flex;
    max-width: 25rem;
    width: 100%;
    margin-bottom: 2rem; }
    .customer-account-index .aged-balance .item > div {
      flex: 0 0 50%; }

.customer-account-index .box-shipping-address {
  width: 100% !important; }
  .customer-account-index .box-shipping-address .box-content {
    margin: 1rem 0 1.5rem 0; }

.customer-account-index .block-dashboard-orders {
  margin-bottom: 7.6rem; }
  @media (max-width: 575.98px) {
    .customer-account-index .block-dashboard-orders .block-title {
      margin-bottom: 0.5rem !important; } }
  @media (max-width: 575.98px) {
    .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead {
      display: none; } }
  .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead tr th {
    border-bottom: none;
    padding: 0.8rem 1rem 0.8rem 0;
    text-transform: capitalize;
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
  @media (max-width: 575.98px) {
    .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody tr:not(:first-child) {
      border-top: 1px solid #e2e2e2; } }
  .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody tr td {
    padding: 0.8rem 1rem 0.8rem 0; }
    @media (max-width: 575.98px) {
      .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody tr td:not(:last-child) {
        padding: 0.5rem 0 0; }
      .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody tr td::before {
        padding-right: 0.4rem; } }
  .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions {
    display: flex;
    justify-content: flex-start; }
    .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action {
      margin-right: 0;
      flex: 1; }
      .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action .action-link.disabled {
        pointer-events: none;
        user-select: none;
        opacity: 0.875;
        text-decoration: none; }
      .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action.reorder {
        text-align: right; }
    @media (max-width: 575.98px) {
      .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions {
        justify-content: flex-start; }
        .customer-account-index .block-dashboard-orders .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action {
          margin-right: 2rem;
          flex: 0; } }

.customer-account-changepassword .form-edit-account .fieldset.password {
  display: block; }

@media (max-width: 767.98px) {
  .account .column.main .toolbar .limiter-options {
    margin: auto; } }

.data-grid-wrap .list-name-field .cell-label-line-name {
  font-size: inherit; }

.requisition-popup .action-close::before {
  content: ''; }

.requisition-popup .action.action.action {
  height: 50px; }

@media (max-width: 767.98px) {
  .requisition-popup.modal-slide .modal-inner-wrap {
    max-height: 100vh; } }

.sales-order-history .toolbar-products .pages,
.sales-invoice-history .toolbar-products .pages {
  display: block; }

.sales-order-history .table-wrapper,
.sales-invoice-history .table-wrapper {
  margin-bottom: 10rem; }
  @media (max-width: 575.98px) {
    .sales-order-history .table-wrapper,
    .sales-invoice-history .table-wrapper {
      margin-bottom: 5rem; } }
  .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead > tr > th,
  .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead > tr > th {
    border-bottom: none !important;
    text-transform: capitalize;
    font-family: "Articulat Cf", "Basic Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }
    .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead > tr > th.col.id,
    .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead > tr > th.col.id {
      padding-left: 0; }
    .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead > tr > th.col:not(:last-child),
    .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead > tr > th.col:not(:last-child) {
      width: 15%; }
    .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead > tr > th.col:last-child,
    .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) thead > tr > th.col:last-child {
      width: auto; }
  .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr .col.id,
  .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr .col.actions,
  .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr .col.id,
  .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr .col.actions {
    padding-left: 0; }
  .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr .col.actions,
  .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr .col.actions {
    padding-right: 0; }
  @media (max-width: 575.98px) {
    .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr,
    .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr {
      border-bottom: 1px solid #e2e2e2; }
      .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr td:not(:last-child),
      .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr td:not(:last-child) {
        padding: 0.5rem 0 0; }
      .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr td::before,
      .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr td::before {
        padding-right: 0.4rem; } }
  .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr:not(:first-child),
  .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr:not(:first-child) {
    margin-top: 2rem; }
  @media (max-width: 575.98px) {
    .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:first-child,
    .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) tbody > tr > td:first-child {
      padding-top: 0; } }
  .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.track,
  .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.track {
    display: flex;
    flex-direction: column; }
  .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions,
  .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions {
    display: flex;
    justify-content: space-evenly; }
    .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action .action-link.disabled,
    .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action .action-link.disabled {
      pointer-events: none;
      user-select: none;
      opacity: 0.875;
      text-decoration: none; }
    .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action:not(:last-child),
    .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action:not(:last-child) {
      margin-right: 0; }
    @media (max-width: 767.98px) {
      .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions,
      .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions {
        justify-content: flex-start; }
        .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action,
        .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action {
          flex: 0; }
        .sales-order-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action:not(:last-child),
        .sales-invoice-history .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) .col.actions .action:not(:last-child) {
          margin-right: 2rem; } }

@media (max-width: 575.98px) {
  .sales-order-history .toolbar.toolbar-products,
  .sales-invoice-history .toolbar.toolbar-products {
    margin-bottom: 3rem; } }

.sales-order-view .mark,
.sales-order-print .mark,
.sales-order-invoice .mark,
.sales-order-printinvoice .mark {
  background-color: transparent; }

.sales-order-print .order-status,
.sales-order-printinvoice .order-status {
  display: block; }

.sales-order-invoice .order-date {
  margin: 0 0 1.8rem; }
  .sales-order-invoice .order-date .label {
    padding-left: 0; }
