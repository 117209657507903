@font-face {
  font-family: 'Articulat Cf';
  src:
    url('../fonts/articulatcf-demibold-webfont.woff2') format('woff2'),
    url('../fonts/articulatcf-demibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat Cf';
  src:
    url('../fonts/articulatcf-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/articulatcf-extrabold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat Cf';
  src:
    url('../fonts/articulatcf-bold-webfont.woff2') format('woff2'),
    url('../fonts/articulatcf-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat Cf';
  src:
    url('../fonts/articulatcf-normal-webfont.woff2') format('woff2'),
    url('../fonts/articulatcf-normal-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basic Sans';
  src:
    url('../fonts/basic-sans.woff2') format('woff2'),
    url('../fonts/basic-sans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Basic Sans';
  src:
    url('../fonts/basic-sans-bold.woff2') format('woff2'),
    url('../fonts/basic-sans-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
