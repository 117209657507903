.footer.content {
  border-top: none;
  max-width: inherit;
  margin-top: 0;
  padding: 0;
  background-color: $dark;

  .newsletter-wrapper {
    display: flex;
    justify-content: center;
    background-color: $secondary;
    margin-bottom: 4rem;

    &.b2c {
      background-color: transparent;

      @include media-breakpoint-up(md) {
        position: relative;
        top: 41px;
        margin-bottom: 0.1rem;
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 3.5rem;
    }

    .block.newsletter {
      max-width: 132.8rem;
      width: 100%;
      display: flex;
      margin-bottom: 0;
      float: none;
      flex-wrap: wrap;

      @include media-breakpoint-down(md) {
        padding: 2.3rem 3.4rem 3.7rem 3.5rem;
        flex-direction: column;
        align-items: center;

        .content {
          width: 100%;
          margin-bottom: 1rem;
          margin-top: -0.5rem;
        }

        label {
          width: 100%;
        }
      }

      div {
        flex-grow: 1;
        margin: 1rem 0;
      }

      .stay-in-the-know {
        color: $white;
        margin-left: 4rem;
        align-items: center;
        display: flex;
        height: 70px;

        @include media-breakpoint-down(sm) {
          margin: 0;
          height: 64px;

          svg {
            height: 46px;
          }
        }
      }

      .subscribe {

        .control {

          &::before {
            content: '';
          }

          label {
            position: relative;

            &::before {
              position: absolute;
              background-repeat: no-repeat;
              background-size: contain;
              background-image: url('../images/icon-email.svg');
              content: '';
              height: 2.9rem;
              width: 2.9rem;
              top: 1rem;
              left: 2rem;
              font-size: 4rem;
            }

            &::after {
              position: absolute;
              background-repeat: no-repeat;
              background-size: contain;
              background-image: url('../images/icon-arrow-right.svg');
              content: '';
              height: 2.9rem;
              width: 2.9rem;
              top: 1rem;
              right: 2rem;
              font-size: 4rem;
            }
          }

          button {
            position: absolute;
            right: 1rem;
            top: 0;
            width: 5rem;
            height: 100%;
            z-index: 1;
            background: none;
            border: none;

            &:active {
              box-shadow: none;
            }

            i {
              font-size: 2.4rem;
            }
          }
        }

        input[name='email'] {
          border-radius: $border-radius-pill;
          border: 2px solid $light;
          padding: 0 0 0 6rem;
          font-size: 1.5rem;

          &::placeholder {
            color: #a5a5a5;
          }

          @include media-breakpoint-up(md) {
            min-width: 30rem;
          }

          @include media-breakpoint-up(lg) {
            min-width: 40rem;
          }
        }

        #newsletter-error {
          position: absolute;
          color: $white;
          margin: 0;
        }
      }
    }
  }

  .footer-social {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include media-breakpoint-down(md) {
      justify-content: center;
      padding: 0;
      margin: 0 !important;
    }

    p {
      color: $white;
      margin: 0 2rem 0 0;
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;
    }

    a {
      transition: $transition-fade;

      &::before {
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: 2.9rem;
        width: 2.9rem;
        margin-right: 1rem;
        display: inline-block;
        vertical-align: top;

        @include media-breakpoint-down(sm) {
          margin-right: 1.358rem;
        }
      }

      &:last-child::before {
        margin-right: 0;
      }

      &.facebook::before {
        background-image: url('../images/icon-facebook.svg');
      }

      &.instagram::before {
        background-image: url('../images/icon-instagram.svg');
      }

      &.linkedin::before {
        background-image: url('../images/icon-linkedin.svg');
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .footer-wrapper {

    @include media-breakpoint-down(sm) {
      padding: 0 2rem;
    }

    .accordion {

      @include media-breakpoint-up(md) {

        .collapse,
        .collapsing {
          display: block !important;
          height: auto !important;
        }

        .btn-link {
          font-weight: $btn-font-weight;
          text-decoration: none;
          color: $white;
          padding: 0;
          text-align: left;

          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
            cursor: default;
            box-shadow: none !important;
          }

          svg {
            display: none;
          }
        }
      }

      @include media-breakpoint-down(md) {

        ul {
          margin: -0.5rem 0 0 0.3rem;
          padding-bottom: 1rem;

          li {
            margin: 0.85rem 0 !important;
          }
        }

        .btn-link {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 0.2rem;
          padding: 1.8rem 0 1.8rem 0;
          font-weight: $btn-font-weight;
          text-decoration: none;
          color: $white;

          &:focus {
            box-shadow: none;
          }

          svg {
            margin-top: -1rem;

            &:last-child {
              display: none;
            }
          }

          &:not(.collapsed) svg {
            display: none;

            &:last-child {
              display: block;
            }
          }
        }
      }
    }

    .footer-links {

      @include media-breakpoint-down(sm) {
        padding-left: 2rem;
        padding-right: 0.5rem;
      }

      h2 button {
        font-family: $headings-font-family;
        font-size: 1.6rem;
      }

      ul {

        li {
          margin: 1rem 0;

          a {
            font-size: 1.3rem;
            text-decoration: none;
            color: $white;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .bottom-footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 3rem 0;

      @include media-breakpoint-down(sm) {
        padding: 3.5rem 0 5.097rem;
        flex-direction: column;
        align-items: flex-start;

        .acknowledgement {
          max-width: 33.8rem;
          width: 100%;
          text-align: left;
          margin-top: 2.8rem;
        }
      }
    }

    .copyright {
      margin-top: 0;

      span {
        color: $white;
        font-size: 1.3rem;
        font-weight: 700;
      }
    }

    .acknowledgement {
      width: 42.2rem;
      text-align: right;
      line-height: 1.7;
    }

    .acknowledgement,
    .footer-note {
      font-size: 1.3rem;
      color: $white;
    }
  }

  @include media-breakpoint-down(md) {

    .newsletter-wrapper.b2c {
      margin-bottom: 4.5rem;

      .block.newsletter {
        padding: 2.61rem 0 2.148rem;
      }
    }

    .footer-wrapper.b2c {

      .bottom-footer {
        padding: 5rem 0 8.897rem;
      }
    }
  }
}
