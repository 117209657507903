%offcanvas-css-vars {
  // scss-docs-start offcanvas-css-vars
  --#{$prefix}offcanvas-width: #{$offcanvas-horizontal-width};
  --#{$prefix}offcanvas-height: #{$offcanvas-vertical-height};
  --#{$prefix}offcanvas-padding-x: #{$offcanvas-padding-x};
  --#{$prefix}offcanvas-padding-y: #{$offcanvas-padding-y};
  --#{$prefix}offcanvas-color: #{$offcanvas-color};
  --#{$prefix}offcanvas-bg: #{$offcanvas-bg-color};
  --#{$prefix}offcanvas-border-width: #{$offcanvas-border-width};
  --#{$prefix}offcanvas-border-color: #{$offcanvas-border-color};
  --#{$prefix}offcanvas-box-shadow: #{$offcanvas-box-shadow};
  // scss-docs-end offcanvas-css-vars
}

.page-products .columns {

  @include media-breakpoint-down(lg) {
    position: unset;
  }
}

.am_shopby_apply_filters {
  transform: translateX(100%);
}

.catalog-category-view,
.catalogsearch-result-index,
.ambrand-index-index,
.wishlist-index-index,
.myproducts-index-view {
  @extend %offcanvas-css-vars;

  .columns {
    display: flex;

    .main {
      padding-left: 0;
      width: 100%;
    }
  }

  @include media-breakpoint-down(lg) {

    .page-main {
      padding-left: 0;
      padding-right: 0;
    }

    .message.message {
      margin: 0 $spacer 2rem;
    }
  }

  .page-title-wrapper {
    text-align: center;
  }

  .block.filter {
    margin: 4.4rem 0 4.4rem;
  }

  .filter-mobile {
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
    }
  }

  .show-more {
    display: none;
  }

  #amasty-shopby-product-list .toolbar {

    @include media-breakpoint-down(lg) {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      padding: 0 1.4rem 0 1.4rem;
    }
  }

  #amasty-shopby-product-list .filter-title {

    @include media-breakpoint-down(lg) {
      flex: 50%;
      display: flex;
      padding-right: 0.7rem;
    }
  }

  .filter-mobile .refine-control {
    position: absolute;
    top: 1rem;
    align-items: center;
    background-color: #fff;
    border: 1px solid $border-color;
    border-radius: 0.3rem;
    color: $gray-700;
    display: inline-flex;
    font-size: 1.4rem;
    font-weight: 400;
    max-height: 5rem;
    width: 100%;
    padding: 1.7rem 1.9rem;
    text-align: center;
    text-transform: unset;
  }

  .sidebar-main {
    min-height: 13.8rem;
  }

  @include media-breakpoint-down(lg) {

    .sidebar-main {
      min-height: unset;
      max-width: 50%;
      padding: 0 1.4rem;
    }

    .filter-mobile {
      position: relative;
      padding-right: 1.4rem;
    }

    #amasty-shopby-product-list {

      .top-toolbar {
        max-width: 50%;
        margin-left: auto;
      }

      .product-items {
        justify-content: center;
      }
    }
  }

  .sorter .dropdown-toggle {

    @include media-breakpoint-down(lg) {
      min-width: unset;
    }
  }

  #amasty-shopby-product-list .filter-title strong {
    font-weight: 400;
  }

  #amasty-shopby-product-list .toolbar-sorter {

    @include media-breakpoint-down(lg) {
      flex: 50%;
      padding: 0 0 0 0.7rem;
      position: unset;
    }
  }

  .block-content .filter-content {
    width: 100%;
    height: 100%;
  }

  .apply-holder {
    display: none;
  }

  .filter-modal-header {
    display: none;
  }

  #filterContainer {

    @include media-breakpoint-down(lg) {
      top: 0;
      right: 0;
      width: var(--offcanvas-width);
      border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      transform: translateX(100%);
      margin: 0;
    }
  }

  #filterContainer.active {

    @include media-breakpoint-down(lg) {
      background: $white;
      position: fixed;
      margin: 0;
      z-index: 1025;
      display: flex;
      flex-direction: column;
      max-width: 100%;
      height: 100%;
      width: 100%;
      outline: 0;
      transition: transform 0.3s ease-in-out;
      top: 0;
      right: 0;
      border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
      visibility: visible;
      transform: none;
    }

    .filter-content {
      display: flex;
      flex-direction: column;
      padding: 0;
      overflow-y: auto;
    }

    .show-more {
      display: block;
      cursor: pointer;
    }

    .filter-options {
      display: block;
      flex-grow: 1;
      padding: 0;
    }

    .filter-options-content {
      margin: 0;
      padding: 2.5rem;
      border-bottom: $border-color solid 1px;

      @include media-breakpoint-down(lg) {
        padding-left: 2.8rem;
        padding-right: 2.8rem;
      }
    }

    .filter-options-content.fold .item {
      margin-bottom: 1.9rem;
    }

    .filter-options-content.fold .item:nth-of-type(1n+7) {
      display: none;
    }

    .filter-options-content.fold.up .item:nth-of-type(1n+7) {
      display: flex;
    }

    .amshopby-items {
      display: flex;
      flex-wrap: wrap;
    }

    .amshopby-item {
      margin-bottom: 1rem;
    }

    .filter-options-content .dropdown-toggle {
      border: none;
      font-weight: 700;
      padding: 0 0 2.5rem 0;
      line-height: 1.5rem;
      font-family: $headings-font-family;
      font-size: 1.5rem;
      color: $dark;
      pointer-events: none;
    }

    .filter-options-content .dropdown-toggle::after {
      display: none;
    }

    .filter-options-content .dropdown-menu {
      position: unset;
      border: none;
      box-shadow: unset;
      padding: 0;
      margin: 0;
      display: block;
    }

    .filter-options .am-labels-folding .item {
      padding-left: 0;
    }

    .filter-options-content .items.-am-multiselect .item {

      .items-children.-folding.-folding.-folding.-folding.-folding {
        padding-left: 2rem;
      }

      [class*='am-filter-item'] {
        padding-left: 2rem;
        line-height: 2.3rem;
      }
    }

    .filter-content .filter-state {
      display: flex;
      padding: 2.5rem 2.5rem 0;
      flex-direction: column-reverse;
      margin: 0;
    }

    .filter-actions {
      width: 100%;
      margin: 0 0 1.5rem 0;
    }

    .filter-current {
      width: 100%;
    }

    .apply-holder {
      position: fixed;
      z-index: 9;
      bottom: 0;
      width: 100%;
      padding: 1.5rem 2.5rem;
      background: rgba($white, 0.57);
      display: block;

      @include media-breakpoint-down(lg) {
        padding-left: 2.8rem;
        padding-right: 2.8rem;
      }
    }

    .filter-modal-header {
      background-color: $primary;
      color: #fff;
      font-family: $headings-font-family;
      font-size: 2.4rem;
      line-height: 1.6;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.6rem 2.5rem;

      @include media-breakpoint-down(lg) {
        padding-left: 2.8rem;
        padding-right: 2.8rem;
      }
    }
  }

  .filter-content .filter-state {

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  .filter-options {
    display: flex;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    .filter-options-content {
      border-radius: unset;
      box-shadow: none;
      margin-bottom: 0;
      position: unset;

      &:not(:last-child) {
        margin-right: 2.2rem;
      }
    }
  }

  .filter-state {
    display: flex;
    align-items: center;
    margin-top: 2rem;

    .amshopby-filter-current {
      display: inline-block;

      .amshopby-items {
        display: inline-flex;
        padding: 0;

        .amshopby-item {
          background-color: $gray-100;
          border-radius: 10rem;
          margin-bottom: 0;
          padding: 0.46rem 3.5rem 0.46rem 1.5rem;

          &:not(:last-child) {
            margin-right: 1rem;
          }

          .amshopby-remove {
            height: 100%;
            width: 100%;
            left: -0.5rem;

            &::before,
            &::after {
              background-color: $dark;
              height: 1px;
              position: absolute;
              width: 12px;
              right: 11px;
            }
          }

          .amshopby-filter-value {
            font-size: 1.3rem;
          }

          &:hover {
            background-color: $dark;
            color: $white;

            .amshopby-remove {

              &::before,
              &::after {
                background-color: $white;
              }
            }
          }
        }
      }
    }

    .filter-actions {
      margin-bottom: 0;
      margin-left: 1rem;

      .filter-clear {
        color: $dark;
      }
    }
  }

  .dropdown-toggle {
    position: relative;
    align-items: center;
    background-color: $form-select-bg;
    border: $border-width $border-style $border-color;
    border-radius: 0.3rem;
    color: $input-placeholder-color;
    display: inline-flex;
    font-size: 1.4rem;
    font-weight: normal;
    max-height: 5rem;
    min-width: 18.2rem;
    padding: 1.7rem 1.9rem;
    text-align: left;
    text-transform: unset;
    width: 100%;

    &::after {
      content: '';
      background-image: url('../images/icon-chevron-down.svg');
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      width: 2.4rem;
      right: 0;
      height: 2.4rem;
      margin-right: 1rem;
    }

    &.show {
      border: $border-width $border-style $dark;
      color: $dark;

      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    box-shadow: $box-shadow-sm;
    color: $list-group-action-color;
    max-width: 29rem;
    padding: 2.5rem;
    width: 100%;

    .dropdown-item {
      padding: 0;

      &:hover {
        color: $dark;
        text-decoration: underline;
        background-color: unset;
      }
    }

    &:not(.sort-options) {
      padding: 3.3rem 4.2rem 2.5rem 3.8rem;
    }

    .item {
      display: flex;
      opacity: 1;

      &:not(:last-child) {
        margin-bottom: 1.9rem;
      }

      .items {

        .item {
          margin-top: 1.9rem;
        }
      }

      [data-am-js='filter-item-default'],
      .amshopby-filter-parent {
        text-decoration: unset;
        padding-left: 3.5rem !important;
      }

      .label,
      .count {
        font-size: 1.3rem;
        font-weight: normal;
        padding-left: 0;

        @include media-breakpoint-down(lg) {
          font-size: 1.5rem;
        }
      }

      .amshopby-filter-parent,
      .sort-desc,
      .sort-asc {

        .label,
        .count {
          margin-left: 0;
        }
      }

      .amshopby-filter-parent .label {
        top: 1px;
        display: block;
        transform: none;
        padding-right: 0;
      }

      [type='checkbox'] {

        + .amshopby-choice {
          top: 0;

          &:hover {

            &::before {
              border-color: $dark;
            }
          }

          &::before {
            border-radius: 0.4rem;
            height: 2.4rem;
            width: 2.4rem;
            border-color: $dark;

            &:not(.-empty-value) {
              border-color: $dark;
            }
          }

          &::after {
            background-color: $dark;
            background-image:
              escape-svg(
                $form-check-input-checked-bg-image
              );
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 2.4rem;
            width: 2.4rem;
            border-radius: 0.4rem;
          }
        }
      }
    }
  }

  .products {
    margin: 0.9rem 0 3rem;
  }

  .top-toolbar {

    .toolbar.toolbar-products {
      margin-bottom: 0;
    }
  }

  .product-items {
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;

    .product-item {
      display: flex;
      flex-direction: column;
      position: relative;
      z-index: 0;
      margin-bottom: 1.5rem;
      max-width: 25rem;

      .product-item-photo {
        max-width: 100%;
      }

      @include media-breakpoint-only(xl) {
        margin-right: 0.8rem;

        &:nth-child(5n) {
          margin-right: 0;
        }
      }

      @include media-breakpoint-down(md) {
        width: 50%;
        padding: 2.5rem 1.15rem 2rem;
        margin-left: 0;
        margin-bottom: 0;
        border-bottom: 2px solid #d3cfcc;

        &:nth-child(odd) {
          border-right: 2px solid #d3cfcc;
        }

        &:first-child,
        &:nth-last-child(2):nth-child(odd),
        &:last-of-type:nth-of-type(odd) {
          border-right: 0;

          &::after {
            border-right-width: 2px;
            border-right-style: solid;
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1;
          }
        }

        &:nth-last-child(2):nth-child(odd),
        &:last-of-type:nth-of-type(odd) {

          &::after {
            border-image:
              linear-gradient(
                to bottom,
                #d3cfcc,
                rgba(0, 0, 0, 0)
              )
              1 100%;
          }
        }

        &:first-child::after {
          border-image:
            linear-gradient(
              to top,
              #d3cfcc,
              rgba(0, 0, 0, 0)
            )
            1 100%;
        }

        &:last-child:first-child::after {
          background:
            linear-gradient(
              0deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(211, 207, 204, 1) 25%,
              rgba(211, 207, 204, 1) 75%,
              rgba(255, 255, 255, 0) 100%
            );
          width: 1px;
          right: 0;
          left: 100%;
          border: none;
        }

        &:last-child,
        &:nth-last-child(2):nth-child(odd) {
          border-bottom: 0;
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;

        @include media-breakpoint-down(lg) {
          content: none;
        }
      }

      &:hover::before {
        border: 2px solid #ededed;
      }

      .product-item-info {
        position: relative;
        padding: 1.5rem 1.9rem;
        width: auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        @include media-breakpoint-down(xl) {
          padding: 1rem;
        }

        @include media-breakpoint-down(lg) {
          padding: 2rem 1.1rem 1.5rem;
        }
      }

      @include media-breakpoint-down(lg) {

        &:nth-last-child(n+2):nth-last-child(-n+2):first-child,
        &:first-child + li:last-child {

          &::after {
            background:
              linear-gradient(
                0deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(211, 207, 204, 1) 25%,
                rgba(211, 207, 204, 1) 75%,
                rgba(255, 255, 255, 0) 100%
              );
            width: 1px;
            left: 0;
            right: 100%;
            border: none;
            content: '';
            position: absolute;
            height: 100%;
          }
        }
      }
    }

    .product-image-container {
      position: relative;
      max-width: inherit;
      margin: 0 auto 0;

      @include media-breakpoint-down(md) {
        position: initial;
        margin-top: 0;
        width: 100%;

        .product-item-photo {
          max-width: 12.8rem;
          display: block;
          margin: auto;
        }
      }

      .actions-secondary {
        position: absolute;
        right: 1rem;
        bottom: 2rem;
        z-index: 1;

        @include media-breakpoint-down(md) {
          bottom: 2rem;

          .action.towishlist {
            padding: 0.88rem;
          }
        }
      }

      .product-item-badge {
        text-align: center;
        position: absolute;
        top: 0;
        z-index: 1;

        + .deal,
        + .clearance,
        + .new {
          top: 3rem;
        }

        &.no-gwp {
          top: 0;
        }

        @include media-breakpoint-down(md) {
          top: 2rem;

          &.deal,
          &.clearance,
          &.new {
            top: 5rem;
          }

          &.no-gwp {
            top: 2rem;
          }
        }

        span {
          font-size: 1.2rem;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }
      }

      .product-label {
        background-color: $secondary;
        color: $white;
        font-size: 1.2rem;
        text-transform: uppercase;
        z-index: 1;
        border-radius: 2px;
        padding: 0.2rem 2.32rem;
        border: 1px solid $secondary;

        @include media-breakpoint-down(md) {
          padding: 0.2rem 1.32rem;
        }
      }

      .deal {
        background-color: $secondary;
        width: 6.5rem;
        border-radius: 0.2rem;

        span {
          color: $white;
        }
      }

      .clearance {
        background-color: $white;
        border: 1px solid $secondary;
        width: 10.2rem;
        border-radius: 0.2rem;

        span {
          color: $secondary;
          font-weight: $headings-font-weight;
        }
      }

      .new {
        right: 0;

        @include media-breakpoint-down(md) {
          right: 0.5rem;
          top: 2rem;
        }
      }

      .unavailable {
        left: 0;
        right: 0;
        top: -0.6rem;

        @include media-breakpoint-down(lg) {
          top: 0;
        }

        span {
          color: $dark;
        }
      }
    }

    .product-item-details {
      margin-top: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      font-family: $headings-font-family;

      .product-item-sku {
        font-size: 1.3rem;
      }

      .product-item-brand {
        font-size: 1.3rem;
        font-weight: $headings-font-weight;
        text-transform: uppercase;
        margin-bottom: 0;
        z-index: 999;

        @include media-breakpoint-down(lg) {
          font-size: 1.2rem;
        }

        a {
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      .product-item-name {
        margin: 0 0 auto;

        @include media-breakpoint-down(lg) {
          font-size: 1.3rem;
        }

        .product-item-link {

          &:hover {
            text-decoration: none;
          }

          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            @include media-breakpoint-down(lg) {
              content: none;
            }
          }
        }
      }

      .amshopby-option-link {
        display: none;
      }

      .price-section,
      .price-container-wrapper {
        width: 100%;
      }

      .price-box,
      .price-container-wrapper {
        clear: both;
        display: flex;
        flex-wrap: wrap;
        margin: 0.25rem 0 0;
        align-items: center;

        .price-container {
          line-height: 1;
        }

        .old-price {
          text-decoration: none;
          margin-left: auto;

          .price {
            font-weight: normal;
            text-decoration: line-through;
          }
        }

        .price-wrapper {

          .price {
            font-size: 2.2rem;

            @include media-breakpoint-down(xl) {
              font-size: 2rem;
            }

            @include media-breakpoint-down(lg) {
              font-size: 1.6rem;
            }
          }

          &.regular-price {
            display: flex;
            align-items: center;

            span {
              font-weight: $headings-font-weight;
            }
          }
        }

        em {
          font-size: 1rem;
          font-weight: $font-weight-normal;
          font-style: normal;
          margin-left: 0.4rem;

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }

        .special-price {
          display: flex;
          align-items: center;
          color: $secondary;
        }

        .rrp-price {
          flex-basis: 100%;
          font-size: 1.5rem;
          font-family: $headings-font-family;

          .gst {
            color: $dark;
            font-style: normal;
          }
        }
      }
    }
  }

  .category-view {
    margin: 0.5rem 0;

    .category-description {
      text-align: center;
      max-width: 73rem;
      margin: 0 auto;

      .read-more-link {
        text-decoration: none;
        padding-right: 1rem;

        &:hover {
          text-decoration: underline;
        }

        &:not(.collapsed) img {
          transform: rotate(180deg);
        }

        img {
          margin: 0 0 0.5rem 1rem;
        }
      }
    }
  }
}

.wishlist-index-index {

  @include media-breakpoint-down(md) {

    .product-items {
      max-width: 550px;
      margin-left: auto;
      margin-right: auto;

      .product-item-details .product-item-inner .action.tocart {
        width: 100%;
      }
    }
  }
}

.page-products .column.main {

  @include media-breakpoint-up(md) {
    width: 100%;
  }
}

.page-products .products-grid .product-item {

  @include media-breakpoint-up(lg) {
    width: 20% !important;
    margin-left: 0 !important;
  }
}

.amblog-related-grid .product-item,
.page-products .products-grid .product-item {

  .ingredient-icons {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      position: relative;
      z-index: 1;
      margin-bottom: 0.3rem;

      @include media-breakpoint-up(md) {
        margin-bottom: 0.5rem;
      }

      &:not(:last-child) {
        margin-right: 0.44rem;

        @include media-breakpoint-only(xl) {
          margin-right: 1.24rem;
        }

        @include media-breakpoint-only(md) {
          margin-right: 1.24rem;
        }
      }
    }
  }
}

.category-advertising-wrapper {
  max-width: 192rem;
  margin: 0 auto;
  width: 100%;

  .block-static-block {
    position: relative;

    .promo-banner {
      height: 0;
      padding-bottom: 38.8%;
      margin-bottom: 0;
      background-position: center !important;

      @media (min-width: 641px) {
        padding-bottom: 18%;
      }

      > div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-position: center !important;
      }
    }

    img {
      width: 100%;
    }

    [data-content-type='button-item'],
    .pagebuilder-button-primary {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
