.actions-secondary,
.product-addto-links {

  .action.towishlist,
  .action.remove {

    &:hover {
      opacity: 0.7;
    }

    svg {
      width: 2rem;
      height: 1.8rem;
    }
  }

  .action.remove {

    &.in-wishlist {

      svg path {
        fill: currentColor;
      }
    }
  }
}
