body {
  font-size: 1.5rem;
}

.navigation, .breadcrumb, .page-header .header.panel, .header.content, .footer.content, .page-wrapper > .widget, .page-wrapper > .page-bottom, .block.category.event, .top-container, .page-main {  /* stylelint-disable-line */
  @extend .container;
}

.page-layout-cms-full-width .page-main,
[data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
  max-width: 1920px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.promo-banner {

  .slick-initialized {
    min-height: 0 !important;
  }

  .row-full-width-inner,
  .slick-initialized,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div,
  [data-content-type='slide'],
  [data-element='link'],
  .pagebuilder-slide-wrapper {
    height: inherit;
  }

  [data-element='link'] {
    display: block;
  }
}

[data-content-type='row'][data-appearance='full-width'] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

[data-content-type='row'][data-appearance='contained'] {
  max-width: map-get($container-max-widths, 'xl');

  [data-element='inner'] {
    margin: 0;
    padding: 0 $spacer;
  }
}

::selection {
  background-color: $primary;
  color: $white;
}

.column.main.main {
  padding-bottom: 0;
}

.loader-container {
  bottom: 0;
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($white, 0.3);

  .spinner-lg {
    width: 4rem;
    height: 4rem;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}
