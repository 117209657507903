.ambrand-index-index {

  @include media-breakpoint-down(sm) {

    .page-title-wrapper {

      .page-title {
        margin-bottom: 1.5rem;
      }
    }
  }

  .category-advertising-wrapper {

    .category-image {
      position: relative;
      height: 0;
      padding-bottom: 18%;
      margin-bottom: 0;

      &.mobile-image {
        padding-bottom: 38.7%;
      }

      .image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  .category-view {
    padding-left: 1.8rem;
    padding-right: 1.7rem;
  }

  .category-description {

    p.title {
      font-size: 1.6rem;
      font-family: $headings-font-family;
      line-height: 1.25em;
      font-weight: 700;
    }

    p {
      line-height: 1.3em;
    }

    ul.brand-tags {
      display: block;
      text-transform: uppercase;
      font-size: 1.8rem;
      justify-content: center;
      font-family: $headings-font-family;
      font-weight: $headings-font-weight;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      padding-left: 0;
      text-align: center;
      list-style: none;

      li {
        line-height: 1.3;
      }
    }

    .read-more {

      @include media-breakpoint-down(sm) {
        margin-top: 1.3rem;
      }
    }
  }
}

.brand-listing-guest {

  .page-main {
    max-width: none;

    @include media-breakpoint-down(sm) {
      padding-left: 3.5rem;
      padding-right: 3.5rem;
    }
  }

  .category-description {
    max-width: 50.9rem;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    .amshopby-descr {
      text-align: center;

      ul {
        padding-left: 0;
      }
    }

    .brand-cta-block {
      text-align: center;
    }
  }

  .page-title-wrapper {
    padding: 4.5rem;

    @include media-breakpoint-down(sm) {
      padding: 2.3em 4.5rem 2rem;
    }
  }

  .bestseller-wrapper {
    margin-bottom: 12rem;

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 0;
      overflow-x: hidden;

      .row {
        margin: 0;

        > div {
          padding: 0;
        }
      }
    }

    .bestseller-items-wrapper {

      @include media-breakpoint-down(md) {
        margin-top: 5rem;
        padding: 0 6.1rem;
      }

      .product-item-details {
        margin-top: 0.5rem;
      }

      .title {
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        text-align: center;
        margin-top: -2.5rem;
        margin-bottom: 3.5rem;

        @include media-breakpoint-down(sm) {
          margin-top: 0;
        }
      }

      .bestseller-items {
        padding-left: 0;

        .product-item-brand {
          font-size: 1.3rem;
          font-family: $headings-font-family;
          font-weight: $headings-font-weight;
          margin: 0;
        }

        @include media-breakpoint-down(md) {

          .product-item-photo {
            display: flex;
            justify-content: center;
          }
        }

        .product-item-name {
          font-family: $headings-font-family;
          font-size: 1.3rem;
          margin: 0;
        }

        .slick-list {

          @include media-breakpoint-down(sm) {
            overflow: visible;
          }
        }

        .slick-track {
          display: flex;

          .slick-slide {
            margin-right: 3rem;

            @include media-breakpoint-down(md) {
              margin-right: 0;

              > div {
                max-width: 20.2rem;
                margin: 0 auto;
              }
            }
          }
        }

        .slick-prev {

          @include media-breakpoint-down(md) {
            left: -9.5rem;
          }
        }

        .slick-next {

          @include media-breakpoint-down(md) {
            right: -9.5rem;
          }
        }

        .slick-arrow {
          background: $white;
          border-radius: 40px;
          height: 5rem;
          width: 5rem;
          top: 40%;

          @include media-breakpoint-down(md) {
            top: 0;
            bottom: 8rem;
            width: 9.6rem;
            height: auto;
            border-radius: 0;
            transform: none;
            background: rgba($white, 0.39);
          }

          &::before {
            font-size: 2rem;
            color: $black;
          }
        }

        @include media-breakpoint-down(md) {

          .slick-dots {
            padding: 3rem 0 3.7rem;

            li {

              button {
                background: $input-border-color;
                height: 1.2rem;
                width: 1.2rem;
              }

              &.slick-active button {
                background: $input-placeholder-color;
              }
            }
          }
        }
      }
    }
  }
}
