.catalogsearch-result-index {

  @include media-breakpoint-down(sm) {

    .page-title-wrapper {
      padding: 0 10rem;
    }

    .category-advertising-wrapper .block-static-block {
      padding-bottom: 33.9%;
    }
  }

  .search.results {

    dl.block {
      padding: 1.5rem 2rem;
      margin-bottom: 0;
    }
  }

  .message.message.message {
    font-size: 1.3rem;

    .query-text {
      font-weight: 700;
    }

    dl.block {
      margin-top: 2rem;

      dd {
        margin-bottom: 1rem;
      }
    }
  }
}
