.minicart-wrapper {
  display: list-item;

  .block-minicart {
    border: none;
    list-style: none none;
    min-width: unset;
    z-index: 1045;
    display: flex;
    position: fixed;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;

    &::before,
    &::after {
      content: unset;
    }

    [id='minicart-content-wrapper'] {
      height: 100%;

      .offcanvas-header {
        background-color: $green;
        padding: 1.5rem 3.3rem 1.1rem 4.8rem;
        height: 5rem;
        align-items: center;

        .offcanvas-title {
          line-height: 1.12;
        }

        .actions {
          flex-grow: 1;
          text-align: right;

          .action {
            border-radius: 0;
            color: $white;
            font-size: 1.2rem;
          }
        }
      }

      .offcanvas-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-bottom: 5rem;

        .minicart-items-wrapper {
          flex-grow: 1;
          padding: 0;
          height: 100%;
          border: none;
          margin: 0 -2.5rem;
          overflow-x: hidden;

          li.item {

            > .product {
              display: flex;
              position: relative;
              z-index: 0;

              .product-item-photo {
                width: 8rem;
                padding: 0;
                margin-right: 1.1rem;
              }

              .product-item-details {
                padding-left: 0;
                flex-grow: 1;

                .product-item-name {
                  margin-bottom: 2rem;
                  font-weight: normal;

                  a {
                    padding-left: 0;
                    color: inherit;
                    font-size: 1.5rem;
                    line-height: 1.6;
                  }
                }

                .product-sku,
                .product-brand {
                  font-size: 1.3rem;
                  line-height: 1.7;
                  text-transform: uppercase;
                }

                .product-brand {
                  font-weight: bold;
                }

                .product-item-pricing {
                  display: none;
                }

                .actions {
                  float: none;
                  justify-content: space-between;
                  align-items: center;

                  .qty-wrapper {
                    display: flex;

                    .details-qty {
                      position: relative;
                      width: 10.5rem;
                      min-width: 10.5rem;
                      display: flex;
                      margin-top: 0;
                      text-align: left;
                      border: 1px solid #dedbd8;
                      border-radius: 0.2rem;
                      height: 4rem;

                      .btn-qty {
                        display: flex;
                        padding: 0;
                        justify-content: center;
                        align-items: center;
                        min-width: 30%;
                        height: auto;
                        border: none;
                        box-shadow: none;

                        &:hover,
                        &:active {
                          border: none;
                          box-shadow: none;
                          opacity: 0.7;
                        }
                      }

                      .cart-item-display-qty {
                        font-size: 1.5rem;
                        line-height: 1.6;
                        width: 100%;
                        padding: 0;
                        text-align: center;
                      }

                      .cart-item-qty {
                        display: none;
                      }

                      .form-control {
                        border: none;
                      }

                      .spinner-border {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 2;
                        margin: auto;
                        border-color: $secondary;
                        border-right-color: transparent;
                      }

                      &.loading {

                        &::before {
                          content: '';
                          display: block;
                          position: absolute;
                          top: 0;
                          left: 0;
                          right: 0;
                          bottom: 0;
                          background: $white;
                          z-index: 1;
                          opacity: 0.6;
                        }

                        .spinner-border {
                          display: block;
                        }
                      }
                    }

                    .cart-item-uom-qty {
                      margin-left: 2rem;
                      padding: 0.7rem 2.2rem;
                      font-size: 1.5rem;
                      line-height: 1.6;
                    }
                  }

                  .update-cart-item {
                    padding: 0;
                  }

                  .secondary {

                    span {
                      position: relative;
                      width: auto;
                      margin: 0;
                      height: 100%;
                    }
                  }
                }
              }

              .action-delete {
                position: absolute;
                top: 0;
                right: 0;

                .action {
                  text-decoration: underline;

                  &:hover,
                  &:visited {
                    text-decoration: underline;
                  }

                  .delete-label {
                    position: unset;
                    font-size: 1.3rem;
                    line-height: 1.7;
                    margin: 0;
                  }
                }
              }
            }
          }
        }

        .subtotal,
        .tax,
        .total {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 0 0.5rem 0;
          flex-shrink: 0;

          .label {
            margin: 0;
            padding-left: 0;
            font-size: 1.5rem;
            line-height: 1.6;
            font-weight: normal;
            transform: none;

            &::after {
              content: unset;
            }
          }

          .price-wrapper {

            .price {
              font-size: 1.5rem;
              font-weight: normal;
              line-height: 1.6;
            }
          }
        }

        .total {
          margin: 0 0 0.8rem 0;

          .label {
            font-weight: bold;
          }

          .price-wrapper {

            .price {
              font-weight: bold;
            }
          }
        }

        .items-total {
          display: block;
          margin: 2.5rem 0 1.5rem;

          @media (max-height: 820px) {
            margin-top: 0;
          }

          .item,
          .count {
            font-weight: bold;
          }
        }

        .product-item {
          border-top: 1px solid rgba(#707070, 0.15);
          border-bottom: none;
          position: relative;

          &:first-child {
            padding-top: 2rem;
          }

          &:last-child {
            border-bottom: 1px solid rgba(#707070, 0.15);
          }

          .loading-mask {
            position: absolute;

            .loader {
              height: 100%;

              .loader-container {
                position: absolute;
              }
            }
          }
        }

        .actions {
          margin-top: 0.5rem;

          .secondary {
            background-color: $secondary;
            border-radius: 0.3rem;

            .action.viewcart {
              padding-top: 1.3rem;
              padding-bottom: 1.3rem;
              text-decoration: none;
              display: block;
              height: 100%;
              width: 100%;

              .view-cart-label {
                color: $white;
                font-size: 1.4rem;
                line-height: 1;
                letter-spacing: 0.056rem;
                text-transform: uppercase;
                text-decoration: none;
              }
            }
          }

          .btn-continue-shopping {
            position: relative;
            border: none;
            background-color: unset;
            margin-top: 1.5rem;
            letter-spacing: 0;

            &:active {
              box-shadow: unset;
              color: $dark;
            }

            &::after {
              content: ' ';
              background: escape-svg(url('data:image/svg+xml, <svg data-name="icon-arrow2-right 24x24" xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path data-name="Path 10" d="M0 0h24v24H0Z" fill="none"/><g data-name="Group 17703"><path data-name="Line 9" d="m17.762 13.145-13.465-.013a.769.769 0 0 1-.769-.77.769.769 0 0 1 .77-.769l13.464.014a.769.769 0 0 1 .769.77.769.769 0 0 1-.769.768Z"/><path data-name="Path 8287" d="M11.029 19.876a.769.769 0 0 1-.544-1.313l6.188-6.188-6.188-6.188a.77.77 0 0 1 1.088-1.088l6.732 6.732a.769.769 0 0 1 0 1.088l-6.732 6.732a.767.767 0 0 1-.544.225Z"/></g></svg>')) center no-repeat;
              position: absolute;
              bottom: 0;
              right: -3rem;
              width: 3rem;
              height: 3rem;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.minicart-wrapper-mobile {
  display: none;

  .counter.empty {
    display: none;
  }

  .counter-label {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    display: list-item;
  }
}
