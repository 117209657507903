.cms-brands,
.cms-meet-our-brands {

  .brands-gateway-banner-wrapper {
    position: relative;
    height: 0;
    padding-bottom: 18%;
    margin-bottom: 0;

    @include media-breakpoint-down(sm) {
      padding-bottom: 34%;
    }

    .hero-banner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-position: center !important;
    }
  }

  .page-main {
    margin: 0 auto 1rem;
    max-width: 901px;
    padding: 0 2rem;

    @include media-breakpoint-down(sm) {
      padding: 0;

      &.b2c {
        margin: 2.3rem auto 1rem;
      }
    }

    .page-title-wrapper {
      display: flex;
      justify-content: center;

      .page-title {
        margin-bottom: 0;

        .base {
          line-height: 1.125;
        }
      }
    }

    .column.main {
      margin: 1.3rem auto;

      .short-description {
        margin-top: 3.15rem;
        padding: 0 1.6rem;
        text-align: center;
        line-height: 1.6;

        @include media-breakpoint-down(md) {
          margin-top: 2rem;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 1.5rem;
        }
      }

      .top-categories-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 770px;
        margin: 3rem auto 2.8rem;

        @include media-breakpoint-down(sm) {
          margin: 3.5rem 3.6rem 4rem 3.7rem;
        }

        .top-categories {
          display: flex;
          list-style: none;
          margin: 0;
          flex-direction: row;
          justify-content: space-evenly;
          padding: 0;

          .top-category-item {
            height: 50px;
            display: flex;
            align-items: center;
            padding: 1.5rem 2rem;
            border-radius: 0.2rem;
            border: 1px solid #ddd;
            margin: 0 0.6rem 1.4rem;
            background-color: $white;
            text-transform: uppercase;
            font-family: $headings-font-family;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.2;
            color: $primary;
            white-space: nowrap;
            flex-grow: 1;
            justify-content: center;

            &.active,
            &:hover {
              border-color: $primary;
              background-color: $primary;
              color: $white;

              .label {
                color: $white;
                background-color: inherit;
              }
            }
          }

          &:first-child {

            .top-category-item {
              padding: 1.5rem 2.7rem;
            }
          }
        }
      }

      .ambrands-filters-block {
        margin: 2rem 0 4.6rem;

        @include media-breakpoint-down(sm) {
          margin: 2rem 0 3rem;
        }
      }

      .ambrands-letters-filter {
        margin-left: -0.4rem;
        margin-right: -0.4rem;
        max-width: 850px;

        .ambrands-letter {
          width: 50px;
          height: 50px;
          font-size: 1.5rem;
          font-weight: bold;
          font-family: $headings-font-family;
          color: $primary;
          border-radius: 0.2rem;
          border: 1px solid #ddd;
          margin: 0 1rem 1rem 0;

          &:hover,
          &.-active {
            background-color: $primary;
            border: none;
            color: $white;
          }
        }

        .brands-list {
          font-size: 1.4rem;
          color: $gray-700;
          background-image: url('../images/icon-chevron-down.svg');
          background-size: 24px auto;
          margin: 0 1.7rem;

          @include media-breakpoint-down(sm) {
            margin: 0 3.6rem 0 3.7rem;
          }
        }
      }

      .ambrands-letters-list {

        @include media-breakpoint-down(sm) {
          position: relative;
        }

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          background-color: #f8f7f7;
          position: absolute;
          left: 0;
          z-index: -1;
          min-height: 400px;

          @include media-breakpoint-down(sm) {
            height: 100%;
          }
        }

        &.even {

          &::before {
            background-color: $white;
          }

          .ambrands-letter {
            padding: 3rem 8rem;

            @include media-breakpoint-down(md) {
              padding: 3rem 4.7rem;
            }

            @include media-breakpoint-down(sm) {
              padding: 3rem 5.7rem;
            }

            .ambrands-content {
              margin-bottom: 1rem;

              .ambrands-brand-item {
                flex-grow: 1;
                max-width: 33%;
              }
            }
          }
        }

        &:nth-child(even)::before {
          background-color: $white;
        }

        &[style*='none'] ~ .ambrands-letters-list::before {
          background-color: #f8f7f7;
        }

        .ambrands-letter {
          margin: 0 auto;
          max-width: 862px;
          padding: 3rem 3rem 6.6rem;
          width: 100%;

          @include media-breakpoint-down(md) {
            padding: 3rem 4.7rem;
          }

          @include media-breakpoint-down(sm) {
            min-height: auto;
          }

          .ambrands-title {
            margin-bottom: 1.5rem;
            font-size: 2rem;
            position: relative;

            @include media-breakpoint-down(md) {
              margin-bottom: 3.2rem;
            }
          }

          .ambrands-content {
            display: grid;
            grid-auto-flow: dense;
            grid-auto-columns: 33% 33% 33%;

            @include media-breakpoint-down(md) {
              display: flex;
            }

            .grid-1 {
              grid-column: 1;
            }

            .grid-2 {
              grid-column: 2;
            }

            .grid-3 {
              grid-column: 3;
            }

            .ambrands-brand-item {
              margin: 0;
              text-align: left;
              max-width: 100% !important;

              @include media-breakpoint-down(md) {
                max-width: 100%;
                margin-bottom: 0.5rem;
              }

              .ambrands-inner {
                display: inline-block;
                border: none;
                border-radius: unset;
                box-shadow: unset;
                background-color: transparent;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }

                &.no-link:hover {
                  text-decoration: none;
                }

                .ambrands-label {
                  font-size: 1.5rem;
                  font-weight: normal;
                  padding: 0 10px;
                  display: flex;

                  @include media-breakpoint-up(md) {
                    line-height: 23px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .page-footer {
    position: relative;
  }
}

.mbrands-wrapper,
.top-categories-wrapper {
  scroll-margin-top: 20rem;
}

.cms-meet-our-brands .page-main {
  margin: 4.8rem auto 1rem;
}
