.shipping-offer-message {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 0 -2.5rem;
  padding: 2.1rem 2.5rem;
  border: 1px solid #efeeed;
  border-radius: 0.3rem;

  @media (max-height: 820px) {

    .block-minicart & {
      display: none;
    }
  }

  .message.message {
    align-self: center;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: 0.8rem;
    align-items: baseline;
    width: 100%;
    max-width: 46rem;
    border: none;
    border-radius: 0;

    .text {
      color: $dark;
      font-size: 1.5rem;
      line-height: 1.6;
    }
  }

  .bar-wrapper {
    align-self: center;
    max-width: 46rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    .bar {
      display: flex;
      height: 0.4rem;
      width: 100%;

      .ordered {
        height: 100%;
        background-color: $green-700;
      }

      .distance {
        height: 100%;
        background-color: $green-100;
      }
    }

    .range {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .min,
      .max {
        font-size: 1.3rem;
        line-height: 1.7;
      }
    }
  }
}
