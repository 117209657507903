@mixin btn() {
  // scss-docs-start btn-css-vars
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-family: #{$btn-font-family};

  @include rfs($btn-font-size, --#{$prefix}btn-font-size);

  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$body-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
  --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
  --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), 0.5);
  // scss-docs-end btn-css-vars

  display: inline-block;
  padding: var(--#{$prefix}btn-padding-y) var(--#{$prefix}btn-padding-x);
  font-family: var(--#{$prefix}btn-font-family);

  @include font-size(var(--#{$prefix}btn-font-size));

  font-weight: var(--#{$prefix}btn-font-weight);
  line-height: var(--#{$prefix}btn-line-height);
  color: var(--#{$prefix}btn-color);
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  border: var(--#{$prefix}btn-border-width) solid var(--#{$prefix}btn-border-color);

  @include border-radius(var(--#{$prefix}btn-border-radius));
  @include gradient-bg(var(--#{$prefix}btn-bg));
  @include box-shadow(var(--#{$prefix}btn-box-shadow));
  @include transition($btn-transition);

  &:hover {
    color: var(--#{$prefix}btn-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
  }

  .btn-check:focus + &,
  &:focus {
    color: var(--#{$prefix}btn-hover-color);

    @include gradient-bg(var(--#{$prefix}btn-hover-bg));

    border-color: var(--#{$prefix}btn-hover-border-color);
    outline: 0;

    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
    }
    @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.show {
    color: var(--#{$prefix}btn-active-color);
    background-color: var(--#{$prefix}btn-active-bg);
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-active-border-color);

    @include box-shadow(var(--#{$prefix}btn-active-shadow));

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: var(--#{$prefix}btn-active-shadow), var(--#{$prefix}btn-focus-box-shadow);
      }
      @else {
        box-shadow: var(--#{$prefix}btn-focus-box-shadow);
      }
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: var(--#{$prefix}btn-disabled-color);
    pointer-events: none;
    background-color: var(--#{$prefix}btn-disabled-bg);
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-disabled-border-color);
    opacity: var(--#{$prefix}btn-disabled-opacity);

    @include box-shadow(none);
  }
}

.btn {
  height: 50px;
  line-height: 1.55;

  &.btn {
    border-width: $btn-border-width;

    &:not(.btn-link) {
      text-transform: uppercase;
      letter-spacing: 0.056rem;
    }
  }

  &:focus-visible {
    outline: none;
  }
}

.btn-sm {
  height: 30px;
  line-height: 1.4;
}

.btn-primary {

  @include button-variant(
    $primary,
    $primary,
    $color: $white,
    $hover-background: $primary-hover,
    $hover-border: $primary-hover,
    $hover-color: $gray-900,
    $active-background: $primary-hover,
    $active-border: $primary,
    $active-color: $dark,
    $disabled-background: $gray-100,
    $disabled-border: $gray-100,
    $disabled-color: $gray-500
  );
}

.btn-secondary {

  @include button-variant(
    $secondary,
    $secondary,
    $color: $white,
    $hover-background: $secondary-hover,
    $hover-border: $secondary-hover,
    $hover-color: $dark,
    $active-background: $secondary-hover,
    $active-border: $secondary,
    $disabled-background: $gray-100,
    $disabled-border: $gray-100,
    $disabled-color: $gray-500
  );
}

.btn-dark {

  @include button-variant(
    $dark,
    $dark,
    $hover-background: $dark-hover,
    $hover-border: $dark-hover,
    $active-background: $dark-hover,
    $active-border: $dark,
    $disabled-background: $gray-100,
    $disabled-border: $gray-100,
    $disabled-color: $gray-500
  );
}

.action {

  &::before {
    display: none !important;
  }

  &.primary,
  &.secondary {
    text-transform: uppercase;
    border-radius: $btn-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: $btn-border-width;
    border-style: solid;
    padding: $btn-padding-y $btn-padding-x;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    font-size: $btn-font-size;
    line-height: 1.3;
    color: $white;

    &:focus {
      box-shadow: $input-btn-focus-box-shadow !important;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &.clear,
  &.back,
  &.link {
    background: none;
    border: none;
    text-decoration: underline;
    font-weight: normal;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &.continue,
  &.update {
    padding-left: $btn-padding-x;
    padding-right: $btn-padding-x;
  }
}

.btn-close {
  background-size: 32px 32px;

  &:hover {
    border: none;
  }
}

.action.primary,
.action-primary {

  @include btn();
  @include button-variant(
    $primary,
    $primary,
    $color: $white,
    $hover-background: $primary-hover,
    $hover-border: $primary-hover,
    $hover-color: $gray-900,
    $active-background: $primary-hover,
    $active-border: $primary,
    $active-color: $dark,
    $disabled-background: $gray-100,
    $disabled-border: $gray-100,
    $disabled-color: $gray-500
  );

  text-transform: uppercase;
}

.action.secondary,
.action-secondary {

  @include btn();
  @include button-variant(
    $secondary,
    $secondary,
    $color: $white,
    $hover-background: $secondary-hover,
    $hover-border: $secondary-hover,
    $hover-color: $dark,
    $active-background: $secondary-hover,
    $active-border: $secondary,
    $disabled-background: $gray-100,
    $disabled-border: $gray-100,
    $disabled-color: $gray-500
  );

  text-transform: uppercase;
}

.btn-outline-primary {
  color: $dark;
}
