.toolbar.toolbar-products {
  margin-bottom: 10rem;

  .sorter {
    position: absolute;
    right: 0;
    top: 4rem;
    z-index: 1;
  }

  .pages {
    float: none !important;
    max-width: 100%;

    .pages-items {
      display: flex;
      justify-content: center;

      li {
        margin-left: 1.6rem;

        @include media-breakpoint-down(xl) {
          margin-left: 0.8rem;
        }

        &.item {
          display: block;
          height: 2.4rem;
          width: 2.4rem;
          border-radius: 3rem;
          color: $white;

          a {
            text-decoration: none;
          }
        }

        &.item:hover {
          background-color: $secondary;

          a {
            color: $white;
          }
        }

        &.current {
          background-color: $secondary;
        }
      }
    }
  }
}

.cart-products-toolbar,
.order-pager-wrapper {
  margin-bottom: 10rem;

  .pager {
    display: flex;
    justify-content: space-between;

    .toolbar-amount {
      float: none !important;
    }

    .pages {
      float: none !important;
      margin: 0 0 1.5rem;

      .pages-items {
        display: flex;
        justify-content: flex-end;

        li {
          margin-left: 1.6rem;

          @include media-breakpoint-down(xl) {
            margin-left: 0.8rem;
          }

          &.item {
            display: block;
            height: 2.4rem;
            width: 2.4rem;
            border-radius: 3rem;
            color: $white;
            text-align: center;

            a {
              text-decoration: none;
            }
          }

          &.item:hover {
            background-color: $secondary;

            a {
              color: $white;
            }
          }

          &.current {
            background-color: $secondary;
          }
        }
      }
    }
  }
}
