.amsearch-products-section.-grid {
  padding: 3rem 0 0;
  display: block;

  @include media-breakpoint-down(sm) {
    border-top: none;
    padding: 3rem 0 0;
  }

  .amsearch-product-list {
    max-width: 120rem;
    width: 100%;
    margin: 0 auto;
    gap: 0 3.5rem;
    justify-content: center;

    .amsearch-item {
      max-width: 24rem;
      width: 100%;
      padding: 0 0 2.8rem;
      justify-content: center;

      @include media-breakpoint-down(sm) {
        justify-content: flex-start;
        padding: 0 0 1rem 1rem;
        max-width: 100%;

        &:nth-of-type(1n+5) {
          display: none;
        }
      }

      &:hover {
        background: transparent;
      }

      .amsearch-image {
        max-width: 8.7rem;
        display: block;
        padding-bottom: 0;
      }

      .product-item-details {
        max-width: 13.9rem;
        width: 100%;
        padding: 0.7rem 0 0 1rem;

        @include media-breakpoint-down(sm) {
          max-width: 25rem;
        }

        .product-item-link {
          font-size: 1.3rem;
          color: $black;
          line-height: 1.3;

          .amsearch-highlight {
            color: $black;
            font-weight: $font-weight-normal;
          }
        }

        .product-item-brand {
          font-family: $headings-font-family;
          font-weight: 700;
          font-size: 0.9rem;
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}

.view-all {
  padding: 2rem 0 3rem;

  @include media-breakpoint-down(sm) {
    padding: 0 0 2.9rem 2.7rem;
  }

  .amsearch-link {
    color: $black;
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      justify-content: flex-start;
    }

    img {
      margin-left: 1rem;
    }
  }
}

.amsearch-result-section {
  flex-direction: column;
  flex-wrap: wrap;

  &::before {
    box-shadow: inset 0 3px 6px rgba($black, 0.06);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1.2rem;
    pointer-events: none;
  }

  @include media-breakpoint-down(sm) {
    max-height: 70vh;
    flex-direction: initial;
  }

  .results-for {
    text-align: center;
    margin-top: 2.5rem;
    font-family: $headings-font-family;

    @include media-breakpoint-down(sm) {
      text-align: left;
      margin: 2.3rem 0 0 2rem;
    }

    .search-string {
      font-weight: $headings-font-weight;
    }

    .ellipsis {
      font-weight: $headings-font-weight;
    }

    span:first-child {
      margin-right: 0.5rem;
    }
  }

  .close-search {
    position: absolute;
    right: 4rem;

    @include media-breakpoint-down(sm) {
      right: -1rem;
    }

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  .amsearch-message-block {
    background: transparent;
  }
}
