.account.page-layout-2columns-left {

  .column.main {
    padding-left: 3.7rem;
    padding-right: 7.3rem;

    /* Have replaced breakpoint includes to get this to work at exactly 768px
     * (Sorry future me - we could have changed the Magento breakpoints from the start if we knew)
     */
    @media (max-width: 768.98px) {
      margin-top: 3rem;
      padding-left: 1.3rem;
      padding-right: 1.5rem;
    }
  }

  .page-main {
    max-width: 136.6rem;
    padding-left: 2.5rem;
    color: $dark;
  }

  .sidebar-main {
    padding-right: 3.7rem;
  }

  .account-title {
    margin: 1.9rem 0 1.5rem;

    @media (max-width: 768.98px) {
      margin: 1.9rem 0 0;
    }

    border-bottom: 1px solid #e2e2e2;

    h2 {
      margin-bottom: 0.6rem;
    }
  }

  .page-title {
    text-align: center;
    margin-bottom: 2.2rem;

    @media (max-width: 768.98px) {
      margin: 1.2rem 0 1.8rem 0;
    }
  }

  @media (max-width: 768.98px) {

    .block-collapsible-nav {
      top: 0;
    }
  }

  @media (max-width: 768.98px) {

    .block-collapsible-nav-title {
      border: 1px solid $gray-500;
      margin: 0 3.6rem 0 3.7rem;
      border-radius: 2px;
      padding: 1.2rem 4rem 1.2rem 2rem;
      color: $gray-700;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right 1.55rem center;
      background-size: 1.6rem 1.2rem;

      &::after {
        content: '';
      }
    }
  }

  .block-collapsible-nav-content {
    min-width: 180px;
    padding: 3.35rem 0 5.2rem 4.1rem;
    background-color: rgba(#efeeed, 0.6);
    border-radius: 2px;

    @media (max-width: 768.98px) {
      margin: 0 3.6rem 0 3.7rem;
      padding: 1.2rem 4rem 1.2rem 2rem;
      background-color: $light;
    }

    h2 {
      margin-bottom: 3.4rem;

      @media (max-width: 768.98px) {
        display: none;
      }
    }

    .nav.items {
      flex-direction: column;

      .nav.item {
        margin: 0;

        &.current {

          > strong,
          a {
            border-color: transparent;
          }
        }

        a:hover {
          background: transparent;
          text-decoration: underline;
        }

        a,
        strong {
          padding-left: 0;
          padding-bottom: 0.4rem;
          color: $dark;

          @include media-breakpoint-down(sm) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .order-actions-toolbar {

    .actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.wishlist-index-index .message.info.empty {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.customer-account-logoutsuccess {

  .page-main {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;
  }

  .column.main {
    min-height: 0;
  }
}
