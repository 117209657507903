.customer-account-index {

  .block-dashboard-info {

    .box-content {
      margin: 1.7rem 0 1.5rem 0;
      line-height: 1.6;
    }
  }

  .account-type {
    margin: 1rem 0 3.8rem 0;
  }

  .aged-balance {
    padding-left: 0;
    list-style: none none;
    margin: 2rem 0 4.4rem 0;

    .item {
      display: flex;
      max-width: 25rem;
      width: 100%;
      margin-bottom: 2rem;

      & > div {
        flex: 0 0 50%;
      }
    }
  }

  .box-shipping-address {
    width: 100% !important;

    .box-content {
      margin: 1rem 0 1.5rem 0;
    }
  }

  .block-dashboard-orders {
    margin-bottom: 7.6rem;

    @include media-breakpoint-down(sm) {

      .block-title {
        margin-bottom: 0.5rem !important;
      }
    }

    .table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) {

      thead {

        @include media-breakpoint-down(sm) {
          display: none;
        }

        tr th {
          border-bottom: none;
          padding: 0.8rem 1rem 0.8rem 0;
          text-transform: capitalize;
          font-family: $headings-font-family;
        }
      }

      tbody {

        tr {

          @include media-breakpoint-down(sm) {

            &:not(:first-child) {
              border-top: 1px solid #e2e2e2;
            }
          }

          td {
            padding: 0.8rem 1rem 0.8rem 0;

            @include media-breakpoint-down(sm) {

              &:not(:last-child) {
                padding: 0.5rem 0 0;
              }

              &::before {
                padding-right: 0.4rem;
              }
            }
          }
        }
      }

      .col.actions {
        display: flex;
        justify-content: flex-start;

        .action {
          margin-right: 0;
          flex: 1;

          .action-link.disabled {
            pointer-events: none;
            user-select: none;
            opacity: $btn-close-disabled-opacity;
            text-decoration: none;
          }

          &.reorder {
            text-align: right;
          }
        }

        @include media-breakpoint-down(sm) {
          justify-content: flex-start;

          .action {
            margin-right: 2rem;
            flex: 0;
          }
        }
      }
    }
  }
}

.customer-account-changepassword {

  .form-edit-account {

    .fieldset.password {
      display: block;
    }
  }
}

.account .column.main .toolbar .limiter-options {

  @include media-breakpoint-down(md) {
    margin: auto;
  }
}

.data-grid-wrap .list-name-field .cell-label-line-name {
  font-size: inherit;
}

.requisition-popup {

  .action-close::before {
    content: '';
  }

  .action.action.action {
    height: 50px;
  }

  &.modal-slide .modal-inner-wrap {

    @include media-breakpoint-down(md) {
      max-height: 100vh;
    }
  }
}
