.account.page-layout-2columns-left.wishlist-index-index {

  .columns {
    position: relative;
  }

  .sidebar-main {
    padding-right: 2%;

    @include media-breakpoint-down(md) {
      order: 0;
    }

    @include media-breakpoint-down(lg) {
      min-height: unset;
      max-width: 50%;
      padding: 0 1.4rem;
    }
  }

  .page-main,
  .column.main {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {

    .column.main {
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {

    .products-grid .product-item {
      width: 20% !important;
      margin-left: 0 !important;
    }
  }

  .page-main {
    padding-top: 0;
    max-width: 132.2rem;
    padding-right: calc(var(--#{$prefix}gutter-x) * 0.5);
    padding-left: calc(var(--#{$prefix}gutter-x) * 0.5);
  }

  .page-title-wrapper .page-title {
    margin-right: 0;
  }

  .toolbar.toolbar-products {
    position: unset;
  }

  .product-items {

    .product-item {

      &:first-child {
        border-top: 0;
      }

      .product-item-info {

        .product-item-photo {
          float: none;
        }

        @include media-breakpoint-down(md) {

          .product-image-container {
            max-width: inherit;
          }
        }
      }

      .product-item-details {

        .product-item-inner {
          z-index: 1;

          .product-item-actions {
            text-align: center;
            float: none;

            .btn-remove {
              font-size: 1.5rem;
            }

            @include media-breakpoint-down(sm) {

              .actions-primary,
              .action.tocart {
                width: 100%;
              }

              .action.delete {
                float: none;
              }
            }
          }
        }

        .ingredient-icons {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          li {
            position: relative;
            z-index: 1;
            margin-bottom: 0.3rem;

            @include media-breakpoint-up(md) {
              margin-bottom: 0.5rem;
            }

            &:not(:last-child) {
              margin-right: 0.44rem;

              @include media-breakpoint-only(xl) {
                margin-right: 1.24rem;
              }

              @include media-breakpoint-only(md) {
                margin-right: 1.24rem;
              }
            }
          }
        }
      }
    }
  }
}
