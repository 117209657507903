@include media-breakpoint-up(lg) {

  .nav-sections {
    background-color: $white;
    z-index: auto;
    margin-bottom: 0;

    .level-top.level-top {
      color: $body-color;
    }

    .nav-sections-items {
      display: flex;
    }

    .nav-sections-item-content {
      display: flex !important;
      width: 100%;
      z-index: auto;
    }

    .nav-sections-item-content:last-child {
      display: none !important;
    }

    .navigation {
      background-color: $white;
      font-weight: $font-weight-normal;
      display: flex;
      padding: 0 0.5rem;
      max-height: 20rem;
      z-index: auto;
      position: static;

      @include media-breakpoint-only(lg) {
        max-width: none;
        padding: 0;
      }

      > .navigation-items {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0;
        text-align: center;
        position: static;

        .level0 {

          &.parent {
            position: unset;

            .category-content {
              background: $white;
              display: flex;
              min-width: 230px;
              margin-left: 0;
              margin-right: 0;
              position: absolute;
              padding: 3rem 0 3rem;
              left: 0;
              right: 0;
              visibility: hidden;
              opacity: 0;
              z-index: -1;
              border: 1px solid rgba(#707070, 0.25);

              @include media-breakpoint-up(xl) {
                padding: 3.8rem calc((100% - 1317px) / 2) 3.2rem;
              }
            }

            .level0.submenu {
              right: 0;
              border: none;
              box-shadow: none;
              position: unset;

              .level1 {
                max-width: 100%;
                break-inside: avoid;

                &.force-break {
                  break-before: column;
                }
              }

              [href] {
                text-decoration: none;
                color: $dark;
                font-size: 1.5rem;
                font-weight: 700;

                &:hover {
                  background-color: unset;
                  text-decoration: underline;
                }
              }
            }

            .level1.submenu {
              right: 0;

              [href] {
                font-weight: normal;
                padding: 0;
              }
            }

            &::after {
              background-color: $modal-backdrop-bg;
              opacity: 0;
              visibility: hidden;
              content: '';
              display: block;
              height: 100%;
              left: auto;
              pointer-events: none;
              position: fixed;
              right: 0;
              top: 0;
              width: 100%;
              z-index: -3;
            }

            &.show {

              .category-content {
                font-family: $headings-font-family;
                justify-content: space-between;
                visibility: visible;
                opacity: 1;
                transition: $transition-base;
              }

              .category-extra {
                width: 23.5%;
                border: 1px solid #f2f2f2;
                height: fit-content;
                margin-right: 2rem;

                .category-image {
                  padding: 0;
                  position: relative;

                  figure {
                    margin-bottom: 0;
                  }
                }

                .pagebuilder-mobile-hidden.pagebuilder-mobile-hidden {
                  width: 100%;
                }

                [data-content-type=row] {

                  .category-info {
                    padding: 0;

                    h1 {
                      line-height: 1.08 !important;
                      font-size: 2.6rem;
                      margin-bottom: 0;
                    }

                    h1 + p {
                      margin-top: 1rem;
                    }

                    p {
                      font-family: $font-family-sans-serif;
                      margin-bottom: 0;
                      font-size: 1.3rem;
                      line-height: 1.69 !important;
                    }

                    .shop-now-btn {
                      margin: 1.5rem 0 2.5rem;

                      .pagebuilder-button-secondary {
                        height: 3rem;
                        width: 11.9rem;
                        padding: 0.7rem 0 !important;
                        line-height: 1.1 !important;

                        @include btn();
                        @include button-variant(
                          $dark,
                          $dark,
                          $hover-background: $dark-hover,
                          $hover-border: $dark-hover,
                          $active-background: $dark-hover,
                          $active-border: $dark,
                          $disabled-background: $gray-100,
                          $disabled-border: $gray-100,
                          $disabled-color: $gray-500
                        );
                      }
                    }
                  }
                }
              }

              .level0.submenu {
                display: block;
                column-count: 5;
                gap: 0;
                width: 76.5%;

                &.snacks,
                &.home,
                &.naturalremedies {
                  column-count: 4;
                  width: 60%;
                }

                &.ecoliving,
                &.superfoods,
                &.sportsnutrition,
                &.display {
                  column-count: 3;
                  width: 46%;
                }

                .level1 {

                  &.all-category {
                    margin-top: 1rem;
                  }

                  [href] {
                    color: $dark;
                  }

                  &.highlighted {
                    margin-bottom: 1.4rem;

                    [href] {
                      color: $secondary;
                      padding: 0.3rem 2rem;
                      line-height: 1;
                    }

                    +.non-highlighted {
                      break-before: column;
                    }
                  }

                  &.submenu {
                    font-family: $font-family-sans-serif;
                    position: unset;
                    display: block;
                    border: none;
                    box-shadow: none;
                    padding-left: 2rem;
                    padding-bottom: 1rem;
                    min-width: 100%;
                  }
                }
              }

              &::after {
                display: block;
                opacity: $modal-backdrop-opacity;
                visibility: visible;
                transition: $transition-base;
              }
            }
          }

          &.active,
          &:hover,
          &.show {

            > .level-top {
              color: $secondary;
              box-shadow: inset 0 -3px 0 $secondary;
            }
          }

          > .level-top {
            border: none;
            font-family: $headings-font-family;
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 40px;
            padding-right: 1.2rem;
          }

          .ui-icon-caret-1-e.ui-icon-caret-1-e.ui-icon-caret-1-e {
            display: none;
          }

          .submenu {
            text-align: left;
            font-weight: $font-weight-normal;
          }
        }
      }
    }
  }
}

.navigation {

  @include media-breakpoint-up(md) {

    .level0 .submenu .active a {
      border: none;
    }
  }

  @include media-breakpoint-up(sm) {
    max-width: 132.2rem;
  }

  @include media-breakpoint-up(md) {

    .menu-cta {
      padding: 0;
    }
  }

  @include media-breakpoint-down(md) {

    a,
    a:hover {
      font-size: 1.5rem;
      color: $dark;
    }
  }
}

@include media-breakpoint-down(lg) {

  .nav-sections {
    left: -100%;
    width: 100%;
    background: $white;

    .ui-menu-item,
    .category-item,
    .all-category {

      &.parent {

        > [href]::after {
          content: '';
          mask-image: url(../images/icon-chevron-down.svg);
          background-color: $dark;
          background-position: center;
          background-repeat: no-repeat;
          position: absolute;
          top: auto;
          width: 2.4rem;
          right: 1rem;
          height: 2.4rem;
          margin-right: 1rem;
        }
      }

      &.open,
      &:hover,
      &:active {

        > [href] {
          background-color: $secondary;
          color: $white !important;

          &::after {
            background-color: $white;
          }
        }
      }
    }

    .section-header {
      display: flex;
      align-items: center;
      margin: 1.9rem 0 0.7rem;

      .btn.btn-close {
        width: 2rem;
        height: 2rem;
        padding: 0.6rem;
        margin-left: 1.1rem;
        margin-right: 1.1rem;

        &.b2c {
          margin-top: 3rem;
        }

        > span {
          border: 0;
          clip: rect(0, 0, 0, 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
      }

      .customer-section {
        display: flex;
        align-items: flex-start;
        margin-left: 0.7rem;

        .svg-container {
          display: flex;
          align-items: center;
          padding: 0.6rem;
          width: 4.4rem;
          height: 4.4rem;
        }

        .welcome {
          display: flex;
          padding: 1rem 0.6rem 0;
          align-items: center;
          font-size: 1.1rem;
          line-height: 1.27;

          > p {
            margin-bottom: unset;
            max-width: 22.4rem;
            padding-right: 2.4rem;
          }
        }
      }
    }

    .nav-sections-item-title {
      display: none;
    }

    .nav-sections-item-content {
      margin-top: 1.5rem;
      padding-top: 0;

      .parent {
        flex: 1;

        > .submenu,
        > .category-content {
          display: none;
          overflow: hidden;
          max-height: 0;
        }

        &.open {

          > [href]::after {
            transform: scale(1, -1);
          }

          > .submenu,
          > .category-content {
            display: flex !important;
            flex-direction: column;
            max-height: 300rem;
            transition: max-height 0.5s linear;
          }
        }

        [href] {
          position: relative;
          text-transform: none;
        }
      }

      .level0,
      .level1 {

        &:hover,
        &:active {
          background-color: unset;

          > [href] {
            color: $white;
            background-color: $secondary;
          }

          &::after {
            background-color: $white;
          }
        }
      }

      .header-menu-cta-wrapper {
        background-color: $white;

        .menu-cta {

          > [data-content-type='html'] {
            width: 100%;
            display: flex;
            flex-direction: column;

            .submenu {
              display: none;
            }

            .level0 {
              border-top: unset;
              margin-bottom: 0.8rem;
              display: block;

              > [href] {
                padding-top: 1.6rem;
                padding-left: 7rem;
                padding-bottom: 1.6rem;
              }

              &.parent {
                border: none;
                margin-bottom: 0;

                > .submenu {
                  flex-direction: column;

                  .level1 {
                    padding-left: 0;
                    margin-bottom: 1rem;

                    &:first-child {
                      margin-top: 0.5rem;
                    }

                    &:last-child {
                      border-bottom: 1px solid rgba(#707070, 0.2);
                      padding-bottom: 2rem;
                      margin-bottom: 0;
                    }

                    &::after {
                      content: unset;
                    }

                    > [href] {
                      font-family: $headings-font-family;
                      padding-left: 7rem;
                      font-size: 1.5rem;
                      line-height: 1.3;
                      font-weight: normal;
                      color: $dark;
                    }
                  }
                }
              }

              &:not(.parent) {

                .submenu {

                  .ui-menu-item {

                    &::after {
                      content: unset;
                    }
                  }
                }
              }
            }
          }

          &.menu-cta-b2c {

            [data-content-type='html'] {

              .level-top:last-child {
                border-bottom: none;
              }
            }
          }
        }

        [href] {
          font-size: 1.8rem;
          line-height: 1.11;
          font-weight: 700;
          text-transform: none;
          color: $secondary;
        }

        .ui-menu {
          justify-content: flex-start;
        }

        .ui-menu-item {
          flex-direction: column;
        }
      }

      .navigation[data-action='navigation'] {
        background-color: $white;
        padding-left: 0;
        padding-right: 0;

        .navigation-items {
          padding-left: 0;

          span {
            font-family: $headings-font-family;
          }

          .level0,
          .highlighted {

            > [href] > span {
              font-weight: 700;
            }
          }

          .level0 {
            border: none;
            margin-bottom: 0.5rem;

            [href] {
              padding-left: 7rem;
            }

            &.active > a:not(.ui-state-active) span:not(.ui-menu-icon),
            &.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon),
            &.show > a:not(.ui-state-active) span:not(.ui-menu-icon), {
              margin-left: 0;
            }
          }
        }

        .all-category {
          font-weight: normal;
        }

        .category-item {

          &.level1 {
            font-weight: normal;
          }

          .category-content {
            margin-bottom: 2.5rem;
            border-bottom: 0.1rem solid rgba(#707070, 0.2);

            .category-extra {
              display: flex;
              max-height: 14rem;
              margin: 2.5rem 3rem 5.5rem;
              border: 0.1rem solid #f2f2f2;

              a {
                padding: 0;
              }

              figure {
                margin: 0;
              }

              .pagebuilder-button-secondary {
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0.3rem auto;
                width: 11.9rem;
                height: 2.8rem;
                background-color: $dark;
                color: $white;
                font-size: 1.2rem;
                line-height: 1;
                font-weight: 700;
                text-decoration: unset;
                text-transform: uppercase;
                border-radius: 0.3rem;
                padding-left: 0 !important;
                padding-right: 0 !important;
                letter-spacing: 0.048rem;

                span {
                  transform: translateY(1px);
                }
              }

              .category-image {
                padding: 0 !important;
                height: 100%;
                overflow: hidden;

                .pagebuilder-mobile-only {
                  height: 14rem !important;
                  object-fit: cover;
                  width: 100%;
                }
              }

              [data-content-type='row'] {
                flex: 1 1 40%;

                h1,
                h2 {
                  margin: 0;
                  font-size: 1.8rem;
                  line-height: 1.125 !important;

                  span {
                    font-size: inherit !important;
                    font-weight: 700;
                  }
                }

                h3 {
                  margin-bottom: 0;
                  line-height: 1.125;
                  font-size: 1.6rem;

                  @include media-breakpoint-only(xs) {
                    line-height: 1.1;
                  }
                }

                p {
                  display: none;
                }
              }

              [data-content-type='row'] + [data-content-type='row'] {
                flex: 1 1 60%;
                display: flex;
                align-items: center;
                justify-content: center;

                .category-info {
                  margin-top: 1.2rem;

                  @include media-breakpoint-only(xs) {
                    padding-left: 0.5rem;
                    padding-right: 0.5rem;
                  }

                  .mt-3 {
                    margin-top: 0.5rem !important;
                  }

                  .action {
                    margin-top: 0.1rem;
                    font-size: 1.2rem;
                    font-weight: 700;
                    line-height: 1.08;
                  }
                }
              }
            }
          }
        }

        .level1.submenu {
          background-color: $light;

          .level2 > [href] {
            padding-left: 9rem;
          }
        }

        .active > a:not(.ui-state-active),
        .has-active > a:not(.ui-state-active),
        .show > a:not(.ui-state-active), {
          border: none;
          padding-left: 7rem !important;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {

  .menu-cta {

    .ui-menu-item {
      min-height: 5rem;

      [href] {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
      }
    }
  }

  .nav-sections {

    .ui-menu-item,
    .category-item {
      min-height: 5rem;

      [href] {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }

    .category-extra {

      .category-image {
        padding: 0 !important;
      }

      [data-content-type='buttons'] > .action {
        margin-top: 1rem;
      }
    }
  }
}
