.messages .btn-close {
  display: none;
}

.message.message.message {
  position: relative;
  margin-bottom: 0.9rem;
  padding: $alert-padding-y $alert-padding-x;
  background-color: $white;
  font-weight: 500;
  font-size: 1.3rem;
  color: $white;
  border-left: 5px solid $success;
  border-radius: 2px;

  &.error,
  &.success,
  &.info,
  &.notice,
  &.warning {

    .btn-close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      display: block;
    }
  }

  &.error {
    background-color: #fce1e2;
    border-left-color: #e82e31;
    color: #e82e31;
  }

  &.success {
    background-color: #cbead6;
    border-left-color: #004d23;
    color: #004d23;
  }

  &.info,
  &.notice {
    background-color: #d3eaff;
    border-left-color: #2680eb;
    color: #2680eb;
  }

  &.warning {
    background-color: #fff1c9;
    border-left-color: #a36e1b;
    color: #a36e1b;
  }

  > *:first-child::before {
    content: '';
    width: auto;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
