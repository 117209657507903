.cms-home-page,
.cms-b2b-home-page {

  .page-wrapper .page-main {
    max-width: none;
    padding: 0;
    margin: 0;

    .page-title-wrapper {

      @include visually-hidden();
    }

    .column.main {
      margin: 0;
      max-width: none;

      .home-blog {
        max-width: 1325px;
        padding: 0 1.5rem;

        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }

      .home-banner {

        [data-content-type='slide'] {
          position: relative;
          height: 0;
          padding-bottom: 35.4%;
        }

        .pagebuilder-column,
        .pagebuilder-overlay {
          min-height: 0 !important;
        }

        .pagebuilder-slide-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-position: center !important;
          min-height: 0 !important;
        }

        .slick-slider {
          min-height: unset !important;
        }

        @media (max-width: 640px) {

          .slick-slider {

            [data-content-type='slide'] {
              padding-bottom: 60.4%;

              .pagebuilder-slide-wrapper {
                min-height: unset !important;
              }
            }
          }
        }
      }
    }
  }
}

.cms-home-page {

  .home-shop-our-brands {
    margin-top: 3rem !important;

    h2 {
      margin-bottom: 1rem;
      font-size: 2.4rem;
    }

    .pagebuilder-column {
      margin: 0 1rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .home-view-all-brands {
    padding: 1rem 2rem 3rem;

    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &::after {
        content: '';
        display: inline-block;
        background-image: url('../images/icon-arrow-right.svg');
        background-size: 24px 24px;
        background-repeat: no-repeat;
        width: 2.4rem;
        height: 2.4rem;
        margin-left: 0.5rem;
      }
    }
  }

  .home-featured-on-the-blog {
    margin-top: 3rem !important;

    h2 {
      margin-bottom: 2rem;
      font-size: 2.4rem;
    }
  }

  .home-specialisation-container {
    padding: 6rem 6rem 3rem !important;

    h2 {
      display: none;
    }

    .svg-container {
      margin-bottom: 1rem;
      height: 47.5px;
      display: flex;
      align-items: center;

      svg {
        fill: var(--body-color);
        margin: auto;

        @include media-breakpoint-down(sm) {
          height: 35px;
        }
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      padding: 0 2rem;

      li {
        text-transform: uppercase;
        font-size: 3rem;
        font-weight: $headings-font-weight;
        font-family: $headings-font-family;

        &:not(:last-child)::after {
          content: '';
          display: inline-block;
          width: 1rem;
          height: 1rem;
          border-radius: 10px;
          background-color: $secondary;
          margin-left: 3.5rem;
          margin-right: 3rem;
          margin-bottom: 0.5rem;
        }

        @include media-breakpoint-down(lg) {
          font-size: 2.5rem;
        }

        @include media-breakpoint-down(sm) {
          font-size: 1.8rem;
          display: inline;

          &:not(:last-child)::after {
            margin-left: 1rem;
            margin-right: 1rem;
            margin-bottom: 0.1rem;
            font-size: 0.4rem;
          }
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 0;
        justify-content: center;
        margin-bottom: 3rem;
      }
    }

    p {
      font-size: 2rem;
      font-weight: $headings-font-weight;
      font-family: $headings-font-family;
      text-align: center;
      line-height: 1.5;
    }

    @include media-breakpoint-down(sm) {
      padding: 4.5rem 3rem 3rem !important;
    }
  }

  .home-brands-container {
    margin-left: auto;
    margin-right: auto;
    background-color: $green-300;
    padding: 6rem 0 7rem !important;

    .b2c-brands {
      max-width: 132.2rem;
      width: 100%;
      margin: 0 auto;
      padding: 0 4rem;

      .slick-track {
        gap: 0;

        .slick-slide {
          line-height: 0;

          .b2c-brand {
            overflow: hidden;
          }

          .slick-item-img {
            transition: 0.5s all ease-in-out;

            &:hover {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .pagebuilder-column-group {
      justify-content: center;

      .pagebuilder-column {
        display: inline-flex !important;
        width: auto !important;
        margin-bottom: -1rem;

        @include media-breakpoint-down(sm) {
          flex-basis: 50%;
        }
      }
    }

    h2 {
      margin-bottom: 0;
      font-size: 3rem;

      @include media-breakpoint-down(sm) {
        font-size: 2.2rem;
        padding: 0 7.5rem;
      }
    }

    [data-content-type='buttons'] {
      margin: 4rem auto 4.5rem;

      &.desktop-only {
        display: block;
      }

      &.mobile-only {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 !important;

        &.desktop-only {
          display: none;
        }

        &.mobile-only {
          display: block;
        }
      }

      a {
        height: 50px;
        text-transform: uppercase;
        letter-spacing: 0.056rem;

        @include btn();
        @include button-variant(
          transparent,
          $dark,
          $color: $dark,
          $hover-background: $dark,
          $hover-border: $dark,
          $hover-color: $white,
          $active-background: $dark,
          $active-border: $dark,
          $disabled-background: $gray-100,
          $disabled-border: $gray-100,
          $disabled-color: $gray-500
        );
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 3.8rem 0 1.8rem !important;

      .b2c-brands {
        padding: 0;
      }

      .slick-initialized.slick-standard {

        .b2c-brand {
          max-width: 16rem;
        }

        .slick-list {
          padding-left: 4.7rem;
        }

        .slick-prev:not(.primary),
        .slick-next:not(.primary) {
          background: rgba($green-300, 0.8);
        }

        .slick-arrow {
          width: 4.7rem;
          top: 43%;
          bottom: -29%;
          height: calc(100% - 5.1rem);
        }

        .slick-prev {
          left: 0;
        }

        .slick-next {
          right: 0;
        }

        .slick-dots {
          margin-top: 0.5rem;
          margin-left: 0;
        }
      }
    }
  }

  .home-business-container {
    padding: 6rem 4rem !important;

    h2 {
      margin-bottom: 0;
      font-size: 3rem;

      & + div {
        width: 60rem;
        margin: 0 auto;
      }
    }

    .pagebuilder-column-group {
      gap: 3.6rem;

      @include media-breakpoint-up(sm) {
        flex-wrap: nowrap;

        .pagebuilder-column {
          width: 50%;
        }
      }
    }

    .pagebuilder-column {
      color: $white;

      &:first-child {
        background-color: #ff8674;
      }

      &:last-child {
        background-color: $primary;
      }

      a {
        height: 50px;
        min-width: 115px;
        letter-spacing: 0.54px;
        text-transform: uppercase;

        @include btn();
        @include button-variant(
          transparent,
          $white,
          $color: $white,
          $hover-background: $white,
          $hover-border: $white,
          $hover-color: $dark,
          $active-background: $white,
          $active-border: $white,
          $active-color: $dark,
          $disabled-background: $gray-100,
          $disabled-border: $gray-100,
          $disabled-color: $gray-500
        );
      }

      div p:last-child {
        margin-top: auto;
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        position: relative;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: left center;

        &:first-child {
          background-image: url('../images/brand-partner-bg.png');
        }

        &:last-child {
          background-image: url('../images/retailer-signup-bg.png');
        }

        figure {
          position: absolute;
          top: 0;
          left: 50%;
          width: 50%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100% !important;
          object-fit: cover;
          object-position: right;
        }

        div {
          width: 50%;
          height: 100%;
          padding: 5.6rem 1.6rem 5.6rem 4.6rem;
          font-weight: 700;
          font-size: 1.7rem;
          line-height: 1.3;
        }

        h3 {
          font-size: 2.4rem;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      padding: 6rem 2.3rem 5.8rem !important;

      h2 {
        font-size: 2.4rem;
        padding: 0 5rem;
      }

      h2 + div {
        font-size: 1.5rem;
        padding: 0 2.8rem;
      }

      .pagebuilder-column-group {
        gap: 2.5rem;
      }

      .pagebuilder-column {

        img {
          width: 100%;
        }

        div {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: 2.6rem 3.6rem 4.1rem;

          p:last-child {
            padding-top: 2rem;
          }

          br {
            display: none;
          }
        }
      }
    }
  }

  .home-unique-container {
    margin-left: auto;
    margin-right: auto;
    background-color: $green-300;
    padding: 6rem 3rem 2rem !important;

    .pagebuilder-column-group {
      max-width: 900px;
      justify-content: space-between;
      margin: 3.5rem auto;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }

      .pagebuilder-column {
        text-align: center;
        max-width: 290px;
        padding: 0 20px;

        [data-content-type='html'] {
          display: flex;
          justify-content: center;
        }

        .inner-wrapper {
          height: 25rem;
          width: 25rem;

          &:hover {

            .main-image {
              display: none;

              + .hover-image {
                display: block !important;
              }
            }
          }

          p {
            width: 22.5rem;
            margin: 1.5rem auto 0;
          }
        }

        .hover-image {
          display: none;
        }

        .we-are-unique-text {
          max-width: 22.5rem;
          width: 100%;
          margin: 1.5rem auto 0;
          padding: 0 1.3rem;
        }

        .we-are-unique-mobile-text {
          display: none;
        }
      }
    }

    h2 {
      font-size: 3rem;
    }

    @include media-breakpoint-down(sm) {
      padding: 6rem 3rem 1rem !important;
      margin-bottom: 4rem;

      .pagebuilder-column-group {
        margin-top: 1.5rem;
        gap: 1.5rem;

        .pagebuilder-column {

          .inner-wrapper {
            max-width: 16rem;
            height: auto !important;
          }

          .we-are-unique-mobile-text {
            display: block;
            margin-top: 1rem;
            font-size: 1.5rem;
          }

          .we-are-unique-text {
            display: none;
          }
        }
      }

      h2 {
        font-size: 2.4rem;
      }
    }
  }

  @include media-breakpoint-down(lg) {

    .home-business-container h2 + div {
      width: auto;
    }
  }
}

.cms-b2b-home-page {

  .breadcrumb {

    @include visually-hidden();
  }

  .page.messages {
    max-width: 132.2rem;
    margin: 0 auto;
  }

  .home-b2b-whats-new {
    padding: 5rem 0 !important;

    @include media-breakpoint-down(sm) {
      padding: 4rem 0 6.6rem !important;
    }

    h2 + div {
      margin-top: 2rem;

      @include media-breakpoint-down(sm) {
        margin-top: 3rem;
      }
    }

    .pagebuilder-column-group {
      justify-content: space-between;
      padding: 0 3.7rem;

      @include media-breakpoint-down(sm) {
        justify-content: center;
      }
    }

    .pagebuilder-column {
      flex-basis: auto;

      @include media-breakpoint-down(sm) {
        width: 16.4rem !important;
      }

      > div {
        max-width: 28rem;
        margin: 0 auto;
      }

      .inner-wrapper {

        &:hover {

          .main-image {
            display: none;

            + .hover-image {
              display: block !important;
            }
          }
        }
      }

      .hover-image {
        display: none;
      }
    }

    .home-b2b-btn {
      margin-top: 1rem;
    }
  }

  .home-b2b-whats-on-deal {
    background-color: $green-300;
    padding: 6rem 3rem 5rem !important;
    max-width: 192rem;
    min-height: 55rem;
    margin: 0 auto;

    h2 + div {
      margin-top: 2.2rem;
    }

    [data-content-type='buttons'] {
      margin: 4rem auto 0;
    }

    .deals {
      padding: 0;

      .slick-track {

        .slick-slide {
          max-width: 28rem;

          &:not(:last-child) {
            margin-right: 2.2rem;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 5rem 0 4rem !important;

      [data-content-type='buttons'] {
        margin-top: 2rem !important;
        margin-bottom: 2rem;
      }

      .slick-track {

        .slick-slide {
          width: 28rem;
        }
      }
    }
  }

  .home-b2b-promo {
    padding: 5rem 0 !important;

    @include media-breakpoint-down(sm) {
      padding: 3rem 0 !important;

      .pagebuilder-column-group {
        padding: 1rem 3rem;
      }
    }

    .pagebuilder-column {
      padding: 1rem 0;

      figure {
        max-width: 63.2rem;
        margin: 0 auto;
      }
    }
  }

  .home-b2b-btn a {
    height: 50px;
    text-transform: uppercase;
    letter-spacing: 0.056rem;

    @include btn();
    @include button-variant(
      transparent,
      $dark,
      $color: $dark,
      $hover-background: $dark,
      $hover-border: $dark,
      $hover-color: $white,
      $active-background: $dark,
      $active-border: $dark,
      $disabled-background: $gray-100,
      $disabled-border: $gray-100,
      $disabled-color: $gray-500
    );
  }

  .home-b2b-shop-our-brands {
    padding: 0 0 5rem 0 !important;

    h2 {
      margin-bottom: 2rem;
    }

    .brands {
      padding: 0 1.5rem;

      .slick-track {

        .slick-slide {
          flex: 0 0 25%;

          .brand {
            display: flex !important;
            position: relative;
            min-width: 17.3rem;
            min-height: 20rem;
            height: 20rem;
            background-color: $light;
            background-position: left top;
            background-size: cover;
            background-repeat: no-repeat;
            background-attachment: scroll;
            padding: 0.5rem;
            background-clip: content-box;

            .brand-url {
              position: relative;
              display: flex;
              flex: 1;

              &:hover {

                .slick-item-img {
                  display: none;
                }

                .slick-item-hover-img {
                  display: block;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .slick-item-img {
                position: absolute;
                max-width: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0.5);

                @include media-breakpoint-down(md) {
                  transform: translate(-50%, -50%) scale(0.6);
                }
              }

              .slick-item-hover-img {
                display: none;
                width: 100%;
              }

              img:not(.slick-item-hover-img) {
                margin: auto;
                object-fit: contain;
                width: 100%;
                height: 100%;
                object-position: center;
                padding: 2.5rem;
              }
            }
          }
        }
      }

      .slick-dots {
        margin-top: 0;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 3rem;

        h2 {
          margin-bottom: 3rem;
        }

        [data-content-type='heading'] {
          margin-bottom: 3rem;
        }

        .slick-track {
          width: auto;

          .slick-slide {

            div:first-child {
              margin-bottom: 1.2rem;
            }

            .brand {
              width: calc((100vw - 7rem) / 2) !important;
              height: 11.9rem;
              min-width: 0;
              min-height: 0;
              padding: 0;
              margin-right: 1.3rem;
              background-clip: content-box;
            }
          }
        }
      }
    }

    .pagebuilder-column-group {
      padding: 1rem 0;
      gap: 0.8rem;

      @include media-breakpoint-down(md) {
        justify-content: space-evenly;
        padding: 1rem 3rem;
      }

      .pagebuilder-column {
        position: relative;
        min-width: 17.3rem;
        min-height: 11.9rem !important;

        @include media-breakpoint-down(md) {

          &:not(:first-child) {
            margin-top: 0;
          }

          flex-basis: 45%;
        }

        figure {
          width: 100%;

          a {
            width: 100%;
            height: 100%;
            display: block;
          }

          img {
            position: absolute;
            transform: translate(-50%, -50%) scale(0.5);
            top: 50%;
            left: 50%;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {

    .home-b2b-shop-our-brands {

      .pagebuilder-column:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }
}

.home-blog.home-blog.home-blog {
  padding: 0;

  @include media-breakpoint-up(md) {
    max-width: 1295px;
    margin: 4.3rem auto 6.3rem;

    .cms-b2b-home-page & {
      margin-bottom: 11rem;
    }
  }

  .amblog-element-block {
    background: none;
    border-radius: 0;
    box-shadow: none;
    border: none;

    @include media-breakpoint-down(sm) {

      .btn-outline-primary {
        width: 14.2rem;
      }
    }
  }

  h2 {
    margin-bottom: 4.5rem;
    text-align: center;
    font-size: 3rem;

    .cms-b2b-home-page & {
      font-size: 2rem;
    }
  }

  .amblog-list {
    padding: 0;

    >.slick-standard:not(.slick-initialized) {
      display: none;
    }
  }

  .post-items {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    justify-content: center;

    .slick-list {
      padding: 0 !important;

      .slick-track {
        gap: 0;
      }

      .slick-slide:first-child {
        margin-left: 0;
      }

      .slick-slide:last-child {
        margin-right: 0;
      }
    }
  }

  .slick-slide {
    height: auto;

    > div {
      display: flex;
      height: 100%;
    }
  }

  .post-item {
    position: relative;
    border: 1px solid $border-color;
    border-radius: $border-radius-sm;
    overflow: hidden;
    text-align: center;

    @include media-breakpoint-down(sm) {
      width: 30rem !important;
    }

    @include media-breakpoint-up(md) {
      width: 25%;
      width: calc(25% - ((1.5rem * 3) / 4));

      + .post-item {
        margin-left: 1.5rem;
      }
    }

    @include media-breakpoint-only(lg) {
      width: calc(25% - ((2rem * 3) / 4));

      + .post-item {
        margin-left: 2rem;
      }
    }

    @include media-breakpoint-only(xl) {
      width: calc(25% - ((2.5rem * 3) / 4));

      + .post-item {
        margin-left: 2.5rem;
      }
    }

    article {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .post-item-details {
      flex-grow: 1;
      padding: 3rem 1.5rem 3.3rem;
      align-items: center;

      @include media-breakpoint-up(lg) {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }

    .amblog-featured-card-bottom {
      margin-top: auto;
    }

    .amblog-headline {
      margin-bottom: 1.8rem;
      line-height: 1.3;
      font-family: $headings-font-family;
      font-size: 1.8rem;

      @include media-breakpoint-only(xl) {
        font-size: 2rem;
      }

      @include media-breakpoint-down(sm) {
        padding: 0 1rem;
        font-size: 2rem;
        line-height: 1.3;
      }

      &:hover {
        color: inherit;
      }
    }

    .btn-primary {
      font-size: 1rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .btn-outline-primary {

    @include media-breakpoint-up(md) {
      margin-top: 3.3rem;
      padding-left: 3.425rem;
      padding-right: 3.425rem;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 2.4rem 0 6.5rem;

    h2 {
      font-size: 2rem;
      margin-bottom: 3.5rem;
    }

    .text-center {
      margin-top: 2.5rem;
    }

    .slick-slide {
      width: 30.3rem !important;
      margin: 0 1.5rem 0 0;

      &:last-child {
        margin: 0;
      }
    }
  }
}
