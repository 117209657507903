.qty-wrapper {
  display: flex;
  margin-bottom: 0.7rem;
  gap: 0.7rem;
  font-size: 1.5rem;
  line-height: 1.6;

  &.out-of-stock {

    .control.qty {
      background-color: $gray-200;
    }
  }

  .control.qty {
    position: relative;
    width: 35%;
    height: 4rem;
    max-width: 35%;
    display: flex;
    margin-top: 0;
    text-align: left;
    border: 1px solid #dedbd8;
    border-radius: 0.2rem;

    .btn-qty {
      width: 33%;
      display: flex;
      padding: 0;
      justify-content: center;
      align-items: center;
      min-width: 30%;
      height: auto;
      border: none;
      box-shadow: none;

      &:hover,
      &:active {
        border: none;
        box-shadow: none;
        opacity: 0.7;
      }

      svg {
        width: 1.2rem;
        height: 1.2rem;
        color: $dark;
      }
    }

    .input-text {
      width: 34%;
      padding: 1.1rem 0;
      text-align: center;
      height: 100%;
      border: none;
    }

    .cart-item-display-qty {
      font-size: 1.5rem;
      line-height: 1.6;
      width: 40%;
      padding: 0;
      text-align: center;
    }

    .cart-item-qty {
      display: none;
    }

    .form-control {
      border: none;
    }
  }

  .control.uom {
    width: 65%;
    height: 4rem;

    .unit-of-measures {
      height: 100%;
      font-size: inherit;
      line-height: inherit;
      padding: 0 0 0 2rem;

      @include media-breakpoint-down(xl) {
        padding: 0 0 0 0.5rem;
        background-position: right 0.5rem center;
      }
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;

    .control.qty,
    .control.uom {
      min-width: 100%;
    }
  }
}
