.block-search {

  @include media-breakpoint-up(lg) {
    float: none;
    max-width: 515px;
    width: 56%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 0;

    @include media-breakpoint-down(xl) {
      margin-left: -0.5rem;
    }

    .block-content {

      &:focus-within {

        form .input-text {
          border-color: $secondary;
        }

        .action.search svg {
          fill: $secondary;
        }
      }

      form .input-text {
        height: 5rem;
        border-radius: $border-radius-pill;
        border-width: 2px;
        padding-left: 2.8rem;
        padding-right: 2.8rem;
        font-size: 1.5rem;

        &::placeholder {
          color: $input-placeholder-color;
        }

        @include media-breakpoint-down(xl) {
          padding-left: 1.6rem;
          padding-right: 1.6rem;
        }
      }

      form .minisearch-clear {
        position: absolute;
        right: 8rem;
        top: 0;
        line-height: 5rem;

        a {
          color: rgba($black, 0.32);
          text-decoration: none;
          font-size: 1.3rem;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .action.search {
      height: 5rem;
      width: 5rem;
      background-color: unset;
      border: 0;
      right: 2.3rem;

      span {
        display: none;
      }

      &:active {
        box-shadow: none;
      }
    }
  }

  @include media-breakpoint-only(lg) {
    max-width: 346px;
  }

  @include media-breakpoint-only(xl) {
    max-width: 515px;
  }

  .control {
    padding-bottom: 0;
  }
}
